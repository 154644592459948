import './AccidentFilters.scss'
import { useEffect, useState } from 'react'
import { Slider } from 'primereact/slider';
import { MultiSelect } from 'primereact/multiselect';
import { useDispatch } from 'react-redux';
import { setAccidentFilters, setFilterIsActive } from '../state/features/elasticSlice';
import { useAppSelector } from '../state/hooks';
import { Button } from 'primereact/button';
import { ActiveButtonState } from '../shared/button-state/ButtonState';
import { setActiveButtonType } from '../state/features/buttonStateSlice';

const kazaSiddetiOptions = [
    { id: 1, name: 'Ölümlü Kaza', value: 'ÖLÜMLÜ' },
    { id: 2, name: 'Yaralanmalı Kaza', value: 'YARALANMALI' },
    { id: 3, name: 'Maddi Hasarlı Kaza', value: 'MADDİ' },
];
const kazaTurleriOptions = [
    { id: 1, name: 'Çok Araçlı', value: 'ÇOK ARAÇLI' },
    { id: 2, name: 'İki Araçlı', value: 'İKİ ARAÇLI' },
    { id: 3, name: 'Tek Araçlı', value: 'TEK ARAÇLI' },
];
const yerlesimYeriOptions = [
    { id: 1, name: 'Yerleşim Yeri Dışı', value: 'YERLEŞİM YERİ DIŞI' },
    { id: 2, name: 'Yerleşim Yeri İçi', value: 'YERLEŞİM YERİ İÇİ' },
];
const yolSinifiOptions = [
    { id: 1, name: 'İki Yönlü Yol', value: 'İKİ YÖNLÜ YOL' },
    { id: 2, name: 'Tek Yönlü Yol', value: 'TEK YÖNLÜ YOL' },
    { id: 3, name: 'Bölünmüş Yol', value: 'BÖLÜNMÜŞ YOL' },
    { id: 4, name: 'Diğer', value: 'DİĞER' },
];
const duseyGeometrikAlanOptions = [
    { id: 1, name: 'Eğimli', value: 'EĞİMLİ' },
    { id: 2, name: 'Eğimsiz', value: 'EĞİMSİZ' },
    { id: 3, name: 'Tehlikeli Eğim', value: 'TEHLİKELİ EĞİM' },
    { id: 4, name: 'Tepe Üstü', value: 'TEPE ÜSTÜ' },
];
const kazaOlusOptions = [
    { id: 1, name: 'Araçtan Cisim Düşmesi', value: 'ARAÇTAN CİSİM DÜŞMESİ' },
    { id: 2, name: 'Araçtan İnsan Düşmesi', value: 'ARAÇTAN İNSAN DÜŞMESİ' },
    { id: 3, name: 'Arkadan Çarpma', value: 'ARKADAN ÇARPMA' },
    { id: 4, name: 'Çoklu Çarpma', value: 'ÇOKLU ÇARPMA' },
    { id: 5, name: 'Devrilme/Savrulma/Takla', value: 'DEVRİLME/SAVRULMA/TAKLA' },
    { id: 6, name: 'Engel/Cisim İle Çarpışma', value: 'ENGEL/CİSİM İLE ÇARPIŞMA' },
    { id: 7, name: 'Hayvana Çarpma', value: 'HAYVANA ÇARPMA' },
    { id: 8, name: 'Karşılıklı Çarpışma', value: 'KARŞILIKLI ÇARPIŞMA' },
    { id: 9, name: 'Park Etmiş Araca Çarpma', value: 'PARK ETMİŞ ARACA ÇARPMA' },
    { id: 10, name: 'Yan Yana Çarpma', value: 'YAN YANA ÇARPIŞMA' },
    { id: 11, name: 'Yandan Çarpışma', value: 'YANDAN ÇARPMA' },
    { id: 12, name: 'Yayaya Çarpma', value: 'YAYAYA ÇARPMA' },
    { id: 13, name: 'Yoldan Çıkma', value: 'YOLDAN ÇIKMA' },
    { id: 14, name: 'Zincirleme Çarpışma', value: 'ZİNCİRLEME ÇARPIŞMA' },
    { id: 15, name: 'Duran Araca Çarpma', value: 'DURAN ARACA ÇARPMA' },
];
// const kazayaKarisanlarOptions = [
//     { id: 1, name: 'Arazi Taşıtı', value: 'ARAZİ TAŞITI' },
//     { id: 2, name: 'At Arabası', value: 'AT ARABASI' },
//     { id: 3, name: 'Özel Amaçlı', value: 'ÖZEL AMAÇLI' },
//     { id: 4, name: 'Belirtilmemiş', value: 'BELİRTİLMEMİŞ' },
//     { id: 5, name: 'Bisiklet', value: 'BİSİKLET' },
//     { id: 6, name: 'Çekici', value: 'ÇEKİCİ' },
//     { id: 7, name: 'Tren', value: 'TREN' },
//     { id: 8, name: 'E-Skuter', value: 'E-SKUTER' },
//     { id: 9, name: 'İş Makinesi', value: 'İŞ MAKİNESİ' },
//     { id: 10, name: 'Kamyon', value: 'KAMYON' },
//     { id: 11, name: 'Kamyonet', value: 'KAMYONET' },
//     { id: 12, name: 'Minibüs', value: 'MİNİBÜS' },
//     { id: 13, name: 'Motorlu Bisiklet', value: 'MOTORLU BİSİKLET' },
//     { id: 14, name: 'Motosiklet', value: 'MOTOSİKLET' },
//     { id: 15, name: 'Otobüs', value: 'OTOBÜS' },
//     { id: 16, name: 'Otomobil', value: 'OTOMOBİL' },
//     { id: 17, name: 'Tanker', value: 'TANKER' },
//     { id: 18, name: 'Traktör', value: 'TRAKTÖR' },
//     { id: 19, name: 'Diğer', value: 'DİĞER' },
// ];
const kavsakTuruOptions = [
    { id: 1, name: 'Beş veya Daha Fazla Yönlü', value: 'BEŞ VEYA DAHA FAZLA YÖNLÜ' },
    { id: 2, name: 'Dönel Kavşak', value: 'DÖNEL KAVŞAK' },
    { id: 3, name: 'Dört Yönlü', value: 'DÖRT YÖNLÜ' },
    { id: 4, name: 'Kavşak Yok', value: 'KAVŞAK YOK' },
    { id: 5, name: 'Üç Yönlü(T)', value: 'ÜÇ YÖNLÜ (T)' },
    { id: 6, name: 'Üç Yönlü(Y)', value: 'ÜÇ YÖNLÜ (Y)' },
    { id: 7, name: 'Diğer Kavşak Çeşidi', value: 'DİĞER KAVŞAK ÇEŞİDİ' },
];
const havaDurumuOptions = [
    { id: 1, name: 'Açık', value: 'AÇIK' },
    { id: 2, name: 'Bulutlu', value: 'BULUTLU' },
    { id: 3, name: 'Yağmur', value: 'YAĞMUR' },
    { id: 4, name: 'Kuvvetli Rüzgar', value: 'KUVVETLİ RÜZGAR' },
    { id: 5, name: 'Kar', value: 'KAR' },
    { id: 6, name: 'Tipi', value: 'TİPİ' },
    { id: 7, name: 'Dolu', value: 'DOLU' },
    { id: 8, name: 'Sis/Duman', value: 'SİS/DUMAN' },
    { id: 9, name: 'Toz/Kum Fırtınası', value: 'TOZ/KUM FIRTINASI' },
    { id: 10, name: 'Sulusepken', value: 'SULUSEPKEN' },
];
const gunDurumuOptions = [
    { id: 1, name: 'Gündüz', value: 'GÜNDÜZ' },
    { id: 2, name: 'Gece', value: 'GECE' },
    { id: 3, name: 'Alacakaranlık', value: 'ALACAKARANLIK' },
];

const AccidentFilters = () => {
    const dispatch = useDispatch();
    const activeFilter = useAppSelector(state => state.elastic.isActive);
    const accidentFilters = useAppSelector(state => state.elastic.accidentFilters);
    const [clearAll, setClearAll] = useState(false)
    const [oluSayisi, setOluSayisi] = useState<any>([0, 50])
    const [yaraliSayisi, setYaraliSayisi] = useState<any>([0, 150])
    const [aracSayisi, setAracSayisi] = useState<any>([0, 80])
    const [filterObj, setFilterObj] = useState<any>({
        kaza_siddet: [],
        olussekli1: [],
        havadurumuid: [],
        gunid: [],
        arackazaturuid: [],
        cinsi: [],
        yerlesimyeriack: [],
        yoltipi: [],
        geoduseyguzergahid: [],
        kavsakid: [],
        olu_say: [],
        // kaza_say: [],
        yarali_say: [],
    });

    const onInputChange = (e: any) => {
        if (e) {
            if (e.target.type === "checkbox") {
                setFilterObj({
                    ...filterObj,
                    [e.target.id]: e.checked
                })
            } else {
                setFilterObj({ ...filterObj, [e.target.id]: e.target.value })
            }
        }
    }

    useEffect(() => {
        let arr: any = []

        Object.entries(filterObj).map((entry, index) => (
            arr.push(entry[1])
        ));

        const isFilterActive = arr.find((b: any) => b.length > 0) ? true : false
        if (isFilterActive) {
            dispatch(setFilterIsActive(isFilterActive))
            dispatch(setAccidentFilters(arr))
        } else {
            dispatch(setAccidentFilters([]))
            dispatch(setFilterIsActive(false))
        }
    }, [filterObj, clearAll, dispatch])

    let newFilterArr: any = []
    useEffect(() => {
        if (clearAll && activeFilter && accidentFilters.length > 0) {
            const copyArr = [...accidentFilters]
            newFilterArr = copyArr.map((ss: any) => {
                if (ss && ss.length > 0) {
                    ss = []
                }
            })
        }
    }, [activeFilter, accidentFilters])

    useEffect(() => {
        switch (activeFilter) {
            case false:
                setFilterObj({
                    kaza_siddet: [],
                    olussekli1: [],
                    havadurumuid: [],
                    gunid: [],
                    arackazaturuid: [],
                    cinsi: [],
                    yerlesimyeriack: [],
                    yoltipi: [],
                    geoduseyguzergahid: [],
                    kavsakid: [],
                    olu_say: [],
                    kaza_say: [],
                    yarali_say: [],
                })
                setOluSayisi([0, 50])
                setYaraliSayisi([0, 150])
                setAracSayisi([0, 80])
                break;
            default:
                break;
        }
    }, [activeFilter])

    useEffect(() => {
        if (newFilterArr && newFilterArr.length === 0 && activeFilter && accidentFilters.length > 0) {
            dispatch(setAccidentFilters([]))
            setFilterObj({
                kaza_siddet: [],
                olussekli1: [],
                havadurumuid: [],
                gunid: [],
                arackazaturuid: [],
                cinsi: [],
                yerlesimyeriack: [],
                yoltipi: [],
                geoduseyguzergahid: [],
                kavsakid: [],
                olu_say: [],
                kaza_say: [],
                yarali_say: [],
            })
        }
    }, [clearAll])

    const clearFilters = () => {
        if (clearAll) {
            setClearAll(false)
        } else {
            setClearAll(true)
        }
    }

    const closeFilters = () => {
        dispatch(setActiveButtonType(ActiveButtonState.DEFAULT))
    }

    const onMouseUp = (e: any, name: string) => {
        if (name === 'kaza_say') {
            setFilterObj({
                ...filterObj,
                kaza_say: [`${aracSayisi[0] + ':' + aracSayisi[1]}`],
            })
        }
        if (name === 'olu_say') {
            setFilterObj({
                ...filterObj,
                olu_say: [`${oluSayisi[0] + ':' + oluSayisi[1]}`],
            })
        }
        if (name === 'yarali_say') {
            setFilterObj({
                ...filterObj,
                yarali_say: [`${yaraliSayisi[0] + ':' + yaraliSayisi[1]}`]
            })
        }
    }

    return (
        <div className='AccidentFilters'>
            <div style={{ height: '100%', maxHeight: "calc(100% - 50px)", overflowX: 'hidden', position: 'absolute', paddingRight: '20px', width: "94%" }}>
                <table width="100%">
                    <tbody>
                        <tr>
                            <td>
                                <img alt='--->' src='/filter_icon/kaza_siddeti.svg' />
                            </td>
                            <td>
                                Kaza Şiddeti
                            </td>
                            <td style={{ display: "flex", alignItems: "center", flexWrap: "wrap", width: "50%", height: "100%" }}>
                                <MultiSelect id='kaza_siddet' value={filterObj.kaza_siddet} options={kazaSiddetiOptions} onChange={onInputChange} optionLabel="name" placeholder="Kaza Şiddeti Seçiniz" display="chip" filter filterBy='name' />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <img alt='--->' src='/filter_icon/olus_sekli.svg' />
                            </td>
                            <td>
                                Oluş Şekli
                            </td>
                            <td style={{ display: "flex", alignItems: "center", flexWrap: "wrap", width: "50%", height: "100%" }}>
                                <MultiSelect id='olussekli1' value={filterObj.olussekli1} options={kazaOlusOptions} onChange={onInputChange} optionLabel="name" placeholder="Kaza Oluş Şekli Seçiniz" display="chip" filter filterBy='name' />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <img alt='--->' src='/filter_icon/hava_durumu.svg' />
                            </td>
                            <td>
                                Hava Durumu
                            </td>
                            <td style={{ display: "flex", alignItems: "center", flexWrap: "wrap", width: "50%", height: "100%" }}>
                                <MultiSelect id='havadurumuid' value={filterObj.havadurumuid} options={havaDurumuOptions} onChange={onInputChange} optionLabel="name" placeholder="Hava Durumu Seçiniz" display="chip" filter filterBy='name' />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <img alt='--->' src='/filter_icon/gundurumu.svg' style={{ opacity: '0.8' }} />
                            </td>
                            <td>
                                Gün Durumu
                            </td>
                            <td style={{ display: "flex", alignItems: "center", flexWrap: "wrap", width: "50%", height: "100%" }}>
                                <MultiSelect id='gunid' value={filterObj.gunid} options={gunDurumuOptions} onChange={onInputChange} optionLabel="name" placeholder="Gün Durumu Seçiniz" display="chip" filter filterBy='name' />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <img alt='--->' src='/filter_icon/kaza_turleri.svg' />
                            </td>
                            <td>
                                Kaza Türleri
                            </td>
                            <td style={{ display: "flex", alignItems: "center", flexWrap: "wrap", width: "50%", height: "100%" }}>
                                <MultiSelect id='arackazaturuid' value={filterObj.arackazaturuid} options={kazaTurleriOptions} onChange={onInputChange} optionLabel="name" placeholder="Kaza Türü Seçiniz" display="chip" filter filterBy='name' />
                            </td>
                        </tr>
                        {/* <tr>
                            <td>
                                <img src='/filter_icon/kazaya_karisan.svg' />
                            </td>
                            <td>
                                Kazaya Karışanlar
                            </td>
                            <td style={{ display: "flex", alignItems: "center", flexWrap: "wrap", width: "50%", height: "100%" }}>

                                <MultiSelect id='cinsi' value={filterObj.cinsi} options={kazayaKarisanlarOptions} onChange={onInputChange} optionLabel="name" placeholder="Kazaya Karışanları Seçiniz" display="chip" filter filterBy='name' />
                            </td>
                        </tr> */}
                        <tr>
                            <td>
                                <img alt='--->' src='/filter_icon/yerlesim_yeri.svg' />
                            </td>
                            <td>
                                Yerleşim Yeri
                            </td>
                            <td style={{ display: "flex", alignItems: "center", flexWrap: "wrap", width: "50%", height: "100%" }}>
                                <MultiSelect id='yerlesimyeriack' value={filterObj.yerlesimyeriack} options={yerlesimYeriOptions} onChange={onInputChange} optionLabel="name" placeholder="Yerleşim Yeri Seçiniz" display="chip" filter filterBy='name' />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <img alt='--->' src='/filter_icon/yol_sinifi.svg' />
                            </td>
                            <td>
                                Yol Sınıfı
                            </td>
                            <td style={{ display: "flex", alignItems: "center", flexWrap: "wrap", width: "50%", height: "100%" }}>
                                <MultiSelect id='yoltipi' value={filterObj.yoltipi} options={yolSinifiOptions} onChange={onInputChange} optionLabel="name" placeholder="Yol Sınıfı Seçiniz" display="chip" filter filterBy='name' />
                            </td>
                        </tr>
                        {/* <tr>
                            <td>
                                <img src='/filter_icon/arac_sayisi.svg' />
                            </td>
                            <td>
                                Araç Sayısı
                            </td>
                            <td style={{ display: "flex", flexWrap: "wrap", alignItems: "center", height: "100%", flexDirection: 'column' }}>
                                <span style={{ marginBottom: '10px' }}>{aracSayisi[0]} - {aracSayisi[1]}</span>
                                <Slider value={aracSayisi} onChange={(e) => setAracSayisi(e.value)} onSlideEnd={(e) => onMouseUp(e, 'kaza_say')} max={80} range />
                            </td>
                        </tr> */}
                        <tr>
                            <td>
                                <img alt='--->' src='/filter_icon/yarali_sayisi.svg' style={{ paddingLeft: '15px' }} />
                            </td>
                            <td>
                                Yaralı Sayısı
                            </td>
                            <td style={{ display: "flex", flexWrap: "wrap", alignItems: "center", width: "50%", height: "100%", flexDirection: 'column' }}>
                                <span>{yaraliSayisi[0]} - {yaraliSayisi[1]}</span>
                                <Slider value={yaraliSayisi} onChange={(e) => setYaraliSayisi(e.value)} onSlideEnd={(e) => onMouseUp(e, 'yarali_say')} max={150} range />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <img alt='--->' src='/filter_icon/olu_sayisi.svg' />
                            </td>
                            <td>
                                Ölü Sayısı
                            </td>
                            <td style={{ display: "flex", flexWrap: "wrap", alignItems: "center", width: "50%", height: "100%", flexDirection: 'column' }}>
                                <span>{oluSayisi[0]} - {oluSayisi[1]}</span>
                                <Slider value={oluSayisi} onChange={(e) => setOluSayisi(e.value)} onSlideEnd={(e) => onMouseUp(e, 'olu_say')} max={50} range />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <img alt='--->' src='/filter_icon/dusey_geometri.svg' style={{ opacity: '0.8' }} />
                            </td>
                            <td>
                                Düşey Geometrik Alan
                            </td>
                            <td style={{ display: "flex", alignItems: "center", flexWrap: "wrap", width: "50%", height: "100%" }}>
                                <MultiSelect id='geoduseyguzergahid' value={filterObj.geoduseyguzergahid} options={duseyGeometrikAlanOptions} onChange={onInputChange} optionLabel="name" placeholder="Düşey Geometrik Alan Seçiniz" display="chip" filter filterBy='name' />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <img alt='--->' src='/filter_icon/kavsak_durumu.svg' />
                            </td>
                            <td>
                                Kavşak Türü
                            </td>
                            <td style={{ display: "flex", alignItems: "center", flexWrap: "wrap", width: "50%", height: "100%" }}>
                                <MultiSelect id='kavsakid' value={filterObj.kavsakid} options={kavsakTuruOptions} onChange={onInputChange} optionLabel="name" placeholder="Kavşak Türü Seçiniz" display="chip" filter filterBy='name' />
                            </td>
                        </tr>
                    </tbody>
                </table>

            </div>
            <div className='apply-button'>
                <Button className="clearAll" label='Temizle' onClick={clearFilters} disabled={!activeFilter} />
                <Button className="apply" label='Tamamla' onClick={closeFilters} />
            </div>
        </div>
    )
}

export default AccidentFilters