import { createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  filter: [],
};

export const tileSlice = createSlice({
  name: "tile",
  initialState,
  reducers: {
    setFilter: (state: any, action) => {
      state.filter = action.payload;
    },
  },
});

export const { setFilter } = tileSlice.actions;

export default tileSlice.reducer;
