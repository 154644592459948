import { createSlice } from '@reduxjs/toolkit'

interface ImportState {
    importedFile: any,
    importedFileVisibility: boolean
    showImport: boolean
    importLayerGroup: any
    layerNodes: [],
    importedGroupAlias:"",
    importCompleted:boolean,
    importedEpsg:string
}

const initialState: ImportState = {
    importedFile: null,
    importedFileVisibility: true,
    showImport: false,
    importLayerGroup: null,
    layerNodes: [],
    importedGroupAlias:"",
    importCompleted:false,
    importedEpsg:""
}

export const importSlice = createSlice({
    name: "import",
    initialState,
    reducers: {
      setImportedFile: (state: ImportState, action) => {
        state.importedFile = action.payload;
      },
      setImportedFileVisibility: (state: ImportState, action) => {
        state.importedFileVisibility = action.payload;
      },
      setShowImport: (state: any, action) => {
        state.showImport = action.payload;
      },
      setImportLayerGroup: (state: any, action) => {
        state.importLayerGroup = action.payload;
      },
      setLayerNodes: (state: any, action) => {
        state.layerNodes = action.payload;
      },
      setImportName: (state: any, action) => {
        state.importedGroupAlias = action.payload;
      },
      setImportCompleted: (state: any, action) => {
        state.importCompleted = action.payload;
      },
      setImportedEpsg: (state: any, action) => {
        state.importedEpsg = action.payload;
      },
    },
  })

  export const {
    setImportedFile,
    setImportedFileVisibility,
    setShowImport,
    setImportLayerGroup,
    setLayerNodes,
    setImportName,
    setImportCompleted,
    setImportedEpsg,
  } = importSlice.actions

export default importSlice.reducer;