import { createSlice } from "@reduxjs/toolkit";
const initialState: any = {
  sidebarServices: [],
  customMap: "kazalar",
  isClicked: false,
  isSidebarActive: true,
  layerForGoToLayer: {},
  kknLayerForDelete: {},
  enabledGroupAlias: "",
  removeGroupAlias: "",
  kknProgress:false
};
export const sidebarSlice = createSlice({
  name: "sidebar",
  initialState,
  reducers: {
    setSidebarServices: (state: any, action) => {
      state.sidebarServices = action.payload;
    },
    setCustomMap: (state: any, action) => {
      state.customMap = action.payload;
    },
    setClickState: (state: any, action) => {
      state.isClicked = !state.isClicked;
    },
    setIsSidebarActive: (state: any, action) => {
      state.isSidebarActive = action.payload;
    },
    setLayerForGoToLayer: (state: any, action) => {
      state.layerForGoToLayer = action.payload;
    },
    setKKNLayer: (state: any, action) => {
      state.kknLayerForDelete = action.payload;
    },
    setEnabledGroupAlias: (state: any, action) => {
      state.enabledGroupAlias = action.payload;
    },
    setRemoveGroupAlias: (state: any, action) => {
      state.removeGroupAlias = action.payload;
    },
    setKknProgress: (state: any, action) => {
      state.kknProgress = action.payload;
    },
  },
});
export const {
  setClickState,
  setSidebarServices,
  setCustomMap,
  setIsSidebarActive,
  setLayerForGoToLayer,
  setKKNLayer,
  setEnabledGroupAlias,
  setRemoveGroupAlias,
  setKknProgress
} = sidebarSlice.actions;
export default sidebarSlice.reducer;
