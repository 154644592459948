import * as echarts from "echarts";
import { useEffect, useRef, useState } from "react";
import "./ThematicMap.scss";
import { Button } from "../shared/button/Button";
import { useDispatch } from "react-redux";
import { setActiveButtonTypeRight } from "../state/features/buttonStateSlice";
import { ActiveButtonStateRight } from "../shared/button-state/ButtonState";
import saveAs from "file-saver";
import ExcelJS from "exceljs";
import { UtilRestApi } from "../../utils/restapi/util";
import { ProgressBar } from 'primereact/progressbar';

const ThematicMap = () => {
  const dispatch = useDispatch();
  const [list, setList] = useState("");
  const [title, setTitle] = useState("KAZA");
  const optionRef = useRef(null);
  const [optionChanged, setOptionChanfed] = useState<any>();
  const [saveExcelClicked, setExcelClicked] = useState<any>();
  const [gifLoading, setGifLoading] = useState<Boolean>(false);
  const { createGIF } = require("gifshot");
  const ar: string[] = []
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    function resizeChart() {
      let c = document.querySelectorAll("[data-zr-dom-id]")[0] as HTMLElement;
      let windowRatio = window.devicePixelRatio * 100;
      if (windowRatio === 100) {
        c.style.height = "846px";
        c.style.width = "1862px";
      } else if (windowRatio >= 110 && windowRatio <= 111) {
        c.style.height = "750px";
        c.style.width = "1700px";
      } else if (windowRatio >= 125) {
        c.style.height = "650px";
        c.style.width = "1500px";
      }
    }
    window.addEventListener("resize", resizeChart);


    let chartDom = document.getElementById("container") as HTMLElement;
    let expand: boolean = true;
    let exampleCityData = require("./cityList.json");
    UtilRestApi.geoserverWfsReq('version=1.0.0&request=GetFeature&typeName=trafik%3Akazasay_yil&outputFormat=application%2Fjson')
      .then((res) => {
        chartDom.style.height = "88vh";
        chartDom.style.width = "97vw";

        let option: any;
        echarts.registerMap("TURKIYE", exampleCityData);

        let val: any = [];
        let x: any = [];

        res.data.features.find(function (d: any) {
          if (d.properties.yil) {
            x.push([d.properties]);
            return d.yil;
          }
          return false;
        });

        let statList: any = ([] = Object.keys(x[0][0]));

        statList.splice(0, 3);
        statList.pop();

        const chunk1 = statList.slice(0, 4);
        const chunk2 = statList.slice(4, 8);

        let firstBlock: any = document.querySelector(
          ".first-chart-list"
        ) as HTMLElement;
        let secondBlock: any = document.querySelector(
          ".second-chart-list"
        ) as HTMLElement;

        const mainListButton = () => {
          document
            .getElementById("accident-button")
            ?.addEventListener("click", mainListEvent);
          document
            .getElementById("person-button")
            ?.addEventListener("click", mainListEvent);
        };

        const mainListEvent = (event: any) => {
          expand = !expand;
          let accidentSection = document.getElementById(
            "accident"
          ) as HTMLElement;
          let personSection = document.getElementById("person") as HTMLElement;

          if (event.target === document.getElementById("accident-button")) {
            accidentSection.style.display = "none";
            if (expand === true) {
              accidentSection.style.display = "inherit";
            }
          } else if (event.target === document.getElementById("person-button")) {
            personSection.style.display = "none";
            if (expand === true) {
              personSection.style.display = "inherit";
            }
          }
          return !expand;
        };

        const subListButton = () => {
          document
            .querySelectorAll(".accident-list")[0]
            ?.addEventListener("click", subListEvent);
          document
            .querySelectorAll(".accident-list")[1]
            ?.addEventListener("click", subListEvent);
          document
            .querySelectorAll(".accident-list")[2]
            ?.addEventListener("click", subListEvent);
          document
            .querySelectorAll(".accident-list")[3]
            ?.addEventListener("click", subListEvent);
          document
            .querySelectorAll(".person-list")[0]
            ?.addEventListener("click", subListEvent);
          document
            .querySelectorAll(".person-list")[1]
            ?.addEventListener("click", subListEvent);
          document
            .querySelectorAll(".person-list")[2]
            ?.addEventListener("click", subListEvent);
          document
            .querySelectorAll(".person-list")[3]
            ?.addEventListener("click", subListEvent);
        };

        const subListEvent = (event: any) => {
          setList(event.target.innerHTML);
          setTitle(event.target.innerHTML);

          return event.target.innerHTML;
        };

        firstBlock.innerHTML =
          '<p id="accident-button" style="color:#286688;font-weight: bold; cursor: pointer; margin-bottom:2%;">Kaza Verileri</p><ul id="accident" style="list-style-type: none; margin-left:5%">' +
          chunk1
            .map(function (stat: any) {
              if (stat === "kaza_say") {
                stat = "KAZA SAYISI";
              }
              if (stat === "arac_say") {
                stat = "ARAÇ SAYISI";
              }
              if (stat === "olu_say") {
                stat = "ÖLÜ SAYISI";
              }
              if (stat === "yarali_say") {
                stat = "YARALI SAYISI";
              }
              return (
                '<li class="list-item"><span class="accident-list" style="cursor: pointer; font-size: 12px;">' +
                stat +
                "</span></li>"
              );
            })
            .join("") +
          "</ul>";

        secondBlock.innerHTML =
          '<p id="person-button" style="color:#286688;font-weight: bold; cursor: pointer; margin-bottom:2%;">Şahıs Verileri</p><ul id="person" style="list-style-type: none; margin-left:5%;">' +
          chunk2
            .map(function (stat: any) {
              if (stat === "kisi_say") {
                stat = "KİŞİ SAYISI";
              }
              if (stat === "erkek_say") {
                stat = "ERKEK SAYISI";
              }
              if (stat === "kadin_say") {
                stat = "KADIN SAYISI";
              }
              if (stat === "surucu_yas_ortalama") {
                stat = "KAZAYA KARIŞAN KİŞİLERİN YAŞ ORTALAMASI";
              }
              return (
                '<li class="list-item"><span class="person-list" style="cursor: pointer; font-size: 12px;">' +
                stat +
                "</span></li>"
              );
            })
            .join("") +
          "</ul>";

        mainListButton();
        subListButton();

        res.data.features.filter(function (d: any) {
          if (d.properties.yil) {
            val.push([
              d.properties.ad,
              d.properties.yil,
              d.properties.kaza_say,
              d.properties.olu_say,
              d.properties.yarali_say,
              d.properties.arac_say,
              d.properties.kisi_say,
              d.properties.erkek_say,
              d.properties.kadin_say,
              d.properties.surucu_yas_ortalama,
            ]);
            return d.yil;
          }
          return false;
        });

        const years: any = [];
        let cityDetail: any = [];
        // const test: any = []
        for (let i = 0; i < res.data.features.length; ++i) {
          // test.push(res.data.features[i].properties)
          if (
            years.length === 0 ||
            years[years.length - 1] !== res.data.features[i].properties.yil
          ) {
            years.push(res.data.features[i].properties.yil);
          }
        }

        val.forEach(function (e: any) {
          if (list === "ARAÇ SAYISI") {
            cityDetail.push({ name: e[0], value: e[5] });


          } else if (list === "ÖLÜ SAYISI") {
            cityDetail.push({ name: e[0], value: e[3] });
          } else if (list === "YARALI SAYISI") {
            cityDetail.push({ name: e[0], value: e[4] });
          } else if (list === "KİŞİ SAYISI") {
            cityDetail.push({ name: e[0], value: e[6] });
          } else if (list === "ERKEK SAYISI") {
            cityDetail.push({ name: e[0], value: e[7] });
          } else if (list === "KADIN SAYISI") {
            cityDetail.push({ name: e[0], value: e[8] });
          } else if (list === "KAZAYA KARIŞAN KİŞİLERİN YAŞ ORTALAMASI") {
            cityDetail.push({ name: e[0], value: e[9] });
          } else {
            cityDetail.push({ name: e[0], value: e[2] });
          }
        });

        function getMaxValue(arr: any) {
          let maxValue = Number.MIN_VALUE;

          for (let i = 0; i < cityDetail.length; i++) {
            if (arr[i].value > maxValue) {
              maxValue = cityDetail[i].value;
            }
          }
          // return maxValue ? maxValue-(maxValue/1.5) : maxValue < 20000;
          return maxValue;
        }

        function getMinValue(arr: any) {
          let minValue = Number.MAX_VALUE;

          for (let i = 0; i < cityDetail.length; i++) {
            if (arr[i].value < minValue) {
              minValue = cityDetail[i].value;
            }
          }
          // return minValue? minValue+(minValue/3.1) : minValue<40;
          return minValue;
        }

        var maxValue = getMaxValue(cityDetail);
        var minValue = getMinValue(cityDetail);

        var myChart: any = echarts.init(chartDom);

        const img = new Image();
        img.src = "icons/favicon.svg";
        img.crossOrigin = "anonymous"
        let tmpCanvas = document.createElement("canvas") as HTMLCanvasElement;
        let tmpCanvasCtx = tmpCanvas.getContext("2d");
        tmpCanvasCtx?.drawImage(img, 50, 50, 50, 50);


        option = {
          baseOption: {
            graphic: [{
              id: 'bg',
              type: 'image',
              style: {
                image: img,
                x: 50,
                y: 715,
                width: 100
              }
            }],
            timeline: {
              axisType: "category",
              orient: "vertical",
              autoPlay: false,
              inverse: true,
              playInterval: 500,
              left: null,
              right: 55,
              top: 55,
              bottom: 0,
              width: 55,
              height: 700,
              symbol: "none",
              checkpointStyle: {
                borderWidth: 2
                // animationDuration : 1
              },
              controlStyle: {
                showNextBtn: false,
                showPrevBtn: false
              },
              data: []
            },
            title: {
              text: `İLLERİN TRAFİK ${title} İSTATİSTİKLERİ`,
              left: "center",
              top: '3%'
            },
            tooltip: {
              trigger: "item",
              showDelay: 0,
              transitionDuration: 0.2,
            },
            visualMap: {
              left: "center",
              bottom: '3%',
              min: minValue,
              max: maxValue,
              inRange: {
                color: [
                  "#FFFFFF",
                  "#ECF1F4",
                  "#DAE3E8",
                  "#BFD1DB",
                  "#A9C2CF",
                  "#94B3C4",
                  "#7EA3B8",
                  "#6894AC",
                  "#5385A0",
                  "#3E7594",
                  "#286688",
                ],
              },
              text: ["Yüksek", "Düşük"],
              calculable: true,
              orient: "horizontal",
            },
            toolbox: {
              show: true,
              right: 0,
              itemSize: 40,
              bottom: -5,
              feature: {
                // dataView: { readOnly: false },
                // restore: {},
                myFeature: {
                  show: true,
                  title: "GIF olarak indir",
                  icon: "image://data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAACsAAAArCAYAAADhXXHAAAAABGdBTUEAALGPC/xhBQAAAAlwSFlzAAAOwgAADsIBFShKgAAAA5hJREFUWEftmVlPE1EUgOevurwZE43xxUh8MtFHn1yiD2gsKLLJvssia4E0BYvWAoIUKBUKtJRuHPnG3NppLrHTDtM+OMmXDHfuueeb2zNT7q1xdnYmuVzOJJvLSiabkXQmLal0Wk5TKUmmTi2cnCYdoXhccpGT3DjgorxwBKNQks4EJpInEk8m5ChxLIfxI4kdH1o4OI5VRPF45CAXOcmNAy6F0qYsf3A3dIifJMzA5sWQ1HUuyPWGKbnimXQFcpGT3DjgghNuStjgDvhYuLPgzp7UdS1oB3MTHHDBCTccETaUaDS2L/c757XB1QAXnJQwM2wo0YbZgDaomuCkhCkJY/8oJuHojtz7OKcNqCY44YYjNWzs7u/JWnjD1YepVHDCDUceOuNnJCwrP75rO9cCuOHI7Brr25viD9VevSpww5HaNUJb67L4za/tWAvghmPkICpGcHNVvAGftmMtgBuOO78iYnw9r4mZZXtfBI8HlySRykipx8vpoHacUsANx+3orhgU8PSSvS+Dld1D6V8Jm9L/wjO/VpEwbjjyCjNlp/xebceLQBYR3TUdiJYrjFteNrAelKmly5WFcoVxw3Frb9s9WaAkiNVdu4iqyRLjuqybOCLL2yBwPkuK4jbOaWMmi9vs4IgsEtDm3zShjYdHtasHiWNiNWL2Kett4JSskiykUB5M2VCkrBoHR2Uf9PjyZQA62Y2DeFmzCo7KMmMcql0nW+6sgmOyhUdxG+e0cVRdlo8fCUVxG+e0cX7jw6wl1g4Vy1J/d9vtr4SJsVu7FcvyEavZtAMxqjxKxRFZ9S/iIxsQ47oskifprPnw2DmIUbVcKhXLusl/2cvCIlvOssZNLMsaTliUXWvQd64mOFkWjGopfqf5szagmuBkWYqrTY4nfePagGqCk2WTQ20fDXsn5HZT7QjjgpNl+0htzE34ZuTd6JDcahrTBrsJDrjgZNmYU1ues18WZXBuTFpHu+VhW6/cbByRqx736phc5CQ3DrjghFt+y1NtJnMHk/456Z/5ZHZuGGiV+u738qrDIy/a38jzttfyrPUPT1vqK0KNw5iMTQ5ykZPcOOCCk2UzWW3TU8S+4LLZacg7Ll2Tg2Zg03CHNA60iae/xeRtX7MjqPEYmxzkIie5ccAFJ9zy2/TqBxCmmovcDdNPvVDgA7Oj0js9Ij1Tw9I9OZSn6/NgWRSOwZiMTQ5ykZPcOOCC098fQE7lN/Wm4/GL3GrpAAAAAElFTkSuQmCC",
                  onclick: runTest
                },
                myButton: {
                  show: true,
                  title: "Excel olarak indir",
                  icon: "image://data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAqCAYAAADBNhlmAAAABGdBTUEAALGPC/xhBQAAAAlwSFlzAAAOwgAADsIBFShKgAAAAy5JREFUWEftmM1PGlEUxd9/27ptmyYumnbXrUnTtDbpoklbAn60KGgLkRSniCKhgFCVVkaFVCoowjiIUnM65+lYYmZRmQFm4eKEyXt37vnN8N7L3Cu0kz9otjo4Oj7FodZGrdlCtaFj/+gYv+vHqNQ1VA6bV9qzqe5czE0PetGT3mQgC5nIJhr6mRysNnUZPJHaxpO5LEZ8Sdzxrg5E9KInvclAFjKRTRxoJ/IJ8uUaHgczlgkGKTKQhUxkE7woV+uugDNFFjKRTfDCkyhYBg5TZCKbUPf28Wg2bRk0TJGJbGJjt4wRi4Bhi0xkE9miahngBpFNpAruW3+myCYS6+uWk24Q2UQ8n7OcdIPIJmJrWctJN4hs4uuavQN6dOEHxmNbV3q+4NyBTzahZHtNqEI5OId+imvqoLDuzMYjm1AyPQJutqC321Di+X9jyQZqDkKSrXfAggGoNeDpHpOAbaS3z9BwALJPgC1EvBl4HIDsEyCM77hzKXNNfk93xdxAzgMGdhD8WYNypTpUHVALXTE3kHOAgS34MzsYlXMZjKd2MCavS0hrbgCMHaJyem6AFDBmjHPtpVcZ5xZAbopy53LNAbVy6fJtugbQULSGUtsA5NkYNePcAhi4ANG1FlT5a4wHGOeiNVgy4CJ8c9GqAXmGdIpxwwTM6RcHcbH7WLmmolGMG5unkLO4/z9kD5Abo9hC7fJQtlYHatHcMDeXTcD+6xbQrm4B7eoCsOdP/v5LfvKzMHFr60MWTSztHn5IWAYNU2SSZSeL42ehZcugYYpMsnBneyGcXMGDKfdAkoVMsvXBBs2Xbyl4o4u4Pxm3vGGQIgNZyCSbR2xxxXJr+JyIYyoawdOZCO75YrjrHdy6pBc96U0GspBJtt/YJCTpYiaN+eUlGfA+PIc38368Dkzj1cwkxv0TeOn3Sb34aE9mHuZkbnrQi570JgNZyCQbmGyz5rd3kdzYlBOh1WXMxqJG8AJ8kRA84Xm8Cwel3oackZmPuelBL3rSmwxkIZNsAbNRvfWrIgdIzVfL/5+L9NNKHMF4DIElxUhALTokReZkbnrQi570JgNZyFSu1vEXVyaqYIPF1MYAAAAASUVORK5CYII=",
                  onclick: saveExcel,
                },
                saveAsImage: {
                  show: true,
                  title: "PNG olarak indir",
                  icon: "image://data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAACYAAAArCAYAAAAUo/pwAAAABGdBTUEAALGPC/xhBQAAAAlwSFlzAAAOwgAADsIBFShKgAAAAuFJREFUWEftmFFv0lAYhs+/1d2qMeHC6J03XizxQjHxwkQlg01lg+EgEhljMEaQMiYbcYKDMDYaGLCSTbK8nreTBbOzubpZyMLFE9p+p9/75LQ9DRXtbg/7B0dodA6ht7uotwzs7R9gt3mAWrODWqN9ys4VGezF3sxgFjOZTQe60Elwp942zEGedAmP5rOYcKdwa2rVFpjFTGbTgS50EjRer+h46NeUJ9oJHehCJ1GpN0dCqg9d6CRcyYJywDChk3gwl1EWhwmdxISiMGzoJFSFUWAsZpWxmFVukpiGJ0tlBL/piP6VXXiWNuFQ9rkYi2IaXJUejCOgZRxfCo7VK2XLchbF6ijKoEL+8u9WR96Qcl2EFbWL+AexHjIpVe0cUi3o9otpcKZ/YPLMuAGGITZZ6KLF+02vny9nt5gjzcBjFEsNFLsXyNkqlpO/Uub0aYuc7J/IySe3/BOtdgueBVmzT0wKqGaoL9fpycsrlwq5bXSkXMZGMQa6fGfrjq+Gec+ZM+krI9ORY7tcy+y6lGlF7Y/L+ftYX+7/i1UhJ0XOSBXO2NYA1RMBw0AwMXh8C96qfFMYHXiV/c7HophcIuRKrh9yFi7JYU++Kaz/4bEsZhdjMauMxawyFrPK6H4iuP8+qSwOEzqJp8GEsjhM6CRCqRXcmxkdObrQSXz+ksZUZBF3p+PKgXZCB7rQScRya1hIxjETCePxbBh33DHcnrLvvmMWM5lNB7rQSaQLBSxqGQQSy2bhbWgerwJevPS9w4vZaTi9Hjz3uk2efbga/T7syd7MYBYzmU0HutBJrJe2kdrYNA8EVxOYi0XkoE9wh4NwhQJ4E/KbvA5eD/1+7M0MZjGT2XSgC53EVrVmbtCSU8jry5vv40oc/ngMvuWoPJEsXhNRsyd7M4NZzGQ2HehCJ/NzenFnDxvbFWS/F81iMp9HfD2H2FoWS1kNUaJdE7IXe7I3M5jFTGbTgS6VehO/AIDZD9yH7S0AAAAAAElFTkSuQmCC",
                },
              },
            },
            series: [
              {
                name: "",
                type: "map",
                roam: false,
                map: "TURKIYE",
                emphasis: {
                  label: {
                    show: true,
                  },
                },
                data: cityDetail,
                width: 1400,
                left: 300,
              },
            ],
          },
          options: [],
        };

        val = val.map((value: any) => {
          let year = value[1];
          value[1] = value[4];
          value[4] = year;
          return value;
        });

        for (let n = 0; n < years.length; n++) {
          option.baseOption.timeline.data.push(years[n]);

          option.options.push({
            series: {
              data: val
                .filter((el: any) => el[4] === years[n])
                .map((x: any) => {
                  if (list === "ARAÇ SAYISI") {
                    return {
                      name: x[0],
                      value: x[5],
                    };
                  } else if (list === "ÖLÜ SAYISI") {
                    return {
                      name: x[0],
                      value: x[3],
                    };
                  } else if (list === "YARALI SAYISI") {
                    return {
                      name: x[0],
                      value: x[1],
                    };
                  } else if (list === "KİŞİ SAYISI") {
                    return {
                      name: x[0],
                      value: x[6],
                    };
                  } else if (list === "ERKEK SAYISI") {
                    return {
                      name: x[0],
                      value: x[7],
                    };
                  } else if (list === "KADIN SAYISI") {
                    return {
                      name: x[0],
                      value: x[8],
                    };
                  } else if (list === "KAZAYA KARIŞAN KİŞİLERİN YAŞ ORTALAMASI") {
                    return {
                      name: x[0],
                      value: x[9],
                    };
                  } else {
                    return {
                      name: x[0],
                      value: x[2],
                    };
                  }
                }),
            },
          });
        }
        // console.log("option", option);

        myChart.setOption(option);

        const len = myChart.getOption().timeline[0].data.length;
        myChart.on("timelinechanged", (ev: any) => {
          let currentIndex = ev.currentIndex;
          if (myChart.gif) {
            ar.push(myChart.getDataURL({ backgroundColor: '#fff', excludeComponents: ['toolbox'] }))
            if (currentIndex + 1 === len) {
              myChart.dispatchAction({
                type: 'timelinePlayChange',
                playState: false
              })
              myChart.gif = false;
              callback(myChart)
              myChart.getOption().timeline[0].currentIndex = currentIndex;
            }
          }
        })
      })
  }, [list, title]);

  useEffect(() => {
    if (
      optionRef &&
      saveExcelClicked
    ) {
      setExcel(optionRef);
      setExcelClicked(false);
    }
  }, [optionRef, saveExcelClicked]);

  const saveExcel = async () => {
    if (!gifLoading) {
      setExcelClicked(true);
    }
  };

  useEffect(() => {
    const el = document.getElementsByClassName("chart-list")[0] as HTMLElement;
    if (gifLoading) {
      el.style.display = 'none'
    } else {
      el.style.display = 'block'
    }
  }, [gifLoading]);

  const callback = function (ec: any) {
    ec.showLoading({
      text: 'Gif oluşturuluyor', spinnerRadius: 6
    });
    const options = {
      images: ar,
      numFrames: ar.length,
      gifWidth: 1600,
      gifHeight: 750,
      numWorkers: 11,
      frameDuration: 8,
      sampleInterval: 1,
      progressCallback: (e: any) => {
        setProgress(parseInt(e) * 100)
      }
    };
    createGIF(options, (obj: any) => {
      if (!obj.error) {
        const link = document.createElement('a');
        link.download = `İLLERİN TRAFİK ${title} İSTATİSTİKLERİ.gif`
        link.href = obj.image;
        link.click();
        setProgress(0);
        setTimeout(() => {
          setGifLoading(false)
          ec.hideLoading();
        }, 800);
      }
    });
  }
  const runTest = (myChart: any) => {
    const ec = myChart.scheduler.ecInstance;
    ar.length = 0;
    ar.push(ec.getDataURL({ backgroundColor: '#fff', excludeComponents: ['toolbox'] }))
    ec.dispatchAction({
      type: 'timelinePlayChange',
      playState: true,
    })
    ec.gif = true;
    setGifLoading(true)
  };
  const setExcel = async (option: any) => {

    let exampleCityData = require("./cityList.json");
    UtilRestApi.geoserverWfsReq('version=1.0.0&request=GetFeature&typeName=trafik%3Akazasay_yil&outputFormat=application%2Fjson')
      .then(async (res) => {
        let option: any;
        echarts.registerMap("TURKIYE", exampleCityData);

        let val: any = [];
        let x: any = [];

        res.data.features.find(function (d: any) {
          if (d.properties.yil) {
            x.push([d.properties]);
            return d.yil;
          }
          return false;
        });

        let statList: any = ([] = Object.keys(x[0][0]));

        statList.splice(0, 3);
        statList.pop();

        res.data.features.filter(function (d: any) {
          if (d.properties.yil) {
            val.push([
              d.properties.ad,
              d.properties.yil,
              d.properties.kaza_say,
              d.properties.olu_say,
              d.properties.yarali_say,
              d.properties.arac_say,
              d.properties.kisi_say,
              d.properties.erkek_say,
              d.properties.kadin_say,
              d.properties.surucu_yas_ortalama,
            ]);
            return d.yil;
          }
          return false;
        });

        const years: any = [];
        let cityDetail: any = [];

        for (let i = 0; i < res.data.features.length; ++i) {
          if (
            years.length === 0 ||
            years[years.length - 1] !== res.data.features[i].properties.yil
          ) {
            years.push(res.data.features[i].properties.yil);
          }
        }

        val.forEach(function (e: any) {
          if (list === "ARAÇ SAYISI") {
            cityDetail.push({ name: e[0], value: e[5] });
          } else if (list === "ÖLÜ SAYISI") {
            cityDetail.push({ name: e[0], value: e[3] });
          } else if (list === "YARALI SAYISI") {
            cityDetail.push({ name: e[0], value: e[4] });
          } else if (list === "KİŞİ SAYISI") {
            cityDetail.push({ name: e[0], value: e[6] });
          } else if (list === "ERKEK SAYISI") {
            cityDetail.push({ name: e[0], value: e[7] });
          } else if (list === "KADIN SAYISI") {
            cityDetail.push({ name: e[0], value: e[8] });
          } else if (list === "KAZAYA KARIŞAN KİŞİLERİN YAŞ ORTALAMASI") {
            cityDetail.push({ name: e[0], value: e[9] });
          } else {
            cityDetail.push({ name: e[0], value: e[2] });
          }
        });

        option = {
          baseOption: {
            timeline: {
              data: [],
            },

            series: [
              {
                data: cityDetail,
              },
            ],
          },
          options: [],
        };

        val = val.map((value: any) => {
          let year = value[1];
          value[1] = value[4];
          value[4] = year;
          return value;
        });

        for (let n = 0; n < years.length; n++) {
          option.baseOption.timeline.data.push(years[n]);

          option.options.push({
            series: {
              data: val
                .filter((el: any) => el[4] === years[n])
                .map((x: any) => {
                  if (list === "ARAÇ SAYISI") {
                    return {
                      name: x[0],
                      value: x[5],
                    };
                  } else if (list === "ÖLÜ SAYISI") {
                    return {
                      name: x[0],
                      value: x[3],
                    };
                  } else if (list === "YARALI SAYISI") {
                    return {
                      name: x[0],
                      value: x[1],
                    };
                  } else if (list === "KİŞİ SAYISI") {
                    return {
                      name: x[0],
                      value: x[6],
                    };
                  } else if (list === "ERKEK SAYISI") {
                    return {
                      name: x[0],
                      value: x[7],
                    };
                  } else if (list === "KADIN SAYISI") {
                    return {
                      name: x[0],
                      value: x[8],
                    };
                  } else if (
                    list === "KAZAYA KARIŞAN KİŞİLERİN YAŞ ORTALAMASI"
                  ) {
                    return {
                      name: x[0],
                      value: x[9],
                    };
                  } else {
                    return {
                      name: x[0],
                      value: x[2],
                    };
                  }
                }),
            },
          });
        }

        const workSheetName = "İstatistik";
        const workbook = new ExcelJS.Workbook();
        try {
          const worksheet = workbook.addWorksheet(workSheetName);

          let matrix = [];
          for (let i = 0; i < 81; i++) {
            matrix.push(new Array(option.baseOption.timeline.data.length));
          }

          for (let i = 0; i < 81; i++) {
            matrix[i][0] = option.options[0].series.data[i].name;
          }

          for (let i = 0; i < option.baseOption.timeline.data.length; i++) {
            for (let j = 0; j < 81; j++) {
              let data = option.options[i].series.data[j].value;
              matrix[j][i + 1] = data;
            }
          }

          option.baseOption.timeline.data.unshift("");

          worksheet.columns = option.baseOption.timeline.data;
          worksheet.columns = option.baseOption.timeline.data.map(
            (column: any) => {
              return {
                header: column,
                key: column,
                width: 20,
              };
            }
          );

          // İlk satırın fontu güncellendi.
          worksheet.getRow(1).font = { bold: true };
          worksheet.getRow(1).height = 30;
          worksheet.getRow(1).alignment = {
            horizontal: "center",
            vertical: "middle",
          };

          matrix.forEach((singleData: any) => {
            worksheet.addRow(singleData);
          });

          worksheet.eachRow({ includeEmpty: false }, (row: any) => {
            const currentCell = row._cells;

            currentCell.forEach((singleCell: any) => {
              const cellAddress = singleCell._address;

              worksheet.getCell(cellAddress).border = {
                top: { style: "thin" },
                left: { style: "thin" },
                bottom: { style: "thin" },
                right: { style: "thin" },
              };
            });
          });

          const buf = await workbook.xlsx.writeBuffer();

          saveAs(new Blob([buf]), `istatistik.xlsx`);
        } catch (error: any) {
          console.error(
            "İstatistik dosyası indirilirken hata ile karşılaşıldı",
            error.message
          );
        } finally {
          workbook.removeWorksheet(workSheetName);
        }
      })
  };

  const onHide = () => {
    // let chartDom = document.getElementById("chart-container") as HTMLElement;
    // chartDom.style.display = "none";
    dispatch(setActiveButtonTypeRight(ActiveButtonStateRight.DEFAULT));
  };

  const listCliked = () => {
    setOptionChanfed(!optionChanged);
  };

  return (
    <div id="chart-container" onClick={listCliked}>
      {gifLoading && <ProgressBar mode="indeterminate" style={{ height: '6px' }}></ProgressBar>}
      <div className="chart-list">
        <div className="first-chart-list"></div>
        <div className="second-chart-list"></div>
      </div>

      <div
        className="button"
        style={{ display: "flex", justifyContent: "end" }}
      >
        <Button disabled={gifLoading} label="HARİTAYA GİT" onClick={onHide} moreStyle="btn"></Button>
      </div>
      <div id="container"></div>
    </div>
  );
};

export default ThematicMap;
