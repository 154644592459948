import axios from "axios";

const { REACT_APP_BASE_URL } = process.env;

const getToken = () => {
  return localStorage.getItem("ag_token");
};

async function getLayerGroups() {
  const config = {
    headers: { Authorization: "Bearer " + getToken() },
  };
  return await axios.get(REACT_APP_BASE_URL + "layer/layergroup", config);
}

async function deleteLayerGroup(layerGroupId: number) {
  const config = {
    headers: { Authorization: "Bearer " + getToken() },
  };
  return await axios.delete(
    REACT_APP_BASE_URL + "layer/layergroup/" + layerGroupId,
    config
  );
}

async function getLayerGroupById(groupId: number) {
  const config = {
    headers: { Authorization: "Bearer " + getToken() },
  };
  return await axios.get(
    REACT_APP_BASE_URL + `layer/layergroup/${groupId}`,
    config
  );
}

async function getAllLayers() {
  const config = {
    headers: { Authorization: "Bearer " + getToken() },
  };
  return await axios.get(REACT_APP_BASE_URL + "layer", config);
}

async function getLayerById(layerId: number) {
  const config = {
    headers: { Authorization: "Bearer " + getToken() },
  };
  return await axios.get(REACT_APP_BASE_URL + `layer/${layerId}`, config);
}

async function addLayerGroup(layerGroup: any) {
  const config = {
    headers: { Authorization: "Bearer " + getToken() },
  };
  return await axios.post(
    REACT_APP_BASE_URL + "layer/layergroup",
    layerGroup,
    config
  );
}
async function exportLayer(body: any) {
  const config = {
    headers: { Authorization: "Bearer " + getToken() },
  };
  return await axios.post(REACT_APP_BASE_URL + "layer/convert", body, config);
}

async function editFields(layerId: number, fieldId: number, field: any) {
  const config = {
    headers: { Authorization: "Bearer " + getToken() },
  };
  return await axios.put(
    REACT_APP_BASE_URL + "layer/" + layerId + "/field/" + fieldId,
    field,
    config
  );
}

async function addLayer(layerGroupId: number, layerFormData: any) {
  const config = {
    headers: {
      Authorization: "Bearer " + getToken(),
      "content-type": "multipart/form-data",
    },
  };
  return await axios.post(
    REACT_APP_BASE_URL + "layer/layergroup/" + layerGroupId + "/upload",
    layerFormData,
    config
  );
}
async function deleteLayer(layerId: number) {
  const config = {
    headers: { Authorization: "Bearer " + getToken() },
  };
  return await axios.delete(REACT_APP_BASE_URL + "layer/" + layerId, config);
}

async function getFeatures(
  layers: string[],
  addToUrl?: string,
  filter?: string
) {
  if (layers.length > 1) {
    const layersString = layers.join(",");
    const urlWfs =
      "https://trafik.simurgbilisim.com/rest/v1/geoserver/anadolu/ows?service=WFS&version=1.0.0&request=GetFeature&outputFormat=application/json&srsname=EPSG:3857&typeName=" +
      layersString +
      "&" +
      addToUrl +
      "&" +
      filter +
      `&token=${getToken()}`;
    return axios.get(urlWfs);
  } else if (layers.length === 1) {
    const urlWfs =
      "https://trafik.simurgbilisim.com/rest/v1/geoserver/anadolu/ows?service=WFS&version=1.0.0&request=GetFeature&outputFormat=application/json&srsname=EPSG:3857&typeName=" +
      layers +
      `&token=${getToken()}`;
    return axios.get(urlWfs);
  }
}

export const LayerRestApi = {
  getLayerGroups,
  deleteLayerGroup,
  getLayerGroupById,
  getAllLayers,
  getFeatures,
  getLayerById,
  addLayerGroup,
  addLayer,
  deleteLayer,
  editFields,
  exportLayer,
};
