import { createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  value: {
    url: "",
    icon: "",
    layername: "",
    visible: false,
  },
  customMapClicked: false,
  isBasemapSelected: false,
};

export const basemapSlice = createSlice({
  name: "basemap",
  initialState,
  reducers: {
    setBasemaps: (state: any, action) => {
      state.value = action.payload;
    },
    setCustomBasemapClicked: (state: any, action) => {
      state.customMapClicked = action.payload;
    },
    changeVisibility: (state: any, action) => {
      const { index, visibility } = action.payload as {
        index: number;
        visibility: boolean;
      };
      state.value.map((basemap: any) => (basemap.visible = false));
      state.value[index].visible = visibility;
    },
    setBasemapSelection: (state: any, action) => {
      state.isBasemapSelected = action.payload;
    },
  },
});

export const {
  setBasemaps,
  setCustomBasemapClicked,
  changeVisibility,
  setBasemapSelection,
} = basemapSlice.actions;

export default basemapSlice.reducer;
