import { createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  value: [],
  extentedValue: [],
  hoveredResult: {},
  selectedResult: {},
  clickedFeatureId: null,
  resultTime: 0,
  resultScore: 0,
  searchListVisibilty: false,
  selectedListVisibilty: false,
  extent: [2734611.1239, 4295149.4934, 5146352.2404, 5185487.9989],
  quickSearchValue: null,
  city: "",
  town: "",
  neighborhood: "",
  limit: 6000,
  date: null,
  activeDateId: 2,
  dateInfo: null,
  shape: null,
  accidentFilters: {},
  isActive: false,
  isSidebarAccidentVisible: true,
  allFiltersClear: false,
  isHeatMapActive: true,
  legendIndex: 1,
  dateRange:[]
};

export const elasticSlice = createSlice({
  name: "elastic",
  initialState,
  reducers: {
    //elastik aramadan dönen tüm sonuçlar burada setleniyor.
    setResults: (state: any, action) => {
      state.value = action.payload;
    },
    setDateRange: (state: any, action) => {
      state.dateRange = action.payload;
    },
    setExtentedResults: (state: any, action) => {
      state.extentedValue = action.payload;
    },
    setHoveredResult: (state: any, action) => {
      state.hoveredResult = action.payload;
    },
    setSelectedResult: (state: any, action) => {
      state.selectedResult = action.payload;
    },
    setClickedFeatureId: (state: any, action) => {
      state.clickedFeatureId = action.payload;
    },
    setResultTime: (state: any, action) => {
      state.resultTime = action.payload;
    },
    setResultScore: (state: any, action) => {
      state.resultScore = action.payload;
    },
    searchListVisibilty: (state: any, action) => {
      state.searchListVisibilty = action.payload;
    },
    selectedListVisibilty: (state: any, action) => {
      state.selectedListVisibilty = action.payload;
    },
    setExtent: (state: any, action) => {
      state.extent = action.payload;
    },
    setQuickSearchValue: (state: any, action) => {
      state.quickSearchValue = action.payload;
    },
    setSelectedCity: (state: any, action) => {
      state.city = action.payload;
    },
    setSelectedTown: (state: any, action) => {
      state.town = action.payload;
    },
    setSelectedNeighborhood: (state: any, action) => {
      state.neighborhood = action.payload;
    },
    setLimit: (state: any, action) => {
      state.limit = action.payload;
    },
    //tarih filtresi buradan setleniyor.
    setDate: (state: any, action) => {
      state.date = action.payload;
    },
    //aktif tarihin idsi buradan dinleniyor.
    setActiveDateId: (state: any, action) => {
      state.activeDateId = action.payload;
    },
    setDateInfo: (state: any, action) => {
      state.dateInfo = action.payload;
    },
    //çizilen şeklin koordinatları buradan setleniyor.
    setShape: (state: any, action) => {
      state.shape = action.payload;
    },
    //kaza filtreleri bununla setleniyor.
    setAccidentFilters: (state: any, action) => {
      state.accidentFilters = action.payload;
    },
    //kaza filtrelerinin aktif olup olmadığına buradan bakılıyor.
    setFilterIsActive: (state: any, action) => {
      state.isActive = action.payload;
    },
    //sidebardaki kazaların açık olup olmadığı buradan dinleniyor.
    setIsSidebarAccidentVisible: (state: any, action) => {
      state.isSidebarAccidentVisible = action.payload;
    },
    //arama inputu tüm filtreleri kaldırma butonuna basıldığını buradan dinleyerek temizleniyor.
    setAllFiltersClear: (state: any, action) => {
      state.allFiltersClear = action.payload;
    },
    //ısı haritası açıkken featurelar gözükmeyecek.
    setHeatMapStatus: (state: any, action) => {
      state.isHeatMapActive = action.payload;
    },
    //legend indexi buradan setleniyor.
    setLegendIndex: (state: any, action) => {
      state.legendIndex = action.payload;
    },
  },
});

export const {
  setResults,
  setDateRange,
  setExtentedResults,
  setHoveredResult,
  setClickedFeatureId,
  setSelectedResult,
  setResultTime,
  setResultScore,
  searchListVisibilty,
  selectedListVisibilty,
  setQuickSearchValue,
  setSelectedCity,
  setSelectedTown,
  setSelectedNeighborhood,
  setLimit,
  setDate,
  setActiveDateId,
  setDateInfo,
  setShape,
  setAccidentFilters,
  setFilterIsActive,
  setIsSidebarAccidentVisible,
  setAllFiltersClear,
  setHeatMapStatus,
  setLegendIndex,
} = elasticSlice.actions;

export default elasticSlice.reducer;
