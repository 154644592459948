import "./zoomSection.scss";
import { ZoomStateType } from "../../../utils/model";
import { Button } from "../../shared/button/Button";
import { useAppSelector } from "../../state/hooks";
import { setZoomState } from "../../state/features/zoomStateSlice";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

export const ZoomSection = () => {
  const dispatch = useDispatch();
  const zoomState = useAppSelector((state) => state.zoomState.value);
  const zoomValue = useAppSelector((state) => state.zoomState.zoomValue);
  const [zoomChangestate, setZoomChangestate] = useState<any>();

  useEffect(() => {
    setZoomChangestate(zoomValue);
  }, [zoomValue, zoomChangestate]);

  return (
    <div className="zoom-block">
      <Button
        icon="pi pi-plus"
        disabled={zoomChangestate >= 20 ? true : false}
        active={zoomState === ZoomStateType.ZOOMIN}
        onClick={() => dispatch(setZoomState(ZoomStateType.ZOOMIN))}
        moreStyle="zoom-button"
      >
        +
      </Button>
      <Button
        icon="pi pi-minus"
        disabled={zoomChangestate <= 6.7 ? true : false}
        active={zoomState === ZoomStateType.ZOOMOUT}
        onClick={() => dispatch(setZoomState(ZoomStateType.ZOOMOUT))}
        moreStyle="zoom-button"
      >
        -
      </Button>
    </div>
  );
};
