import { createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  open3D: false,
  coordinate: {},
};

export const threeDSlice = createSlice({
  name: "threeD",
  initialState,
  reducers: {
    setOpen3D: (state: any, action) => {
      state.open3D = action.payload;
    },
    setCoordinate: (state: any, action) => {
      state.coordinate = action.payload;
    },
  },
});

export const { setOpen3D, setCoordinate } = threeDSlice.actions;

export default threeDSlice.reducer;
