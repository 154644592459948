import {
  PropsWithChildren,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { MapContext } from "../MapContext";
import TileLayer from "ol/layer/Tile";
import XYZ from "ol/source/XYZ";
import { TileWMS } from "ol/source";
import { useAppSelector } from "../../state/hooks";
import { GeoJSON } from "ol/format";
import { transformExtent } from "ol/proj";
import { get } from "ol/proj";
import { TileLayerContext } from "./TileLayerContext";
import { useDispatch } from "react-redux";
import { setFilter } from "../../state/features/tileSlice";
import {
  setCityState,
  setShapeState,
  setZoomState,
  setZoomValue,
} from "../../state/features/zoomStateSlice";
import { ZoomStateType } from "../../../utils/model";
import { UtilRestApi } from "../../../utils/restapi/util";
import { setBasemapSelection } from "../../state/features/basemapSlice";
const { REACT_APP_GEOSERVER_USER, REACT_APP_GEOSERVER_PASSWORD } = process.env;

interface Props {
  url: string;
  visible: boolean;
  layername?: string;
  opacity?: number;
}
export const Tile = (props: PropsWithChildren<Props>) => {
  const dispatch = useDispatch();
  const map = useContext(MapContext);
  const t = useRef<TileLayer<any>>(new TileLayer());
  const t2 = useRef<TileLayer<any>>(new TileLayer());
  const tileLayer2: any = t2.current!;
  const selectedCity = useAppSelector((state) => state.elastic.city);
  const selectedTown = useAppSelector((state) => state.elastic.town);
  const selectedNeighborhood = useAppSelector(
    (state) => state.elastic.neighborhood
  );
  const zoomState = useAppSelector((state) => state.zoomState.value);
  const zoomExtent = useAppSelector((state) => state.elastic.shape);
  const isBasemapSelected = useAppSelector(state => state.basemaps.isBasemapSelected);
  const [extentValue, setExtentValue] = useState<any>();
  const mapZoomValue = useAppSelector(state => state.maps.zoom);
  const extent = useAppSelector(state => state.maps.extent);
  // const [defaultExtent, setDefaultExtent] = useState<any>([2734611.1239, 4295149.4934, 5146352.2404, 5185487.9989])
  map.on("moveend", () => {
    let zoomValue = map.getView().getZoom();
    dispatch(setZoomValue(zoomValue));
  });

  useEffect(() => {
    if (zoomState) {
      if (zoomState === "TURKIYE") {
        let fullExtent = [
          2737541.962661694, 4098882.270688692, 5123013.687827613,
          5292860.5664618425,
        ];
        map.getView().fit(fullExtent, {
          padding: [0, 0, 0, 0],
          maxZoom: 14,
          duration: 500,
        });
        dispatch(setZoomState(ZoomStateType.NONE));
      } else if (zoomState === "SHAPE") {
        let shapeExtent = zoomExtent.getExtent();
        map.getView().fit(shapeExtent, {
          padding: [20, 20, 20, 20],
          maxZoom: 14,
          duration: 500,
        });
        dispatch(setZoomState(ZoomStateType.NONE));
      } else if (zoomState === "PROVINCE") {
        if (extentValue) {
          map.getView().fit(extentValue, {
            padding: [20, 20, 20, 20],
            maxZoom: 14,
            duration: 500,
          });
          dispatch(setZoomState(ZoomStateType.NONE));
        }
      } else if (zoomState === "ZOOMIN") {
        let zoomValue = map.getView().getZoom();
        map.getView().setZoom(zoomValue! + 1);
        dispatch(setZoomState(ZoomStateType.NONE));
      } else if (zoomState === "ZOOMOUT") {
        let zoomValue = map.getView().getZoom();
        map.getView().setZoom(zoomValue! - 1);
        dispatch(setZoomState(ZoomStateType.NONE));
      } else if (zoomState === "DEFAULT") {
        if (isBasemapSelected === true) {
          map.getView().fit(extent, {
            padding: [0, 0, 0, 0],
            maxZoom: mapZoomValue,
          });
          dispatch(setZoomState(ZoomStateType.DEFAULT));
        }
        else {
          let fullExtent = [
            2737541.962661694, 4098882.270688692, 5123013.687827613,
            5292860.5664618425,
          ];
          map.getView().fit(fullExtent, {
            padding: [0, 0, 0, 0],
            maxZoom: 14,
          });
          dispatch(setZoomState(ZoomStateType.DEFAULT));
        }
      }
    }
  }, [zoomState]);

  useEffect(() => {
    const tileLayer = t.current!;
    tileLayer.setSource(
      new XYZ({
        url: props.url,
        crossOrigin: "anonymous",
      })
    );
    tileLayer.set("basemap", true);

    tileLayer.setVisible(props.visible);
    tileLayer.setZIndex(-1);

    map.addLayer(tileLayer);

    return () => {
      map.removeLayer(tileLayer);
    };
  }, []);

  useEffect(() => {
    let selected = { filterName: "", value: "", layername: "" };

    if (selectedCity) {
      selected.value = selectedCity.uavtil;
      selected.filterName = "uavtil";
      selected.layername = "trafik:usr1_ag_snr_il";
    } else {
      return;
    }
    if (selectedTown) {
      selected.value = selectedTown.uavtilce;
      selected.filterName = "uavtilce";
      selected.layername = "trafik:usr1_ag_snr_ilce";
    }
    if (selectedNeighborhood) {
      selected.value = selectedNeighborhood.uavtmah;
      selected.filterName = "uavtmah";
      selected.layername = "trafik:usr1_ag_snr_mahalle";
    }

    tileLayer2.setSource(
      new TileWMS({
        params: {
          LAYERS: `${selected.layername}`,
          CQL_FILTER: `${selected.filterName}=${selected.value}`,
        },
        url: `${process.env.REACT_APP_TRAFIK_URL + "geoserver/trafik/wms"}`,
        serverType: "geoserver",
        crossOrigin: "Anonymous",
        tileLoadFunction: function (tile: any, src: any) {
          var xhr = new XMLHttpRequest();
          xhr.responseType = "blob";
          xhr.open("GET", src);
          xhr.setRequestHeader(
            "Authorization",
            "Basic " +
            window.btoa(
              `${REACT_APP_GEOSERVER_USER}` +
              ":" +
              `${REACT_APP_GEOSERVER_PASSWORD}`
            )
          );
          xhr.onload = function () {
            if (this.response) {
              var objectUrl = URL.createObjectURL(xhr.response);
              tile.getImage().onload = function () {
                URL.revokeObjectURL(objectUrl);
              };
              tile.getImage().src = objectUrl;
            } else {
              tile.setState(3);
            }
          };
          xhr.onerror = function () {
            tile.setState(3);
          };
          xhr.send();
        },
      })
    );
    tileLayer2.set();
    map.addLayer(tileLayer2);

    UtilRestApi.geoserverWfsReq(
      `version=1.0.0&request=GetFeature&typeName=${selected.layername}&maxFeatures=50&outputFormat=application%2Fjson&cql_filter=${selected.filterName}=${selected.value}`
    ).then((res) => {
      if (res) {
        const geojsonInstance = new GeoJSON();
        const feature = geojsonInstance.readFeature(res.data.features[0]);

        if (isBasemapSelected === false) {

          if (feature.getGeometry()) {
            let extent = feature!.getGeometry()!.getExtent();
            extent = transformExtent(
              extent,
              get("EPSG:4326") as any,
              get("EPSG:3857") as any
            );

            map.getView().fit(extent, {
              padding: [20, 20, 20, 20],
              maxZoom: 14,
              duration: 500,
            });
            dispatch(setShapeState(false));
            dispatch(setCityState(true));
            setExtentValue(extent);
          }

        }
        else {
          map.getView().fit(extent, {
            padding: [0, 0, 0, 0],
            maxZoom: mapZoomValue,
          });
          dispatch(setBasemapSelection(false))
        }
      }
    });

    UtilRestApi.geoserverWfsReq(
      `version=1.0.0&request=GetFeature&typeName=trafik%3Aist1_tarih&outputFormat=application%2Fjson&cql_filter=${selected.filterName}=${selected.value}`
    ).then((res) => {
      if (res) {
        dispatch(setFilter(res.data.features));
      }
    });

    return () => {
      map.removeLayer(tileLayer2);
    };
  }, [selectedCity, selectedTown, selectedNeighborhood]);

  useEffect(() => {
    const tileLayer = t.current!;
    if (props.opacity) {
      tileLayer.setVisible(true);
      tileLayer.setOpacity(props.opacity / 100);
    }
  }, [props.opacity]);

  return (
    <TileLayerContext.Provider value={t.current}>
      {props.children}
    </TileLayerContext.Provider>
  );
};
