import { InputText } from "primereact/inputtext"
import { useAppSelector } from "../state/hooks";

export const Others = () => {
    const selectedFeature = useAppSelector(state => state.info.selectedFeature);

    return (
        <div className='container'>
            <div className="left-group">

                <div className='input-group'>
                    <label htmlFor="name">Kaza Türü</label>
                    <InputText id="name" value={selectedFeature[0].arackazaturuid || ''} />
                </div>
                <div className='input-group'>
                    <label htmlFor="name">Oluş Şekli 1</label>
                    <InputText id="name" value={selectedFeature[0].olussekli1 || ''} />
                </div>
                <div className='input-group'>
                    <label htmlFor="name">Oluş Şekli 2</label>
                    <InputText id="name" value={selectedFeature[0].olussekli2 || ''} />
                </div>
            </div>

            <div className="right-group">
                <div className='input-group'>
                    <label htmlFor="name">Araç Hariç Hasar Unsuru</label>
                    <InputText id="name" value={selectedFeature[0].aracharichasunsurid || ''} />
                </div>
                <div className='input-group'>
                    <label htmlFor="name">İlk Çarpışma</label>
                    <InputText id="name" value={selectedFeature[0].ilkcarpisma || ''} />
                </div>
            </div>

        </div>
    )
}
