import { createSlice } from '@reduxjs/toolkit';
import { DrawStateType } from '../../../utils/model';

const initialState: any = {
    value: DrawStateType.NONE,
    isDrawing: false,
}

export const drawStateSlice = createSlice({
    name: "map-draw",
    initialState,
    reducers: {
      setDrawState: (state: any, action) => {
        state.value = action.payload;
      },
      setIsDrawing: (state: any, action) => {
        state.isDrawing = action.payload;
      },
    },
  });

export const { setDrawState, setIsDrawing } = drawStateSlice.actions

export default drawStateSlice.reducer