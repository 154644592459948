import { Toast } from 'primereact/toast';
import { useRef, useState } from 'react'
import { Dialog } from 'primereact/dialog';
import { RadioButton } from 'primereact/radiobutton';
import { Button } from 'primereact/button';
import './PdfDownload.scss';
import html2canvas from 'html2canvas';
import { useAppDispatch, useAppSelector } from '../../../state/hooks';
import { setShowExportPdf } from '../../../state/features/statisticSlice';
import jsPDF from 'jspdf';
import logoImg from '../bakanlik-logo.png';
const PdfDownload = () => {

    const dispatch = useAppDispatch();
    const pdfVertical = new jsPDF();
    const pdfHorizontal = new jsPDF('landscape');
    const toast = useRef<any>();
    const displayExportPdf = useAppSelector((state) => state.statistic.showExportPdf);
    const [exporttImg, setExporttImg] = useState<any>('');
    const statisticName = useAppSelector((state) => state.statistic.statisticName);

    const onClickExport = async () => {

        switch (exporttImg) {
            case "map":
                downloadMap();
                break;
            case "statistics":
                downloadStatistics();
                break;
            case "both":
                downloadAll();
                break;
            default:
                break;
        }
        onHide();
    };

    const onHide = async () => {
        dispatch(setShowExportPdf(!displayExportPdf))
    };

    const downloadAll = () => {
        const buttons = document.querySelectorAll('.download-group button');
        const leftContent = document.querySelector('.left-content');
        const search = document.querySelector('.search');
        const buttonList = document.querySelectorAll('.button-groups button:not(.immovable-button)');
        const stats = statisticName === 'Statistic2'
            ? document.querySelector('.Statistic2')
            : document.querySelector('.date-based-stats');
        const generalResize = stats?.querySelector(statisticName === 'Statistic2'
            ? '.General'
            : '.date-based-data'
        );
        const dropDownButton = document.querySelector('.Statistic2 .subgroup .selection-group .select-kkn-group');
        const hrElement = document.querySelector('.right-content .button-groups .hr') as HTMLElement;
        const basemap = document.querySelector('.basemaps');
        const logo = document.querySelector('.logo');
        const coordinateButton = document.querySelector('.coordinate-container-button');

        const addClasses = (elements: any, className: any) => elements.forEach((elem: any) => elem?.classList.add(className));
        const removeClasses = (elements: any, className: any) => elements.forEach((elem: any) => elem?.classList.remove(className));

        addClasses(buttons, 'remove-shadow');
        addClasses([logo, basemap], 'remove-bottom-left-icons');
        leftContent?.classList.add('remove-for-analyse');
        search?.classList.add('remove-for-analyse');
        addClasses(buttonList, 'remove-for-analyse');
        hrElement?.classList.add('remove-for-hr');
        stats?.classList.add(statisticName === 'Statistic2' ? 'stats-redesign-all' : 'date-based-stats-redesign');
        generalResize?.classList.add(statisticName === 'Statistic2' ? 'general-resize' : 'date-based-data-resize');
        dropDownButton?.classList.add('remove-shadow');
        coordinateButton?.classList.add('remove-coordinate-button');

        const app = document.getElementById('App') as HTMLElement;
        const SCALE_FACTOR = 1;

        app.style.transform = `scale(${SCALE_FACTOR})`;
        document.documentElement.requestFullscreen();

        const appWidth = app.offsetWidth * SCALE_FACTOR;
        const appHeight = app.offsetHeight * SCALE_FACTOR;

        html2canvas(app, { width: appWidth, height: appHeight }).then((canvas: any) => {



            const ctx: any = canvas.getContext('2d');
            // Create an image element for the logo
            const logo = new Image();
            logo.src = logoImg;
            // After the logo is loaded, draw it on the canvas before generating the PNG
            logo.onload = () => {
                // Set the desired opacity for the logo (value between 0 and 1)
                const logoOpacity = 0.20;

                // Save the current globalAlpha value
                const previousGlobalAlpha = ctx.globalAlpha;

                // Set the new globalAlpha value
                ctx.globalAlpha = logoOpacity;

                // Draw the logo on the canvas
                ctx.drawImage(logo, (appWidth / 2) - 200, (appHeight / 2) - 175, 400, 300);

                // Restore the previous globalAlpha value
                ctx.globalAlpha = previousGlobalAlpha;

                pdfHorizontal.addImage(canvas, 'PNG', 10, 20, 280, 150);
                pdfHorizontal.save('harita_ve_sehir_istatistikleri.pdf');
            };


            if (document.fullscreenElement) {
                document.exitFullscreen();
            }

            app.style.transform = '';
        });

        removeClasses(buttons, 'remove-shadow');
        removeClasses([logo, basemap], 'remove-bottom-left-icons');
        leftContent?.classList.remove('remove-for-analyse');
        search?.classList.remove('remove-for-analyse');
        removeClasses(buttonList, 'remove-for-analyse');
        hrElement?.classList.remove('remove-for-hr');
        stats?.classList.remove(statisticName === 'Statistic2' ? 'stats-redesign-all' : 'date-based-stats-redesign');
        generalResize?.classList.remove(statisticName === 'Statistic2' ? 'general-resize' : 'date-based-data-resize');
        coordinateButton?.classList.remove('remove-coordinate-button');
    };



    const downloadStatistics = () => {
        const buttons = document.querySelectorAll('.download-group button');
        const dropDownButton = document.querySelector('.Statistic2 .subgroup .selection-group .select-kkn-group');

        for (let i = 0; i < buttons.length; i++) {
            const button = buttons[i];
            button.classList.add("remove-shadow");
        }

        window.scrollTo(0, 0);
        document.documentElement.requestFullscreen();
        dropDownButton?.classList.add("remove-shadow");

        const statistics = document.getElementById(statisticName)!;
        const options = {
            scrollX: 0,
            scrollY: 0,
            windowHeight: statistics.offsetHeight,
            windowWidth: statistics.offsetWidth,
            backgroundColor: null,
            scale: 1,
            // rotate: -90,
        };

        html2canvas(statistics, options).then((canvas) => {

            const rotatedCanvas = document.createElement("canvas");
            const rotatedCtx: any = rotatedCanvas.getContext("2d");

            rotatedCanvas.width = canvas.height;
            rotatedCanvas.height = canvas.width;

            rotatedCtx.translate(rotatedCanvas.width / 3.5, rotatedCanvas.height / 3.5);
            // rotatedCtx.rotate(-0.5 * Math.PI);

            rotatedCtx.drawImage(canvas, -100, -200, statistics.offsetWidth, statistics.offsetWidth);

            const ctx = rotatedCtx;

            const logo = new Image();
            logo.src = logoImg;

            logo.onload = () => {
                const logoWidth = canvas.width - 100;
                const logoHeight = logoWidth * (logo.height / logo.width);

                const logoX = (canvas.width - logoWidth);
                const logoY = (canvas.height - logoHeight) / 2;

                const logoOpacity = 0.20;
                const previousGlobalAlpha = ctx.globalAlpha;
                ctx.globalAlpha = logoOpacity;

                ctx.drawImage(logo, logoX, logoY / 4, 300, 200);

                ctx.globalAlpha = previousGlobalAlpha;

                const pdfVertical = new jsPDF(); // PDF objesi oluşturulacaksa burada tanımlanmalı

                pdfVertical.addImage(rotatedCanvas.toDataURL(), "PNG", -5, 10, 200, 250);
                pdfVertical.save(`${statisticName === "Statistic2" ? "sehir_istatistikleri" : "degisim_analizi"}.pdf`);

                if (document.fullscreenElement) {
                    document.exitFullscreen();
                }
            };
        });

        for (let i = 0; i < buttons.length; i++) {
            const button = buttons[i];
            button.classList.remove("remove-shadow");
        }

        dropDownButton?.classList.remove("remove-shadow");
    };




    const downloadMap = () => {
        const coordinateButton = document.querySelector('.coordinate-container-button');
        const map = document.getElementById("map") as HTMLElement;
        let page: any;
        page = map;

        coordinateButton?.classList.add("remove-coordinate-button");
        window.scrollTo(0, 0);
        document.documentElement.requestFullscreen();


        const app = document.getElementById('App') as HTMLElement;
        const SCALE_FACTOR = 1; // Sabit bir ölçek kullanabilirsiniz
        const appWidth = app.offsetWidth * SCALE_FACTOR;
        const appHeight = app.offsetHeight * SCALE_FACTOR;

        html2canvas(page).then((canvas: any) => {


            const ctx: any = canvas.getContext('2d');
            // Create an image element for the logo
            const logo = new Image();
            logo.src = logoImg;
            // After the logo is loaded, draw it on the canvas before generating the PNG
            logo.onload = () => {
                // Set the desired opacity for the logo (value between 0 and 1)
                const logoOpacity = 0.20;

                // Save the current globalAlpha value
                const previousGlobalAlpha = ctx.globalAlpha;

                // Set the new globalAlpha value
                ctx.globalAlpha = logoOpacity;

                // Draw the logo on the canvas
                ctx.drawImage(logo, (appWidth / 2) - 200, (appHeight / 2) - 175, 400, 300);

                // Restore the previous globalAlpha value
                ctx.globalAlpha = previousGlobalAlpha;

                // link.href = canvas.toDataURL();
                // link.click();
                pdfHorizontal.addImage(canvas, "PNG", 10, 20, 280, 180);
                pdfHorizontal.save("harita_istatistikleri.pdf");
            };

            if (document.fullscreenElement) {
                document.exitFullscreen();
            }
        });

        coordinateButton?.classList.remove("remove-coordinate-button");
    };


    return (
        <div className='export-image'>
            <Toast ref={toast}></Toast>
            <Dialog
                className='export-pdf-dialog'
                header='PDF Olarak Ekran Görüntüsü Al'
                visible={true}
                onHide={() => dispatch(setShowExportPdf(!displayExportPdf))}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                    }}
                >
                    <div className='pair'>
                        <div className='content'>
                            <fieldset className='fieldset'>
                                <legend className='data-type-header'>Seçenekler</legend>
                                <div className='radio-button-group'>
                                    <div className='selection'>
                                        <RadioButton
                                            inputId='Map'
                                            className='radio-btn'
                                            value='map'
                                            onChange={(e: any) => {
                                                setExporttImg(e.value);
                                            }}
                                            checked={exporttImg === 'map'}
                                        />
                                        <label style={{ cursor: 'pointer' }} htmlFor='Map'>
                                            Harita Sayfası
                                        </label>
                                    </div>
                                    <div className='selection'>
                                        <RadioButton
                                            inputId='Statistics'
                                            value='statistics'
                                            className='radio-btn'
                                            onChange={(e: any) => {
                                                setExporttImg(e.value);
                                            }}
                                            checked={exporttImg === 'statistics'}
                                            style={{ cursor: 'pointer' }}
                                        />
                                        <label style={{ cursor: 'pointer' }} htmlFor='Statistics'>
                                            İstatistik Sayfası
                                        </label>
                                    </div>
                                    <div className='selection'>
                                        <RadioButton
                                            inputId='Both-Areas'
                                            value='both'
                                            className='radio-btn'
                                            onChange={(e: any) => {
                                                setExporttImg(e.value);
                                            }}
                                            checked={exporttImg === 'both'}
                                            style={{ cursor: 'pointer' }}
                                        />
                                        <label style={{ cursor: 'pointer' }} htmlFor='Both-Areas'>
                                            Harita ve İstatistik Sayfası
                                        </label>
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                    </div>
                    <br></br>
                    <div
                        style={{
                            marginBottom: '20px',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                        }}
                    >
                    </div>
                    <div className='button-area'>
                        <Button
                            disabled={exporttImg === "" ? true : false}
                            onClick={() => onClickExport()}
                            label='Pdf Olarak İndir'
                        ></Button>
                    </div>
                </div>
            </Dialog>
        </div>
    )
}

export default PdfDownload