import { createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  kknLayerGroup: [],
  accidentFeatures: [],
  kknGroupFeature: {},
  showExportImage: false,
  showExportPdf: false,
  gotoKKN:{},
  statisticName:"",
  imageRequest: false,
  pdfRequest:false
};

export const statisticSlice = createSlice({
  name: "statistic",
  initialState,
  reducers: {
    setSelectedKKNLayerGroup: (state: any, action) => {
      state.kknLayerGroup = action.payload;
    },
    //ilk sayfadaki line chart icin yapilan istegin sonucunun setlendigi yer
    setAccidentFeatures: (state: any, action) => {
      state.accidentFeatures = action.payload;
    },
    setSelectedKKNGroupFeature: (state: any, action) => {
      state.kknGroupFeature = action.payload;
    },
    setShowExportImage: (state: any, action) => {
      state.showExportImage = action.payload;
    },
    setShowExportPdf: (state: any, action) => {
      state.showExportPdf = action.payload;
    },
    setImageRequest: (state: any, action) => {
      state.imageRequest = action.payload;
    },
    setPdfRequest: (state: any, action) => {
      state.pdfRequest = action.payload;
    },
    setGotoKKN: (state: any, action) => {
      state.gotoKKN = action.payload;
    },
    setStatisticName: (state: any, action) => {
      state.statisticName = action.payload;
    },
  },
});

export const {
  setSelectedKKNLayerGroup,
  setAccidentFeatures,
  setSelectedKKNGroupFeature,
  setShowExportImage,
  setShowExportPdf,
  setGotoKKN,
  setStatisticName,
  setImageRequest,
  setPdfRequest
} = statisticSlice.actions;

export default statisticSlice.reducer;
