import { createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  allFilters: [{ selected: false, tagName: "", icon: "" }],
  selectedFilters: [],
  type: [],
  category: [],
  subcategory: [],
  accidentFilters: [],
};

export const filterSlice = createSlice({
  name: "basemap",
  initialState,
  reducers: {
    setAllFilters: (state: any, action) => {
      state.allFilters = action.payload;
    },
    setSelectedFilters: (state: any, action) => {
      state.selectedFilters = action.payload;
    },
    setFilterSelectedStatus: (state: any, action) => {
      const index = action.payload.index;
      state.allFilters[index].selected = action.payload.selected;
    },
    setFilterDisabledStatus: (state: any, action) => {
      const index = action.payload.index;
      if (index) {
        state.allFilters[index].disabled = action.payload.disabled;
      }
    },
    setType: (state: any, action) => {
      state.type = action.payload;
    },
    setCategory: (state: any, action) => {
      state.category = action.payload;
    },
    setSubcategory: (state: any, action) => {
      state.subcategory = action.payload;
    },
    setAccidentFilterArray: (state: any, action) => {
      state.accidentFilters = action.payload;
    },
  },
});

export const {
  setAllFilters,
  setFilterSelectedStatus,
  setSelectedFilters,
  setFilterDisabledStatus,
  setType,
  setCategory,
  setSubcategory,
  setAccidentFilterArray,
} = filterSlice.actions;

export default filterSlice.reducer;
