import "./Statistic2.scss";
import { useRef, useEffect } from "react";
import type { EChartsOption } from "echarts";
import { useAppSelector } from "../state/hooks";
import * as echarts from "echarts";
import AccidentMain from "./AccidentMain";

const MomentOfAccident2 = () => {
  const barChart = useRef<HTMLDivElement>(null);
  const vehicleQuantityChart = useRef<HTMLDivElement>(null);
  const trafficOfficerChart = useRef<HTMLDivElement>(null);
  const searchResults = useAppSelector((state) => state.elastic.value);

  useEffect(() => {
    var chartDom = document.getElementById("bar-chart")!;
    var myChart = echarts.init(chartDom);
    var option: EChartsOption;

    option = {
      height: "85%",
      title: {
        text: "KAZANIN OLUŞ ŞEKLİ",
        left: "center",
        top: "5%",
        textStyle: {
          color: "#1A4158",
          fontWeight: "bold",
          fontSize: 18,
        },
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
      },
      grid: {
        left: "2%",
        right: "5%",
        top: "15%",
        containLabel: true,
      },
      xAxis: {
        type: "value",
        boundaryGap: [0, 0.01],
      },
      yAxis: {
        type: "category",
        data: [
          "YAYAYA ÇARPMA",
          "YANDAN ÇARPMA",
          "DEVRİLME/SAVRULMA/TAKLA",
          "YOLDAN ÇIKMA",
          "ENGEL/CİSİM İLE ÇARPIŞMA",
          "BELİRTİLMEMİŞ",
          "ARAÇTAN İNSAN DÜŞMESİ",
          "YAN YANA ÇARPIŞMA",
          "DURAN ARACA ÇARPMA",
          "HAYVANA ÇARPMA",
          "ÇOKLU ÇARPIŞMA",
          "ZİNCİRLEME ÇARPIŞMA",
          "ARAÇTAN CİSİM DÜŞMESİ",
          "PARK ETMİŞ ARACA ÇARPMA",
          "ARKADAN ÇARPMA",
          "KARŞILIKLI ÇARPIŞMA",
        ],
      },
      series: [
        {
          name: "Oluş Şekli 1",
          type: "bar",
          barGap: 0,
          data: [
            searchResults.filter((el: any) => el.olussekli1 === "YAYAYA ÇARPMA")
              .length > 0
              ? searchResults.filter(
                (el: any) => el.olussekli1 === "YAYAYA ÇARPMA"
              ).length
              : "",
            searchResults.filter((el: any) => el.olussekli1 === "YANDAN ÇARPMA")
              .length > 0
              ? searchResults.filter(
                (el: any) => el.olussekli1 === "YANDAN ÇARPMA"
              ).length
              : "",
            searchResults.filter(
              (el: any) => el.olussekli1 === "DEVRİLME/SAVRULMA/TAKLA"
            ).length > 0
              ? searchResults.filter(
                (el: any) => el.olussekli1 === "DEVRİLME/SAVRULMA/TAKLA"
              ).length
              : "",
            searchResults.filter((el: any) => el.olussekli1 === "YOLDAN ÇIKMA")
              .length > 0
              ? searchResults.filter(
                (el: any) => el.olussekli1 === "YOLDAN ÇIKMA"
              ).length
              : "",
            searchResults.filter(
              (el: any) => el.olussekli1 === "ENGEL/CİSİM İLE ÇARPIŞMA"
            ).length > 0
              ? searchResults.filter(
                (el: any) => el.olussekli1 === "ENGEL/CİSİM İLE ÇARPIŞMA"
              ).length
              : "",
            searchResults.filter((el: any) => el.olussekli1 === "BELİRTİLMEMİŞ")
              .length > 0
              ? searchResults.filter(
                (el: any) => el.olussekli1 === "BELİRTİLMEMİŞ"
              ).length
              : "",
            searchResults.filter(
              (el: any) => el.olussekli1 === "ARAÇTAN İNSAN DÜŞMESİ"
            ).length > 0
              ? searchResults.filter(
                (el: any) => el.olussekli1 === "ARAÇTAN İNSAN DÜŞMESİ"
              ).length
              : "",
            searchResults.filter(
              (el: any) => el.olussekli1 === "YAN YANA ÇARPIŞMA"
            ).length > 0
              ? searchResults.filter(
                (el: any) => el.olussekli1 === "YAN YANA ÇARPIŞMA"
              ).length
              : "",
            searchResults.filter(
              (el: any) => el.olussekli1 === "DURAN ARACA ÇARPMA"
            ).length > 0
              ? searchResults.filter(
                (el: any) => el.olussekli1 === "DURAN ARACA ÇARPMA"
              ).length
              : "",
            searchResults.filter(
              (el: any) => el.olussekli1 === "HAYVANA ÇARPMA"
            ).length > 0
              ? searchResults.filter(
                (el: any) => el.olussekli1 === "HAYVANA ÇARPMA"
              ).length
              : "",
            searchResults.filter(
              (el: any) => el.olussekli1 === "ÇOKLU ÇARPIŞMA"
            ).length > 0
              ? searchResults.filter(
                (el: any) => el.olussekli1 === "ÇOKLU ÇARPIŞMA"
              ).length
              : "",
            searchResults.filter(
              (el: any) => el.olussekli1 === "ZİNCİRLEME ÇARPIŞMA"
            ).length > 0
              ? searchResults.filter(
                (el: any) => el.olussekli1 === "ZİNCİRLEME ÇARPIŞMA"
              ).length
              : "",
            searchResults.filter(
              (el: any) => el.olussekli1 === "ARAÇTAN CİSİM DÜŞMESİ"
            ).length > 0
              ? searchResults.filter(
                (el: any) => el.olussekli1 === "ARAÇTAN CİSİM DÜŞMESİ"
              ).length
              : "",
            searchResults.filter(
              (el: any) => el.olussekli1 === "PARK ETMİŞ ARACA ÇARPMA"
            ).length > 0
              ? searchResults.filter(
                (el: any) => el.olussekli1 === "PARK ETMİŞ ARACA ÇARPMA"
              ).length
              : "",
            searchResults.filter(
              (el: any) => el.olussekli1 === "ARKADAN ÇARPMA"
            ).length > 0
              ? searchResults.filter(
                (el: any) => el.olussekli1 === "ARKADAN ÇARPMA"
              ).length
              : "",
            searchResults.filter(
              (el: any) => el.olussekli1 === "KARŞILIKLI ÇARPIŞMA"
            ).length > 0
              ? searchResults.filter(
                (el: any) => el.olussekli1 === "KARŞILIKLI ÇARPIŞMA"
              ).length
              : "",
          ],
          label: {
            show: true,
            position: "right",
            fontWeight: "bold",
          },
        },
        {
          name: "Oluş Şekli 2",
          type: "bar",
          color: "#E24C4C",
          data: [
            searchResults.filter((el: any) => el.olussekli2 === "YAYAYA ÇARPMA")
              .length > 0
              ? searchResults.filter(
                (el: any) => el.olussekli2 === "YAYAYA ÇARPMA"
              ).length
              : "",
            searchResults.filter((el: any) => el.olussekli2 === "YANDAN ÇARPMA")
              .length > 0
              ? searchResults.filter(
                (el: any) => el.olussekli2 === "YANDAN ÇARPMA"
              ).length
              : "",
            searchResults.filter(
              (el: any) => el.olussekli2 === "DEVRİLME/SAVRULMA/TAKLA"
            ).length > 0
              ? searchResults.filter(
                (el: any) => el.olussekli2 === "DEVRİLME/SAVRULMA/TAKLA"
              ).length
              : "",
            searchResults.filter((el: any) => el.olussekli2 === "YOLDAN ÇIKMA")
              .length > 0
              ? searchResults.filter(
                (el: any) => el.olussekli2 === "YOLDAN ÇIKMA"
              ).length
              : "",
            searchResults.filter(
              (el: any) => el.olussekli2 === "ENGEL/CİSİM İLE ÇARPIŞMA"
            ).length > 0
              ? searchResults.filter(
                (el: any) => el.olussekli2 === "ENGEL/CİSİM İLE ÇARPIŞMA"
              ).length
              : "",
            searchResults.filter((el: any) => el.olussekli2 === "BELİRTİLMEMİŞ")
              .length > 0
              ? searchResults.filter(
                (el: any) => el.olussekli2 === "BELİRTİLMEMİŞ"
              ).length
              : "",
            searchResults.filter(
              (el: any) => el.olussekli2 === "ARAÇTAN İNSAN DÜŞMESİ"
            ).length > 0
              ? searchResults.filter(
                (el: any) => el.olussekli2 === "ARAÇTAN İNSAN DÜŞMESİ"
              ).length
              : "",
            searchResults.filter(
              (el: any) => el.olussekli2 === "YAN YANA ÇARPIŞMA"
            ).length > 0
              ? searchResults.filter(
                (el: any) => el.olussekli2 === "YAN YANA ÇARPIŞMA"
              ).length
              : "",
            searchResults.filter(
              (el: any) => el.olussekli2 === "DURAN ARACA ÇARPMA"
            ).length > 0
              ? searchResults.filter(
                (el: any) => el.olussekli2 === "DURAN ARACA ÇARPMA"
              ).length
              : "",
            searchResults.filter(
              (el: any) => el.olussekli2 === "HAYVANA ÇARPMA"
            ).length > 0
              ? searchResults.filter(
                (el: any) => el.olussekli2 === "HAYVANA ÇARPMA"
              ).length
              : "",
            searchResults.filter(
              (el: any) => el.olussekli2 === "ÇOKLU ÇARPIŞMA"
            ).length > 0
              ? searchResults.filter(
                (el: any) => el.olussekli2 === "ÇOKLU ÇARPIŞMA"
              ).length
              : "",
            searchResults.filter(
              (el: any) => el.olussekli2 === "ZİNCİRLEME ÇARPIŞMA"
            ).length > 0
              ? searchResults.filter(
                (el: any) => el.olussekli2 === "ZİNCİRLEME ÇARPIŞMA"
              ).length
              : "",
            searchResults.filter(
              (el: any) => el.olussekli2 === "ARAÇTAN CİSİM DÜŞMESİ"
            ).length > 0
              ? searchResults.filter(
                (el: any) => el.olussekli2 === "ARAÇTAN CİSİM DÜŞMESİ"
              ).length
              : "",
            searchResults.filter(
              (el: any) => el.olussekli2 === "PARK ETMİŞ ARACA ÇARPMA"
            ).length > 0
              ? searchResults.filter(
                (el: any) => el.olussekli2 === "PARK ETMİŞ ARACA ÇARPMA"
              ).length
              : "",
            searchResults.filter(
              (el: any) => el.olussekli2 === "ARKADAN ÇARPMA"
            ).length > 0
              ? searchResults.filter(
                (el: any) => el.olussekli2 === "ARKADAN ÇARPMA"
              ).length
              : "",
            searchResults.filter(
              (el: any) => el.olussekli2 === "KARŞILIKLI ÇARPIŞMA"
            ).length > 0
              ? searchResults.filter(
                (el: any) => el.olussekli2 === "KARŞILIKLI ÇARPIŞMA"
              ).length
              : "",
          ],
          label: {
            show: true,
            position: "right",
            fontWeight: "bold",
          },
        },
      ],
    };

    myChart.setOption(option);
    myChart?.resize();
  }, [searchResults]);

  useEffect(() => {
    var firstChartDom = document.getElementById("vehicle-quantity-chart")!;
    var secondChartDom = document.getElementById("traffic-officer-chart")!;
    var chart1 = echarts.init(firstChartDom);
    var chart2 = echarts.init(secondChartDom);
    var option1: EChartsOption;
    var option2: EChartsOption;

    option1 = {
      height: "50%",
      tooltip: {
        trigger: "item",
      },
      title: {
        text: "Araç Sayısı Durum",
        left: "center",
        top: "10%",
        textStyle: {
          color: "#1A4158",
          fontWeight: "bold",
          fontSize: 18,
        },
      },
      series: [
        {
          type: "pie",
          top: "35%",
          radius: [35, 70],
          itemStyle: {
            borderColor: "#fff",
            borderWidth: 1,
          },
          label: {
            fontSize: "14px",
            minMargin: 5,
          },
          data: [
            {
              value:
                searchResults.filter(
                  (el: any) => el.arackazaturuid === "TEK ARAÇLI"
                ).length > 0
                  ? searchResults.filter(
                    (el: any) => el.arackazaturuid === "TEK ARAÇLI"
                  ).length
                  : "",
              name: "Tek Araçlı",
            },
            {
              value:
                searchResults.filter(
                  (el: any) => el.arackazaturuid === "İKİ ARAÇLI"
                ).length > 0
                  ? searchResults.filter(
                    (el: any) => el.arackazaturuid === "İKİ ARAÇLI"
                  ).length
                  : "",
              name: "İki Araçlı",
            },
            {
              value:
                searchResults.filter(
                  (el: any) => el.arackazaturuid === "ÇOK ARAÇLI"
                ).length > 0
                  ? searchResults.filter(
                    (el: any) => el.arackazaturuid === "ÇOK ARAÇLI"
                  ).length
                  : "",
              name: "Çok Araçlı",
            },
          ],
        },
      ],
    };
    option2 = {
      height: "50%",
      tooltip: {
        trigger: "item",
      },
      title: {
        text: "Trafik Görevlisi Durum",
        left: "center",
        top: "10%",
        textStyle: {
          color: "#1A4158",
          fontWeight: "bold",
          fontSize: 18,
        },
      },
      series: [
        {
          type: "pie",
          top: "35%",
          radius: [35, 70],
          itemStyle: {
            borderColor: "#fff",
            borderWidth: 1,
          },
          label: {
            fontSize: "14px",
            minMargin: 5,
          },
          data: [
            {
              value:
                searchResults.filter((el: any) => el.trafikgorevlisi === "YOK")
                  .length > 0
                  ? searchResults.filter(
                    (el: any) => el.trafikgorevlisi === "YOK"
                  ).length
                  : "",
              name: "Yok",
            },
            {
              value:
                searchResults.filter((el: any) => el.trafikgorevlisi === "VAR")
                  .length > 0
                  ? searchResults.filter(
                    (el: any) => el.trafikgorevlisi === "VAR"
                  ).length
                  : "",
              name: "Var",
            },
          ],
        },
      ],
    };
    chart1.setOption(option1);
    chart2.setOption(option2);
    // myChart?.resize();
  }, [searchResults]);

  return (
    <div className="MomentOfAccident2">
      <AccidentMain />
      <div style={{ width: "100%", height: "425px" }}>
        <div
          ref={barChart}
          id="bar-chart"
          className="bar-chart"
          style={{ width: "680px", height: "100%" }}
        />
      </div>
      <div style={{ display: "flex", width: "100%", height: "250px" }}>
        <div
          ref={vehicleQuantityChart}
          id="vehicle-quantity-chart"
          className="pie-chart"
          style={{ width: "50%", height: "250px" }}
        />
        <div
          ref={trafficOfficerChart}
          id="traffic-officer-chart"
          className="pie-chart"
          style={{ width: "50%", height: "250px" }}
        />
      </div>
    </div>
  );
};
export default MomentOfAccident2;
