import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useEffect, useRef, useState } from "react";
import { UtilRestApi } from "../../utils/restapi/util";
import { useAppSelector } from "../state/hooks";

export const People = () => {
    const dt = useRef<any>(null);
    const selectedFeature = useAppSelector(state => state.info.selectedFeature);
    const [properties, setProperties] = useState([]);
    const [rows, setRows] = useState(10);
    const [first, setFirst] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getPeopleDetails()
    }, [selectedFeature[0].kazaid])

    const getPeopleDetails = () => {
        setLoading(true)

           UtilRestApi.geoserverWfsReq(`maxFeatures=${rows}&startIndex=${first}&version=1.0.0&request=GetFeature&typeName=usr1_ag_sahis&outputFormat=application%2Fjson&CQL_FILTER=kazaid='${selectedFeature[0].kazaid}'`)
            .then(res => {
                const propertyArr = res.data.features.map((feature: any) => feature.properties)
                const totalCount = res.data.totalFeatures
                setProperties(propertyArr)
                setTotalRecords(totalCount ? totalCount : propertyArr.length);
                setLoading(false)
            })
    }

    const onPage = (event: any) => {
        setRows(event.rows);
        setFirst(event.first);
    }

    const totalRecordsTemplate = <div>Veri Sayısı : {totalRecords}</div>

    return (
        <>
            <DataTable
                ref={dt}
                className='info-table'
                value={properties}
                filterDisplay="menu"
                size="small"
                selectionMode="single"
                responsiveLayout="scroll"
                resizableColumns
                stripedRows
                lazy
                paginator
                first={first}
                rows={rows}
                onPage={onPage}
                totalRecords={totalRecords}
                paginatorLeft={totalRecordsTemplate}
                paginatorPosition='top'
                loading={loading}

            >
                <Column header='Şahıs Türü' field='sahisturuidack' />
                <Column header='Doğum Yılı' field='dogumyili' />
                <Column header='Cinsiyet' field='cinsiyetidack' />
                <Column header='Yaya Giysisi' field='yayagiysiidack' />
                <Column header='Yaya Hareketi' field='yayahareketiack' />
                <Column header='Yolcu Yeri' field='yolcuyeriack' />
                <Column header='Koruyucu' field='koruyucutertibatidack' />
                <Column header='Alkol Durumu' field='alkolkontdurumuack' />
                <Column header='Alkol Sonucu' field='alkolsonucuack' />
                <Column header='Kaza Sonucu' field='kazasonucuack' />
                <Column header='Son Kaza Sonucu' field='sonkazasonucuack' />
                <Column header='Son Kaza Sonucu Tarihi' field='sonkazasonucutarihi' />
                <Column header='Sürücü İhlali 1' field='surucuihlal1ack' />
                <Column header='Sürücü İhlali 2' field='surucuihlal2ack' />
                <Column header='Yolcu İhlali 1' field='yolcuihlal1ack' />
                <Column header='Yolcu İhlali 2' field='yolcuihlal2ack' />
                <Column header='Yaya İhlali 1' field='yayaihlal1ack' />
                <Column header='Yaya İhlali 2' field='yayaihlal2ack' />
            </DataTable>
        </>
    )
}
