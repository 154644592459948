import axios from 'axios';

const { REACT_APP_BASE_URL } = process.env

const getToken = () => {
    return localStorage.getItem('ag_token')
}

async function login(username: string, password: string) {
    const postData = {
        username: username,
        password: password
    }

    return await axios.post(REACT_APP_BASE_URL + "auth/login", postData)

}

async function logout() {
    const config = {
        headers: { Authorization: 'Bearer ' + getToken() }
    };
    return await axios.post(REACT_APP_BASE_URL + "auth/logout", config)

}

export const LoginRestApi = {
    login,
    logout
}