import './Legend.scss';
import { Image } from "primereact/image";
import { useAppSelector } from '../state/hooks';
interface IProps {
    index: number
}

const legendArr = [
    { color: 'red', label: 'Ölümlü Kaza' },
    { color: 'orange', label: 'Yaralanmalı Kaza' },
    { color: 'blue', label: 'Maddi Hasarlı Kaza' },
]

export const Legend = (props: IProps) => {
    const index = props.index
    const mapZoomValue = useAppSelector(state => state.maps.zoom);
    
    return (
        <>
            {index === 0 && mapZoomValue>12 && <div className='Legend'>
                <div className='color-info' style={{color:"#ffff", marginBottom:"5px", display:"flex",justifyContent:"center"}}>Trafik Kazaları Lejantı</div>
                {legendArr.map((item: any) => {
                    return (
                        <div key={item.color} className='lejant-info'>
                            <div className='color' style={{ background: `${item.color}` }}></div>
                            <div className='color-info'>{item.label}</div>
                        </div>
                        
                    )
                })}
            
                {/* <div><span style={{ color: 'white', fontSize: '13.5px' }}>* Lejantlar belli bir zoom seviyesinde görülebilmektedir.</span></div> */}
            </div>}

            {index === 0 && mapZoomValue<=12 && <div className='cluster-Legend'>
            
                 <Image src="kümele-lejant.png" width="175px" />
                {/* <div><span style={{ color: 'white', fontSize: '13.5px' }}>* Lejantlar belli bir zoom seviyesinde görülebilmektedir.</span></div> */}
            </div>}
            
            {index === 1 &&
                <></>
            }
            {index === 2 &&
                <></>
            }
        </>
    )
}
