import { createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  value: {
    dropdownStateType: "DEFAULT",
  },
};

export const dropdownStateSlice = createSlice({
  name: "buttonState",
  initialState,
  reducers: {
    dropdownState: (state: any, action) => {
      state.value = action.payload;
    },
  },
});

export const { dropdownState } = dropdownStateSlice.actions;

export default dropdownStateSlice.reducer;
