import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import { CitiesRestApi } from "../../utils/restapi/getCities";
import GoToLocation from '../map/coordinate/go-to-location/GoToLocation';
import { setDrawState } from '../state/features/drawStateSlice';
import { dropdownState } from '../state/features/dropdownButtonState';
import { setSelectedCityStatus } from '../state/features/dropdownResults';
import { selectedListVisibilty, setClickedFeatureId, setResults, setResultScore, setResultTime, setSelectedResult, setSelectedCity, setSelectedTown, setSelectedNeighborhood } from '../state/features/elasticSlice';
import { setGoToLocation } from '../state/features/sharedSlice';
import { setShapeState } from '../state/features/zoomStateSlice';
import { useAppSelector } from '../state/hooks';
import { DrawStateType } from "../../utils/model"
import './DropdownGroup.scss';
// import { ActiveButtonState } from '../shared/button-state/ButtonState';

export enum DropDownSelectState {
    CITY = 'CITY',
    TOWN = 'TOWN',
    NEIGBORHOOD = 'NEIGBORHOOD',
    DEFAULT = 'DEFAULT'
}

export const DropdownGroup = () => {
    const dispatch = useDispatch();
    const [cities, setCities] = useState<any>(null);
    const [towns, setTowns] = useState<any>(null);
    const [neighborhood, setNeighborhood] = useState<any>(null);
    const selectedCity = useAppSelector(state => state.elastic.city)
    const selectedTown = useAppSelector(state => state.elastic.town)
    const selectedNeighborhood = useAppSelector(state => state.elastic.neighborhood)
    const goToCoordinateState = useAppSelector(state => state.shared.goToLocation);
    const activeButtonType = useAppSelector(
        (state) => state.buttonState.activeButtonType
      );
    useEffect(() => {

        if(activeButtonType==="LOCATION"){
            CitiesRestApi.getCities().then((res) => {
                const citiesArray = res.data
                sortArrayAlphabetically(citiesArray)
                setCities(citiesArray)
            })
        }

    }, [activeButtonType]);

    useEffect(() => {
        setTowns(undefined)
        setNeighborhood(undefined)
        dispatch(setSelectedTown(''))
        dispatch(setSelectedNeighborhood(''))
        if (selectedCity && selectedTown === '' && selectedNeighborhood === '') {
            dispatch(setSelectedCityStatus(true))
            CitiesRestApi.getTowns(selectedCity.ad).then((res) => {
                const townsArray = res.data;
                sortArrayAlphabetically(townsArray)
                setTowns(townsArray)
            })
        }
        else {
            dispatch(setSelectedCityStatus(false))
        }
    }, [selectedCity]);

    useEffect(() => {
        setNeighborhood(undefined)
        dispatch(setSelectedNeighborhood(''))
        if (selectedTown === '') {
            dispatch(dropdownState(DropDownSelectState.CITY))
        }

        if (selectedTown) {
            CitiesRestApi.getNeighborhood(selectedCity.ad, selectedTown.ad).then((res) => {
                const neighborhoodArray = res.data
                sortArrayAlphabetically(neighborhoodArray)
                setNeighborhood(neighborhoodArray)
            })
        }
    }, [selectedTown]);

    useEffect(() => {
        if (selectedNeighborhood === '') {
            dispatch(dropdownState(DropDownSelectState.TOWN))
        }
        if (selectedNeighborhood) {
        }
    }, [selectedNeighborhood])


    const clearDraw=()=>{
        dispatch(setDrawState(DrawStateType.CLEAR) );
        dispatch(setShapeState(false));
    }

    const onCityChange = (e: any) => {
        // dispatch(searchListVisibilty(true))
        // dispatch(setSelectedResult({}));
        // setDetailsData(null)
        dispatch(setClickedFeatureId(null));
        dispatch(selectedListVisibilty(false))
        // dispatch(setResults(searchResultsArray))

        if (e.value) {
            dispatch(setSelectedCity(e.value))
            dispatch(dropdownState(DropDownSelectState.CITY))
            clearDraw();
        }
        if (!e.value) {
            dispatch(setSelectedCity(''))
            dispatch(setSelectedTown(''))
            dispatch(setSelectedNeighborhood(''))
            dispatch(setResults([]));
            dispatch(setResultScore(0))
            dispatch(setResultTime(0))
            dispatch(setSelectedResult({}));
        }
    }

    const onTownChange = (e: any) => {
        if (e.value) {
            dispatch(setSelectedTown(e.value))
            dispatch(dropdownState(DropDownSelectState.TOWN))
            clearDraw();
        }
        if (!e.value) {
            dispatch(setSelectedTown(''))
            dispatch(setSelectedNeighborhood(''))
            dispatch(dropdownState(DropDownSelectState.CITY))
        }
    }

    const onNeighborhoodChange = (e: any) => {
        if (e.value) {
            dispatch(setSelectedNeighborhood(e.value))
            dispatch(dropdownState(DropDownSelectState.NEIGBORHOOD))
            clearDraw();
        } else {
            dispatch(setSelectedNeighborhood(''))
        }
    }

    const sortArrayAlphabetically = (array: any[]) => {
        if (array && array.length > 0) {
            array.sort((a: any, b: any) => a.ad.localeCompare(b.ad, 'tr'))
        }
    }

    const toggleGoCoordinate = () => {
        dispatch(setGoToLocation(!goToCoordinateState));
    };

    return (
        <>
        <div className='dropdown-group'>
            <Dropdown showClear={selectedCity} value={selectedCity} options={cities} onChange={onCityChange} optionLabel="ad" placeholder="İl" />
            <Dropdown showClear={selectedTown} value={selectedTown} options={towns} onChange={onTownChange} optionLabel="ad" placeholder="İlçe" filter filterBy='ad' />
            <Dropdown showClear={selectedNeighborhood} value={selectedNeighborhood} options={neighborhood} onChange={onNeighborhoodChange} optionLabel="ad" placeholder="Mahalle" filter filterBy='ad' />
            <div className='coordinate-section'>

            <Button
          className="p-button coordinate-button"
          tooltip="Koordinata Git"
          label="Konuma Git"
          tooltipOptions={{
            position: "bottom",
            mouseTrack: true,
            mouseTrackTop: 15,
          }}
          style={{color:"white", width:"200px"}}
          icon="pi pi-map-marker"
          onClick={() => toggleGoCoordinate()}
        ></Button>
            </div>
        </div>

        {
                goToCoordinateState &&
                <GoToLocation />
            }
        </>

    )
};
