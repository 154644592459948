import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import { useContext, useEffect, useState } from "react";
import { useAppSelector } from "../../state/hooks";
import { MapContext } from "../MapContext";
import { Feature } from "ol";
import Point from "ol/geom/Point";
import { Fill, Stroke, Style } from "ol/style";
import CircleStyle from "ol/style/Circle";
import Geometry from "ol/geom/Geometry";
import MultiLineString from "ol/geom/MultiLineString";
import MultiPolygon from "ol/geom/MultiPolygon";
import MultiPoint from "ol/geom/MultiPoint";
import LineString from "ol/geom/LineString";
import Polygon from "ol/geom/Polygon";

export const DrawSelectedFeature = () => {
    const map = useContext(MapContext);
    const selectedFeature: any = useAppSelector(state => state.info.selectedFeature);
    const featureType = useAppSelector(state => state.info.featureType);
    const isTableSelection = useAppSelector(state => state.info.isTableSelection);
    const [highlightLayer, sethighlightLayer] = useState<VectorLayer<VectorSource<Geometry>> | null>(null)
    let feature: Feature<Geometry>

    useEffect(() => {
        const layer = new VectorLayer({
            zIndex: 1000,
            source: new VectorSource(),
        })

        layer.set('featureSelection', true)
        sethighlightLayer(layer);
        map.addLayer(layer);

        return () => {
            if (selectedFeature) {
                map.removeLayer(layer)
            }
        }
    }, [])

    useEffect(() => {
        if (highlightLayer && selectedFeature.length === 1) {
            
            highlightLayer.getSource()?.clear();
            let translated;

            if (featureType) {
                let geom: any;
                if (featureType.type === 'accident') {
                    switch (featureType.geomtype) {
                        case 'Point':
                            geom = new Point(selectedFeature[0].wkb_geometry.coordinates)
                            break;
                        default:
                            break;
                    }

                } else {
                    let coord = selectedFeature[0].getGeometry();
                    switch (featureType.geomtype) {
                        case 'Point':
                            geom = new Point((coord as any)?.getCoordinates())
                            break;
                        case 'MultiPoint':
                            geom = new MultiPoint((coord as any)?.getCoordinates())
                            break;
                        case 'LineString':
                            geom = new LineString((coord as any)?.getCoordinates())
                            break;
                        case 'MultiLineString':
                            geom = new MultiLineString((coord as any)?.getCoordinates())
                            break;
                        case 'Polygon':
                            geom = new Polygon((coord as any)?.getCoordinates())
                            break;
                        case 'MultiPolygon':
                            geom = new MultiPolygon((coord as any)?.getCoordinates())
                            break;
                        default:
                            break;
                    }
                }

                if (isTableSelection || featureType.type === 'accident') {
                    translated = geom?.transform('EPSG:4326', 'EPSG:3857')
                    feature = new Feature(translated);
                }
                if (!isTableSelection) {
                    feature = new Feature(geom);
                }


                if (featureType.geomtype === 'Point' || featureType.geomtype === 'MultiPoint') {
                    feature?.setStyle(new Style({
                        image: new CircleStyle({
                            radius: 15,
                            fill: new Fill({
                                color: 'transparent',
                            }),
                            stroke: new Stroke({
                                color: 'rgb(40,102,136,0.65)',
                                width: 8,
                            })
                        }),
                    }))
                }
                if (featureType.geomtype === 'Polygon' || featureType.geomtype === 'MultiPolygon') {
                    feature?.setStyle(new Style({
                        stroke: new Stroke({
                            color: '#00000090',
                            width: 2
                        }),
                        fill: new Fill({
                            color: '#00fffe70',

                        }),
                    }))
                }
                if (featureType.geomtype === 'LineString' || featureType.geomtype === 'MultiLineString') {
                    feature?.setStyle(new Style({
                        stroke: new Stroke({
                            color: '#00000090',
                            width: 2
                        }),
                        fill: new Fill({
                            color: '#00fffe70',

                        }),
                    }))
                }

                highlightLayer?.getSource()?.addFeature(feature);
                map.getView().fit(isTableSelection ? (translated as any) : (geom as any), { duration: 1000, padding: [10, 10, 10, 10], maxZoom: 22 })
            }
        }
        if (highlightLayer && selectedFeature.length === 0) {
            highlightLayer.getSource()?.clear();
        }
    }, [featureType, isTableSelection, selectedFeature, highlightLayer])


    return (
        <></>
    )
}
