import { InputText } from "primereact/inputtext"
import { useAppSelector } from "../state/hooks";

export const AccidentResult = () => {
    const selectedFeature = useAppSelector(state => state.info.selectedFeature);

    return (
        <div className='container'>

            <div className="left-group">
                <div className='input-group'>
                    <label htmlFor="name">Kaza Şiddeti</label>
                    <InputText id="name" value={selectedFeature[0].kaza_siddet || ''} />
                </div>
                <div className='input-group'>
                    <label htmlFor="name">Araç Sayısı</label>
                    <InputText id="name" value={selectedFeature[0].arac_say || ''} />
                </div>
                <div className='input-group'>
                    <label htmlFor="name">Ölü Sayısı</label>
                    <InputText id="name" value={selectedFeature[0].olu_say || ''} />
                </div>
                <div className='input-group'>
                    <label htmlFor="name">Yaralı Sayısı</label>
                    <InputText id="name" value={selectedFeature[0].yarali_say || ''} />
                </div>
            </div>

            <div className="right-group">
                <div className='input-group'>
                    <label htmlFor="name">İlk Yardım</label>
                    <InputText id="name" value={selectedFeature[0].ilkyardim || ''} />
                </div>
                <div className='input-group'>
                    <label htmlFor="name">Sağlam Sayısı</label>
                    <InputText id="name" value={selectedFeature[0].saglam_say || ''} />
                </div>
                <div className='input-group'>
                    <label htmlFor="name">Sürücü Yok Sayısı</label>
                    <InputText id="name" value={selectedFeature[0].surucuyok_say || ''} />
                </div>
            </div>

        </div>
    )
}
