import axios from 'axios';

const { REACT_APP_BASE_URL } = process.env
const getToken = () => {
    return localStorage.getItem('ag_token')
}

async function getCities() {
    const config = {
        headers: { Authorization: 'Bearer ' + getToken() }
    };
    return await axios.get(REACT_APP_BASE_URL + 'layer/il', config);
}

async function getTowns(selectedCity: string) {
    const config = {
        headers: { Authorization: 'Bearer ' + getToken() }
    };
    return await axios.get(REACT_APP_BASE_URL + 'layer/ilce/' + selectedCity, config)
}

async function getNeighborhood(selectedCity: string, selectedTown: string) {
    const config = {
        headers: { Authorization: 'Bearer ' + getToken() }
    };
    return await axios.get(REACT_APP_BASE_URL + 'layer/mahalle/' + selectedCity + '/ilce/' + selectedTown, config)
}
async function getStreet(street: string) {
    const config = {
        headers: { Authorization: 'Bearer ' + getToken() }
    };
    return await axios.get(REACT_APP_BASE_URL + 'data/street/' + street, config)
}

export const CitiesRestApi = {
    getCities, getTowns, getNeighborhood, getStreet
}