import "./Statistic2.scss";
import { useRef, useEffect, useState } from "react";
import type { EChartsOption } from "echarts";
import { useAppSelector } from "../state/hooks";
import * as echarts from "echarts";
import AccidentMain from "./AccidentMain";
const General = () => {
  const barChart = useRef<HTMLDivElement>(null);
  const weeklyChart = useRef<HTMLDivElement>(null);
  const firstChart = useRef<HTMLDivElement>(null);
  const secondChart = useRef<HTMLDivElement>(null);
  const searchResults = useAppSelector((state) => state.elastic.value);
  const [accidentFilterLength, setAccidentFilterLength] = useState<number>(
    searchResults.length
  );

  const hours = [
    "24",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
  ];
  const days = ["Ctesi", "Cuma", "Perş", "Çarş", "Salı", "Ptesi", "Pazar"];
  const data: any[] = [];
  for (let i = 0; i < 7; i++) {
    for (let j = 0; j < 24; j++) {
      const accidents = searchResults.filter(
        (dd: any) =>
          new Date(dd.kazatarihi).getDay() === i &&
          new Date(dd.kazatarihi).getHours() === j
      );
      const row = [j, i, accidents.length > 0 ? accidents.length : undefined];
      data.push(row);
    }
  }

  useEffect(() => {
    if (searchResults) {
      setAccidentFilterLength(searchResults.length);
    }
  }, [searchResults]);

  useEffect(() => {
    var weeklyChartDom = document.getElementById("bar-chart")!;
    var chart1 = echarts.init(weeklyChartDom);
    var option: any;

    option = {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          // Use axis to trigger tooltip
          type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
        },
      },
      legend: {},
      grid: {
        left: "3%",
        right: "4%",
        bottom: "65%",
        top: "100%",
        containLabel: true,
      },
      xAxis: {
        type: "value",
      },
      yAxis: {
        type: "category",
        data: [""],
      },
      series: [
        {
          name: "Yerleşim Yeri Dışı",
          type: "bar",
          stack: "total",
          label: {
            show: true,
          },
          emphasis: {
            focus: "series",
          },
          data: [
            searchResults.filter(
              (el: any) => el.yerlesimyeriack === "YERLEŞİM YERİ DIŞI"
            ).length > 0
              ? searchResults.filter(
                (el: any) => el.yerlesimyeriack === "YERLEŞİM YERİ DIŞI"
              ).length
              : 0,
          ],
        },
        {
          name: "Yerleşim Yeri İçi",
          type: "bar",
          stack: "total",
          label: {
            show: true,
          },
          emphasis: {
            // focus: 'series'
          },
          data: [
            searchResults.filter(
              (el: any) => el.yerlesimyeriack === "YERLEŞİM YERİ İÇİ"
            ).length > 0
              ? searchResults.filter(
                (el: any) => el.yerlesimyeriack === "YERLEŞİM YERİ İÇİ"
              ).length
              : 0,
          ],
        },
      ],
    };

    chart1.setOption(option);
    // myChart?.resize();
  }, [searchResults]);



  //the function that calculates the maximum number of given data for heatchart
  function findMaxInNestedList(arr: any) {
    let max = null;
    for (let i = 0; i < arr.length; i++) {
      const innerArr = arr[i];
      const value = innerArr[2]
      if (value !== null && value !== undefined && typeof value === 'number') {
        if (max === null || value > max) {
          max = value;
        }
      }
    }
    return max;
  }


  useEffect(() => {

    var weeklyChartDom = document.getElementById("weekly-chart")!;
    var chart1 = echarts.init(weeklyChartDom);
    var option: any;

    option = {
      tooltip: {
        position: "top",
        formatter: function (params: any) {
          let tooltip = params;
          return "Kaza Sayısı <br/>" + tooltip.marker + tooltip.data[2];
        },
      },
      grid: {
        left: "9%",
        right: "3%",
        height: "65%",
        top: "5%",
      },
      xAxis: {
        type: "category",
        data: hours,
        splitArea: {
          show: true,
        },
      },
      yAxis: {
        type: "category",
        data: days,
        splitArea: {
          show: true,
        },
      },
      visualMap: {
        min: 0,
        max: findMaxInNestedList(data),
        calculable: true,
        orient: "horizontal",
        left: "center",
        top: "80%",
        itemHeight: 250,
      },
      series: [
        {
          name: "Kaza Sayısı",
          type: "heatmap",
          data: data,
          center: ["30%", "50%"],
          label: {
            show: true,
          },
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
      ],
    };
    chart1.setOption(option);
    // myChart?.resize();
  }, [searchResults, accidentFilterLength]);

  useEffect(() => {
    var firstChartDom = document.getElementById("first-chart")!;
    var secondChartDom = document.getElementById("second-chart")!;
    var chart1 = echarts.init(firstChartDom);
    var chart2 = echarts.init(secondChartDom);
    var option1: EChartsOption;
    var option2: EChartsOption;

    option1 = {
      height: "50%",
      tooltip: {
        trigger: "item",
      },
      title: {
        top: "15%",
        text: "Hava Durumu",
        left: "center",
        textStyle: {
          color: "#1A4158",
          fontWeight: "bold",
          fontSize: 18,
        },
      },
      series: [
        {
          type: "pie",
          top: "35%",
          radius: [35, 70],
          itemStyle: {
            borderColor: "#fff",
            borderWidth: 1,
          },
          label: {
            fontSize: "14px",
            minMargin: 5,
          },
          data: [
            {
              value:
                searchResults.filter((el: any) => el.havadurumuid === "AÇIK")
                  .length > 0
                  ? searchResults.filter(
                    (el: any) => el.havadurumuid === "AÇIK"
                  ).length
                  : "",
              name: "Açık",
            },
            {
              value:
                searchResults.filter((el: any) => el.havadurumuid === "BULUTLU")
                  .length > 0
                  ? searchResults.filter(
                    (el: any) => el.havadurumuid === "BULUTLU"
                  ).length
                  : "",
              name: "Bulutlu",
            },
            {
              value:
                searchResults.filter((el: any) => el.havadurumuid === "DOLU")
                  .length > 0
                  ? searchResults.filter(
                    (el: any) => el.havadurumuid === "DOLU"
                  ).length
                  : "",
              name: "Dolu",
            },
            {
              value:
                searchResults.filter((el: any) => el.havadurumuid === "KAR")
                  .length > 0
                  ? searchResults.filter((el: any) => el.havadurumuid === "KAR")
                    .length
                  : "",
              name: "Kar",
            },
            {
              value:
                searchResults.filter((el: any) => el.havadurumuid === "TİPİ")
                  .length > 0
                  ? searchResults.filter(
                    (el: any) => el.havadurumuid === "TİPİ"
                  ).length
                  : "",
              name: "Tipi",
            },
            {
              value:
                searchResults.filter((el: any) => el.havadurumuid === "YAĞMUR")
                  .length > 0
                  ? searchResults.filter(
                    (el: any) => el.havadurumuid === "YAĞMUR"
                  ).length
                  : "",
              name: "Yağmur",
            },
            {
              value:
                searchResults.filter(
                  (el: any) => el.havadurumuid === "SULUSEPKEN"
                ).length > 0
                  ? searchResults.filter(
                    (el: any) => el.havadurumuid === "SULUSEPKEN"
                  ).length
                  : "",
              name: "Sulusepken",
            },
            {
              value:
                searchResults.filter(
                  (el: any) => el.havadurumuid === "TOZ/KUM FIRTINASI"
                ).length > 0
                  ? searchResults.filter(
                    (el: any) => el.havadurumuid === "TOZ/KUM FIRTINASI"
                  ).length
                  : "",
              name: "Toz/Kum Fırtınası",
            },
            {
              value:
                searchResults.filter(
                  (el: any) => el.havadurumuid === "SİS/DUMAN"
                ).length > 0
                  ? searchResults.filter(
                    (el: any) => el.havadurumuid === "SİS/DUMAN"
                  ).length
                  : "",
              name: "Sis/Duman",
            },
            {
              value:
                searchResults.filter(
                  (el: any) => el.havadurumuid === "KUVVETLİ RÜZGAR"
                ).length > 0
                  ? searchResults.filter(
                    (el: any) => el.havadurumuid === "KUVVETLİ RÜZGAR"
                  ).length
                  : "",
              name: "Kuvvetli Rüzgar",
            },
          ],
        },
      ],
    };
    option2 = {
      height: "50%",
      tooltip: {
        trigger: "item",
      },
      title: {
        top: "15%",
        text: "Vakit Durumu",
        left: "center",
        textStyle: {
          color: "#1A4158",
          fontWeight: "bold",
          fontSize: 18,
        },
      },
      series: [
        {
          type: "pie",
          top: "35%",
          radius: [35, 70],
          itemStyle: {
            borderColor: "#fff",
            borderWidth: 1,
          },
          label: {
            fontSize: "14px",
            minMargin: 5,
          },
          data: [
            {
              value:
                searchResults.filter((el: any) => el.gunid === "ALACAKARANLIK")
                  .length > 0
                  ? searchResults.filter(
                    (el: any) => el.gunid === "ALACAKARANLIK"
                  ).length
                  : "",
              name: "Alacakaranlık",
            },
            {
              value:
                searchResults.filter((el: any) => el.gunid === "GÜNDÜZ")
                  .length > 0
                  ? searchResults.filter((el: any) => el.gunid === "GÜNDÜZ")
                    .length
                  : "",
              name: "Gündüz",
            },
            {
              value:
                searchResults.filter((el: any) => el.gunid === "GECE").length >
                  0
                  ? searchResults.filter((el: any) => el.gunid === "GECE")
                    .length
                  : "",
              name: "Gece",
            },
          ],
        },
      ],
    };
    chart1.setOption(option1);
    chart2.setOption(option2);

    // myChart?.resize();
  }, [searchResults]);

  return (
    <div className="General">
      <AccidentMain />
      <div
        className="third-row"
        style={{ display: "flex", width: "100%", height: "100px", marginTop: "1px" }}
      >
        <div
          ref={barChart}
          id="bar-chart"
          className="bar-chart"
          style={{ width: "100%", height: "100%" }}
        />
      </div>
      <div
        className="fourth-row"
        style={{ display: "flex", width: "100%", height: "275px" }}
      >
        <div
          ref={weeklyChart}
          id="weekly-chart"
          className="bar-chart"
          style={{ width: "100%", height: "275px" }}
        />
      </div>
      <div
        className="fifth-row"
        style={{ display: "flex", width: "100%", height: "275px" }}
      >
        <div
          ref={firstChart}
          id="first-chart"
          className="pie-chart"
          style={{ width: "50%", height: "275px" }}
        />
        <div
          ref={secondChart}
          id="second-chart"
          className="pie-chart"
          style={{ width: "50%", height: "275px" }}
        />
      </div>
    </div>
  );
};
export default General;
