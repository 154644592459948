import axios from "axios";

const { REACT_APP_BASE_URL } = process.env;

const getToken = () => {
  return localStorage.getItem("ag_token");
};

async function getAllExternalServices() {
  const config = {
    headers: { Authorization: "Bearer " + getToken() },
  };
  return await axios.get(REACT_APP_BASE_URL + "external/services", config);
}

async function getExternalServiceById(serviceId: number) {
  const config = {
    headers: { Authorization: "Bearer " + getToken() },
  };
  return await axios.get(
    REACT_APP_BASE_URL + "external/services/" + serviceId,
    config
  );
}

async function getExternalLayers(serviceId: number) {
  const config = {
    headers: { Authorization: "Bearer " + getToken() },
  };
  return axios.get(REACT_APP_BASE_URL + `external/${serviceId}/layers`, config);
}

export const ExternalServiceRestApi = {
  getAllExternalServices,
  getExternalLayers,
  getExternalServiceById,
};
