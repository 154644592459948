import { createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  selectedFeature: undefined,
  columns: [],
  isTableSelection: false,
  featureType: {
    type: "",
    geomtype: "",
  },
  pixel: [],
  layerForInfo: {},
  layerForTable: {},
  resultFromMapClick: {
    accident: [],
    geoserver: [],
  },
};

export const infoSlice = createSlice({
  name: "info",
  initialState,
  reducers: {
    //info alirken koşulları ayırabilmek için info alınan
    // her feature'ın bağlı olduğu layer bulunuyor ve gereken yerlere setleniyor.
    setLayerForInfo: (state: any, action) => {
      state.layerForInfo = action.payload;
    },
    //hangi katmanın tablosu açılacak bununla setleniyor,
    // tablo modülünde ona göre kaza ya da geoserver için açılacak tablo belirleniyor.
    setLayerForTable: (state: any, action) => {
      state.layerForTable = action.payload;
    },
    setSelectedFeature: (state: any, action) => {
      state.selectedFeature = action.payload;
    },
    setColumns: (state: any, action) => {
      state.columns = action.payload;
    },
    // feature'in tablodan secilip secilmedigi bilgisi
    setIsTableSelection: (state: any, action) => {
      state.isTableSelection = action.payload;
    },
    // feature bilgisinin nereden alindigini ve acilacak olan paneli belirlemek icin gonderilen type
    setFeatureSelectionType: (state: any, action) => {
      state.featureType = action.payload;
    },
    //mapclick pixel
    setPixel: (state: any, action) => {
      state.pixel = action.payload;
    },
  },
});

export const {
  setLayerForInfo,
  setLayerForTable,
  setSelectedFeature,
  setColumns,
  setIsTableSelection,
  setFeatureSelectionType,
  setPixel,
} = infoSlice.actions;
export default infoSlice.reducer;
