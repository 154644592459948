export enum ActiveButtonState {
    LOCATION = 'LOCATION',
    DATE_FILTER = 'DATE_FILTER',
    DRAW = 'DRAW',
    FILTERS = 'FILTERS',
    SIDEBAR = 'SIDEBAR',
    CLEAR = 'CLEAR',
    SEARCH = 'SEARCH',
    ZOOM = 'ZOOM',
    DEFAULT = 'DEFAULT'
}

export enum ActiveButtonStateRight {
    MINIMIZE = 'MINIMIZE',
    DASHBOARD = 'DASHBOARD',
    THEME = 'THEME',
    STATISTIC = 'STATISTIC',
    USER_OPERATIONS = 'USER_OPERATIONS',
    DEFAULT = 'DEFAULT',
    DATE_STATS = 'DATE_STATS'
    
}