import "./Statistic2.scss";
import { useRef, useEffect } from "react";
import type { EChartsOption } from "echarts";
import { useAppSelector } from "../state/hooks";
import * as echarts from "echarts";
import AccidentMain from "./AccidentMain";

const MomentOfAccident = () => {
  const barChart = useRef<HTMLDivElement>(null);
  const roadWorkChart = useRef<HTMLDivElement>(null);
  const visionBlockerChart = useRef<HTMLDivElement>(null);
  const searchResults = useAppSelector((state) => state.elastic.value);

  useEffect(() => {
    var chartDom = document.getElementById("bar-chart")!;
    var myChart = echarts.init(chartDom);
    var option: EChartsOption;

    option = {
      height: "75%",
      title: {
        text: "İLK ÇARPIŞMA",
        left: "center",
        top: "5%",
        textStyle: {
          color: "#1A4158",
          fontWeight: "bold",
          fontSize: 18,
        },
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
      },
      grid: {
        right: 40,
        width: "65%",
      },
      xAxis: {
        max: "dataMax",
      },
      yAxis: {
        type: "category",
        data: [
          "YOL ÜZERİNDE",
          "YOL KENARINDA (BANKET DIŞI)",
          "ORTA REFÜJDE",
          "BANKET ÜZERİNDE",
          "YAYA KALDIRIMINDA",
          "DİĞER",
          "TESPİT EDİLEMEDİ",
          "YAYA GEÇİDİ",
          "DURAK",
          "HEMZEMİN GEÇİT",
          "OKUL GEÇİDİ",
          "BİSİKLET YOLU",
          "BİSİKLET ŞERİDİ",
        ],
      },
      series: [
        {
          data: [
            searchResults.filter((el: any) => el.ilkcarpisma === "YOL ÜZERİNDE")
              .length > 0
              ? searchResults.filter(
                (el: any) => el.ilkcarpisma === "YOL ÜZERİNDE"
              ).length
              : "",
            searchResults.filter(
              (el: any) => el.ilkcarpisma === "YOL KENARINDA (BANKET DIŞI)"
            ).length > 0
              ? searchResults.filter(
                (el: any) => el.ilkcarpisma === "YOL KENARINDA (BANKET DIŞI)"
              ).length
              : "",
            searchResults.filter((el: any) => el.ilkcarpisma === "ORTA REFÜJDE")
              .length > 0
              ? searchResults.filter(
                (el: any) => el.ilkcarpisma === "ORTA REFÜJDE"
              ).length
              : "",
            searchResults.filter(
              (el: any) => el.ilkcarpisma === "BANKET ÜZERİNDE"
            ).length > 0
              ? searchResults.filter(
                (el: any) => el.ilkcarpisma === "BANKET ÜZERİNDE"
              ).length
              : "",
            searchResults.filter(
              (el: any) =>
                el.ilkcarpisma === "YAYA KALDIRIMINDA" ||
                el.ilkcarpisma === "YAYA KALDIRIminDA"
            ).length > 0
              ? searchResults.filter(
                (el: any) =>
                  el.ilkcarpisma === "YAYA KALDIRIMINDA" ||
                  el.ilkcarpisma === "YAYA KALDIRIminDA"
              ).length
              : "",
            searchResults.filter((el: any) => el.ilkcarpisma === "DİĞER")
              .length > 0
              ? searchResults.filter((el: any) => el.ilkcarpisma === "DİĞER")
                .length
              : "",
            searchResults.filter(
              (el: any) => el.ilkcarpisma === "TESPİT EDİLEMEDİ"
            ).length > 0
              ? searchResults.filter(
                (el: any) => el.ilkcarpisma === "TESPİT EDİLEMEDİ"
              ).length
              : "",
            searchResults.filter((el: any) => el.ilkcarpisma === "YAYA GEÇİDİ")
              .length > 0
              ? searchResults.filter(
                (el: any) => el.ilkcarpisma === "YAYA GEÇİDİ"
              ).length
              : "",
            searchResults.filter((el: any) => el.ilkcarpisma === "DURAK")
              .length > 0
              ? searchResults.filter((el: any) => el.ilkcarpisma === "DURAK")
                .length
              : "",
            searchResults.filter(
              (el: any) => el.ilkcarpisma === "HEMZEMİN GEÇİT"
            ).length > 0
              ? searchResults.filter(
                (el: any) => el.ilkcarpisma === "HEMZEMİN GEÇİT"
              ).length
              : "",
            searchResults.filter((el: any) => el.ilkcarpisma === "OKUL GEÇİDİ")
              .length > 0
              ? searchResults.filter(
                (el: any) => el.ilkcarpisma === "OKUL GEÇİDİ"
              ).length
              : "",
            searchResults.filter(
              (el: any) => el.ilkcarpisma === "BİSİKLET YOLU"
            ).length > 0
              ? searchResults.filter(
                (el: any) => el.ilkcarpisma === "BİSİKLET YOLU"
              ).length
              : "",
            searchResults.filter(
              (el: any) => el.ilkcarpisma === "BİSİKLET ŞERİDİ"
            ).length > 0
              ? searchResults.filter(
                (el: any) => el.ilkcarpisma === "BİSİKLET ŞERİDİ"
              ).length
              : "",
          ],
          label: {
            show: true,
            position: "right",
            fontWeight: "bold",
          },
          type: "bar",
          showBackground: true,
          backgroundStyle: {
            color: "rgba(180, 180, 180, 0.6)",
          },
        },
      ],
    };

    myChart.setOption(option);
    myChart?.resize();
  }, [searchResults]);

  useEffect(() => {
    var firstChartDom = document.getElementById("road-work-chart")!;
    var secondChartDom = document.getElementById("vision-blocker-chart")!;
    var chart1 = echarts.init(firstChartDom);
    var chart2 = echarts.init(secondChartDom);
    var option1: EChartsOption;
    var option2: EChartsOption;

    option1 = {
      height: "50%",
      tooltip: {
        trigger: "item",
      },
      title: {
        text: "Yolda Çalışma Durum",
        left: "center",
        top: "5%",
        textStyle: {
          color: "#1A4158",
          fontWeight: "bold",
          fontSize: 18,
        },
      },
      series: [
        {
          type: "pie",
          top: "35%",
          radius: [35, 70],
          itemStyle: {
            borderColor: "#fff",
            borderWidth: 1,
          },
          label: {
            fontSize: "14px",
            minMargin: 5,
          },
          data: [
            {
              value:
                searchResults.filter((el: any) => el.yoldacalisma === "YOK")
                  .length > 0
                  ? searchResults.filter((el: any) => el.yoldacalisma === "YOK")
                    .length
                  : "",
              name: "Yok",
            },
            {
              value:
                searchResults.filter((el: any) => el.yoldacalisma === "VAR")
                  .length > 0
                  ? searchResults.filter((el: any) => el.yoldacalisma === "VAR")
                    .length
                  : "",
              name: "Var",
            },
          ],
        },
      ],
    };
    option2 = {
      height: "50%",
      tooltip: {
        trigger: "item",
      },
      title: {
        text: "Görüşe Engel Cisim Durum",
        left: "center",
        top: "5%",
        textStyle: {
          color: "#1A4158",
          fontWeight: "bold",
          fontSize: 18,
        },
      },
      series: [
        {
          type: "pie",
          top: "35%",
          radius: [35, 70],
          itemStyle: {
            borderColor: "#fff",
            borderWidth: 1,
          },
          label: {
            fontSize: "14px",
            minMargin: 5,
          },
          data: [
            {
              value:
                searchResults.filter(
                  (el: any) => el.goruseengelcisimid === "YOK"
                ).length > 0
                  ? searchResults.filter(
                    (el: any) => el.goruseengelcisimid === "YOK"
                  ).length
                  : "",
              name: "Yok",
            },
            {
              value:
                searchResults.filter(
                  (el: any) => el.goruseengelcisimid === "VAR"
                ).length > 0
                  ? searchResults.filter(
                    (el: any) => el.goruseengelcisimid === "VAR"
                  ).length
                  : "",
              name: "Var",
            },
          ],
        },
      ],
    };
    chart1.setOption(option1);
    chart2.setOption(option2);
    // myChart?.resize();
  }, [searchResults]);

  return (
    <div className="MomentOfAccident">
      <AccidentMain />
      <div style={{ width: "100%", height: "350px" }}>
        <div
          ref={barChart}
          id="bar-chart"
          className="bar-chart"
          style={{ width: "680px", height: "100%" }}
        />
      </div>
      <div
        style={{
          display: "flex",
          width: "100%",
          height: "250px",
          paddingTop: "25px",
        }}
      >
        <div
          ref={roadWorkChart}
          id="road-work-chart"
          className="pie-chart"
          style={{ width: "50%", height: "250px" }}
        />
        <div
          ref={visionBlockerChart}
          id="vision-blocker-chart"
          className="pie-chart"
          style={{ width: "50%", height: "250px" }}
        />
      </div>
    </div>
  );
};
export default MomentOfAccident;
