import "./zoom-action.scss";
import { useDispatch } from "react-redux";
import { ZoomStateType } from "../../../utils/model";
import { Button } from "../../shared/button/Button";
import { useAppSelector } from "../../state/hooks";
import { setZoomState } from "../../state/features/zoomStateSlice";
import { useEffect, useState } from "react";

export const ZoomAction = () => {
  const dispatch = useDispatch();
  const zoomState = useAppSelector((state) => state.zoomState.value);
  const shapeState = useAppSelector((state) => state.zoomState.shapeState);
  const cityState = useAppSelector((state) => state.zoomState.cityState);
  const zoomValue = useAppSelector((state) => state.zoomState.zoomValue);
  const [zoomChangestate, setZoomChangestate] = useState<any>()

  useEffect(() => {
    setZoomChangestate(zoomValue)

  }, [zoomValue, zoomChangestate])

  return (
    <div className="zoom-container">
      <Button
        svg='icons/home_icon.svg'
        tooltip="Türkiye Kapsamına Dön"
        tooltipOptions={{ position: "bottom" }}
        active={zoomState === ZoomStateType.HOME}
        onClick={() => dispatch(setZoomState(ZoomStateType.HOME))}
      ></Button>
      <Button
        svg='icons/arrow_icon.svg'
        tooltip="Seçili Alana Git"
        disabled={shapeState === true || cityState === true ? false : true}
        tooltipOptions={{ position: "bottom" }}
        onClick={() => {
          if (shapeState === true) {
            dispatch(setZoomState(ZoomStateType.SHAPE))
          }
          else if (cityState === true) {

            dispatch(setZoomState(ZoomStateType.PROVINCE))
          }

        }
        }
      ></Button>
    </div>
  );
};
