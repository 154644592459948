import "./Basemap.scss";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { changeVisibility, setBasemapSelection } from "../state/features/basemapSlice";
import { useAppSelector } from "../state/hooks";
import { ZoomSection } from "./zoom-section/zoomSection";

export const Basemaps = () => {
  const dispatch = useDispatch();
  const [selectedBasemap, setSelectedBasemap] = useState<any>(null);
  const [showBasemaps, setShowBasemaps] = useState<Boolean>(false);
  const [results, setResults] = useState<any>([]);
  const basemaps = useAppSelector((state) => state.basemaps.value);
  const searchListVisibilty = useAppSelector(
    (state) => state.elastic.searchListVisibilty
  );
  const selectedListVisibilty = useAppSelector(
    (state) => state.elastic.selectedListVisibilty
  );
  const searchResults = useAppSelector((state) => state.elastic.value);

  useEffect(() => {
    if (basemaps.length > 0) {
      const selectedBasemap = basemaps.find((basemap: any) => {
        return basemap.visible === true;
      });
      setSelectedBasemap(selectedBasemap);
    }
  }, [basemaps]);

  useEffect(() => {
    setResults(searchResults);
  }, [searchResults]);

  const changeBasemapVisibility = (index: number, visibility: boolean) => {
    dispatch(setBasemapSelection(true))
    dispatch(changeVisibility({ index, visibility }));
  };

  return (
    <div
      className={
        searchListVisibilty || selectedListVisibilty || results.length > 0
          ? "Basemap-left"
          : "Basemap"
      }
    >
      <div className="zoom-section">
        <ZoomSection />
      </div>
      <div
        className="basemap-button"
        onClick={() => setShowBasemaps(!showBasemaps)}
        title="Harita Altlıkları"
      >
        {selectedBasemap && (
          <img
            className="image-box"
            src={selectedBasemap.icon}
            alt="googleStreet"
          />
        )}
        {!selectedBasemap && (
          <img
            className="image-box"
            src="basemap-icons/open-street.png"
            alt="googleStreet"
          />
        )}
      </div>
      {showBasemaps && (
        <div className="basemap-group">
          {basemaps.length > 0 &&
            basemaps.map((basemap: any, index: number) => (
              <div
                className="basemap"
                key={index}
                onClick={() => changeBasemapVisibility(index, true)}
              >
                <img
                  className="image-box"
                  src={basemap.icon}
                  alt="googleStreet"
                />
                <p className="title">{basemap.layername}</p>
              </div>
            ))}
        </div>
      )}
    </div>
  );
};
