import axios from 'axios';

const { REACT_APP_BASE_URL } = process.env

const getAnkaToken = () => {
    return localStorage.getItem('ag_token')
}

function sleep() {
    return new Promise(((res) => setTimeout(res, 100)));
}

async function getBaselayers() {
    const config = {
        headers: { Authorization: 'Bearer ' + getAnkaToken() }
    };
    await sleep()
    return axios.get(REACT_APP_BASE_URL + 'baselayer', config);
}


export const BaselayerRestApi = {
    getBaselayers
}