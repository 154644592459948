import { InputText } from "primereact/inputtext"
import { useEffect, useState } from "react";
import { LayerRestApi } from "../../utils/restapi/layer";
import { useAppSelector } from "../state/hooks";

export const OthersInfo = () => {
    const [columns, setColumns] = useState<any>([])
    const selectedFeature = useAppSelector(state => state.info.selectedFeature);
    const layerForInfo: any = useAppSelector(state => state.info.layerForInfo);
    const layerForTable: any = useAppSelector(state => state.info.layerForTable);
    let dataColumns: any = []

    useEffect(() => {
        if (layerForInfo.id || layerForTable.id) {
            getColumns()
        }
    }, [layerForInfo, layerForTable])

    const getColumns = () => {
        LayerRestApi.getLayerById(layerForInfo.id).then(res => {
            const selectedLayer = res.data.layer
            if (selectedLayer && selectedLayer.fields) {
                selectedLayer.fields.map((obj: any) => {
                    dataColumns.push({ name: obj.field, alias: obj.alias, position: obj.position })
                })
            }

            const filteredDataColumns = dataColumns.filter((dc: any) => (
                dc.name !== 'zoneid' &&
                dc.name !== 'zonetype' &&
                dc.name !== 'ogc_fid' &&
                dc.name !== 'etltarih' &&
                dc.name !== 'gid' &&
                dc.name !== 'id' &&
                dc.name !== 'bbox' &&
                dc.name !== 'geometry' &&
                dc.name !== 'xkoordinat' &&
                dc.name !== 'ykoordinat' &&
                dc.name !== 'geoyatyguzergahid' &&
                dc.name !== 'wkb_geometry' &&
                dc.name !== 'geodigerid' &&
                dc.name !== 'il' &&
                dc.name !== 'ilce' &&
                dc.name !== 'arac_say' &&
                dc.name !== 'koymahalleadi' &&
                dc.name !== 'uavtil' &&
                dc.name !== 'uavtmah' &&
                dc.name !== 'uavtilce' &&
                dc.name !== 'corr1' &&
                dc.name !== 'corr1_val' &&
                dc.name !== 'corr2' &&
                dc.name !== 'corr2_val' &&
                dc.name !== 'corr3' &&
                dc.name !== 'corr3_val' &&
                dc.name !== 'corr4' &&
                dc.name !== 'corr4_val' &&
                dc.name !== 'corr5' &&
                dc.name !== 'corr5_val' &&
                dc.name !== 'indeks' &&
                dc.name !== 'geodigerid'
            ))

            const alphabeticalDataColumns = filteredDataColumns.sort(function (a: any, b: any) { return a.position - b.position });
            setColumns(alphabeticalDataColumns)
        })
    }

    return (
        <div className='OthersInfoPanel'>
            {columns.map((column: any) => {
                return (
                    <div key={column.id} className='input-group'>
                        <label>{column.alias}</label>
                        <InputText value={selectedFeature[0].getProperties()[column.name] || ''} />
                    </div>
                )
            })}
        </div>
    )
}
