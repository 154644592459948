import { createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  fitExtent: undefined,
  extent: undefined,
  zoom: null,
  mapValue: null
};

export const mapSlice = createSlice({
  name: "map",
  initialState,
  reducers: {
    setZoom: (state: any, action) => {
      state.zoom = action.payload;
    },
    setExtent: (state: any, action) => {
      state.extent = action.payload;
    },
    setFitExtent: (state: any, action) => {
      state.fitExtent = action.payload;
    },
    setMapValue: (state: any, action) => {
      state.mapValue = action.payload;
    },
  },
});

export const { 
  setMapValue, 
  setZoom, 
  setExtent, 
  setFitExtent 
} = mapSlice.actions;

export default mapSlice.reducer;
