import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

const initialState: any = {
  token: localStorage.getItem("ag_token"),
  session: Cookies.get("session"),
};

export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    setToken: (state: any, action) => {
      state.token = action.payload;
    },
    setSession: (state: any, action) => {
      state.session = action.payload;
    },
    setJsonToken: (state: any, action) => {
      state.jsonToken = action.payload;
    },
  },
});

export const { setToken, setSession, setJsonToken } = loginSlice.actions;

export default loginSlice.reducer;
