import "./Statistic2.scss";
import { useEffect, useState } from "react";
import { useAppSelector } from "../state/hooks";
import { UtilRestApi } from "../../utils/restapi/util";
import { Polygon } from 'ol/geom';
import { get, transformExtent } from "ol/proj";
import { ProgressSpinner } from "primereact/progressspinner";

function AccidentMain() {
    const searchResults = useAppSelector((state) => state.elastic.value);
    const shapeArr = useAppSelector(state => state.elastic.shape)
    const shapeState = useAppSelector((state) => state.zoomState.shapeState);
    const [convertedPolygon, setConvertedPolygon] = useState<any>();
    const [costlyAccident, setCostlyAccident] = useState<any>("");
    const date = useAppSelector(state => state.elastic.date)
    const extent = useAppSelector((state) => state.maps.extent);
    const selectedCity = useAppSelector((state) => state.elastic.city);
    const selectedTown = useAppSelector((state) => state.elastic.town);
    const selectedNeighborhood = useAppSelector(
        (state) => state.elastic.neighborhood
    );
    const accidentFilters = useAppSelector(state => state.elastic.accidentFilters);
    const dateInfo = useAppSelector((state) => state.elastic.dateInfo);
    const [dateInfoUpdated, setdateInfoUpdated] = useState<any>([]);
    const [progressSpinner, setprogressSpinner] = useState<any>(false)

    useEffect(() => {
        if (extent && shapeState === false && shapeArr === null) {

            const dateExtent = transformExtent(extent, get('EPSG:3857') as any, get('EPSG:4326') as any)

            const polygon = new Polygon([
                [
                    [dateExtent[0], dateExtent[1]],
                    [dateExtent[0], dateExtent[3]],
                    [dateExtent[2], dateExtent[3]],
                    [dateExtent[2], dateExtent[1]],
                    [dateExtent[0], dateExtent[1]]
                ]
            ]);

            let coordinate = polygon.getFlatCoordinates()

            let extenttoPolygon = `POLYGON((${coordinate[0]} ${coordinate[1]}//${coordinate[2]} ${coordinate[3]}//${coordinate[4]} ${coordinate[5]}//${coordinate[6]} ${coordinate[7]}//${coordinate[8]} ${coordinate[9]}//${coordinate[0]} ${coordinate[1]}))`

            setConvertedPolygon(extenttoPolygon)

        }
        else {
            let transformed: boolean = false;

            if (shapeArr) {
                let transformedCoords: any;

                if (!transformed) {
                    transformedCoords = shapeArr.clone().transform('EPSG:3857', 'EPSG:4326').getFlatCoordinates()
                    let polygonUrl: any = `POLYGON((`

                    for (let t = 0; t < transformedCoords.length - 1; t += 2) {
                        polygonUrl += `${transformedCoords[t]} ${transformedCoords[t + 1]}//`
                        if (t + 1 === transformedCoords.length - 1) {
                            polygonUrl += `${transformedCoords[0]} ${transformedCoords[1]}))`
                        }
                    }
                    setConvertedPolygon(polygonUrl)
                    transformed = true;
                } else {
                    transformed = false;
                }
            }
        }

    }, [extent, shapeArr, shapeState])

    useEffect(() => {
        const dateInfoChange = dateInfo
        const parts = dateInfoChange.split(' ');

        const months = ['', 'Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran',
            'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık'];

        const formattedDates = parts.map((date: any) => {
            // Tarih bilgisi bulunmayan elemanları geri döndürür
            if (date.trim() === '') return '';

            const [day, month, year] = date.split('-');
            const formattedMonth = months[parseInt(month, 10)];

            return `${day} ${formattedMonth} ${year}`;

        });

        setdateInfoUpdated(formattedDates)

        let firstDate = date.slice(0, 10);
        let secondDate = date.slice(11)

        let city: string = selectedCity ? `&cql_filter=uavtil=${selectedCity.uavtil}` : ""
        let town: string = selectedTown ? ` and uavtilce=${selectedTown.uavtilce}` : ""

        if (convertedPolygon !== "") {
            setprogressSpinner(true)
            UtilRestApi.geoserverWfsReq(
                `version=1.0.0&request=GetFeature&typeName=trafik%3Amaddi_istatistik&outputFormat=application%2Fjson&viewparams=poly:${convertedPolygon};tarih1:${firstDate};tarih2:${secondDate};${city}${town}`
            ).then((res) => {
                if (res.data.totalFeatures) {
                    setCostlyAccident(res.data.features[res.data.features.length - 1].properties.kaza_sayisi)
                    setprogressSpinner(false)
                }

            }).finally(() => setprogressSpinner(false));
        }
    }, [convertedPolygon, date, selectedCity, selectedTown, accidentFilters])

    return (
        <div className="first-two-rows">
            <div className={`${selectedTown}` ? "first-row first-row-activated" : "first-row"}>
                <div className="section">
                    <div className="title" style={{ transform: "rotate(270deg)" }}>
                        <span className="title-text">
                            BÖLGE
                        </span>
                    </div>
                    <div className="detail">
                        <span className="detail-text">
                            {selectedCity ? selectedCity.ad : "Harita Ekranı"}
                        </span>
                        <span className="detail-text">
                            {selectedTown ? selectedTown.ad : ""}
                        </span>
                        <span className="detail-text">
                            {selectedNeighborhood ? selectedNeighborhood.ad : ""}
                        </span>
                    </div>
                </div>
                <div className="section date-section">
                    <div className="title" style={{ transform: "rotate(270deg)" }}>
                        <span className="title-text">
                            TARİH
                        </span>
                    </div>
                    {dateInfoUpdated[0]?.startsWith('Son') ? <div className="detail date-detail">
                        <span className="detail-text">
                            {dateInfo}
                        </span>
                    </div>
                        :
                        <div className="detail date-detail">
                            <span className="detail-text">
                                {dateInfoUpdated[0]}
                            </span>
                            <span className="detail-text">
                                {dateInfoUpdated[2]}
                            </span>
                        </div>
                    }
                </div>
            </div>
            <div className="second-row">
                <div className="title">
                    <span className="title-text">
                        KAZA SAYISI
                    </span>
                </div>

                {progressSpinner && selectedNeighborhood === "" && <div className="load-screen">
                    <ProgressSpinner
                        className="progress-spinner"
                        strokeWidth="8"
                        fill="var(--surface-ground)"
                        animationDuration=".5s"

                    /></div>}

                <div className="second-row-section">
                    <div className="data-section">
                        <div className="first-section">
                            <div className="accident accident-red">
                                <div className="accident-text">
                                    <span>
                                        ÖLÜMLÜ
                                    </span>
                                </div>
                                <div className="accident-data">
                                    <span>
                                        {
                                            searchResults.filter((el: any) => el.kaza_siddet === "ÖLÜMLÜ")
                                                .length
                                        }
                                    </span>
                                </div>
                            </div>
                            <div className="accident accident-yellow">
                                <div className="accident-text">
                                    <span >
                                        YARALANMALI
                                    </span>
                                </div>
                                <div className="accident-data">
                                    <span>
                                        {
                                            searchResults.filter(
                                                (el: any) => el.kaza_siddet === "YARALANMALI"
                                            ).length
                                        }
                                    </span>
                                </div>
                            </div>
                            <div className="accident accident-blue">
                                <div className="accident-text">
                                    <span >
                                        MADDİ HASARLI
                                    </span>
                                </div>
                                <div className="accident-data">
                                    <span>
                                        {
                                            searchResults.filter((el: any) => el.kaza_siddet === "MADDİ")
                                                .length
                                        }
                                        <span> | </span>
                                        {!selectedNeighborhood && accidentFilters.length === 0 ? <span>
                                            {costlyAccident}
                                        </span> :
                                            <span>
                                                -
                                            </span>
                                        }
                                    </span>
                                    <span className="quote">*</span>
                                </div>
                            </div>
                        </div>
                        <div className="second-section">
                            <div className="accident accident-purple">
                                <div className="accident-text">
                                    <span >
                                        TOPLAM KAZA
                                    </span>
                                </div>
                                <div className="accident-data">
                                    <span>
                                        {
                                            searchResults.filter((el: any) => el.kaza_siddet === "ÖLÜMLÜ")
                                                .length + searchResults.filter((el: any) => el.kaza_siddet === "MADDİ")
                                                    .length + searchResults.filter(
                                                        (el: any) => el.kaza_siddet === "YARALANMALI"
                                                    ).length > 0

                                                ? searchResults.filter((el: any) => el.kaza_siddet === "ÖLÜMLÜ")
                                                    .length + searchResults.filter((el: any) => el.kaza_siddet === "MADDİ")
                                                    .length + searchResults.filter(
                                                        (el: any) => el.kaza_siddet === "YARALANMALI"
                                                    ).length :
                                                "-"
                                        }
                                        <span> | </span>
                                        {
                                            searchResults.filter((el: any) => el.kaza_siddet === "ÖLÜMLÜ")
                                                .length + searchResults.filter(
                                                    (el: any) => el.kaza_siddet === "YARALANMALI"
                                                ).length + costlyAccident > 0 && !selectedNeighborhood && accidentFilters.length === 0

                                                ? searchResults.filter((el: any) => el.kaza_siddet === "ÖLÜMLÜ")
                                                    .length + searchResults.filter(
                                                        (el: any) => el.kaza_siddet === "YARALANMALI"
                                                    ).length + costlyAccident :
                                                "-"
                                        }
                                    </span>
                                    <span className="quote">*</span>
                                </div>
                            </div>
                        </div>
                        <div className="info">
                            {(selectedNeighborhood || accidentFilters.length >= 1) ? <span className="info">*Mahalle ve genel filtreleme ilçe bazlı maddi kazaları desteklememektedir.</span> : <span className="info">*Maddi hasarlı kazalarda ve toplamında ilçe merkezi baz alınmıştır.</span>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default AccidentMain