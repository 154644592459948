import ImageLayer from 'ol/layer/Image';
import TileLayer from 'ol/layer/Tile';
import { TileWMS } from 'ol/source';
import { PropsWithChildren, useContext, useEffect, useRef } from 'react'
import { useAppSelector } from '../../state/hooks';
import { MapContext } from '../MapContext'
const { REACT_APP_GEOSERVER_USER, REACT_APP_GEOSERVER_PASSWORD } = process.env;
interface IProps {
    url: string;
    layername: any;
    serviceId: number;
    tag: string;
    opacity: number;
    zIndex: number;
    // styles: string
}

export const WmsLayer = (props: PropsWithChildren<IProps>) => {
    const token = localStorage.getItem('ag_token');
    const selectedMap = useAppSelector((state) => state.sidebar.customMap)
    const map = useContext(MapContext)
    const t = useRef<ImageLayer<any> | null>(null);

    useEffect(() => {

        const tileLayer = new TileLayer({
            source: props.url.startsWith("https://trafik.simurgbilisim.com/") || props.url.startsWith("https://trafik.csb.gov.tr/") ? new TileWMS({
                params: {
                    layers: props.layername,
                    STYLES: props.layername.includes('layer_kkn') ? 'layer_kkn' : '',
                    TILED: false
                },
                url: token ? props.url + `?token=${token}` : props.url,
                crossOrigin: "anonymous",
                tileLoadFunction: function (tile: any, src: any) {
                    var xhr = new XMLHttpRequest();
                    xhr.responseType = 'blob';
                    xhr.open('GET', src);
                    xhr.setRequestHeader("Authorization", "Basic " + window.btoa(`${REACT_APP_GEOSERVER_USER}:${REACT_APP_GEOSERVER_PASSWORD}`));
                    xhr.onload = function () {
                        if (this.response) {
                            var objectUrl = URL.createObjectURL(xhr.response);
                            tile.getImage().onload = function () {
                                URL.revokeObjectURL(objectUrl);
                            };
                            tile.getImage().src = objectUrl;
                        } else {
                            tile.setState(3);
                        }
                    };
                    xhr.onerror = function () {
                        tile.setState(3);
                    };
                    xhr.send();
                }
            }) :
                new TileWMS({
                    params: {
                        layers: props.layername,
                        STYLES: props.layername.includes('layer_kkn') ? 'layer_kkn' : '',
                        TILED: false
                    },
                    url: props.url,
                    // url: token ? props.url + `?token=${token}` : props.url,
                    crossOrigin: "anonymous",

                }),
            zIndex: props.zIndex//100

        })
        tileLayer.set('serviceId', props.serviceId);
        tileLayer.set('tag', props.tag);
        tileLayer.setVisible(props.layername !== "")
        tileLayer.getVisible()
        t.current = tileLayer;
        map.addLayer(tileLayer);
        return () => { map.removeLayer(tileLayer) }
    }, [])

    useEffect(() => {
        const tileLayer = t.current!;
        tileLayer.setVisible(props.layername !== "");
        tileLayer.setOpacity(props.opacity)
        tileLayer.setZIndex(props.zIndex)
        tileLayer.getSource().updateParams({
            layers: props.layername,
            STYLES: `${props.layername.includes('layer_kkn') ? 'layer_kkn' : '' || props.layername.includes('layer_kyyb') ? 'layer_kyyb' : ''}`,
        })
    }, [props.layername, props.serviceId, selectedMap, props.opacity, props.zIndex])

    return null;
}
