import './Toolbar.scss';
import { Button } from "../shared/button/Button";
import { Search } from "../search/Search";
import { DropdownGroup } from "../dropdown-group/DropdownGroup";
import { useEffect } from "react";
import { ActiveButtonState, ActiveButtonStateRight } from '../shared/button-state/ButtonState';
import Dashboard from '../dashboards/Dashboard';
import { useAppSelector } from '../state/hooks';
import { useDispatch } from 'react-redux';
import UserInfoCard from '../user/UserInfoCard';
import Sidebar from '../sidebar/Sidebar';
import { setActiveButtonType, setActiveButtonTypeRight } from '../state/features/buttonStateSlice';
import DateFilter from '../date-filter/DateFilter';
import AccidentFilters from '../accident-filters/AccidentFilters';
import ThematicMap from '../thematic/ThematicMap';
import { setAccidentFilters, setActiveDateId, setAllFiltersClear, setFilterIsActive, setQuickSearchValue, setSelectedCity, setShape } from '../state/features/elasticSlice';
import { DrawTools } from '../map/draw/DrawTools';
import { DrawStateType } from '../../utils/model';
import { setDrawState } from '../state/features/drawStateSlice';
import { setMinimizeKKNDialog } from '../state/features/kknSlice';
import { setKKNLayer } from '../state/features/sidebarSlice';
import Statistic2 from '../statistics/Statistic2';
import { ZoomAction } from '../map/zoom-action/zoom-action';
import { setCityState, setShapeState } from '../state/features/zoomStateSlice';
import { Button as DateButton } from 'primereact/button';
import DateBasedStats from '../date-based-stats/DateBasedStats';

export const Toolbar = () => {
    const dispatch = useDispatch()
    const activeButtonType = useAppSelector((state) => state.buttonState.activeButtonType)
    const activeButtonTypeRight = useAppSelector((state) => state.buttonState.activeButtonTypeRight)
    const isCitySelected = useAppSelector(state => state.dropdownResults.isCitySelected)
    const shape = useAppSelector(state => state.elastic.shape)
    const drawState = useAppSelector(state => state.drawState.value);
    const activeFilter = useAppSelector(state => state.elastic.isActive);
    const isSidebarActive = useAppSelector(state => state.sidebar.isSidebarActive);
    const userFunctionPerm = useAppSelector(state => state.user.userFunctionPerm);
    const isMinimize = useAppSelector((state) => state.kkn.isMinimize);
    const progressVisibility = useAppSelector((state) => state.shared.visible);
    const dateInfo = useAppSelector(state => state.elastic.dateInfo);

    useEffect(() => {
        if (drawState === DrawStateType.CLEAR) {
            dispatch(setShape(null))
        }
    }, [drawState])

    const toggleDashboard = () => {
        if (activeButtonTypeRight === ActiveButtonStateRight.DASHBOARD) {
            dispatch(setActiveButtonTypeRight(ActiveButtonStateRight.DEFAULT))
            return;
        }
        dispatch(setActiveButtonTypeRight(ActiveButtonStateRight.DASHBOARD))
    }
    const toggleStatistic = () => {
        if (activeButtonTypeRight === ActiveButtonStateRight.STATISTIC) {
            dispatch(setActiveButtonTypeRight(ActiveButtonStateRight.DEFAULT))
            return;
        }
        dispatch(setActiveButtonTypeRight(ActiveButtonStateRight.STATISTIC))
    }
    const toggleDateStats = () => {
        if (activeButtonTypeRight === ActiveButtonStateRight.DATE_STATS) {
            dispatch(setActiveButtonTypeRight(ActiveButtonStateRight.DEFAULT))
            return;
        }
        dispatch(setActiveButtonTypeRight(ActiveButtonStateRight.DATE_STATS))
    }
    const toggleMinimizeWindow = () => {
        dispatch(setMinimizeKKNDialog(false))
        if (activeButtonTypeRight === ActiveButtonStateRight.MINIMIZE) {
            dispatch(setActiveButtonTypeRight(ActiveButtonStateRight.DEFAULT))
            return;
        }
    }
    const toggleThematic = () => {
        if (activeButtonTypeRight === ActiveButtonStateRight.THEME) {
            dispatch(setActiveButtonTypeRight(ActiveButtonStateRight.DEFAULT))
            return;
        }
        dispatch(setActiveButtonTypeRight(ActiveButtonStateRight.THEME))
    }
    const toggleLocation = () => {
        if (activeButtonType === ActiveButtonState.LOCATION) {
            dispatch(setActiveButtonType(ActiveButtonState.DEFAULT))
            return;
        }
        dispatch(setActiveButtonType(ActiveButtonState.LOCATION))
    }
    const toggleDateFilter = () => {
        if (activeButtonType === ActiveButtonState.DATE_FILTER) {
            dispatch(setActiveButtonType(ActiveButtonState.DEFAULT))
            return;
        }
        dispatch(setActiveButtonType(ActiveButtonState.DATE_FILTER))
    }
    const toggleDrawArea = () => {
        if (activeButtonType === ActiveButtonState.DRAW) {
            dispatch(setActiveButtonType(ActiveButtonState.DEFAULT))
            return;
        }
        dispatch(setActiveButtonType(ActiveButtonState.DRAW))
    }
    const toggleZoomArea = () => {
        if (activeButtonType === ActiveButtonState.ZOOM) {
            dispatch(setActiveButtonType(ActiveButtonState.DEFAULT))
            return;
        }
        dispatch(setActiveButtonType(ActiveButtonState.ZOOM))
    }
    const toggleAllFilters = () => {
        if (activeButtonType === ActiveButtonState.FILTERS) {
            dispatch(setActiveButtonType(ActiveButtonState.DEFAULT))
            return;
        }
        dispatch(setActiveButtonType(ActiveButtonState.FILTERS))
    }
    const toggleAllFilterClear = () => {
        dispatch(setShapeState(false))
        dispatch(setCityState(false))
        if (activeButtonType !== ActiveButtonState.CLEAR) {
            dispatch(setActiveButtonType(ActiveButtonState.DEFAULT))
            dispatch(setSelectedCity(''))
            dispatch(setShape(null))
            dispatch(setDrawState(DrawStateType.CLEAR))
            dispatch(setActiveDateId(5))
            dispatch(setFilterIsActive(false))
            dispatch(setAccidentFilters([]))
            dispatch(setQuickSearchValue(''))
            dispatch(setAllFiltersClear(true))
            return;
        }
    }

    const toggleUserOperations = () => {
        if (activeButtonTypeRight === ActiveButtonStateRight.USER_OPERATIONS) {
            dispatch(setActiveButtonTypeRight(ActiveButtonStateRight.DEFAULT))
            return;
        }
        dispatch(setActiveButtonTypeRight(ActiveButtonStateRight.USER_OPERATIONS))
    }

    const toggleSidebar = () => {
        if (activeButtonType === ActiveButtonState.SIDEBAR) {
            dispatch(setKKNLayer({}));
            dispatch(setActiveButtonType(ActiveButtonState.DEFAULT))
            return;
        }
        dispatch(setActiveButtonType(ActiveButtonState.SIDEBAR))
    }

    return (

        <>
            <div className="Toolbar">
                <div className="search">
                    <div>
                        <div className="sidebar-button">
                            <Button svg='icons/layer.svg' onClick={toggleSidebar} active={activeButtonType === ActiveButtonState.SIDEBAR} moreStyle={isSidebarActive && activeButtonType !== ActiveButtonState.SIDEBAR ? "button-filter-active" : ""} />
                        </div>
                        <Sidebar />
                    </div>
                    <Search />
                </div>
                <div className="navbar-container">
                    <div className='left-content'>
                        <div className="button-groups">
                            {/* <Button svg='icons/date.svg' style={{ marginRight: '0' }} tooltipOptions={{ position: 'left', mouseTrack: true, mouseTrackTop: 15 }} onClick={toggleDateFilter} active={activeButtonType === ActiveButtonState.DATE_FILTER} moreStyle={activeButtonType !== ActiveButtonState.DATE_FILTER ? "button-filter-active" : ""} /> */}

                            <DateButton className={`date-button ${activeButtonType === ActiveButtonState.DATE_FILTER ? 'active-date-button' : ''} `} onClick={toggleDateFilter}>
                                <img
                                    src='icons/date.svg'
                                    alt='date'
                                />
                                <span>{dateInfo}</span>
                            </DateButton>
                            <div style={{ visibility: activeButtonType === ActiveButtonState.DATE_FILTER ? 'visible' : 'hidden' }}>
                                <DateFilter />
                            </div>
                            {/* <div className='date-info'>Son 3 Ay</div> */}
                            <Button svg='icons/location.svg' disabled={activeButtonTypeRight === ActiveButtonStateRight.DATE_STATS} tooltipOptions={{ position: 'left', mouseTrack: true, mouseTrackTop: 15 }} onClick={toggleLocation} active={activeButtonType === ActiveButtonState.LOCATION} moreStyle={`'' ${isCitySelected && activeButtonType !== ActiveButtonState.LOCATION ? "button-filter-active" : ""}`} />
                            <div style={{ visibility: activeButtonType === ActiveButtonState.LOCATION ? 'visible' : 'hidden' }}>
                                <DropdownGroup />
                            </div>
                            <Button svg='icons/kalem.svg' disabled={activeButtonTypeRight === ActiveButtonStateRight.DATE_STATS} tooltipOptions={{ position: 'left', mouseTrack: true, mouseTrackTop: 15 }} onClick={toggleDrawArea} active={activeButtonType === ActiveButtonState.DRAW} moreStyle={`'' ${shape !== null && activeButtonType !== ActiveButtonState.DRAW ? "button-filter-active" : ""}`} />
                            <div style={{ visibility: activeButtonType === ActiveButtonState.DRAW ? 'visible' : 'hidden' }}>
                                <DrawTools />
                            </div>
                            <Button svg='icons/filters.svg' disabled={activeButtonTypeRight === ActiveButtonStateRight.DATE_STATS} tooltipOptions={{ position: 'left', mouseTrack: true, mouseTrackTop: 15 }} onClick={toggleAllFilters} active={activeButtonType === ActiveButtonState.FILTERS} moreStyle={`'' ${activeFilter && activeButtonType !== ActiveButtonState.FILTERS ? "button-filter-active" : ""}`} />
                            <div style={{ visibility: activeButtonType === ActiveButtonState.FILTERS ? 'visible' : 'hidden' }}>
                                <AccidentFilters />
                            </div>
                            <div className='hr'></div>
                            {/* <Button svg='icons/zoom_icon.svg' tooltipOptions={{ position: 'left', mouseTrack: true, mouseTrackTop: 15 }} onClick={toggleZoomArea} active={activeButtonType === ActiveButtonState.ZOOM} moreStyle={`'' ${shape !== null && activeButtonType !== ActiveButtonState.ZOOM ? "button-filter-active" : ""}`} />
                    <div style={{ visibility: activeButtonType === ActiveButtonState.ZOOM ? 'visible' : 'hidden' }}>
                        <ZoomAction />
                    </div> */}
                            {/* <Button svg='icons/zoom_icon.svg' tooltipOptions={{ position: 'left', mouseTrack: true, mouseTrackTop: 15 }} onClick={toggleZoomArea} active={activeButtonType === ActiveButtonState.ZOOM} moreStyle={`'' ${shape !== null && activeButtonType !== ActiveButtonState.ZOOM ? "button-filter-active" : ""}`} /> */}
                            <div style={{ visibility: 'visible' }}>
                                <ZoomAction />
                            </div>
                            <Button tooltipOptions={{ position: 'left', mouseTrack: true, mouseTrackTop: 15 }} moreStyle="filter-clean" svg='icons/clearr.svg' onClick={toggleAllFilterClear} active={activeButtonType === ActiveButtonState.CLEAR} />
                        </div>
                    </div>
                    <div className="right-content">
                        <div className="button-groups">

                            {isMinimize && progressVisibility && <div>
                                <Button icon='pi pi-spin pi-spinner' tooltip='Simge Durumundaki Pencere' tooltipOptions={{ position: 'left', mouseTrack: true, mouseTrackTop: 15 }} onClick={toggleMinimizeWindow} active={activeButtonTypeRight === ActiveButtonStateRight.MINIMIZE} />
                            </div>}
                            {userFunctionPerm.find((perm: any) => perm.item === 'İstatistik') && <div>
                                <Button svg='icons/statistic.svg' tooltipOptions={{ position: 'left', mouseTrack: true, mouseTrackTop: 15 }} onClick={toggleStatistic} active={activeButtonTypeRight === ActiveButtonStateRight.STATISTIC} />
                            </div>}
                            <div>
                                <Button icon='pi pi-clock' moreStyle='date-stats' onClick={toggleDateStats} active={activeButtonTypeRight === ActiveButtonStateRight.DATE_STATS} />
                                <div className='statistic'>
                                    {activeButtonTypeRight === ActiveButtonStateRight.DATE_STATS && <DateBasedStats />}

                                </div>
                            </div>
                            <div className='hr'></div>
                            {userFunctionPerm.find((perm: any) => perm.item === 'Tematik') && <div>
                                <Button svg='icons/theme.svg' tooltipOptions={{ position: 'left', mouseTrack: true, mouseTrackTop: 15 }} onClick={toggleThematic} active={activeButtonTypeRight === ActiveButtonStateRight.THEME} />
                                <div className='thematic'>
                                    {activeButtonTypeRight === ActiveButtonStateRight.THEME && <ThematicMap />}
                                </div>
                            </div>}
                            {userFunctionPerm.find((perm: any) => perm.item === 'Gösterge Sayfası') && <div>
                                <Button svg='icons/collage.svg' tooltipOptions={{ position: 'left', mouseTrack: true, mouseTrackTop: 15 }} onClick={toggleDashboard} active={activeButtonTypeRight === ActiveButtonStateRight.DASHBOARD} />
                                <div className='dashboard'>
                                    {activeButtonTypeRight === ActiveButtonStateRight.DASHBOARD && <Dashboard />}
                                </div>
                            </div>}
                            <div>
                                <Button svg='icons/profile.svg' tooltipOptions={{ position: 'left', mouseTrack: true, mouseTrackTop: 15 }} onClick={toggleUserOperations} active={activeButtonTypeRight === ActiveButtonStateRight.USER_OPERATIONS} />
                                <div style={{ visibility: activeButtonTypeRight === ActiveButtonStateRight.USER_OPERATIONS ? 'visible' : 'hidden' }}>
                                    <UserInfoCard />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            <div className="right-content">
                <div className="button-groups">
                    {userFunctionPerm.find((perm: any) => perm.item === 'İstatistik') &&
                        <div className='statistic'>
                            {activeButtonTypeRight === ActiveButtonStateRight.STATISTIC && <Statistic2 />}
                        </div>}
                </div></div>
        </>
    )

};