import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { ListBox } from "primereact/listbox";
import { Dialog } from "primereact/dialog";
import { useDispatch } from "react-redux";
import { MapContext } from "../MapContext";
import { useAppSelector } from "../../state/hooks";
import {
  setFeatureSelectionType,
  setIsTableSelection,
  setLayerForInfo,
  setPixel,
  setSelectedFeature,
} from "../../state/features/infoSlice";
import { GeoJSON } from "ol/format";
import { transform } from "ol/proj";
import { ElasticRestApi } from "../../../utils/restapi/elastic";

const { REACT_APP_GEOSERVER_USER, REACT_APP_GEOSERVER_PASSWORD } = process.env;

export default function GetFeatureInfo() {
  const dispatch = useDispatch();
  const map = useContext(MapContext);
  const [featureList, setFeatureList] = useState<any>([]);
  const [visibleListBox, setVisibleListBox] = useState(false);
  const services: any = useAppSelector((state) => state.service.services);
  const pixel = useAppSelector((state) => state.info.pixel);
  const isSidebarAccidentVisible = useAppSelector(
    (state) => state.elastic.isSidebarAccidentVisible
  );
  let featureListArr: any = [];
  let featuresArr: any = [];
  let listArr: any = [];
  const limit = useAppSelector((state) => state.elastic.limit);
  const quickSearchValue = useAppSelector(
    (state) => state.elastic.quickSearchValue
  );
  const date = useAppSelector((state) => state.elastic.date);
  const selectedCity = useAppSelector((state) => state.elastic.city);
  const selectedTown = useAppSelector((state) => state.elastic.town);
  const selectedNeighborhood = useAppSelector(
    (state) => state.elastic.neighborhood
  );
  const accidentFilters = useAppSelector(
    (state) => state.elastic.accidentFilters
  );
  const mapZoomValue = useAppSelector(state => state.maps.zoom);

  useEffect(() => {
    if (mapZoomValue > 12) {

      map.on("singleclick", info);
      return () => {
        map.un("singleclick", info);
      };
    }
  }, [isSidebarAccidentVisible, date, selectedCity.ad,
    selectedTown.ad,
    selectedNeighborhood.ad, mapZoomValue]);

  useEffect(() => {
    if (featureList.length > 0) {
      setVisibleListBox(true);
    } else {
      setVisibleListBox(false);
    }
  }, [featureList]);

  const info = async (e: any) => {
    setFeatureList([]);
    featureListArr = [];
    featuresArr = [];
    const latlonValues = transform(e.coordinate, "EPSG:3857", "EPSG:4326");

    //trafik kazaları için map click ile 'getfeatures' isteği yapılan yer.
    if (isSidebarAccidentVisible) {
      // const first = transform(
      //   map.getCoordinateFromPixel([e.pixel[0] - 5, e.pixel[1] - 5]),
      //   "EPSG:3857",
      //   "EPSG:4326"
      // );
      // const second = transform(
      //   map.getCoordinateFromPixel([e.pixel[0] + 5, e.pixel[1] - 5]),
      //   "EPSG:3857",
      //   "EPSG:4326"
      // );
      // const third = transform(
      //   map.getCoordinateFromPixel([e.pixel[0] + 5, e.pixel[1] + 5]),
      //   "EPSG:3857",
      //   "EPSG:4326"
      // );
      // const fourth = transform(
      //   map.getCoordinateFromPixel([e.pixel[0] - 5, e.pixel[1] + 5]),
      //   "EPSG:3857",
      //   "EPSG:4326"
      // );

      // shape.push(first, second, third, fourth, first);
      dispatch(setPixel(e.pixel));

      let postBody: any = {};

      if (latlonValues && latlonValues.length > 0) {
        postBody.geo_distance = {
          distance: "1km",
          geom: {
            lat: latlonValues[1],
            lon: latlonValues[0],
          },
        };
      }

      await ElasticRestApi.getElasticResult(
        limit,
        quickSearchValue,
        date,
        selectedCity.ad,
        selectedTown.ad,
        selectedNeighborhood.ad,
        accidentFilters[0],
        accidentFilters[1],
        accidentFilters[2],
        accidentFilters[3],
        accidentFilters[4],
        accidentFilters[5],
        accidentFilters[6],
        accidentFilters[7],
        accidentFilters[8],
        accidentFilters[9],
        accidentFilters[10],
        accidentFilters[11],
        accidentFilters[12],
        postBody
      ).then((res) => {
        if (!res || res.data.hits.hits.length === 0) {
          listArr = [];
        } else {
          const resultArray = res.data.hits.hits.map(
            (value: any) => value._source
          );
          resultArray.map((res: any) => {
            let geomString = res.geom;
            let mySubString = geomString.substring(
              geomString.indexOf("(") + 1,
              geomString.lastIndexOf(")")
            );
            const parsed = mySubString.split(" ");
            const geom = [+parsed[0], +parsed[1]];
            res.geom = geom;
            return res
          });

          listArr = resultArray.map((obj: any) => {
            return {
              name: `Trafik Kazası (${obj.ogc_fid})`,
              value: obj,
            };
          });
        }
      });
    }

    //diğer tüm servisler için geoserverdan 'getfeatures' isteği yapılan yer.
    const layers = map.getLayers().getArray();

    const view = map.getView();
    const infoLayers = layers.filter(
      (obj) =>
        obj.getVisible() &&
        obj.getProperties().basemap !== true &&
        obj.getProperties().webgl !== true &&
        obj.getProperties().heatmap !== true &&
        obj.getProperties().featureSelection !== true &&
        obj.getProperties().alias !== "ClusteredOBJ"
    );

    for (const layer of infoLayers) {
      const source = (layer as any).getSource()!;
      const res = source.getParams();

      if (res) {
        let url = source.getFeatureInfoUrl(
          e.coordinate,
          view.getResolution()!,
          view.getProjection(),
          {
            QUERY_LAYERS: source.getParams().layers !== undefined ? source.getParams().layers : source.getParams().LAYERS,
            info_format: "application/json",
            feature_count: 3,
          }
        )!;

        if (url) {
          const geoserverFeatures = await axios({
            method: "get",
            url: url,
            auth: {
              username: `${REACT_APP_GEOSERVER_USER}`,
              password: `${REACT_APP_GEOSERVER_PASSWORD}`,
            },
          });

          featureListArr.push(geoserverFeatures.data.features);
          featureListArr = featureListArr.flat();
        }
      }
    }

    const filteredFeatureList = featureListArr.filter(
      (list: any) => list.length !== 0
    );

    const features = filteredFeatureList.map((obj: any) => {
      let layerName = obj.id.split(".")[0];
      const layerAlias = services
        .find((service: any) =>
          service.layers.some((layer: any) => layer.layer === layerName)
        )
        ?.layers.find((layer: any) => layer.layer === layerName)?.alias;

      return {
        name: layerAlias + " (" + obj.id.split(".")[1] + ")",
        value: obj,
      };
    });

    featuresArr.push(features);
    setFeatureList([...listArr, ...featuresArr[0]]);
  };

  const selectFeature = (feature: any) => {
    let readFeature;
    dispatch(setIsTableSelection(false));
    if (feature.type === "Feature") {
      const geojsonInstance = new GeoJSON();
      readFeature = geojsonInstance.readFeature(feature);

      services.map((service: any) => {
        return service.layers.find((layer: any) => {
          if (layer.layer === feature.id.split(".")[0]) {
            dispatch(setLayerForInfo(layer));
            return true;
          }
          return false;
        });
      });
      dispatch(
        setFeatureSelectionType({
          type: feature.id.includes("layer_kkn") ? "kkn" : "other",
          geomtype: feature.geometry.type,
        })
      );
      dispatch(setSelectedFeature([readFeature]));
    } else {
      services.map((service: any) => {
        return service.layers.find((layer: any) => {
          if (layer.layer === "usr1_ag_kaza") {
            dispatch(setLayerForInfo(layer));
            return true;
          }
          return false;
        });
      });
      dispatch(setSelectedFeature([feature]));
      dispatch(
        setFeatureSelectionType({ type: "accident", geomtype: "Point" })
      );
    }
    setVisibleListBox(false);
  };

  const onHideListBox = () => {
    setVisibleListBox(false);
  };

  return (
    <Dialog
      className="ListBox"
      style={{ position: "absolute", top: pixel[1] + 10, left: pixel[0] + 10 }}
      header="Veri Listesi"
      visible={visibleListBox}
      modal={false}
      onHide={onHideListBox}
    >
      <ListBox
        options={featureList}
        onChange={(e) => {
          selectFeature(e.value);
        }}
        optionLabel="name"
        style={{ width: "15rem" }}
      />
    </Dialog>
  );
}
