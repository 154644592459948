import { useContext, useEffect, useRef, useState } from 'react'
import { getPointResolution, toLonLat } from 'ol/proj';
import { Toast } from 'primereact/toast';
import { ScaleLine } from 'ol/control';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Size } from 'ol/size';
import { useAppSelector } from '../../state/hooks';
import { MapContext } from '../MapContext';
import './Coordinate.scss'


export default function Coordinate() {
    const map = useContext(MapContext)
    const [coordinate, setcoordinate] = useState<any>('28.809624 , 41.069122')
    const [scale, setscale] = useState<any>('1 : 3,395,295')
    const [selectedScale, setSelectedScale] = useState(null);
    const [showScaleSelect, setShowScaleSelect] = useState(false);
    const [showCoordinateContainer, setShowCoordinateContainer] = useState(false);
    const coordContainerVisibility = useAppSelector((state) => state.shared.coordContainerVisibility);
    const layerNodes = useAppSelector(state => state.import.layerNodes);
    const services = useAppSelector(state => state.service.services);

    const scales = [
        { name: '1 : 1,000', code: 1000 },
        { name: '1 : 5,000', code: 5000 },
        { name: '1 : 10,000', code: 10000 },
        { name: '1 : 25,000', code: 25000 },
        { name: '1 : 50,000', code: 50000 },
        { name: '1 : 100,000', code: 100000 },
        { name: '1 : 250,000', code: 250000 },
        { name: '1 : 500,000', code: 500000 },
        { name: '1 : 1,000,000', code: 1000000 },
        { name: '1 : 2,000,000', code: 2000000 },
        { name: '1 : 3,000,000', code: 3000000 }
    ];

    const toast = useRef<any>(null);

    useEffect(() => {
        const scaleLine = new ScaleLine({
            units: 'metric',
            bar: true,
        });

        map.once('rendercomplete', (e: any) => {
            map.on('moveend', () => {
                var mapScale = '1 : ' + Math.round(scaleLine.getScaleForResolution()).toLocaleString();
                setscale(mapScale);
            });
        });

        map.addControl(scaleLine);
        map.on('pointermove', setCoordinate);
        return () => {
            map.un('pointermove', setCoordinate);
        };
    }, []);

    const onScaleChange = (e: any) => {
        const scaleResolution =
            e.value.code /
            getPointResolution(
                map.getView().getProjection(),
                3571.4,
                map.getView().getCenter() as any
            );
        map.getView().setResolution(scaleResolution)
        setTimeout(() => {
            setSelectedScale(e.value);
            setscale(e.value.name);
        }, 50);
    }

    const exportMap = async () => {
        map.once('postrender', async () => {
            const mapCanvas = document.createElement('canvas');
            const size = map.getSize() as Size;

            mapCanvas.width = size[0];
            mapCanvas.height = size[1];

            const mapContext = mapCanvas.getContext('2d') as CanvasRenderingContext2D;
            map.getViewport()
                .querySelectorAll('.ol-layer canvas, canvas.ol-layer')
                .forEach((canvas: any) => {
                    if (canvas.width > 0) {
                        const opacity = canvas.parentNode.style.opacity || canvas.style.opacity;
                        mapContext.globalAlpha = opacity === '' ? 1 : Number(opacity);
                        let matrix;
                        const transform = canvas.style.transform;
                        if (transform) {
                            // Get the transform parameters from the style's transform matrix
                            matrix = transform
                                .match(/^matrix\(([^]*)\)$/)[1]
                                .split(',')
                                .map(Number);
                        } else {
                            matrix = [
                                parseFloat(canvas.style.width) / canvas.width,
                                0,
                                0,
                                parseFloat(canvas.style.height) / canvas.height,
                                0,
                                0,
                            ];
                        }
                        // Apply the transform to the export map context
                        mapContext?.setTransform(matrix);

                        const backgroundColor = canvas.parentNode.style.backgroundColor;
                        if (backgroundColor) {
                            mapContext.fillStyle = backgroundColor;
                            mapContext.fillRect(0, 0, canvas.width, canvas.height);
                        }
                        mapContext.drawImage(canvas, 0, 0);
                    }
                });
            mapContext.globalAlpha = 1;
            mapContext.setTransform(1, 0, 0, 1, 0, 0);

            const image = (await promiseImageLoad('icons/north-arrow.png')) as HTMLImageElement;

            const copyServices = [...services];
            const imageObjects: any[] = [];
            copyServices.forEach((service, index) => {
                if (index === 0) {
                    return;
                } else {
                    const visibleLayers = service.layers.filter((layer: any) => layer.visible);

                    for (let i = 0; i < visibleLayers.length; i++) {

                        const visibleLayerId = visibleLayers[i].id;
                        layerNodes.some((layerNode: any) => {
                            const foundChild = layerNode.children.find((child: any) => child.data.id === visibleLayerId);

                            if (foundChild) {
                                imageObjects.push({
                                    name: foundChild.data.alias,
                                    imageData: foundChild.iconData,
                                });
                                return true;
                            } else {
                                return false;
                            }
                        });
                    }
                }
            });
            mapContext.beginPath();
            mapContext.rect(10, mapCanvas.height - 40, 120, 30);
            mapContext.stroke();
            mapContext.fillText(scale, 25, mapCanvas.height - 20, 120);
            mapContext.drawImage(image, mapCanvas.width - 120, 20);
            const link = document.createElement('a');
            link.download = 'print.png';
            link.href = mapCanvas.toDataURL();
            link.click();
        });
        map.renderSync();
    };

    const promiseImageLoad = (imageData: any) => {
        return new Promise((resolve, reject) => {
            const image = new Image();
            image.crossOrigin = "anonymous"
            image.src = imageData;
            image.onload = () => {
                resolve(image);
            };
            image.onerror = (err) => {
                console.error("err : ", err);
                reject();
            };
        });
    };

    const setCoordinate = (e: any) => {
        const translated = toLonLat(e.coordinate)
        const coordinate = [translated[0].toFixed(6), translated[1].toFixed(6)];
        setcoordinate(coordinate.join(','))
    }

    // const openCoordinateContainer = () => {
    //     if (showCoordinateContainer) {
    //         setShowCoordinateContainer(false)
    //     } else {
    //         setShowCoordinateContainer(true)
    //     }
    // }

    return (
        <>
            {coordContainerVisibility && <div className='coordinate-container'>
                <Button className='p-button-rounded coordinate-button' tooltip='Yazdır' tooltipOptions={{ position: 'top' }} icon="pi pi-print" onClick={() => exportMap()}></Button>
                <div className='epsg'>EPSG:4326</div>
                <div className='coordinate'>{coordinate}</div>
                <div className='scale' onClick={() => { setShowScaleSelect(!showScaleSelect) }}>{scale}</div>
                {showScaleSelect &&
                    <Dropdown className="scale-select" value={selectedScale} options={scales} onChange={onScaleChange} optionLabel="name" placeholder="Ölçek Seçiniz" />
                }
            </div>}
            <Toast ref={toast} />
        </>
    )
}


