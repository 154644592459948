import './InfoPanel.scss';
import { Dialog } from "primereact/dialog"
import { useEffect, useState } from "react";
import { useAppSelector } from '../state/hooks';
import { TabPanel, TabView } from "primereact/tabview";
import { General } from './General';
import { RoadCondition } from './RoadCondition';
import { AccidentResult } from './AccidentResult';
import { Others } from './Others';
import { ThreeD } from '../3d/3D';
import { useDispatch } from 'react-redux';
import { setCoordinate } from '../state/features/threedSlice';
import { setSelectedFeature } from '../state/features/infoSlice';
import { OthersInfo } from './OthersInfo';
import { Vehicle } from './Vecihle';
import { People } from './People';

export const InfoPanel = () => {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const selectedFeature = useAppSelector(state => state.info.selectedFeature);
  const featureType = useAppSelector(state => state.info.featureType);
  const layerForInfo: any = useAppSelector(state => state.info.layerForInfo);

  useEffect(() => {
    if (selectedFeature && selectedFeature.length === 1) {
      setVisible(true)
      dispatch(setCoordinate(selectedFeature[0].geom))
    }
  }, [selectedFeature])

  const onHide = () => {
    setVisible(false)
    dispatch(setSelectedFeature([]))
  }

  return (
    <>
      {selectedFeature && selectedFeature.length > 0 && <Dialog className='InfoPanel' visible={visible} modal={false} resizable={false} header={featureType.type === 'accident' ? 'Kaza Bilgi Kartı' : layerForInfo.alias + ' ' + 'Bilgi Kartı'} position='top-right' onHide={onHide}>
        {featureType.type === 'accident' &&
          <TabView>
            <TabPanel header='Genel'>
              <General />
            </TabPanel>
            <TabPanel header='Yol Durumu'>
              <RoadCondition />
            </TabPanel>
            <TabPanel header='Diğer'>
              <Others />
            </TabPanel>
            <TabPanel header='Kaza Sonuç'>
              <AccidentResult />
            </TabPanel>
            <TabPanel header='Araçlar'>
              <Vehicle />
            </TabPanel>
            <TabPanel header='Şahıslar'>
              <People />
            </TabPanel>
            <TabPanel header='3B' leftIcon='pi pi-box'>
              <ThreeD />
            </TabPanel>
          </TabView>
        }
        {featureType.type !== 'accident' && <OthersInfo />}
      </Dialog>}
    </>
  )
}
