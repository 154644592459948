export enum DrawStateType {
  POINT = "POINT",
  LINESTRING = "LINESTRING",
  POLYGON = "POLYGON",
  CLEAR = "CLEAR",
  FINISH = "FINISH",
  DEFAULT = "DEFAULT",
  NONE = "NONE",
}

export enum ZoomStateType {
  HOME = "TURKIYE",
  SHAPE = "SHAPE",
  NONE = "NONE",
  DEFAULT = "DEFAULT",
  PROVINCE = "PROVINCE",
  ZOOMIN = "ZOOMIN",
  ZOOMOUT = "ZOOMOUT",
}

export interface IUser {
  old_password: string;
  password: string;
  confirmPassword?: string;
}
