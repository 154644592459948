import { Button as PrimeButton } from 'primereact/button';
import { Badge } from 'primereact/badge';
import './Button.scss';

interface IProps {
    label?: string,
    svg?: string,
    svgFocused?: string,
    onClick?: () => void,
    tooltip?: string
    moreStyle?: string,
    active?: boolean,
    icon?: string,
    filteredButton?: boolean,
    tooltipOptions?: any
    style?: any
    children?: any
    disabled?: any
}
export const Button = (props: IProps) => {

    const handleClick = () => {
        if (typeof props.onClick === 'function') {
            props.onClick();
        }
    }

    return (
        <PrimeButton
            label={props.label}
            tooltip={props.tooltip}
            className={`${props.moreStyle} ${props.active ? 'button-active p-button-sm' : 'p-button-sm'} }`}
            icon={props.icon}
            tooltipOptions={{ position: 'bottom' }}
            onClick={handleClick}
            disabled={props.disabled}
            style={props.style}
        >
            {props.svg && !props.active && <img alt='>' src={props.svg} />}
            {props.svg && props.active && props.svgFocused && <img alt='>' src={props.svgFocused} />}
            {props.svg && props.active && !props.svgFocused && <img alt='>' src={props.svg} />}
            {props.filteredButton && <Badge severity="success"></Badge>}
        </PrimeButton>
    )
};
