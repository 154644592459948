import { createSlice } from '@reduxjs/toolkit';
import { number } from 'echarts';

const initialState: any = {
  openDashboard: false,
  accidents: {
    olumlu: number,
    yaralanmali: number,
    maddi: number,
  },
};

export const dashboardSlice = createSlice({
    name: "dashboard",
    initialState,
    reducers: {
      setUserDashboards: (state: any, action) => {
        state.userDashboards = action.payload;
      },
      setDashboardUUid: (state: any, action) => {
        state.uuid = action.payload;
      },
      setSeverityOfAccidents: (state: any, action) => {
        state.accidents = action.payload;
      },
    },
});

export const { setUserDashboards, setDashboardUUid, setSeverityOfAccidents } =
  dashboardSlice.actions;

export default dashboardSlice.reducer