import './Draw.scss';
import { useDispatch } from "react-redux"
import { DrawStateType } from "../../../utils/model"
import { Button } from "../../shared/button/Button"
import { setDrawState } from "../../state/features/drawStateSlice"
import { useAppSelector } from "../../state/hooks"
import { setShapeState } from '../../state/features/zoomStateSlice';

export const DrawTools = () => {
    const dispatch = useDispatch();
    const drawState = useAppSelector(state => state.drawState.value);

    return (
        <div className='draw-container'>
            <Button
                svg='icons/polygon.svg'
                tooltip='Alan Çiz'
                tooltipOptions={{ position: 'bottom' }}
                active={drawState === DrawStateType.POLYGON}
                moreStyle={drawState === DrawStateType.POLYGON ? 'active-draw-button' : 'mobile-draw-button'}
                onClick={() => dispatch(setDrawState(DrawStateType.POLYGON))}>
            </Button>
            <Button
                svg='icons/white-clear.svg'
                tooltip='Temizle'
                tooltipOptions={{ position: 'bottom' }}
                onClick={() => { dispatch(setDrawState(DrawStateType.CLEAR)); dispatch(setShapeState(false)); }}
                moreStyle={drawState === DrawStateType.DEFAULT ? 'passive-draw-button' : 'mobile-draw-button'}
            ></Button>
        </div>
    )
}
