import "./Statistic2.scss";
import { useRef, useEffect } from "react";
import type { EChartsOption } from "echarts";
import { useAppSelector } from "../state/hooks";
import * as echarts from "echarts";
import AccidentMain from "./AccidentMain";

const RoadCondition2 = () => {
  const surfaceChart = useRef<HTMLDivElement>(null);
  const roadTypeChart = useRef<HTMLDivElement>(null);
  const sidewalkChart = useRef<HTMLDivElement>(null);
  const safetyStripChart = useRef<HTMLDivElement>(null);
  const searchResults = useAppSelector((state) => state.elastic.value);

  useEffect(() => {
    var firstChartDom = document.getElementById("surface-chart")!;
    var secondChartDom = document.getElementById("road-type-chart")!;
    var chart1 = echarts.init(firstChartDom);
    var chart2 = echarts.init(secondChartDom);
    var option1: EChartsOption;
    var option2: EChartsOption;

    option1 = {
      height: "50%",
      tooltip: {
        trigger: "item",
      },
      title: {
        text: "Yol Yüzeyi Durum",
        left: "center",
        textStyle: {
          color: "#1A4158",
          fontWeight: "bold",
          fontSize: 18,
        },
      },
      series: [
        {
          type: "pie",
          top: "25%",
          radius: [35, 70],
          itemStyle: {
            borderColor: "#fff",
            borderWidth: 1,
          },
          label: {
            fontSize: "14px",
            minMargin: 5,
          },
          data: [
            {
              value:
                searchResults.filter((el: any) => el.yolyuzey === "BUZLU")
                  .length > 0
                  ? searchResults.filter((el: any) => el.yolyuzey === "BUZLU")
                    .length
                  : "",
              name: "Buzlu",
            },
            {
              value:
                searchResults.filter((el: any) => el.yolyuzey === "ÇAMURLU")
                  .length > 0
                  ? searchResults.filter((el: any) => el.yolyuzey === "ÇAMURLU")
                    .length
                  : "",
              name: "Çamurlu",
            },
            {
              value:
                searchResults.filter((el: any) => el.yolyuzey === "KURU")
                  .length > 0
                  ? searchResults.filter((el: any) => el.yolyuzey === "KURU")
                    .length
                  : "",
              name: "Kuru",
            },
            {
              value:
                searchResults.filter((el: any) => el.yolyuzey === "ISLAK")
                  .length > 0
                  ? searchResults.filter((el: any) => el.yolyuzey === "ISLAK")
                    .length
                  : "",
              name: "Islak",
            },
            {
              value:
                searchResults.filter((el: any) => el.yolyuzey === "KARLI")
                  .length > 0
                  ? searchResults.filter((el: any) => el.yolyuzey === "KARLI")
                    .length
                  : "",
              name: "Karlı",
            },
            {
              value:
                searchResults.filter((el: any) => el.yolyuzey === "TOZLU")
                  .length > 0
                  ? searchResults.filter((el: any) => el.yolyuzey === "TOZLU")
                    .length
                  : "",
              name: "Tozlu",
            },
            {
              value:
                searchResults.filter(
                  (el: any) => el.yolyuzey === "DİĞER KAYGAN YÜZEY"
                ).length > 0
                  ? searchResults.filter(
                    (el: any) => el.yolyuzey === "DİĞER KAYGAN YÜZEY"
                  ).length
                  : "",
              name: "Diğer Kaygan Yüzey",
            },
            {
              value:
                searchResults.filter(
                  (el: any) => el.yolyuzey === "SEL/SU BİRİKİNTİLİ"
                ).length > 0
                  ? searchResults.filter(
                    (el: any) => el.yolyuzey === "SEL/SU BİRİKİNTİLİ"
                  ).length
                  : "",
              name: "Sel/Su Birikintili",
            },
          ],
        },
      ],
    };
    option2 = {
      height: "50%",
      tooltip: {
        trigger: "item",
      },
      title: {
        text: "Yol Tipi Durum",
        left: "center",
        textStyle: {
          color: "#1A4158",
          fontWeight: "bold",
          fontSize: 18,
        },
      },
      series: [
        {
          type: "pie",
          top: "25%",
          radius: [35, 70],
          itemStyle: {
            borderColor: "#fff",
            borderWidth: 1,
          },
          label: {
            fontSize: "14px",
            minMargin: 5,
          },
          data: [
            {
              value:
                searchResults.filter((el: any) => el.yoltipi === "BÖLÜNMÜŞ YOL")
                  .length > 0
                  ? searchResults.filter(
                    (el: any) => el.yoltipi === "BÖLÜNMÜŞ YOL"
                  ).length
                  : "",
              name: "Bölünmüş Yol",
            },
            {
              value:
                searchResults.filter((el: any) => el.yoltipi === "DİĞER")
                  .length > 0
                  ? searchResults.filter((el: any) => el.yoltipi === "DİĞER")
                    .length
                  : "",
              name: "Diğer",
            },
            {
              value:
                searchResults.filter(
                  (el: any) => el.yoltipi === "İKİ YÖNLÜ YOL"
                ).length > 0
                  ? searchResults.filter(
                    (el: any) => el.yoltipi === "İKİ YÖNLÜ YOL"
                  ).length
                  : "",
              name: "İki Yönlü Yol",
            },
            {
              value:
                searchResults.filter(
                  (el: any) => el.yoltipi === "TEK YÖNLÜ YOL"
                ).length > 0
                  ? searchResults.filter(
                    (el: any) => el.yoltipi === "TEK YÖNLÜ YOL"
                  ).length
                  : "",
              name: "Tek Yönlü Yol",
            },
          ],
        },
      ],
    };
    chart1.setOption(option1);
    chart2.setOption(option2);
    // myChart?.resize();
  }, [searchResults]);

  useEffect(() => {
    var firstChartDom = document.getElementById("sidewalk-chart")!;
    var secondChartDom = document.getElementById("safety-strip-chart")!;
    var chart1 = echarts.init(firstChartDom);
    var chart2 = echarts.init(secondChartDom);
    var option1: EChartsOption;
    var option2: EChartsOption;

    option1 = {
      height: "50%",
      tooltip: {
        trigger: "item",
      },
      title: {
        text: "Kaldırım Durum",
        left: "center",
        top: "20%",
        textStyle: {
          color: "#1A4158",
          fontWeight: "bold",
          fontSize: 18,
        },
      },
      series: [
        {
          type: "pie",
          top: "45%",
          radius: [35, 70],
          itemStyle: {
            borderColor: "#fff",
            borderWidth: 1,
          },
          label: {
            fontSize: "14px",
            minMargin: 5,
          },
          data: [
            {
              value:
                searchResults.filter((el: any) => el.kaldirim === "YOK")
                  .length > 0
                  ? searchResults.filter((el: any) => el.kaldirim === "YOK")
                    .length
                  : "",
              name: "Yok",
            },
            {
              value:
                searchResults.filter((el: any) => el.kaldirim === "VAR")
                  .length > 0
                  ? searchResults.filter((el: any) => el.kaldirim === "VAR")
                    .length
                  : "",
              name: "Var",
            },
          ],
        },
      ],
    };
    option2 = {
      height: "50%",
      tooltip: {
        trigger: "item",
      },
      title: {
        text: "Emniyet Şeridi Durum",
        left: "center",
        top: "20%",
        textStyle: {
          color: "#1A4158",
          fontWeight: "bold",
          fontSize: 18,
        },
      },
      series: [
        {
          type: "pie",
          top: "45%",
          radius: [35, 70],
          itemStyle: {
            borderColor: "#fff",
            borderWidth: 1,
          },
          label: {
            fontSize: "14px",
            minMargin: 5,
          },
          data: [
            {
              value:
                searchResults.filter(
                  (el: any) => el.emniyetseridibanketid === "YOK"
                ).length > 0
                  ? searchResults.filter(
                    (el: any) => el.emniyetseridibanketid === "YOK"
                  ).length
                  : "",
              name: "Yok",
            },
            {
              value:
                searchResults.filter(
                  (el: any) => el.emniyetseridibanketid === "VAR"
                ).length > 0
                  ? searchResults.filter(
                    (el: any) => el.emniyetseridibanketid === "VAR"
                  ).length
                  : "",
              name: "Var",
            },
          ],
        },
      ],
    };
    chart1.setOption(option1);
    chart2.setOption(option2);
    // myChart?.resize();
  }, [searchResults]);

  return (
    <div className="RoadCondition2">
      <AccidentMain />
      <div style={{ height: "100%" }}>
        <div
          style={{
            display: "flex",
            width: "100%",
            height: "250px",
            paddingTop: "25px",
          }}
        >
          <div
            ref={surfaceChart}
            id="surface-chart"
            className="pie-chart"
            style={{ width: "50%", height: "250px" }}
          />
          <div
            ref={roadTypeChart}
            id="road-type-chart"
            className="pie-chart"
            style={{ width: "50%", height: "250px" }}
          />
        </div>
        <div style={{ display: "flex", width: "100%", height: "350px" }}>
          <div
            ref={sidewalkChart}
            id="sidewalk-chart"
            className="pie-chart"
            style={{ width: "50%", height: "350px" }}
          />
          <div
            ref={safetyStripChart}
            id="safety-strip-chart"
            className="pie-chart"
            style={{ width: "50%", height: "350px" }}
          />
        </div>
      </div>
    </div>
  );
};
export default RoadCondition2;
