import "./Statistic2.scss";
import { useRef, useEffect } from "react";
import type { EChartsOption } from "echarts";
import { useAppSelector } from "../state/hooks";
import * as echarts from "echarts";
import AccidentMain from "./AccidentMain";

const RoadCondition3 = () => {
  const horizontalPathChart = useRef<HTMLDivElement>(null);
  const verticalPathChart = useRef<HTMLDivElement>(null);
  const laneLineChart = useRef<HTMLDivElement>(null);
  const railingChart = useRef<HTMLDivElement>(null);
  const searchResults = useAppSelector((state) => state.elastic.value);

  useEffect(() => {
    var firstChartDom = document.getElementById("horizontal-path-chart")!;
    var secondChartDom = document.getElementById("vertical-path-chart")!;
    var chart1 = echarts.init(firstChartDom);
    var chart2 = echarts.init(secondChartDom);
    var option1: EChartsOption;
    var option2: EChartsOption;

    option1 = {
      height: "50%",
      tooltip: {
        trigger: "item",
      },
      title: {
        text: "Yatay Güzergah Durum",
        left: "center",
        textStyle: {
          color: "#1A4158",
          fontWeight: "bold",
          fontSize: 18,
        },
      },
      series: [
        {
          type: "pie",
          top: "25%",
          radius: [35, 70],
          itemStyle: {
            borderColor: "#fff",
            borderWidth: 1,
          },
          label: {
            fontSize: "14px",
            minMargin: 5,
          },
          data: [
            {
              value:
                searchResults.filter(
                  (el: any) => el.geoyatayguzergahid === "VİRAJ"
                ).length > 0
                  ? searchResults.filter(
                    (el: any) => el.geoyatayguzergahid === "VİRAJ"
                  ).length
                  : "",
              name: "Viraj",
            },
            {
              value:
                searchResults.filter(
                  (el: any) => el.geoyatayguzergahid === "DÜZ YOL"
                ).length > 0
                  ? searchResults.filter(
                    (el: any) => el.geoyatayguzergahid === "DÜZ YOL"
                  ).length
                  : "",
              name: "Düz Yol",
            },
            {
              value:
                searchResults.filter(
                  (el: any) => el.geoyatayguzergahid === "TEHLİKELİ VİRAJ"
                ).length > 0
                  ? searchResults.filter(
                    (el: any) => el.geoyatayguzergahid === "TEHLİKELİ VİRAJ"
                  ).length
                  : "",
              name: "Tehlikeli Viraj",
            },
          ],
        },
      ],
    };
    option2 = {
      height: "50%",
      tooltip: {
        trigger: "item",
      },
      title: {
        text: "Düşey Güzergah Durum",
        left: "center",
        textStyle: {
          color: "#1A4158",
          fontWeight: "bold",
          fontSize: 18,
        },
      },
      series: [
        {
          type: "pie",
          top: "25%",
          radius: [35, 70],
          itemStyle: {
            borderColor: "#fff",
            borderWidth: 1,
          },
          label: {
            fontSize: "14px",
            minMargin: 5,
          },
          data: [
            {
              value:
                searchResults.filter(
                  (el: any) => el.geoduseyguzergahid === "TEPE ÜSTÜ"
                ).length > 0
                  ? searchResults.filter(
                    (el: any) => el.geoduseyguzergahid === "TEPE ÜSTÜ"
                  ).length
                  : "",
              name: "Tepe Üstü",
            },
            {
              value:
                searchResults.filter(
                  (el: any) => el.geoduseyguzergahid === "TEHLİKELİ EĞİM"
                ).length > 0
                  ? searchResults.filter(
                    (el: any) => el.geoduseyguzergahid === "TEHLİKELİ EĞİM"
                  ).length
                  : "",
              name: "Tehlikeli Eğim",
            },
            {
              value:
                searchResults.filter(
                  (el: any) => el.geoduseyguzergahid === "EĞİMSİZ"
                ).length > 0
                  ? searchResults.filter(
                    (el: any) => el.geoduseyguzergahid === "EĞİMSİZ"
                  ).length
                  : "",
              name: "Eğimsiz",
            },
            {
              value:
                searchResults.filter(
                  (el: any) => el.geoduseyguzergahid === "EĞİMLİ"
                ).length > 0
                  ? searchResults.filter(
                    (el: any) => el.geoduseyguzergahid === "EĞİMLİ"
                  ).length
                  : "",
              name: "Eğimli",
            },
          ],
        },
      ],
    };
    chart1.setOption(option1);
    chart2.setOption(option2);
    // myChart?.resize();
  }, [searchResults]);

  useEffect(() => {
    var firstChartDom = document.getElementById("lane-line-chart")!;
    var secondChartDom = document.getElementById("railing-chart")!;
    var chart1 = echarts.init(firstChartDom);
    var chart2 = echarts.init(secondChartDom);
    var option1: EChartsOption;
    var option2: EChartsOption;

    option1 = {
      height: "50%",
      tooltip: {
        trigger: "item",
      },
      title: {
        text: "Şerit Çizgisi Durum",
        left: "center",
        top: "20%",
        textStyle: {
          color: "#1A4158",
          fontWeight: "bold",
          fontSize: 18,
        },
      },
      series: [
        {
          type: "pie",
          top: "45%",
          radius: [35, 70],
          itemStyle: {
            borderColor: "#fff",
            borderWidth: 1,
          },
          label: {
            fontSize: "14px",
            minMargin: 5,
          },
          data: [
            {
              value:
                searchResults.filter((el: any) => el.seritcizgisi === "YOK")
                  .length > 0
                  ? searchResults.filter((el: any) => el.seritcizgisi === "YOK")
                    .length
                  : "",
              name: "Yok",
            },
            {
              value:
                searchResults.filter((el: any) => el.seritcizgisi === "VAR")
                  .length > 0
                  ? searchResults.filter((el: any) => el.seritcizgisi === "VAR")
                    .length
                  : "",
              name: "Var",
            },
          ],
        },
      ],
    };
    option2 = {
      height: "50%",
      tooltip: {
        trigger: "item",
      },
      title: {
        text: "Otokorkuluk Durum",
        left: "center",
        top: "20%",
        textStyle: {
          color: "#1A4158",
          fontWeight: "bold",
          fontSize: 18,
        },
      },
      series: [
        {
          type: "pie",
          top: "45%",
          radius: [35, 70],
          itemStyle: {
            borderColor: "#fff",
            borderWidth: 1,
          },
          label: {
            fontSize: "14px",
            minMargin: 5,
          },
          data: [
            {
              value:
                searchResults.filter((el: any) => el.otokorkuluk === "YOK")
                  .length > 0
                  ? searchResults.filter((el: any) => el.otokorkuluk === "YOK")
                    .length
                  : "",
              name: "Yok",
            },
            {
              value:
                searchResults.filter((el: any) => el.otokorkuluk === "VAR")
                  .length > 0
                  ? searchResults.filter((el: any) => el.otokorkuluk === "VAR")
                    .length
                  : "",
              name: "Var",
            },
          ],
        },
      ],
    };
    chart1.setOption(option1);
    chart2.setOption(option2);
    // myChart?.resize();
  }, [searchResults]);

  return (
    <div className="RoadCondition3">
      <AccidentMain />
      <div style={{ height: "100%" }}>
        <div
          style={{
            display: "flex",
            width: "100%",
            height: "250px",
            paddingTop: "25px",
          }}
        >
          <div
            ref={horizontalPathChart}
            id="horizontal-path-chart"
            className="pie-chart"
            style={{ width: "50%", height: "250px" }}
          />
          <div
            ref={verticalPathChart}
            id="vertical-path-chart"
            className="pie-chart"
            style={{ width: "50%", height: "250px" }}
          />
        </div>
        <div style={{ display: "flex", width: "100%", height: "350px" }}>
          <div
            ref={laneLineChart}
            id="lane-line-chart"
            className="pie-chart"
            style={{ width: "50%", height: "350px" }}
          />
          <div
            ref={railingChart}
            id="railing-chart"
            className="pie-chart"
            style={{ width: "50%", height: "350px" }}
          />
        </div>
      </div>
    </div>
  );
};
export default RoadCondition3;
