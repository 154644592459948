import { Toast } from 'primereact/toast';
import { useRef, useState } from 'react'

import { Dialog } from 'primereact/dialog';
import { RadioButton } from 'primereact/radiobutton';
import { Button } from 'primereact/button';
import logoImg from '../bakanlik-logo.png';
import './ImageDownload.scss';
import html2canvas from 'html2canvas';
import { useAppDispatch, useAppSelector } from '../../../state/hooks';
import { setImageRequest, setShowExportImage } from '../../../state/features/statisticSlice';
const ImageDownload = () => {

    const dispatch = useAppDispatch();
    const toast = useRef<any>();
    const displayExport = useAppSelector((state) => state.statistic.showExportImage);
    const [exporttImg, setExporttImg] = useState<any>('');
    const statisticName = useAppSelector((state) => state.statistic.statisticName);


    const onClickExport = () => {

        switch (exporttImg) {
            case "map":
                downloadMap();
                dispatch(setImageRequest(true))
                break;
            case "statistics":
                downloadStatistics();
                dispatch(setImageRequest(true))
                break;
            case "both":
                downloadAll();
                dispatch(setImageRequest(true))
                break;
            default:
                break;
        }
        onHide();
    };

    const onHide = () => {
        dispatch(setShowExportImage(!displayExport))
        dispatch(setImageRequest(false))
    };


    const downloadAll = () => {
        const buttons = document.querySelectorAll('.download-group button');
        const leftContent = document.querySelector('.left-content');
        const search = document.querySelector('.search');
        const buttonList = document.querySelectorAll('.button-groups button:not(.immovable-button)');
        const stats = statisticName === 'Statistic2'
            ? document.querySelector('.Statistic2')
            : document.querySelector('.date-based-stats');
        const generalResize = stats?.querySelector(statisticName === 'Statistic2'
            ? '.General'
            : '.date-based-data'
        );
        const dropDownButton = document.querySelector('.Statistic2 .subgroup .selection-group .select-kkn-group');
        const hrElement = document.querySelector('.right-content .button-groups .hr') as HTMLElement;
        const basemap = document.querySelector('.basemaps');
        const logo = document.querySelector('.logo');
        const coordinateButton = document.querySelector('.coordinate-container-button');
        buttons.forEach(button => button.classList.add('remove-shadow'));
        [logo, basemap].forEach(elem => elem?.classList.add('remove-bottom-left-icons'));
        leftContent?.classList.add('remove-for-analyse');
        search?.classList.add('remove-for-analyse');
        buttonList?.forEach(button => button.classList.add('remove-for-analyse'));
        hrElement?.classList.add('remove-for-hr');
        stats?.classList.add(statisticName === 'Statistic2' ? 'stats-redesign-all' : 'date-based-stats-redesign');
        generalResize?.classList.add(statisticName === 'Statistic2' ? 'general-resize' : 'date-based-data-resize');
        dropDownButton?.classList.add('remove-shadow');
        coordinateButton?.classList.add('remove-coordinate-button');
        const app = document.getElementById('App') as HTMLElement;
        const SCALE_FACTOR = 1; // Sabit bir ölçek kullanabilirsiniz
        document.documentElement.requestFullscreen();
        const appWidth = app.offsetWidth * SCALE_FACTOR;
        const appHeight = app.offsetHeight * SCALE_FACTOR;
        html2canvas(app, { width: appWidth, height: appHeight }).then(canvas => {
            const link = document.createElement('a');
            link.download = `${statisticName === "Statistic2" ? "harita_ve_sehir_istatistikleri" : "degisim_analizi"}.png`;
            // Get the context of the canvas
            const ctx: any = canvas.getContext('2d');
            // Create an image element for the logo
            const logo = new Image();
            logo.src = logoImg;
            // After the logo is loaded, draw it on the canvas before generating the PNG
            logo.onload = () => {
                // Set the desired opacity for the logo (value between 0 and 1)
                const logoOpacity = 0.20;

                // Save the current globalAlpha value
                const previousGlobalAlpha = ctx.globalAlpha;

                // Set the new globalAlpha value
                ctx.globalAlpha = logoOpacity;

                // Draw the logo on the canvas
                ctx.drawImage(logo, (appWidth / 2) - 200, (appHeight / 2) - 175, 300, 300);

                // Restore the previous globalAlpha value
                ctx.globalAlpha = previousGlobalAlpha;

                link.href = canvas.toDataURL();
                link.click();
            };
            if (document.fullscreenElement) {
                document.exitFullscreen();
            }
            app.style.transform = '';
        });
        buttons.forEach(button => button.classList.remove('remove-shadow'));
        [logo, basemap].forEach(elem => elem?.classList.remove('remove-bottom-left-icons'));
        leftContent?.classList.remove('remove-for-analyse');
        search?.classList.remove('remove-for-analyse');
        buttonList?.forEach(button => button.classList.remove('remove-for-analyse'));
        hrElement?.classList.remove('remove-for-hr');
        stats?.classList.remove(statisticName === 'Statistic2' ? 'stats-redesign-all' : 'date-based-stats-redesign');
        generalResize?.classList.remove(statisticName === 'Statistic2' ? 'general-resize' : 'date-based-data-resize');
        coordinateButton?.classList.remove('remove-coordinate-button');
    };






    const downloadStatistics = () => {
        const buttons = document.querySelectorAll('.download-group button');
        const dropDownButton = document.querySelector('.Statistic2 .subgroup .selection-group .select-kkn-group');
        buttons.forEach((button) => button.classList.add("remove-shadow"));
        dropDownButton?.classList.add("remove-shadow");
        const stats = document.querySelector(`.${statisticName}`);
        document.querySelectorAll<HTMLElement>(`.${statisticName}>*`).forEach((e) => e.classList.add('re-design'));
        stats?.classList.add("stats-redesign");

        const statistics = document.getElementById(statisticName)!;
        window.scrollTo(0, 0);
        document.documentElement.requestFullscreen();
        const pixelRatio = window.devicePixelRatio || 1;
        const options = {
            scrollX: 0,
            scrollY: 0,
            windowHeight: statistics.offsetHeight, // Window yüksekliği yerine elementin genişliğini alıyoruz
            windowWidth: statistics.offsetWidth, // Window genişliği yerine elementin yüksekliğini alıyoruz
            backgroundColor: null, // Arkaplan rengi
            scale: 1, // Ölçeklendirme faktörü
            // rotate: -90, // 90 derece saat yönünün tersine döndürme
        };
        html2canvas(statistics, options).then((canvas: any) => {
            const link = document.createElement("a");
            link.download = `${statisticName === "Statistic2" ? "sehir_istatistikleri" : "degisim_analizi"}.png`;

            const rotatedCanvas = document.createElement("canvas");
            const rotatedCtx: any = rotatedCanvas.getContext("2d");

            rotatedCanvas.width = canvas.height;
            rotatedCanvas.height = canvas.width;

            rotatedCtx.translate(rotatedCanvas.width / (3.5 * pixelRatio), rotatedCanvas.height / 3.5);
            // rotatedCtx.rotate(-0.5 * Math.PI); // 90 derece döndürme
            rotatedCtx.drawImage(canvas, -100, -200, statistics.offsetWidth * 0.8, rotatedCanvas.height);

            const ctx: any = rotatedCtx;

            const logo = new Image();
            logo.src = logoImg;

            logo.onload = () => {
                const logoWidth = canvas.width - 100; // Canvas genişliğinden 100 piksel geride
                const logoHeight = logoWidth * (logo.height / logo.width);

                const logoX = (canvas.width - logoWidth);
                const logoY = (canvas.height - logoHeight) / 2;

                const logoOpacity = 0.20;
                const previousGlobalAlpha = ctx.globalAlpha;
                ctx.globalAlpha = logoOpacity;

                ctx.drawImage(logo, logoX * 1.25, logoY / 4, 200, 200);

                ctx.globalAlpha = previousGlobalAlpha;

                link.href = rotatedCanvas.toDataURL();
                link.click();

                if (document.fullscreenElement) {
                    document.exitFullscreen();
                }
            };
        });

        buttons.forEach((button) => button.classList.remove("remove-shadow"));
        dropDownButton?.classList.remove("remove-shadow");
        document.querySelectorAll<HTMLElement>(`.${statisticName}>*`).forEach((e) => e.classList.remove('re-design'));
        stats?.classList.remove("stats-redesign");
    };



    const downloadMap = () => {
        const map = document.getElementById("map") as HTMLElement;
        const coordinateButton = document.querySelector('.coordinate-container-button');
        let page: any;
        page = map;
        coordinateButton?.classList.add("remove-coordinate-button");
        window.scrollTo(0, 0);
        document.documentElement.requestFullscreen();

        const app = document.getElementById('App') as HTMLElement;
        const SCALE_FACTOR = 1; // Sabit bir ölçek kullanabilirsiniz
        const appWidth = app.offsetWidth * SCALE_FACTOR;
        const appHeight = app.offsetHeight * SCALE_FACTOR;

        html2canvas(page, {
            width: 1920,
            height: 932
        }).then((canvas: any) => {
            var link = document.createElement("a");
            link.download = "harita_istatistikleri.png";

            const ctx: any = canvas.getContext('2d');
            // Create an image element for the logo
            const logo = new Image();
            logo.src = logoImg;
            // After the logo is loaded, draw it on the canvas before generating the PNG
            logo.onload = () => {
                // Set the desired opacity for the logo (value between 0 and 1)
                const logoOpacity = 0.20;

                // Save the current globalAlpha value
                const previousGlobalAlpha = ctx.globalAlpha;

                // Set the new globalAlpha value
                ctx.globalAlpha = logoOpacity;

                // Draw the logo on the canvas
                ctx.drawImage(logo, (appWidth / 2) - 200, (appHeight / 2) - 175, 300, 300);

                // Restore the previous globalAlpha value
                ctx.globalAlpha = previousGlobalAlpha;

                link.href = canvas.toDataURL();
                link.click();
            };

            // link.href = canvas.toDataURL();
            // link.click();

            if (document.fullscreenElement) {
                document.exitFullscreen();
            }
            app.style.transform = '';
        });
        coordinateButton?.classList.remove("remove-coordinate-button");
    };

    return (
        <div className='export-image'>
            <Toast ref={toast}></Toast>
            <Dialog
                className='export-dialog'
                header='Ekran Görüntüsü Al'
                visible={true}
                onHide={() => dispatch(setShowExportImage(!displayExport))}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                    }}
                >
                    <div className='pair'>
                        <div className='content'>
                            <fieldset className='fieldset'>
                                <legend className='data-type-header'>Seçenekler</legend>
                                <div className='radio-button-group'>
                                    <div className='selection'>
                                        <RadioButton
                                            inputId='Map'
                                            value='map'
                                            onChange={(e: any) => {
                                                setExporttImg(e.value);
                                            }}
                                            checked={exporttImg === 'map'}
                                        />
                                        <label style={{ cursor: 'pointer' }} htmlFor='Map'>
                                            Harita Sayfası
                                        </label>
                                    </div>
                                    <div className='selection'>
                                        <RadioButton
                                            inputId='Statistics'
                                            value='statistics'
                                            onChange={(e: any) => {
                                                setExporttImg(e.value);
                                            }}
                                            checked={exporttImg === 'statistics'}
                                            style={{ cursor: 'pointer' }}
                                        />
                                        <label style={{ cursor: 'pointer' }} htmlFor='Statistics'>
                                            İstatistik Sayfası
                                        </label>
                                    </div>
                                    <div className='selection'>
                                        <RadioButton
                                            inputId='Both-Areas'
                                            value='both'
                                            onChange={(e: any) => {
                                                setExporttImg(e.value);
                                            }}
                                            checked={exporttImg === 'both'}
                                            style={{ cursor: 'pointer' }}
                                        />
                                        <label style={{ cursor: 'pointer' }} htmlFor='Both-Areas'>
                                            Harita ve İstatistik Sayfası
                                        </label>
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                    </div>
                    <br></br>
                    <div
                        style={{
                            marginBottom: '20px',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                        }}
                    >
                    </div>
                    <div className='button-area'>

                        <Button
                            disabled={exporttImg === "" ? true : false}
                            onClick={() => onClickExport()}
                            label='Resim Olarak İndir'
                        ></Button>
                    </div>
                </div>
            </Dialog>
        </div>
    )
}

export default ImageDownload