import axios from "axios";
import { JSEncrypt } from "jsencrypt";

const { REACT_APP_BASE_URL, REACT_APP_GEOSERVER_URL, REACT_APP_PUBLIC_KEY } = process.env;

const getToken = () => {
  return localStorage.getItem("ag_token");
};

async function geoserverWfsReq(url: string) {
  function encryptTextWithRsaPublicKey(toEncrypt: any, publicKeyEncoded: any): any {
    const publicKey: any = atob(publicKeyEncoded);
    const jsEncrypt = new JSEncrypt();
    jsEncrypt.setPublicKey(publicKey);
   
    return jsEncrypt.encrypt(toEncrypt);
  }

  const config = {
    headers: { Authorization: "Bearer " + getToken() },
  };

  const encodedText: string = encryptTextWithRsaPublicKey(
    `${REACT_APP_GEOSERVER_URL}geoserver/trafik/ows?service=WFS&${url}`,
    REACT_APP_PUBLIC_KEY
  );
  
  const postData = {
    mftoInfo: encodedText,
  };

  return await axios.post(`${REACT_APP_BASE_URL}geoauth/geo`, postData, config);
}

export const UtilRestApi = {
  geoserverWfsReq,
};
