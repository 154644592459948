import { InputText } from "primereact/inputtext"
import { useAppSelector } from "../state/hooks";

export const General = () => {
    const selectedFeature = useAppSelector(state => state.info.selectedFeature);

    return (
        <div className='container'>

            <div className="left-group">
                <div className='input-group'>
                    <label htmlFor="name">Kaza ID</label>
                    <InputText id="name" value={selectedFeature[0].kazaid || ''} />
                </div>
                <div className='input-group'>
                    <label htmlFor="name">İl</label>
                    <InputText id="name" value={selectedFeature[0].ilad || ''} />
                </div>
                <div className='input-group'>
                    <label htmlFor="name">İlçe</label>
                    <InputText id="name" value={selectedFeature[0].ilcead || ''} />
                </div>
                <div className='input-group'>
                    <label htmlFor="name">Mahalle</label>
                    <InputText id="name" value={selectedFeature[0].mahad || ''} />
                </div>
            </div>

            <div className="right-group">
                <div className='input-group'>
                    <label htmlFor="name">Kaza Tarihi</label>
                    <InputText id="name" value={selectedFeature[0].kazatarihi || ''} />
                </div>
                <div className='input-group'>
                    <label htmlFor="name">Yerleşim Yeri</label>
                    <InputText id="name" value={selectedFeature[0].yerlesimyeriack || ''} />
                </div>
                <div className='input-group'>
                    <label htmlFor="name">Gün Durumu</label>
                    <InputText id="name" value={selectedFeature[0].gunid || ''} />
                </div>
                <div className='input-group'>
                    <label htmlFor="name">Hava Durumu</label>
                    <InputText id="name" value={selectedFeature[0].havadurumuid || ''} />
                </div>
            </div>

        </div>
    )
}
