import "./Table.scss";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useAppSelector } from "../state/hooks";
import { Button } from "primereact/button";
import { changeTableVisibility } from "../state/features/tableSlice";
import { useDispatch } from "react-redux";
import { useEffect, useRef, useState } from "react";
import {
  setFeatureSelectionType,
  setIsTableSelection,
  setLayerForInfo,
  setSelectedFeature,
} from "../state/features/infoSlice";
import { GeoJSON } from "ol/format";
import Feature from "ol/Feature";
import ExcelJS from "exceljs";
import { ProgressSpinner } from "primereact/progressspinner";
import { setTableProgressVisibility } from "../state/features/sharedSlice";
import { UtilRestApi } from "../../utils/restapi/util";

export const Table = () => {
  const dt = useRef<any>(null);
  const dispatch = useDispatch();
  const [selectedTableRow, setSelectedTableRow] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState<any>([]);
  const [accidentData, setAccidentData] = useState<any>([]);
  const [featuresArr, setFeaturesArr] = useState<Feature<any>[]>([]);
  const [rows, setRows] = useState(10);
  const [first, setFirst] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const searchResults = useAppSelector((state) => state.elastic.value);
  const selectedFeature = useAppSelector((state) => state.info.selectedFeature);
  const tableColumns = useAppSelector((state) => state.info.columns);
  const layerForTable: any = useAppSelector(
    (state) => state.info.layerForTable
  );
  const progressVisibility = useAppSelector(
    (state) => state.shared.tableProgressVisibility
  );
  const userFunctionPerm = useAppSelector(
    (state) => state.user.userFunctionPerm
  );

  useEffect(() => {
    if (selectedFeature && selectedFeature.length === 0) {
      setSelectedTableRow(null);
    }
  }, [selectedFeature]);

  useEffect(() => {
    tableForElasticData();
  }, [searchResults]);

  useEffect(() => {
    tableForGeoserverData();
  }, [layerForTable, first]);

  const tableForElasticData = async () => {
    let copyArr = [...searchResults];
    copyArr = copyArr.map((obj) => {
      const date = new Date(obj.kazatarihi).toLocaleDateString("tr");
      const hour = new Date(obj.kazatarihi).toLocaleTimeString("tr");
      return {
        ...obj,
        kazatarihi: `${date + "  " + hour}`,
      };
    });
    setAccidentData(copyArr);
  };
  const tableForGeoserverData = async () => {
    if (layerForTable.layer !== "usr1_ag_kaza") {
      setLoading(true);

      UtilRestApi.geoserverWfsReq(
        `maxFeatures=${rows}&startIndex=${first}&version=1.0.0&request=GetFeature&typeName=${layerForTable.layer}&outputFormat=application%2Fjson`
      ).then((res) => {
        if (res) {
          const features = res.data.features;
          const totalCount = res.data.totalFeatures;
          setTotalRecords(totalCount ? totalCount : features.length);

          let featuresArray = [];

          for (const feature of features) {
            const geojsonInstance = new GeoJSON();
            const readFeatures = geojsonInstance.readFeature(feature);
            featuresArray.push(readFeatures);
          }


          setFeaturesArr(featuresArray);
        }
        const copyArr = [...res.data.features];
        res.data.features.find((feature: any) => {
          return copyArr.findIndex((obj: any, index: any) => {
            if (feature.id === obj.id) {
              copyArr[index].properties.id = feature.id;
              copyArr[index].properties.geometry = feature.geometry;
              return true;
            }
            return -1;
          });
        });
        const featuresProperties = copyArr.map(
          (feature: any) => feature.properties
        );
        setTableData(featuresProperties);
        setLoading(false);
      });
    }
  };

  const exportExcel = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    format: "xlsx" | "csv"
  ) => {
    e.preventDefault();
    dispatch(setTableProgressVisibility(true));

    const workbook = new ExcelJS.Workbook();
    workbook.addWorksheet(tableColumns?.alias);
    const worksheet: any = workbook?.getWorksheet(tableColumns.alias);

    if (worksheet) {

      //başlıklar
      worksheet.columns = tableColumns.map((column: any) => {
        return {
          header: column.alias,
          key: column.name,
          width: 20,
        };
      });

      // başlık dışındaki satırlar
      if (layerForTable.layer === "usr1_ag_kaza") {
        if (accidentData.length > 0) {
          for (const data of accidentData) {
            worksheet.addRow(data);
          }
        }
      } else {
        const res = await UtilRestApi.geoserverWfsReq(
          `&version=1.0.0&request=GetFeature&typeName=${layerForTable.layer}&outputFormat=application%2Fjson`
        );

        if (res.data.features.length > 0) {
          for (const data of res.data.features) {
            worksheet.addRow(data.properties);
          }
        }
      }

      // Sayfadaki ilk satırın (sütun başlıkları) kalın olmasını sağlar
      worksheet.getRow(1).font = { bold: true };
      // Sayfadaki ilk satırın (sütun başlıkları) ortada hizalanmasını sağlar.
      worksheet.getRow(1).alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.getRow(1).height = 30;
      worksheet.getRow(1).border = {
        top: { style: "thick" },
        left: { style: "thick" },
        right: { style: "thick" },
        bottom: { style: "thick" },
      };
    }


    const uint8Array =
      format === "xlsx"
        ? await workbook.xlsx.writeBuffer()
        : await workbook.csv.writeBuffer();
    const blob = new Blob([uint8Array], { type: "application/octet-binary" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${layerForTable.alias}.` + format;
    a.click();
    a.remove();
    dispatch(setTableProgressVisibility(false));
  };

  const gotoSelectionHandler = async () => {
    let selectedFeature: any;
    featuresArr.find((feature: any) => {

      if (selectedTableRow.id.split(".")[1] === feature.getId().split(".")[1]) {
        selectedFeature = feature;
      }
      return feature;
    });

    dispatch(setSelectedFeature([]));
    dispatch(setIsTableSelection(true));
    if (layerForTable.layer === "usr1_ag_kaza") {
      dispatch(setSelectedFeature([selectedTableRow]));
      dispatch(
        setFeatureSelectionType({ type: "accident", geomtype: "Point" })
      );
    } else if (layerForTable.layer.includes("layer_kkn")) {
      dispatch(setSelectedFeature([selectedFeature]));
      dispatch(setFeatureSelectionType({ type: "kkn", geomtype: "Point" }));
    } else if (layerForTable.layer.includes("layer_kyyb")) {
      dispatch(setSelectedFeature([selectedFeature]));
      dispatch(setFeatureSelectionType({ type: "kyyb", geomtype: "Polygon" }));
    } else {
      dispatch(
        setFeatureSelectionType({ type: "other", geomtype: layerForTable.type })
      );
      dispatch(setSelectedFeature([selectedFeature]));
    }
    dispatch(setLayerForInfo(layerForTable));
  };

  const clearSelectionHandler = async () => {
    setSelectedTableRow(null);
    dispatch(setSelectedFeature(null));
  };

  const header = (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        height: "23px",
      }}
    >
      <div className="left-button-group">
        {layerForTable.layer !== "usr1_ag_arac" &&
          layerForTable.layer !== "usr1_ag_sahis" && (
            <>
              <Button
                className="table-buttons p-button-outlined"
                style={{ marginRight: "10px" }}
                disabled={!selectedTableRow}
                label="Seçili Veriye Git"
                onClick={gotoSelectionHandler}
              />
              <Button
                className="table-buttons p-button-outlined p-button-help"
                style={{ marginRight: "10px" }}
                label="Seçimi Temizle"
                disabled={!selectedTableRow}
                onClick={clearSelectionHandler}
              />
            </>
          )}

        {userFunctionPerm.find(
          (perm: any) => perm.item === "Trafik Kaza Verisi İndirme"
        ) && (
            <Button
              className="table-buttons p-button-outlined p-button-success"
              style={{ marginRight: "10px" }}
              label="Dışa Aktar"
              type="button"
              onClick={(e: any) => exportExcel(e, "xlsx")}
            />
          )}

        <div></div>
      </div>
      <Button
        icon="pi pi-times"
        className="close-button p-button-rounded p-button-text"
        aria-label="Cancel"
        onClick={() => {
          dispatch(changeTableVisibility(false));
        }}
      />
    </div>
  );

  const onPage = (event: any) => {
    setRows(event.rows);
    setFirst(event.first);
  };

  // const onSort = (event: any) => {
  //     setSortProperties({ sortField: event.sortField, sortOrder: event.sortOrder })
  // }
  const totalRecordsTemplate = <div>Veri Sayısı : {totalRecords}</div>;
  const elasticTotalRecordsTemplate = (
    <div>Veri Sayısı : {searchResults.length}</div>
  );

  return (
    <>
      {progressVisibility && (
        <ProgressSpinner
          className="progress-spinner"
          strokeWidth="8"
          fill="var(--surface-ground)"
          animationDuration=".5s"
        />
      )}
      <div className="Table">
        {layerForTable.layer === "usr1_ag_kaza" ? (
          <DataTable
            ref={dt}
            className="single-table-style"
            value={accidentData}
            header={header}
            filterDisplay="menu"
            size="small"
            selectionMode="single"
            responsiveLayout="scroll"
            resizableColumns
            stripedRows
            selection={selectedTableRow}
            onSelectionChange={(e: any) => setSelectedTableRow(e.value)}
            paginator
            rows={10}
            paginatorLeft={elasticTotalRecordsTemplate}
            rowsPerPageOptions={[10, 20, 50]}
            paginatorPosition="top"
          >
            {tableColumns.map((col: any, index: any) => (
              <Column
                key={index}
                field={col.name}
                header={col.alias}
                sortable
              />
            ))}
          </DataTable>
        ) : (
          <DataTable
            ref={dt}
            className="single-table-style"
            value={tableData}
            header={header}
            filterDisplay="menu"
            size="small"
            selectionMode="single"
            responsiveLayout="scroll"
            resizableColumns
            stripedRows
            lazy
            selection={selectedTableRow}
            onSelectionChange={(e: any) => setSelectedTableRow(e.value)}
            paginator
            first={first}
            rows={rows}
            onPage={onPage}
            totalRecords={totalRecords}
            paginatorLeft={totalRecordsTemplate}
            paginatorPosition="top"
            loading={loading}
          // onSort={onSort}
          // sortField={sortProperties.sortField}
          // sortOrder={sortProperties.sortOrder}
          >
            {tableColumns.map((col: any, index: any) => (
              <Column
                key={index}
                field={col.name}
                header={col.alias}
              // sortable
              />
            ))}
          </DataTable>
        )}
      </div>
    </>
  );
};
