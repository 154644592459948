import { Feature } from "ol";
import { useContext, useEffect, useState } from "react";
import { useAppSelector } from "../../state/hooks";
import { MapContext } from "../MapContext";
import { useDispatch } from "react-redux";
import {
    setResults,
    setResultScore,
    setResultTime,
} from "../../state/features/elasticSlice";
import { ElasticRestApi } from "../../../utils/restapi/elastic";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import Point from "ol/geom/Point";
import WebGLPointsLayer from "ol/layer/WebGLPoints";
import Vector from "ol/source/Vector";
import GeoJSON from "ol/format/GeoJSON";
import Geometry from "ol/geom/Geometry";
import { transformExtent } from "ol/proj";
import { ClusterRestApi } from "../../../utils/restapi/cluster";
import { Fill, Stroke, Style, Text } from "ol/style";
import CircleStyle from "ol/style/Circle";
// import { TileWMS } from 'ol/source';
// import JSONFeature from "ol/format/JSONFeature";
// import { Cluster, Stamen } from "ol/source";
// import TileLayer from "ol/layer/Tile";
// import { KML } from "ol/format";
// import { ActiveButtonState } from "../../shared/button-state/ButtonState";
// import { setDrawState } from "../../state/features/drawStateSlice";
// import { setShapeState } from "../../state/features/zoomStateSlice";
// import { DrawStateType } from "../../../utils/model";
// import { createEmpty, extend, getHeight, getWidth } from "ol/extent";



export const WebGL = () => {
    const dispatch = useDispatch();
    const map = useContext(MapContext);
    const [vector, setVector] = useState<any>(new VectorSource({}))
    const [vectorSource, setSource] = useState<any>(new VectorSource({}))
    const token = useAppSelector(state => state.login.token);
    const limit = useAppSelector(state => state.elastic.limit)
    const date = useAppSelector(state => state.elastic.date)
    const selectedCity = useAppSelector(state => state.elastic.city)
    const selectedTown = useAppSelector(state => state.elastic.town)
    const selectedNeighborhood = useAppSelector(state => state.elastic.neighborhood)
    const quickSearchValue = useAppSelector(state => state.elastic.quickSearchValue)
    const shapeArr = useAppSelector(state => state.elastic.shape)
    const searchResults = useAppSelector(state => state.elastic.value);
    const selectedListVisibilty = useAppSelector(state => state.elastic.selectedListVisibilty);
    const accidentFilters = useAppSelector(state => state.elastic.accidentFilters);
    const activeFilter = useAppSelector(state => state.elastic.isActive);
    const isSidebarAccidentVisible = useAppSelector(state => state.elastic.isSidebarAccidentVisible);
    const isHeatMapActive = useAppSelector(state => state.elastic.isHeatMapActive);
    const mapExtent = useAppSelector(state => state.maps.extent);
    const mapZoomValue = useAppSelector(state => state.maps.zoom);
    const searchListVisibilty = useAppSelector(state => state.elastic.searchListVisibilty)
    let featuresArray: any = [];
    const [clusterx, setCluster] = useState<any>([])
    const services = useAppSelector((state) => state.service.services);
    let transformed: boolean;
    // const activeButtonType = useAppSelector(
    //     (state) => state.buttonState.activeButtonType
    //   );

    //bu modülde elastik isteği yapılıyor ve trafik kazaları webglpointslayer olarak gösteriliyor.
    useEffect(() => {
        let pointsLayer: WebGLPointsLayer<Vector<Geometry>>
        const vectorSource = new Vector({
            features: searchResults,
            format: new GeoJSON(),
            wrapX: true,
        });

        // if(activeButtonType === ActiveButtonState.SIDEBAR){}


        const zIndexTrafik = (10000000000 - parseInt(services.find((el: any) => el.name === "trafik_kazalari")?.order));
        pointsLayer = new WebGLPointsLayer({
            source: vectorSource,
            style: circleStyle,
            disableHitDetection: true,
            zIndex: zIndexTrafik,
            properties: {
                layer_type: "WebGLPointsLayer"
            }
        });
        pointsLayer.set('webgl', true)
        setVector(pointsLayer)
        setVector(vectorSource)
        map.addLayer(pointsLayer);

        return () => {
            if (pointsLayer) {
                map.removeLayer(pointsLayer)
                pointsLayer.dispose();
            }
        }

    }, [map])

    let circleStyle = {
        symbol: {
            symbolType: "circle",
            size: ["interpolate", ["linear"], ["get", "kaza_siddet"], 'ÖLÜMLÜ', 16, 'YARALANMALI', 10, 'MADDİ', 10],
            color: [
                "interpolate",
                ["linear"],
                ["get", "kaza_siddet"],
                'ÖLÜMLÜ',
                "#e31e24",
                'YARALANMALI',
                "#ef7f1a",
                'MADDİ',
                "#007bff"
            ],
            rotateWithView: false
        }
    };



    useEffect(() => {

        if (vectorSource.getFeatures().length > 0) {
            vectorSource.clear()
        }

        const textFill = new Fill({
            color: "#fff"
        });
        //  const textStroke = new Stroke({
        //    color: "rgba(100, 100, 100, 0.6)",
        //    width: 3
        //  });
        //  let max_count = 0;
        //  let min_count = 999999999999;

        let maxFeatureCount: any;
        let vector: any = null;

        const calculateClusterInfo = function (resolution: any) {
            maxFeatureCount = 0;
            const features = vector.getSource().getFeatures();
            let feature, radius;

            for (let i = features.length - 1; i >= 0; --i) {
                feature = features[i];
                const size = feature.get("count")
                radius = Math.max(10, 1.8 * Math.log(size) / Math.log(2))//Math.max(8, Math.min(size * 0.75, 24))
                // radius = (0.25 * (getWidth(extent) + getHeight(extent))) / resolution;
                feature.set("radius", radius);
            }
        };

        let currentResolution: any;
        function styleFunction(feature: any, resolution: any) {
            if (resolution !== currentResolution) {
                calculateClusterInfo(resolution);
                currentResolution = resolution;
            }
            let style;
            const size = feature.get("count")// feature.get("features").length;
            //   if (size > 1) {
            style = new Style({
                image: new CircleStyle({
                    radius: feature.get("radius"),
                    fill: new Fill({/*255, 153, 0,*/
                        //#61A6C6
                        color: feature.get("radius") >= 8 && feature.get("radius") < 12 ? [227, 143, 2, Math.min(0.9, 0.4 + feature.get("radius") / maxFeatureCount)] :
                            feature.get("radius") >= 12 && feature.get("radius") < 16 ? [0, 176, 170, Math.min(0.9, 0.4 + feature.get("radius") / maxFeatureCount)] :
                                feature.get("radius") >= 16 && feature.get("radius") < 20 ? [76, 94, 143, Math.min(0.9, 0.4 + feature.get("radius") / maxFeatureCount)] :
                                    [255, 22, 0, Math.min(0.85, 0.4 + feature.get("radius") / maxFeatureCount)]
                    })
                }),
                text: new Text({
                    text: size.toString(),
                    fill: textFill,
                    // stroke: textStroke,
                    font: "bold 14px tahoma"
                })
            });
            //   } 
            //   else {
            //     style = createEarthquakeStyle(feature);
            //   }
            return style;
        }

        vector = new VectorLayer({
            source: new VectorSource({
            }),
            properties: {
                alias: "ClusteredOBJ"
            },
            style: styleFunction
        });

        if (mapZoomValue <= 12) {

            for (let x = 0; x < clusterx.length; x++) {
                const coords = clusterx[x]

                // if(coords.gridCentroid.count > max_count){
                //   max_count = coords.gridCentroid.count
                // }
                // if(coords.gridCentroid.count < min_count && coords.gridCentroid.count !== 1){
                //   min_count = coords.gridCentroid.count
                // }
                vector
                    .getSource()
                    .addFeature(
                        new Feature({
                            geometry: new Point([
                                coords.gridCentroid.location.lon,
                                coords.gridCentroid.location.lat
                            ]).transform("EPSG:4326", "EPSG:3857"),
                            count: coords.gridCentroid.count,
                            key: coords.key,
                        })
                    );
            }
        }

        const zIndexTrafik = (10000000000 - parseInt(services.find((el: any) => el.name === "trafik_kazalari")?.order));
        vector.setZIndex(zIndexTrafik)
        setSource(vector.getSource())

        map.addLayer(vector);

    }, [clusterx]);



    //   useEffect(()=>{
    //     if (vectorSource.getFeatures().length > 0  && isSidebarAccidentVisible && !isHeatMapActive) {
    //         vectorSource.clear();
    //         if (selectedListVisibilty) {
    //             return;
    //         }
    //     } else {
    //         vectorSource.clear();
    //     }
    //   },[vectorSource.getFeatures().length, isSidebarAccidentVisible, isHeatMapActive,
    //     searchListVisibilty, selectedListVisibilty])


    useEffect(() => {
        if (!isHeatMapActive && isSidebarAccidentVisible) {
            postCluster()
        } else {
            if (vectorSource.getFeatures().length > 0) {
                vectorSource.clear()
            }
        }
    }, [token, quickSearchValue, shapeArr,
        date, selectedCity.ad,
        selectedTown.ad, selectedNeighborhood.ad,
        mapExtent, accidentFilters, activeFilter,
        isSidebarAccidentVisible, mapZoomValue, isHeatMapActive])

    const postCluster = async () => {

        if (token && isSidebarAccidentVisible) {
            let postBody: any
            if (mapExtent && mapExtent.length > 0 && mapZoomValue != null && mapZoomValue <= 12) {
                const swap = (array: any, i: any, j: any) => [array[i], array[j]] = [array[j], array[i]];
                const extentTransform = transformExtent(mapExtent, 'EPSG:3857', 'EPSG:4326')
                const copyArr = [...extentTransform]
                swap(copyArr, 4, 2)
                const filteredExtent = copyArr.filter(a => a !== undefined)
                const fixedZoomValue = Math.round(parseInt(mapZoomValue))

                postBody = {
                    cluster: {
                        zoom: fixedZoomValue + 2,
                        bounds: {
                            bottom_right: [filteredExtent[3], filteredExtent[1]],
                            top_left: [filteredExtent[0], filteredExtent[2]]
                        }
                    }
                }
            }

            let transformedCoords: any;
            if (shapeArr) {
                if (!transformed) {
                    transformedCoords = shapeArr.clone().transform('EPSG:3857', 'EPSG:4326').getCoordinates()
                    transformed = true;
                }
                postBody.geo_shape = {
                    geom: {
                        shape: {
                            type: 'polygon',
                            coordinates: transformedCoords,
                            crossOrigin: "anonymous",
                        }
                    }
                }
            } else {
                transformed = false;
            }

            await ClusterRestApi.getClusterResult(
                quickSearchValue,
                date,
                selectedCity.ad,
                selectedTown.ad,
                selectedNeighborhood.ad,
                accidentFilters[0],
                accidentFilters[1],
                accidentFilters[2],
                accidentFilters[3],
                accidentFilters[4],
                accidentFilters[5],
                accidentFilters[6],
                accidentFilters[7],
                accidentFilters[8],
                accidentFilters[9],
                accidentFilters[10],
                accidentFilters[11],
                accidentFilters[12],
                postBody
            ).then((res: any) => {
                setCluster(res.data.aggregations.gridSplit.buckets)
            })
        }
    }


    //elastik isteğinin yapıldığı yer.
    useEffect(() => {
        postElastic()
    }, [token, limit, quickSearchValue, shapeArr,
        date, selectedCity.ad,
        selectedTown.ad, selectedNeighborhood.ad,
        mapExtent, accidentFilters, activeFilter,
        isSidebarAccidentVisible, mapZoomValue, isHeatMapActive])

    const postElastic = async () => {

        if (token && isSidebarAccidentVisible) {
            let postBody: any = {}

            if (mapExtent && mapExtent.length > 0/*  && mapZoomValue!=null && mapZoomValue>12*/) {
                const swap = (array: any, i: any, j: any) => [array[i], array[j]] = [array[j], array[i]];
                const extentTransform = transformExtent(mapExtent, 'EPSG:3857', 'EPSG:4326')
                const copyArr = [...extentTransform]
                swap(copyArr, 4, 2)
                const filteredExtent = copyArr.filter(a => a !== undefined)
                postBody = {
                    geo_bounding_box: {
                        geom: {
                            bottom_right: [filteredExtent[3], filteredExtent[1]],
                            top_left: [filteredExtent[0], filteredExtent[2]]
                        }
                    }
                }
            }
            if ((shapeArr) || (shapeArr && isHeatMapActive)) {
                postBody = {}
                postBody.geo_shape = {
                    geom: {
                        shape: {
                            type: 'polygon',
                            coordinates: shapeArr.clone().transform('EPSG:3857', 'EPSG:4326').getCoordinates(),
                            crossOrigin: "anonymous",
                        }
                    }
                }
            }

            await ElasticRestApi.getElasticResult(
                limit,
                quickSearchValue,
                date,
                selectedCity.ad,
                selectedTown.ad,
                selectedNeighborhood.ad,
                accidentFilters[0],
                accidentFilters[1],
                accidentFilters[2],
                accidentFilters[3],
                accidentFilters[4],
                accidentFilters[5],
                accidentFilters[6],
                accidentFilters[7],
                accidentFilters[8],
                accidentFilters[9],
                accidentFilters[10],
                accidentFilters[11],
                accidentFilters[12],
                postBody,
            ).then((res) => {
                if (!res || res.data.hits.hits.length === 0) {
                    dispatch(setResults([]))
                } else {
                    const resultArray = res.data.hits.hits.map((value: any) => value._source)
                    const resultTime = millisToSeconds(res.data.took);
                    const resultScore = res.data.hits.total.value;
                    dispatch(setResultTime(resultTime))
                    dispatch(setResultScore(resultScore))
                    resultArray.map((res: any) => {
                        let geomString = res.geom;
                        let mySubString = geomString.substring(
                            geomString.indexOf('(') + 1,
                            geomString.lastIndexOf(')')
                        );
                        const parsed = mySubString.split(' ')
                        const geom = [+parsed[0], +parsed[1]]
                        res.geom = geom;
                    });
                    dispatch(setResults(resultArray))
                    // if (shapeArr !== null) {
                    //   console.log("shapppp",shapeArr)
                    // }
                }
            })
        }
    }

    const millisToSeconds = (ms: number) => {
        var seconds: any = ((ms % 60000) / 1000);
        return (
            (seconds < 10 ? "" : "") + seconds
        );
    }

    useEffect(() => {
        if (mapZoomValue > 12) {
            if (searchResults.length > 0 && isSidebarAccidentVisible && !isHeatMapActive) {
                vector.clear();

                if (selectedListVisibilty) {
                    return;
                }

                for (const result of searchResults) {
                    drawAllPoints({
                        coordinates: result.geom,
                        kaza_siddet: result.kaza_siddet,
                        ogc_fid: result.ogc_fid,
                        olu_say: result.olu_say,
                        yarali_say: result.yarali_say
                    })
                }

            } else {
                vector.clear();
            }
        }
        else {
            vector.clear();
        }
    }, [searchResults.length, isSidebarAccidentVisible, isHeatMapActive,
        searchListVisibilty, selectedListVisibilty, mapZoomValue]);

    const drawAllPoints = (properties: any) => {

        const featureCoord = new Point(properties.coordinates);
        featureCoord.transform('EPSG:4326', 'EPSG:3857');

        const feature = new Feature({
            geom: featureCoord,
        });

        featuresArray.push(feature);
        feature.setGeometryName('geom');
        feature.setId(properties.ogc_fid);
        feature.setProperties({
            kaza_siddet: properties.kaza_siddet,
            ogc_fid: properties.ogc_fid,
            olu_say: properties.olu_say,
            yarali_say: properties.yarali_say
        })
        vector.addFeature(feature);
    }

    return <></>;
};
