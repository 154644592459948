import './Login.scss';
import { InputText } from 'primereact/inputtext';
import { useDispatch } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { useAppSelector } from '../state/hooks';
import { AxiosError } from 'axios';
import { Button } from 'primereact/button';
import { Password } from 'primereact/password';
import { LoginRestApi } from '../../utils/restapi/login';
import { setUserDashboards } from '../state/features/dashboardSlice';
import { DashboardRestApi } from '../../utils/restapi/dashboard';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { setJsonToken, setToken } from '../state/features/loginSlice';
import { Toast } from "primereact/toast";
import Home from '../home/Home';

export const Login = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const toast = useRef<any>();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [captcha, setCaptcha] = useState<any>("");
    const [hideMessage, sethideMessage] = useState<any>(false);
    // const [proceed, setProceed] = useState<any>(false);
    const [captchaImage, setCaptchaImage] = useState("");
    // const [disableButton, setDisableButton] = useState<boolean>(true);
    const token = useAppSelector((state) => state.login.token)

    useEffect(() => {

        if (token && isAuthenticated()) {
            navigate("/")
        } else {
            createImage();
            dispatch(setToken(null))
            navigate("/login")
        }
    }, [token])

    const setUsernameInput = (e: any) => {
        setUsername(e)
    }

    const setPasswordInput = (e: any) => {
        setPassword(e)
    }

    function isAuthenticated() {
        if (localStorage.getItem('ag_token')) {
            const { exp } = parseJwt(localStorage.getItem('ag_token'));
            if (Date.now() >= exp * 1000) {
                return false;
            } else {
                return true
            }
        } else {
            return false
        }
    }

    function parseJwt(token: any) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    };

    const handleSubmit = (e: any) => {

        e.preventDefault();
        // onConfirm(e)
        // handleKeyDown(e)

        if (captchaImage === captcha) {

            LoginRestApi.login(username, password).then((res: any) => {
                if (res.data) {
                    localStorage.setItem('ag_token', res.data.ag_token)
                    navigate("/");
                    let parsedToken = parseJwt(res.data.ag_token);
                    dispatch(setToken(res.data.ag_token))
                    dispatch(setJsonToken(parsedToken))
                    DashboardRestApi.getDashboards().then((res: any) => {
                        if (res) {
                            dispatch(setUserDashboards(res.data.result))
                        }
                        else {
                            toast.current?.show({ severity: 'error', detail: "Dashboard'dan veri getirilemiyor", life: 3000 });
                        }
                    })
                }
            }).catch((err: AxiosError) => {

                createImage();
                setCaptcha("")

                if (err.response?.status === 401) {
                    toast.current?.show({ severity: 'error', detail: "Hatalı Şifre ya da Kullanıcı Adı", life: 3000 });
                } else if (err.response?.status === 400) {
                    toast.current?.show({ severity: 'error', detail: "Hatalı İstek", life: 3000 });
                }
                else if (err.response?.status === 404) {
                    toast.current?.show({ severity: 'error', detail: "Sayfa bulunamadı", life: 3000 });
                }
                else if (err.response?.status === 403) {
                    toast.current?.show({ severity: 'error', detail: "Erişim engellendi", life: 3000 });
                }
                else if (err.response?.status === 500) {
                    toast.current?.show({ severity: 'error', detail: "Dahili Sunucu Hatası", life: 3000 });
                }
                else {
                    if (err.message === "Network Error") {
                        toast.current?.show({ severity: 'error', detail: "Ağ Hatası", life: 3000 });
                    }
                    else {
                        toast.current?.show({ severity: 'error', detail: "Hatalı Şifre ya da Kullanıcı Adı", life: 3000 });
                    }
                }
            })
        }
        else if (captchaImage !== captcha && hideMessage === true) {
            sethideMessage(false)
            createImage()
            setCaptcha("")
        }
        else {
            toast.current?.show({ severity: 'error', detail: "Güvenlik Parolası Eşleşmiyor", life: 3000 });
            createImage()
            setCaptcha("")
        }
    }


    function generateRandomString() {
        const length = 6;
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        setCaptchaImage(result)
        return result;
    }

    function createImage() {
        // String değerimiz
        // setProceed(false)
        setCaptcha("")
        const myString = generateRandomString();

        // Yükseklik ve genişlik belirtilerek bir HTML5 Canvas yaratılıyor
        const canvas = document.createElement('canvas');
        canvas.width = 185;
        canvas.height = 50;

        // Canvas konteksti alınıyor ve imaj ayarları belirleniyor
        const ctx: any = canvas.getContext('2d');

        ctx.fillStyle = "#cae6fc";
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        ctx.fillStyle = "#000000";
        ctx.font = "22px Arial";
        ctx.transform(1, -0.1, 0.3, 0.9, 0, 0);

        ctx.textAlign = "center";
        const letters = myString.split("");
        const metrics = letters.map((letter) => ctx.measureText(letter));

        const letterSpacing = Math.floor(Math.random() * 7) + 1; // Harf aralığı
        const totalSpace = metrics.reduce((acc, metric) => acc + metric.width, 0) + letterSpacing * (letters.length - 1);
        const startPos = (canvas.width - totalSpace) / 2;

        let x = startPos;
        const y = 40;
        letters.forEach((letter, i) => {
            ctx.fillText(letter, x, y);
            ctx.strokeText(letter, x, y);
            x += metrics[i].width + letterSpacing; // Harf mesafesi
        });

        for (let i = 0; i < 5; i++) {
            // Rastgele bir çizgi çizin
            ctx.beginPath();
            const randomColor = '#' + Math.floor(Math.random() * 16777215).toString(16);
            ctx.strokeStyle = randomColor;
            const lineWidth = 1.5;
            ctx.lineWidth = lineWidth;
            const startX = Math.random() * canvas.width;
            const startY = Math.random() * canvas.height;
            ctx.moveTo(startX, startY);
            let direction = Math.random() * Math.PI * 2;
            const distance = Math.random() * 100;
            const endX = startX + Math.cos(direction) * distance;
            const endY = startY + Math.sin(direction) * distance;
            ctx.lineTo(endX, endY);
            ctx.stroke();
        }

        // Canvas verileri dataURL formatında alınıyor
        const dataURL = canvas.toDataURL("image/png");

        // Eğer img elementi henüz yaratılmamışsa, yaratılır ve canvas elementi üzerine yerleştirilir
        if (!document.getElementById('password-image')) {

            const img: any = document.createElement('img');
            img.id = 'password-image';
            img.src = dataURL;
            img.addEventListener("click", function () {
                createImage();
            });
            const passwordArea: any = document.querySelector('.password-area');
            if (passwordArea) {

                passwordArea.innerHTML = '';
                passwordArea.appendChild(img);
            }
        }
        // Eğer img elementi zaten varsa, src özelliği değiştirilerek güncellenir
        else {
            const img: any = document.getElementById('password-image');
            img.src = dataURL;
        }
    }


    document.addEventListener('DOMContentLoaded', function () {
        createImage();
        // setProceed(false)
    });


    // const onConfirm = (event: any) => {
    //     event.preventDefault();

    //     if (captchaImage === captcha) {
    //         setDisableButton(false)
    //         setProceed(true)
    //     }
    //     else {
    //         toast.current?.show({ severity: 'error', detail: "Güvenlik Parolası Eşleşmiyor", life: 3000 });
    //         setDisableButton(true)
    //         createImage()
    //     }
    // };

    const onInputChange = async (e: any) => {
        setCaptcha(e.target.value)
    }

    function handleKeyDown(e: any) {
        if (e.key === "Enter") {
            e.preventDefault();
            // onConfirm(e)
            handleSubmit(e)
        }
    }


    const loginContainer =
        <>
            <form onSubmit={handleSubmit}>
                <div className='content'>
                    <div className='header'>
                        <img src="icons/login.svg" alt='logo' />
                        {/* <h2>Road Safety Login</h2> */}
                    </div>
                    <div className='login-container'>
                        <div className='input-group'>
                            <div className='p-field'>
                                {/* <label className='p-d-block'>Kullanıcı Adı</label> */}
                                <div className="field-input">
                                    <InputText className="login-input" placeholder="Kullanıcı Adı" value={username} onChange={(e) => setUsernameInput(e.target.value)} />
                                </div>
                                {/* <small id='username2-help' className='p-error p-d-block'>Username is not available.</small> */}
                            </div>
                            <div className='p-field password-section'>
                                {/* <label className='p-d-block'>Şifre</label> */}
                                <div className="field-input">
                                    <Password className="login-input" placeholder="Şifre" type='password' value={password} onChange={(e) => setPasswordInput(e.target.value)} toggleMask feedback={false} />
                                </div>
                                {/* <small id='username2-help' className='p-error p-d-block'>Username is not available.</small> */}
                            </div>

                            <div className="recaptcha">
                                <div className="recaptcha-form">
                                    <div className="renew-area">

                                        <div className="password-area">
                                        </div>
                                        <div className="renew">
                                            <Button onClick={() => { createImage(); sethideMessage(true); }}><i className="pi pi-replay default-button"></i></Button>
                                        </div>
                                    </div>
                                    <div className="recaptcha-container">
                                        <div className="confirmation-screen">

                                            <InputText
                                                className="password-box"
                                                value={captcha}
                                                onChange={(e: any) => { onInputChange(e) }}
                                                onKeyDown={handleKeyDown}
                                            />
                                            {/* <div className="check-button">
                                                <Button className={proceed ? "true-button default-button" : "default-button"} onClick={onConfirm} disabled={proceed ? true : false}><i className={proceed ? "pi pi-check" : "pi pi-arrow-left"}></i></Button>
                                            </div> */}
                                            <div className="security-info">

                                                <span>Güvenlik parolasını doğrulayınız</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='submit-button'>
                            <Button className='submit-login' type='submit'
                                // disabled={(disableButton === true) || (proceed === false) ? true : false} 
                                label='Giriş' />
                        </div>
                    </div>
                </div>
            </form>
            <Toast
                ref={toast}
                position="bottom-center"
                style={{ zIndex: "9 !important" }}
            ></Toast>
        </>

    return (
        <>
            {!token && <div className='Login'>
                <div>
                    {loginContainer}
                </div>
            </div>}
            {
                token && <Routes>
                    <Route path="/" element={<Home />} />
                </Routes>
            }

        </>
    )
}
