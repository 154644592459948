import { createSlice } from "@reduxjs/toolkit";
import { ZoomStateType } from "../../../utils/model";

const initialState: any = {
  value: ZoomStateType.DEFAULT,
  shapeState: false,
  cityState: false,
  zoomValue: 0,
};

export const zoomStateSlice = createSlice({
  name: "map-zoom",
  initialState,
  reducers: {
    setZoomState: (state: any, action) => {
      state.value = action.payload;
    },
    setShapeState: (state: any, action) => {
      state.shapeState = action.payload;
    },
    setCityState: (state: any, action) => {
      state.cityState = action.payload;
    },
    setZoomValue: (state: any, action) => {
      state.zoomValue = action.payload;
    },
  },
});

export const { setZoomState, setShapeState, setCityState, setZoomValue } =
  zoomStateSlice.actions;

export default zoomStateSlice.reducer;
