import { useAppSelector } from "../../state/hooks";
import VectorSource from 'ol/source/Vector';
import { Heatmap as HeatmapLayer } from 'ol/layer';
import { GeoJSON } from 'ol/format';
import Point from 'ol/geom/Point';
import { useContext, useEffect } from "react";
import { Feature } from "ol";
import { MapContext } from "../MapContext";

export const HeatMap = () => {
    const map = useContext(MapContext)
    const searchResults = useAppSelector(state => state.elastic.value);
    const legendIndex = useAppSelector(state => state.elastic.legendIndex);

    useEffect(() => {
        if (legendIndex === 1) {
            const featureArr: any = []

            const result = searchResults.map(({ ogc_fid, geom, kaza_siddet }: any) => ({ ogc_fid, geom, kaza_siddet }))

            for (const obj of result) {
                const featureCoord = new Point(obj.geom);
                featureCoord.transform('EPSG:4326', 'EPSG:3857');
                const feature = new Feature(featureCoord);
                feature.setProperties({ ogc_fid: obj.ogc_fid, geom: obj.geom, kaza_siddet: obj.kaza_siddet })
                featureArr.push(feature)
            }

            var heatfeature = new VectorSource({
                features: featureArr,
                format: new GeoJSON(),
                wrapX: true,
            });

            var heatmapLayer = new HeatmapLayer({
                source: heatfeature,
                zIndex: 1000000000000,
                weight: function (feature) {
                    const name = feature.get('kaza_siddet');
                    const siddet: any = `${(name === 'YARALANMALI' && 0.5) || (name === 'ÖLÜMLÜ' && 1) || (name === 'MADDİ' && 0.3)}`;
                    return siddet;
                },
            });

            map?.addLayer(heatmapLayer);
            heatmapLayer.set('heatmap', true)

            return () => {
                if (heatmapLayer) {
                    map.removeLayer(heatmapLayer)
                    heatmapLayer.dispose();
                }
            }
        }
    }, [searchResults, legendIndex])

    return (
        <></>
    )
}
