import "./Home.scss";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Basemaps } from "../basemaps/Basemap";
import { useAppSelector } from "../state/hooks";
import { Toolbar } from "../toolbar/Toolbar";
import { Map } from "../map/Map";
import { useNavigate } from "react-router-dom";
import { setJsonToken, setToken } from "../state/features/loginSlice";
import { WmsLayer } from "../map/wms-layer/WmsLayer";
import { DrawPolygon } from "../map/draw/DrawPolygon";
import { Table } from "../table/Table";
import { DrawStateType } from "../../utils/model";
import Import from "../import/Import";
import { TabMenu } from "primereact/tabmenu";
import { Legend } from "../legend/Legend";
import { Tile } from "../map/tile-layer/Tile";
import { HeatMap } from "../map/heatmap/HeatMap";
import { ActiveButtonStateRight } from "../shared/button-state/ButtonState";
import { Toast } from "primereact/toast";
import {
  setHeatMapStatus,
  setLegendIndex,
} from "../state/features/elasticSlice";
import { InfoPanel } from "../info/InfoPanel";
import { addLocale, locale } from "primereact/api";
import Coordinate from "../map/coordinate/Coordinate";
import { Button } from "primereact/button";
import { setCoordinateContainer } from "../state/features/sharedSlice";
import { Image } from "primereact/image";
import { RoleRestApi } from "../../utils/restapi/role";
import { setUserFunctionPerm, setUserInfo } from "../state/features/userSlice";
import { DrawSelectedFeature } from "../map/selected-feature/DrawSelectedFeature";
import { WebGL } from "../map/webgl/WebGL";
import GetFeatureInfo from "../map/get-feature-info/GetFeatureInfo";
import { LayerRestApi } from "../../utils/restapi/layer";
import { setColumns } from "../state/features/infoSlice";
import ImageDownload from "../statistics/DownloadActions/ImageDownload/ImageDownload";
import PdfDownload from "../statistics/DownloadActions/PdfDownload/PdfDownload";

const { REACT_APP_TRAFIK_URL } = process.env;

export enum ServiceSelectState {
  ANY_CHECK = "",
  SOME_CHECK = "indeterminate",
  FULL_CHECK = "checked",
}
const legendTabItems = [
  { label: "Lejant" },
  { label: "Isı Haritası" }
];

const Home = () => {
  const toast = useRef<any>();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const centerX = -573583.4603;
  const centerY = 7176519.7116;
  const zoom = 6;
  const [activeLegendTabIndex, setActiveLegendTabIndex] = useState(-1);
  const basemaps = useAppSelector((state) => state.basemaps.value);
  const activeButtonType = useAppSelector(
    (state) => state.buttonState.activeButtonType
  );
  const token = useAppSelector((state) => state.login.token);
  const services = useAppSelector((state) => state.service.services);
  const externalServices = useAppSelector((state) => state.service.externalServices);
  const tableVisibility = useAppSelector((state) => state.table.visible);
  const drawState = useAppSelector((state) => state.drawState.value);
  const displayImport = useAppSelector((state) => state.import.showImport);
  const displayExportImage = useAppSelector((state) => state.statistic.showExportImage);
  const displayExportPdf = useAppSelector((state) => state.statistic.showExportPdf);
  const activeButtonTypeRight = useAppSelector(
    (state) => state.buttonState.activeButtonTypeRight
  );
  const isSidebarAccidentVisible = useAppSelector(
    (state) => state.elastic.isSidebarAccidentVisible
  );
  const layerForTable: any = useAppSelector(
    (state) => state.info.layerForTable
  );
  const isHeatMapActive = useAppSelector(
    (state) => state.elastic.isHeatMapActive
  );
  const coordContainerVisibility = useAppSelector(
    (state) => state.shared.coordContainerVisibility
  );
  const isKKNActiveButton = useAppSelector(
    (state) => state.buttonState.isKKNActiveButton
  );
  const selectedFeature = useAppSelector((state) => state.info.selectedFeature);
  const userInfo = useAppSelector((state) => state.user.userInfo);
  const tableColumns = useAppSelector((state) => state.info.columns);
  let dataColumns: any = [];

  useEffect(() => {
    if (!token || !isAuthenticated()) {
      navigate("login");
    }
  }, [token]);

  useEffect(() => {
    if (!isAuthenticated()) {
      navigate("login");
    } else {
      if (localStorage.getItem("ag_token")) {
        let parsedToken = parseJwt(localStorage.getItem("ag_token"));
        dispatch(setToken(localStorage.getItem("ag_token")));
        dispatch(setJsonToken(parsedToken));
        navigate("/");
      } else {
        navigate("login");
      }

      axios.interceptors.response.use(
        (successfulReq) => {
          return successfulReq;
        },
        (error) => {
          if (error.response.status === 401) {
            localStorage.removeItem("ag_token");
            dispatch(setToken(null));
            dispatch(setJsonToken(null));
            navigate("login");
          }
          return Promise.reject(error);
        }
      );
    }
  }, []);

  useEffect(() => {
    if (token && token !== null) {
      RoleRestApi.userMeDetail().then((res: any) => {
        dispatch(setUserInfo(res.data));
      });
    }
    else {
      dispatch(setUserInfo({}));
    }
  }, [token]);

  useEffect(() => {
    if (token && userInfo.group) {
      RoleRestApi.getFunctionsByGroupPerm(userInfo.group).then((res: any) => {
        dispatch(setUserFunctionPerm(res.data));
      });
    }


    // ExternalServiceRestApi.getAllExternalServices().then((res: any) => {
    //     setexternalService(res.data);
    //   });
  }, [token, userInfo]);

  function parseJwt(token: any) {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  }

  function isAuthenticated() {
    if (localStorage.getItem("ag_token")) {
      const { exp } = parseJwt(localStorage.getItem("ag_token"));
      if (Date.now() >= exp * 1000) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  useEffect(() => {
    if (token === null) {
      navigate("login");
    }
  }, [token]);

  useEffect(() => {
    document.addEventListener("contextmenu", rightClickHandler);
    return () => document.removeEventListener("contextmenu", rightClickHandler);
  }, [activeButtonType]);

  const rightClickHandler = (event: any) => {
    event.preventDefault();
  };

  useEffect(() => {
    addLocale("tr", {
      monthNames: [
        "Ocak",
        "Şubat",
        "Mart",
        "Nisan",
        "Mayıs",
        "Haziran",
        "Temmuz",
        "Ağustos",
        "Eylül",
        "Ekim",
        "Kasım",
        "Aralık",
      ],
      monthNamesShort: [
        "Ocak",
        "Şubat",
        "Mart",
        "Nisan",
        "Mayıs",
        "Haziran",
        "Temmuz",
        "Ağustos",
        "Eylül",
        "Ekim",
        "Kasım",
        "Aralık",
      ],
      dayNamesMin: ["Pz", "P.tesi", "Sal", "Çar", "Per", "Cum", "C.tesi"],
      emptyMessage: ["Sonuç Bulunamadı"],
    });
    locale("tr");
  }, []);

  useEffect(() => {
    if (activeLegendTabIndex === 0) {
      dispatch(setHeatMapStatus(false));
    }
    if (activeLegendTabIndex === 1) {
      dispatch(setHeatMapStatus(true));
    }
    if (activeLegendTabIndex === -1) {
      dispatch(setHeatMapStatus(false));
    }

    dispatch(setLegendIndex(activeLegendTabIndex));
  }, [activeLegendTabIndex, isHeatMapActive]);

  const onTabChangeHandler = (e: any) => {
    const index = e.index;
    setActiveLegendTabIndex(index);
    if (activeLegendTabIndex === index) {
      setActiveLegendTabIndex(-1);
    }
  };

  const openCoordinateContainer = () => {
    if (coordContainerVisibility) {
      dispatch(setCoordinateContainer(false));
    } else {
      dispatch(setCoordinateContainer(true));
    }
  };

  useEffect(() => {
    if (layerForTable.id) {
      getColumns();
    }
  }, [layerForTable]);

  const getColumns = () => {
    dispatch(setColumns([]));
    LayerRestApi.getLayerById(layerForTable.id).then((res) => {
      const selectedLayer = res.data.layer;
      if (selectedLayer && selectedLayer.fields) {
        selectedLayer.fields.map((obj: any) => {
          dataColumns.push({
            name: obj.field,
            alias: obj.alias,
            position: obj.position,
          });
        });
      }

      const filteredDataColumns = dataColumns.filter(
        (dc: any) =>
          dc.name !== "zoneid" &&
          dc.name !== "zonetype" &&
          dc.name !== "ogc_fid" &&
          dc.name !== "etltarih" &&
          dc.name !== "gid" &&
          dc.name !== "id" &&
          dc.name !== "bbox" &&
          dc.name !== "geometry" &&
          dc.name !== "xkoordinat" &&
          dc.name !== "ykoordinat" &&
          dc.name !== "geoyatyguzergahid" &&
          dc.name !== "wkb_geometry" &&
          dc.name !== "geodigerid" &&
          dc.name !== "il" &&
          dc.name !== "ilce" &&
          dc.name !== "arac_say" &&
          dc.name !== "koymahalleadi" &&
          dc.name !== "uavtil" &&
          dc.name !== "uavtmah" &&
          dc.name !== "uavtilce" &&
          dc.name !== "corr1" &&
          dc.name !== "corr1_val" &&
          dc.name !== "corr2" &&
          dc.name !== "corr2_val" &&
          dc.name !== "corr3" &&
          dc.name !== "corr3_val" &&
          dc.name !== "corr4" &&
          dc.name !== "corr4_val" &&
          dc.name !== "corr5" &&
          dc.name !== "corr5_val" &&
          dc.name !== "indeks" &&
          dc.name !== "geodigerid"
      );

      const alphabeticalDataColumns = filteredDataColumns.sort(function (
        a: any,
        b: any
      ) {
        return a.position - b.position;
      });
      dispatch(setColumns(alphabeticalDataColumns));
    });
  };

  return (
    <>
      <div className="App" id="App">
        <div className="toolbar">
          <Toolbar />
        </div>
        {selectedFeature && <InfoPanel />}
        {tableVisibility && tableColumns && tableColumns.length > 0 && (
          <Table />
        )}
        <div className="map" id="map">
          <Map centerX={centerX} centerY={centerY} zoom={zoom}>
            {basemaps.length > 0 &&
              basemaps.map(
                (basemap: any, index: number) =>
                  basemap.visible && (
                    <Tile
                      layername={basemap.layername}
                      key={index}
                      url={basemap.url}
                      visible={basemap.visible}
                    />
                  )
              )}
            {basemaps.length <= 0 && (
              <Tile
                layername="Google Street"
                key="https://{a-c}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                url="https://{a-c}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                visible={true}
              />
            )}
            <WebGL />
            <GetFeatureInfo />
            {selectedFeature && <DrawSelectedFeature />}
            {activeLegendTabIndex === 1 && isSidebarAccidentVisible && (
              <HeatMap />
            )}
            {drawState === DrawStateType.POLYGON && <DrawPolygon />}
            {services.map((service: any) =>
              service.layers.map(
                (layer: any) =>
                  layer.layer !== "usr1_ag_kaza" && (
                    <WmsLayer
                      key={layer.id}
                      opacity={layer.opacity}
                      serviceId={service.id}
                      zIndex={10000000000 - service.order}
                      tag={service.type}
                      url={
                        // backend'de kullanıcı ve grup adminin dış servis'ten okuma yetkisi olmadığı için geçici olarak revize yapıldı, backend'de yetki verildikten sonra dış servisten yanıt dolu olarak gelecektir.
                        (layer.layer_type !== "External" || externalServices.length <= 0)
                          ? REACT_APP_TRAFIK_URL + "geoserver/trafik/wms"
                          : externalServices.find(
                            (x: any) => x.id === layer.service_id
                          )?.url
                      }
                      layername={layer.visible ? layer.layer : ""}
                    //style={layer.style}
                    />
                  )
              )
            )}
            {displayImport && <Import />}
            {displayExportImage && <ImageDownload />}
            {displayExportPdf && <PdfDownload />}
            <Button
              onClick={openCoordinateContainer}
              className="coordinate-container-button"
              icon="pi pi-angle-double-left"
            />
            {coordContainerVisibility && <Coordinate />}
          </Map>
        </div>
        <div className="logo">
          <a href="https://csb.gov.tr/" target="_blank">
            <Image src="icons/favicon.svg" alt="edevlet" width="55px" />
          </a>
        </div>
        <div className="basemaps">
          <Basemaps />
        </div>
        {
          // isSidebarAccidentVisible &&
          activeButtonTypeRight !== ActiveButtonStateRight.STATISTIC &&
          activeButtonTypeRight !== ActiveButtonStateRight.DASHBOARD &&
          activeButtonTypeRight !== ActiveButtonStateRight.DATE_STATS && (
            <>
              <div className="legend-container">
                <TabMenu
                  className="legend"
                  model={legendTabItems}
                  activeIndex={activeLegendTabIndex}
                  onTabChange={onTabChangeHandler}
                />
              </div>
              <Legend index={activeLegendTabIndex} />
            </>
          )}

        {
          isKKNActiveButton === true &&

          <div className="kkn-icon">
            {/* <Image src="icons/kkn_icon.svg" className="kkn-icon-image" alt="kkn_icon" width="280px" /> */}
            <Image src="icons/kkn-kyyb-lejant.png" className="kkn-icon-image" alt="kkn_icon" width="240px" style={{ background: "#61A6C6", padding: "7px 7px 7px 8px", borderRadius: "6px", opacity: "0.85" }} />
          </div>
        }

        <Toast
          ref={toast}
          position="bottom-center"
          style={{ zIndex: "9 !important" }}
        ></Toast>
      </div>
    </>
  );
};

export default Home;
