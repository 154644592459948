import './Import.scss';
import { Button } from 'primereact/button';
import { useRef, useState } from 'react';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { FileUpload } from 'primereact/fileupload';
import { InputText } from 'primereact/inputtext';
import { useAppDispatch, useAppSelector } from '../state/hooks';
import {
  setImportCompleted,
  setImportedEpsg,
  setImportedFile,
  setImportLayerGroup,
  setImportName,
  setShowImport,
} from '../state/features/importSlice';
import { RadioButton } from 'primereact/radiobutton';

export default function Import() {
  const dispatch = useAppDispatch();
  const toast = useRef<any>();
  const displayImport = useAppSelector((state) => state.import.showImport);
  const [files, setFiles] = useState<any>([]);
  const [fileChooseDisabled, setFileChooseDisabled] = useState<boolean>(false);
  const [error, setError] = useState<number>(0);
  const [importEpsg, setImportEpsg] = useState<string>('EPSG:4326');
  const services = useAppSelector(state => state.service.services)
  const chooseOptions = {
    icon: '',
    label: 'Dosya Seç',
    className: fileChooseDisabled ? 'fileChooseDisabled' : 'custom-choose-btn p-button-rounded p-button-outlined'
  };
  const uploadOptions = {
    label: 'Uplaod',
    icon: 'pi pi-upload',
    className: 'p-button-success',
  };
  const cancelOptions = {
    label: 'Cancel',
    icon: 'pi pi-times',
    className: 'p-button-danger',
  };
  const [group_alias, setGroup_alias] = useState<string>('');
  const onHide = async () => {
    dispatch(setShowImport(false));
  };
  const onClickImport = async () => {
    let conflict=0;
    const name = files.length === 3
        ? files.find((el: any) => el.name.includes('.shp')).name
        : files[0].name;

    services.forEach((service:any) => {
        if(service.alias === group_alias){
          conflict=1;
        }
        service.layers.forEach((layer:any) => {
            if(layer.layer_type === 'offline'){
                if(layer.alias === name){
                    conflict=2;
                }
            }
        });
    });
    if(!conflict){
        dispatch(setImportCompleted(false));
        const name =
        files.length === 3
            ? files.find((el: any) => el.name.includes('.shp')).name
            : files[0].name;
        const offlineLayerGroup = {
        key: group_alias,
        label: group_alias,
        children: [
            {
            key: name,//files[0].name
            label: name, //files[0].name
            data: files[0],
            id: Math.floor(Math.random() * 100),
            },
        ],
        };
        dispatch(setImportName(group_alias));
        dispatch(setImportLayerGroup(offlineLayerGroup));
        dispatch(setImportedEpsg(importEpsg));
        dispatch(setImportedFile(files));
        onHide();
    }else{
        setError(conflict)
    }
  };

  const handleSelectFile = (e: any) => {
    // if(!fileChooseDisabled){
        const files2 = [...e.files]
        setFiles(files2)
        setFileChooseDisabled(true);
    // }
  };

  return (
    <div className='Import'>
      <Toast ref={toast}></Toast>
      <Dialog
        className='import-dialog'
        header='İçe Aktar'
        visible={true}
        onHide={() => dispatch(setShowImport(!displayImport))}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <div className='pair'>
            <fieldset className='fieldset'>
              <legend className='data-type-header'>
                Katman Grup Adı Giriniz
              </legend>
              <div className='p-field'>
                <label className='p-d-block'></label>
                <InputText
                  style={{ border: 'none', width: '100%' }}
                  autoComplete='off'
                  required
                  value={group_alias}
                  name='first_name'
                  onChange={(e) => setGroup_alias(e.target.value)}
                />
              </div>
            </fieldset>
            <div className='content'>
              <fieldset className='fieldset'>
                <legend className='data-type-header'>Koordinat Sistemi</legend>
                <div className='radio-button-group'>
                  <div style={{ cursor: 'pointer' }}>
                    <RadioButton
                      inputId='4326'
                      value='EPSG:4326'
                      onChange={(e: any) => {
                        setImportEpsg(e.value);
                      }}
                      checked={importEpsg === 'EPSG:4326'}
                    />
                    <label style={{ cursor: 'pointer' }} htmlFor='4326'>
                      EPSG:4326
                    </label>
                  </div>
                  <div>
                    <RadioButton
                      inputId='3857'
                      value='EPSG:3857'
                      onChange={(e: any) => {
                        setImportEpsg(e.value);
                      }}
                      checked={importEpsg === 'EPSG:3857'}
                      style={{ cursor: 'pointer' }}
                    />
                    <label style={{ cursor: 'pointer' }} htmlFor='3857'>
                      EPSG:3857
                    </label>
                  </div>
                </div>
              </fieldset>
            </div>
          </div>
          <br></br>
          <div
            style={{
              marginBottom: '20px',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <FileUpload
              multiple
              onSelect={handleSelectFile}
              accept='.json,.geojson,.kml,.gml,.shp,.dbf,.shx'
              chooseOptions={chooseOptions}
              uploadOptions={uploadOptions}
              cancelOptions={cancelOptions}
              onRemove={() => {
                setError(0)
                setFileChooseDisabled(false);
                setFiles([]);
              }}
              // maxFileSize={1000000}
              emptyTemplate={
                <p className='m-0'>Geojson, Kml, ShapeFile, Gml</p>
              }
            />
          </div>
          <Button
            disabled={group_alias === '' || !files.length}
            onClick={() => onClickImport()}
            label='İçe Aktar'
          ></Button>
          <br></br>
          <span
          style={{ display:error===2?'block':'none', color:'red' }}
          >
           Aynı isimde katman var. Lütfen farklı isimde dosya seçiniz
          </span>

          <span
          style={{ display:error===1?'block':'none', color:'red' }}
          >
            Aynı isimde grup var. Lütfen farklı isimde grup adı seçiniz
          </span>
        </div>
      </Dialog>
    </div>
  );
}
