import { createSlice } from '@reduxjs/toolkit';
interface IInitialState {
    services: any,
    features: any,
    externalServices:any
}

const initialState: IInitialState = {
    services: [],
    features: [],
    externalServices: []
}

// const getToken = () => {
//     return localStorage.getItem('ag_token')
// }

export const serviceSlice = createSlice({
    name: 'service',
    initialState,
    reducers: {
        setServices: (state: IInitialState, action) => {
            state.services = action.payload;
            // if (!getToken()) {
            //     const visibleService = state.services.find((layer: any) => { return layer.name === 'tasinmazlar' })
            //     if (visibleService) {
            //         const visibleLayers = visibleService.layers.filter((obj: any) => { return obj.name === 'yapilar_usr' || obj.name === 'aof_alan_usr' })
            //         for (const layer of visibleLayers) {
            //             layer.visible = true;
            //         }
            //     }
            // }
        },
        setFeatures: (state: IInitialState, action) => {
            state.features = action.payload
        },
        setExternal: (state: IInitialState, action) => {
            state.externalServices = action.payload
        },
    }
})

export const { setServices, setFeatures, setExternal } = serviceSlice.actions

export default serviceSlice.reducer