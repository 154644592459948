import "./CreateKKN.scss";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { useEffect, useRef, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { RadioButton } from "primereact/radiobutton";
import {
  setCreateKKNOperation,
  setKKNIsSuccessfull,
} from "../state/features/userSlice";
import { useDispatch } from "react-redux";
import { Checkbox } from "primereact/checkbox";
import { KKNRestApi } from "../../utils/restapi/kkn";
import { setKKNResult, setMinimizeKKNDialog } from "../state/features/kknSlice";
import { useAppSelector } from "../state/hooks";
import { LayerRestApi } from "../../utils/restapi/layer";
import { setProgressVisibility } from "../state/features/sharedSlice";
import { MultiSelect } from "primereact/multiselect";
import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";
import axios from "axios";
import { Calendar } from "primereact/calendar";
import { setLayerForGoToLayer } from "../state/features/sidebarSlice";
import { setGotoKKN, setSelectedKKNGroupFeature } from "../state/features/statisticSlice";
import { setisKKNActiveButton } from "../state/features/buttonStateSlice";

const yontemArr = [
  { id: 1, alias: "Kaza Sayısı Yöntemi" },
  { id: 2, alias: "Kaza Frekans Yöntemi" },
  { id: 3, alias: "Kaza Oran Yöntemi" },
  { id: 4, alias: "Kaza Ağırlık Yöntemi" },
  { id: 5, alias: "Kaza Kalite Oran Yöntemi" },
  { id: 6, alias: "Yapay Zeka İle Kaza Kara Noktası Belirleme" },
];
// const periyotArr = [
//   { id: 1, alias: "1 Yıl", value: "1" },
//   { id: 2, alias: "2 Yıl", value: "2" },
//   { id: 3, alias: "3 Yıl", value: "3" },
//   { id: 4, alias: "5 Yıl", value: "5" },
// ];
const kavsakArr = [
  { id: 1, alias: "10 m", value: "10" },
  { id: 2, alias: "30 m", value: "30" },
  { id: 3, alias: "50 m", value: "50" },
  { id: 4, alias: "100 m", value: "100" },
  { id: 5, alias: "150 m", value: "150" },
];
const karayoluArr = [
  { id: 1, alias: "100 m", value: "100" },
  { id: 2, alias: "200 m", value: "200" },
  { id: 3, alias: "300 m", value: "300" },
  { id: 4, alias: "500 m", value: "500" },
];
const kesimArr = [
  { id: 1, alias: "10 m", value: "10" },
  { id: 2, alias: "30 m", value: "30" },
  { id: 3, alias: "50 m", value: "50" },
  { id: 4, alias: "100 m", value: "100" },
  { id: 5, alias: "150 m", value: "150" },
  { id: 2, alias: "200 m", value: "200" },
  { id: 3, alias: "300 m", value: "300" },
  { id: 4, alias: "500 m", value: "500" },
];
const epsArr = [
  { id: 1, alias: "10", value: "10" },
  { id: 2, alias: "20", value: "20" },
  { id: 3, alias: "30", value: "30" },
  { id: 4, alias: "40", value: "40" },
  { id: 5, alias: "50", value: "50" },
  { id: 6, alias: "60", value: "60" },
  { id: 7, alias: "70", value: "70" },
  { id: 8, alias: "80", value: "80" },
  { id: 9, alias: "90", value: "90" },
  { id: 10, alias: "100", value: "100" },
];
const minpointArr = [
  { id: 1, alias: "1", value: "1" },
  { id: 2, alias: "2", value: "2" },
  { id: 3, alias: "3", value: "3" },
  { id: 4, alias: "4", value: "4" },
  { id: 5, alias: "5", value: "5" },
  { id: 6, alias: "6", value: "6" },
  { id: 7, alias: "7", value: "7" },
  { id: 8, alias: "8", value: "8" },
  { id: 9, alias: "9", value: "9" },
  { id: 10, alias: "10", value: "10" },
];
const kazaSayisiArr = [
  { id: 1, alias: "1", value: "1" },
  { id: 2, alias: "2", value: "2" },
  { id: 3, alias: "3", value: "3" },
  { id: 4, alias: "4", value: "4" },
  { id: 5, alias: "5", value: "5" },
  { id: 6, alias: "6", value: "6" },
  { id: 7, alias: "7", value: "7" },
  { id: 8, alias: "8", value: "8" },
  { id: 9, alias: "9", value: "9" },
  { id: 10, alias: "10", value: "10" },
  { id: 11, alias: "11", value: "11" },
  { id: 12, alias: "12", value: "12" },
  { id: 13, alias: "13", value: "13" },
  { id: 14, alias: "14", value: "14" },
  { id: 15, alias: "15", value: "15" },
  { id: 16, alias: "16", value: "16" },
  { id: 17, alias: "17", value: "17" },
  { id: 18, alias: "18", value: "18" },
  { id: 19, alias: "19", value: "19" },
  { id: 20, alias: "20", value: "20" },
];

export const CreateKKN = () => {
  const dispatch = useDispatch();
  const toast = useRef<any>();
  const dateNow = new Date()
  const token = useAppSelector((state) => state.login.value);
  const progressVisibility = useAppSelector((state) => state.shared.visible);
  const userInfo = useAppSelector((state) => state.user.userInfo);
  const isMinimize = useAppSelector((state) => state.kkn.isMinimize);
  const tokenJson = useAppSelector((state) => state.login.jsonToken);
  const [visible, setVisible] = useState(true);
  const [kknDone, setKKNDone] = useState(false);
  const [zones, setZones] = useState([]);
  const [town, setTown] = useState([]);
  const [file, setFile] = useState<any>();
  const [file2, setFile2] = useState<any>();
  let [kknPostObj, setkknPostObj] = useState<any>();
  const [radioButtonValue, setRadioButtonValue] = useState(null);
  const [createKKNObj, setCreateKKNObj] = useState<any>({
    ad: "",
    yontem: "",
    // periyot: "",
    start_date: "",
    end_date: "",
    // tarih:"",
    bolge: "",
    gid: "",
    bb_kaza_sayisi: "",
    //yapayzekaolmaksizin bolge butunu
    bb_kesim: "",
    //yapayzekaolmaksizin parametrik
    pi_kavsak: "",
    pi_karayol: "",
    pd_kavsak: "",
    pd_karayol: "",
    //yapay zeka ile bolge butunu
    bb_eps: "",
    bb_minp: "",
    //yapay zeka ile parametrik
    pi_eps_kavsak: "",
    pi_minp_kavsak: "",
    pi_eps_karayol: "",
    pi_minp_karayol: "",
    pd_eps_kavsak: "",
    pd_minp_kavsak: "",
    pd_eps_karayol: "",
    pd_minp_karayol: "",
    pi_city: "",
    pd_city: "",
    yapay_zeka_pi: false,
    yapay_zeka_pd: false,
    yapay_zeka_pi_kavsak: false,
    yapay_zeka_pd_kavsak: false,
    yapay_zeka_pi_karayol: false,
    yapay_zeka_pd_karayol: false,
  });

  const [dateObj, setDateObj] = useState<any>({
    startDate: "",
    endDate: ""
  })

  const cal = useRef(null);

  const [group, setGroup] = useState<any>({});



  useEffect(() => {

    if (Object.keys(dateObj).length > 1 && dateObj.endDate !== "") {

      const start = dateObj.startDate
      const end = dateObj.endDate

      if (Math.round((end - start) / (1000 * 60 * 60 * 24)) < 0) {
        toast.current.show({
          severity: "error",
          summary: "Başarısız",
          detail:
            "Bitiş tarihi başlangıç tarihinden önce olamaz!",
          life: 7000,
        });

        setDateObj({
          ...dateObj,
          endDate: ""
        })
      }
      else {
        const createKKNObj_ = { ...createKKNObj }
        createKKNObj_["end_date"] = end.toISOString()
        createKKNObj_["start_date"] = start.toISOString()
        setCreateKKNObj(createKKNObj_);
      }

    } else {
      const createKKNObj_ = { ...createKKNObj }
      createKKNObj_["end_date"] = ""
      createKKNObj_["start_date"] = ""
      setCreateKKNObj(createKKNObj_);
    }
  }, [dateObj])

  useEffect(() => {
  }, [createKKNObj]);
  useEffect(() => {
    getZone();
  }, [token]);

  useEffect(() => {
    if (createKKNObj.bolge) {
      KKNRestApi.getTownByZoneId(userInfo.group, createKKNObj.bolge.pid).then(
        (res) => {
          setTown(
            res.data
              .filter((s: any) => s.check)
              .sort((a: any, b: any) => a.cname.localeCompare(b.cname, "tr"))
          );
        }
      );
    }
  }, [createKKNObj.bolge]);

  const getZone = async () => {
    KKNRestApi.getZones().then((res) => {
      const filteredData = res.data.filter(
        (a: any) =>
          a.pname !== "Türkiye" && a.pname !== "Karayolu Genel Müdürlüğü"
      );
      setZones(
        filteredData.sort((a: any, b: any) =>
          a.pname.localeCompare(b.pname, "tr")
        )
      );
    });
  };

  const onInputChange = async (e: any) => {

    if (e) {
      if (e.target.type === "checkbox") {
        setCreateKKNObj({
          ...createKKNObj,
          [e.target.id]: e.checked,
        });
      }
      else if (e.target.name === "startDate") {

        setDateObj({
          ...dateObj,
          [e.target.name]: e.target.value,
        })

      }
      else if (e.target.name === "endDate") {

        setDateObj({
          ...dateObj,
          [e.target.name]: e.target.value,
        })
      }
      else {
        setCreateKKNObj({
          ...createKKNObj,
          [e.target.id]: e.target.value,
        });
      }
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    dispatch(setProgressVisibility(true));
    const ilceIds = createKKNObj.gid.map((el: any) => el.gid).toString();
    let postObj: any = {
      ad: createKKNObj.ad,
      yontem: createKKNObj.yontem.alias,
      start_date: createKKNObj.start_date,
      end_date: createKKNObj.end_date,
      bolge: ilceIds,
      created_by: tokenJson.id,
    };
    if (
      createKKNObj.yontem.alias !== "Yapay Zeka İle Kaza Kara Noktası Belirleme"
    ) {
      postObj = {
        ...postObj,
        bb_kaza_sayisi: createKKNObj.bb_kaza_sayisi,
      };
      if (radioButtonValue === "Bölge Bütünü") {
        postObj = {
          ...postObj,
          bb_kesim: createKKNObj.bb_kesim,
        };
      }
      if (radioButtonValue === "Parametrik") {
        if (createKKNObj.city) {
          if (
            createKKNObj.pi_kavsak !== "" ||
            createKKNObj.pi_kavsak.value !== ""
          ) {
            postObj = {
              ...postObj,
              pi_kavsak: createKKNObj.pi_kavsak,
            };
          }
          if (
            createKKNObj.pi_karayol !== "" ||
            createKKNObj.pi_karayol.value !== ""
          ) {
            postObj = {
              ...postObj,
              pi_karayol: createKKNObj.pi_karayol,
            };
          }
        }
        if (createKKNObj.upstate) {
          if (
            createKKNObj.pd_kavsak !== "" ||
            createKKNObj.pd_kavsak.value !== ""
          ) {
            postObj = {
              ...postObj,
              pd_kavsak: createKKNObj.pd_kavsak,
            };
          }
          if (
            createKKNObj.pd_karayol !== "" ||
            createKKNObj.pd_karayol.value !== ""
          ) {
            postObj = {
              ...postObj,
              pd_karayol: createKKNObj.pd_karayol,
            };
          }
        }
      }
    }

    if (
      createKKNObj.yontem.alias === "Yapay Zeka İle Kaza Kara Noktası Belirleme"
    ) {
      if (radioButtonValue === "Bölge Bütünü") {
        if (createKKNObj.bb_eps !== "") {
          postObj = {
            ...postObj,
            bb_eps: createKKNObj.bb_eps,
          };
        }
        if (createKKNObj.bb_minp !== "") {
          postObj = {
            ...postObj,
            bb_minp: createKKNObj.bb_minp,
          };
        }
      }
      if (radioButtonValue === "Parametrik") {
        if (createKKNObj.yapay_zeka_pi) {
          if (createKKNObj.yapay_zeka_pi_kavsak) {
            if (createKKNObj.pi_eps_kavsak !== "") {
              postObj = {
                ...postObj,
                pi_eps_kavsak: createKKNObj.pi_eps_kavsak,
              };
            }
            if (createKKNObj.pi_minp_kavsak !== "") {
              postObj = {
                ...postObj,
                pi_minp_kavsak: createKKNObj.pi_minp_kavsak,
              };
            }
          }
          if (createKKNObj.yapay_zeka_pi_karayol) {
            if (createKKNObj.pi_eps_karayol !== "") {
              postObj = {
                ...postObj,
                pi_eps_karayol: createKKNObj.pi_eps_karayol,
              };
            }
            if (createKKNObj.pi_minp_karayol !== "") {
              postObj = {
                ...postObj,
                pi_minp_karayol: createKKNObj.pi_minp_karayol,
              };
            }
          }
        }

        if (createKKNObj.yapay_zeka_pd) {
          if (createKKNObj.yapay_zeka_pd_kavsak) {
            if (createKKNObj.pd_eps_kavsak !== "") {
              postObj = {
                ...postObj,
                pd_eps_kavsak: createKKNObj.pd_eps_kavsak,
              };
            }
            if (createKKNObj.pd_minp_kavsak !== "") {
              postObj = {
                ...postObj,
                pd_minp_kavsak: createKKNObj.pd_minp_kavsak,
              };
            }
          }
          if (createKKNObj.yapay_zeka_pd_karayol) {
            if (createKKNObj.pd_eps_karayol !== "") {
              postObj = {
                ...postObj,
                pd_eps_karayol: createKKNObj.pd_eps_karayol,
              };
            }
            if (createKKNObj.pd_minp_karayol !== "") {
              postObj = {
                ...postObj,
                pd_minp_karayol: createKKNObj.pd_minp_karayol,
              };
            }
          }
        }
      }
    }
    setkknPostObj(postObj);

    //burada seçilen parametrelerle ile bir servise istek yapılıyor.
    let postObj2: any[] = [];
    await KKNRestApi.postKKN(postObj)
      .then((res) => {
        if (res && res.status === 201 && res.data[0].kkn.features !== null) {
          postObj2.push({
            kkn: res.data[0].kkn,
            kyyb: res.data[0].kyyb,
            corr_t: res.data[0].corr_t,
          });
          dispatch(setKKNResult(res.data));
        }
        if (res.data[0].kkn.features === null) {
          dispatch(setProgressVisibility(false));
          toast.current.show({
            severity: "error",
            summary: "Başarısız",
            detail:
              "Girdiğiniz parametreler ile KKN oluşturulamadı, parametrelerinizi kontrol ediniz.",
            life: 7000,
          });
        }
      })
      .catch((err: any) => {
        toast.current.show({
          severity: "error",
          summary: "Başarısız",
          detail: err.response.data.message = "Input Validation Error" ? "Eksik ya da hatalı veri girişi" : err.response.data.message,
          life: 7000,
        });
        dispatch(setProgressVisibility(false));
      });

    //burada servisten dönen verinin işlenmesi için tekrar istek yapılıyor.
    if (postObj2 && postObj2.length > 0) {
      return await axios
        .post(process.env.REACT_APP_TRAFIK_URL + "kkn/group", postObj2)
        .then((res) => {
          if (res.data) {
            setKKNDone(true);
            let blob: any = new Blob([JSON.stringify(res.data.kkn)], {
              type: "application/json",
            });
            let blob2: any = new Blob([JSON.stringify(res.data.kyyb)], {
              type: "application/json",
            });
            setFile(blob);
            setFile2(blob2);
          }
        })
        .catch((err: any) => {
          setKKNDone(false);
          onHide();
          toast.current.show({
            severity: "error",
            summary: "Başarısız",
            detail: err.response.data.message,
            life: 5000,
          });
        });
    }
  };

  useEffect(() => {
    if (kknDone) {
      addLayerGroupAndLayer();
    }
  }, [kknDone]);

  const addLayerGroupAndLayer = async () => {
    const date = `${new Date().toLocaleDateString("tr") + new Date().toLocaleTimeString("tr")
      }`;
    const dateFormat = date.replace(/[^a-zA-Z0-9 ]/g, "");

    let groupId: any;
    let groupName: any;
    const layerGroupBody = {
      alias: createKKNObj.ad,
      name: `${"group_kkn_" + dateFormat}`,
    };
    await LayerRestApi.addLayerGroup(layerGroupBody)
      .then((res) => {
        if (res && res.status === 201) {
          groupId = res.data.id;
          groupName = res.data.name;
        }
      })
      .catch((err: any) => {
        setKKNDone(false);
        onHide();
        toast.current.show({
          severity: "error",
          summary: "Başarısız",
          detail: err.response.data.message,
          life: 5000,
        });
      });

    setTimeout(async () => {
      const kknBody = {
        file: file,
        type: "MultiPoint",
        epsg: 4326,
        alias: "Kaza Kara Noktası",
        layer: `${"layer_kkn_" + dateFormat}`,
      };
      const kyybBody = {
        file: file2,
        type: "MultiPolygon",
        epsg: 4326,
        alias: "Kaza Yüksek Yoğunluk Bölgesi",
        layer: `${"layer_kyyb_" + dateFormat}`,
      };
      let layerIds: any[] = [];
      await LayerRestApi.addLayer(groupId, kyybBody)
        .then((res) => {
          if (res && res.status === 201) {
            layerIds.push(res.data.id);
          }
        })
        .catch((err: any) => {
          onHide();
          toast.current.show({
            severity: "error",
            summary: "Başarısız",
            detail: err.response.data.message,
            life: 5000,
          });
        });

      await LayerRestApi.addLayer(groupId, kknBody)
        .then(async (res) => {
          if (res && res.status === 201) {
            toast.current.show({
              severity: "success",
              summary: "Başarılı",
              detail:
                "KKN ve KYYB başarılı bir şekilde oluşturulmuştur. Katman menüsünden ulaşabilirsiniz.",
              life: 5000,
            });
            layerIds.push(res.data.id);
            await LayerRestApi.getLayerGroupById(groupId).then((res) => {
              if (res && res.status === 200) {
                dispatch(setisKKNActiveButton(true))
                setGroup(res.data)
              }
            })
            setTimeout(() => {
              dispatch(setKKNIsSuccessfull(true));
              dispatch(setProgressVisibility(false));
              dispatch(setCreateKKNOperation(false));
              setVisible(false);
            }, 2000);
          }
        })
        .catch((err: any) => {
          onHide();
          toast.current.show({
            severity: "error",
            summary: "Başarısız",
            detail: err.response.data.message,
            life: 5000,
          });
        });

      kknPostObj = {
        ...kknPostObj,
        status: groupName,
      };

      await KKNRestApi.postKKNParameters(kknPostObj)
        .then((res) => { })
        .catch((err: any) => { });

      let kknFieldResponse: any[] = [];
      let kyybFieldResponse: any[] = [];
      for (const ids of layerIds) {
        await LayerRestApi.getLayerById(ids)
          .then((res: any) => {
            if (res.data) {
              if (res.data.layer.alias === "Kaza Kara Noktası") {
                kknFieldResponse.push(res.data.layer.fields);
              }
              if (res.data.layer.alias === "Kaza Yüksek Yoğunluk Bölgesi") {
                kyybFieldResponse.push(res.data.layer.fields);
              }
            }
          })
          .catch((err: any) => { });
      }

      let kyybFieldAlias: string;
      for (const editField of kyybFieldResponse[0]) {
        switch (editField.alias) {
          case "kazasayisi":
            kyybFieldAlias = "Kaza Sayısı";
            break;
          case "maddi":
            kyybFieldAlias = "Maddi";
            break;
          case "siddet":
            kyybFieldAlias = "Şiddet";
            break;
          case "yaralanmali":
            kyybFieldAlias = "Yaralanmalı";
            break;
          case "olumlu":
            kyybFieldAlias = "Ölümlü";
            break;
          default:
            kyybFieldAlias = editField.alias;
            break;
        }

        await LayerRestApi.editFields(editField.layer_id, editField.id, {
          field: editField.field,
          alias: kyybFieldAlias,
          isdomain: editField.isdomain ? true : false,
          domain_table_id: editField.domain_table_id
            ? editField.domain_table_id
            : 0,
          position: editField.position ? editField.position : 0,
        });
      }

      let kknFieldAlias: string;
      for (const editField of kknFieldResponse[0]) {
        switch (editField.alias) {
          case "kazasayisi":
            kknFieldAlias = "Kaza Sayısı";
            break;
          case "maddi":
            kknFieldAlias = "Maddi";
            break;
          case "siddet":
            kknFieldAlias = "Şiddet";
            break;
          case "yaralanmali":
            kknFieldAlias = "Yaralanmalı";
            break;
          case "olumlu":
            kknFieldAlias = "Ölümlü";
            break;
          default:
            kknFieldAlias = editField.alias;
            break;
        }

        await LayerRestApi.editFields(editField.layer_id, editField.id, {
          field: editField.field,
          alias: kknFieldAlias,
          isdomain: editField.isdomain ? true : false,
          domain_table_id: editField.domain_table_id
            ? editField.domain_table_id
            : 0,
          position: editField.position ? editField.position : 0,
        });
      }
    }, 2000);
  };

  const onHide = () => {
    dispatch(setProgressVisibility(false));
    dispatch(setCreateKKNOperation(false));
    setVisible(false);
  };
  const header = (
    <>
      <span>Kaza Kara Noktası Modülü</span>
      {progressVisibility && (
        <Button
          className="minimize"
          icon="pi pi-window-minimize"
          onClick={() => {
            dispatch(setMinimizeKKNDialog(true));
          }}
        />
      )}
    </>
  );


  const goToLayer = () => {

    const layer = group.layers?.find((layer: any) =>
      layer.layer.includes("kkn")
    );
    dispatch(setLayerForGoToLayer(layer));
    // dispatch(setisKKNActiveButton(true))

  };

  useEffect(() => {
    if (group !== null) {
      dispatch(setSelectedKKNGroupFeature(group))
      dispatch(setGotoKKN(group))
      goToLayer()
    }
    else {
      dispatch(setSelectedKKNGroupFeature({}))
      dispatch(setGotoKKN({}))
      dispatch(setisKKNActiveButton(true))
    }

  }, [group])


  // const onInputChange = async (e: any) => {

  //   if (e) {

  //     if (e.target.name === "startDate") {

  //       setDateObj({
  //         ...dateObj,
  //         [e.target.name]: e.target.value,
  //       })

  //     }
  //     else if (e.target.name === "endDate") {

  //       setDateObj({
  //         ...dateObj,
  //         [e.target.name]: e.target.value,
  //       })
  //     }

  //   }
  // };

  return (
    <>
      <Dialog
        className="CreateKKN"
        style={{ visibility: !isMinimize ? "visible" : "hidden" }}
        visible={visible}
        header={header}
        onHide={onHide}
        modal={false}
        resizable={false}
      >
        {progressVisibility && (
          <ProgressSpinner
            className="progress-spinner"
            strokeWidth="8"
            fill="var(--surface-ground)"
            animationDuration=".5s"
          />
        )}
        <form onSubmit={handleSubmit} className="form">
          <div className="container">
            <div className="input-group">
              <div className="shadow-group">
                <div className="p-field">
                  <label className="p-d-block">Adı</label>
                  <InputText
                    value={createKKNObj.ad}
                    id="ad"
                    onChange={onInputChange}
                  />
                </div>
                <div className="p-field">
                  <label className="p-d-block">Yöntem</label>
                  <Dropdown
                    id="yontem"
                    filter
                    filterBy="alias"
                    optionLabel="alias"
                    className="add-layer-dropdown"
                    emptyFilterMessage="Uygun sonuç bulunamadı"
                    value={createKKNObj.yontem}
                    options={yontemArr}
                    onChange={onInputChange}
                  />
                </div>
                {/* <div className="p-field">
                  <label className="p-d-block">Periyot</label>
                  <Dropdown
                    id="periyot"
                    filter
                    filterBy="alias"
                    optionLabel="alias"
                    className="add-layer-dropdown"
                    value={createKKNObj.periyot}
                    options={periyotArr}
                    onChange={onInputChange}
                  />
                </div> */}
                <div className="p-field">
                  <label className="p-d-block">Başlangıç Tarihi</label>
                  <Calendar
                    className={`.p-calendar .p-inputtext ${dateObj.startDate !== "" ? 'active-calendar' : 'inactive-calendar'}`}
                    name="startDate"
                    maxDate={dateObj.endDate !== "" ? dateObj.endDate : dateNow}
                    value={dateObj.startDate}
                    onChange={onInputChange}
                    dateFormat="dd-mm-yy"
                    readOnlyInput
                    placeholder='Başlangıç Tarihi Seçiniz'
                    ref={cal}
                  >
                  </Calendar>
                </div>


                <div className="p-field">
                  <label className="p-d-block">Bitiş Tarihi</label>
                  <Calendar
                    className={`.p-calendar .p-inputtext ${dateObj.endDate !== "" ? 'active-calendar' : 'inactive-calendar'}`}
                    name="endDate"
                    maxDate={dateNow}
                    value={dateObj.endDate}
                    disabled={dateObj.startDate === ""}
                    onChange={onInputChange}
                    dateFormat="dd-mm-yy"
                    readOnlyInput
                    placeholder='Bitiş Tarihi Seçiniz'
                    ref={cal}
                  >
                  </Calendar>
                </div>





                <div className="p-field">
                  <label className="p-d-block">Bölge</label>
                  <Dropdown
                    id="bolge"
                    filter
                    filterBy="pname"
                    emptyFilterMessage="Uygun sonuç bulunamadı"
                    optionLabel="pname"
                    className="add-layer-dropdown"
                    value={createKKNObj.bolge}
                    options={zones}
                    onChange={onInputChange}
                  />
                </div>
                {createKKNObj.bolge && (
                  <div className="p-field">
                    <label className="p-d-block">İlçe</label>
                    <MultiSelect
                      id="gid"
                      value={createKKNObj.gid}
                      options={town}
                      onChange={onInputChange}
                      emptyFilterMessage="Uygun sonuç bulunamadı"
                      optionLabel="cname"
                      display="chip"
                      filter
                      filterBy="cname"
                    />
                  </div>
                )}
                {createKKNObj.yontem.alias ===
                  "Kaza Sayısı Yöntemi" && (
                    <div className="p-field">
                      <label className="p-d-block">Minimum Kaza Sayısı</label>
                      <Dropdown
                        id="bb_kaza_sayisi"
                        filter
                        filterBy="alias"
                        emptyFilterMessage="Uygun sonuç bulunamadı"
                        optionLabel="alias"
                        className="add-layer-dropdown"
                        value={createKKNObj.bb_kaza_sayisi}
                        options={kazaSayisiArr}
                        onChange={onInputChange}
                      />
                    </div>
                  )}
              </div>

              {((createKKNObj.yontem !== "" && createKKNObj.yontem.id === 6
                ? createKKNObj.ad !== "" &&
                //  createKKNObj.periyot !== "" &&
                // createKKNObj.start_date !== "" && createKKNObj.end_date !== "" &&
                createKKNObj.bolge !== "" &&
                createKKNObj.gid !== "" &&
                createKKNObj.gid.length !== 0
                : false) ||
                (createKKNObj.yontem !== "" && createKKNObj.yontem.id !== 6
                  ? createKKNObj.ad !== "" &&
                  //   createKKNObj.periyot !== "" &&
                  // createKKNObj.start_date !== "" && createKKNObj.end_date !== "" &&
                  createKKNObj.bolge !== "" &&
                  // createKKNObj.bb_kaza_sayisi !== "" &&
                  createKKNObj.gid !== "" &&
                  createKKNObj.gid.length !== 0
                  : false)) && (
                  <>
                    <div className="shadow-group">
                      <div
                        className="radio-button-group"
                        style={{ display: !isMinimize ? "flex" : "none" }}
                      >
                        <RadioButton
                          className="radio-button"
                          inputId="coverage"
                          name="city"
                          value="Bölge Bütünü"
                          onChange={(e) => setRadioButtonValue(e.value)}
                          checked={radioButtonValue === "Bölge Bütünü"}
                        />
                        <label className="p-d-block" htmlFor="coverage">Bölge Bütünü</label>
                      </div>
                      {radioButtonValue === "Bölge Bütünü" && (
                        <>
                          {/* YAPAY ZEKA HARIC */}
                          {createKKNObj.yontem.alias !==
                            "Yapay Zeka İle Kaza Kara Noktası Belirleme" && (
                              <div className="p-field">
                                <label className="p-d-block">Kesim</label>
                                <Dropdown
                                  id="bb_kesim"
                                  filter
                                  filterBy="alias"
                                  optionLabel="alias"
                                  emptyFilterMessage="Uygun sonuç bulunamadı"
                                  value={createKKNObj.bb_kesim}
                                  options={kesimArr}
                                  className="add-layer-dropdown"
                                  onChange={onInputChange}
                                />
                              </div>
                            )}
                          {/* YAPAY ZEKA ILE */}
                          {createKKNObj.yontem.alias ===
                            "Yapay Zeka İle Kaza Kara Noktası Belirleme" && (
                              <>
                                <div className="p-field">
                                  <label className="p-d-block">
                                    En Büyük Adım Yarıçapı
                                  </label>
                                  <Dropdown
                                    id="bb_eps"
                                    filter
                                    filterBy="alias"
                                    optionLabel="alias"
                                    emptyFilterMessage="Uygun sonuç bulunamadı"
                                    className="add-layer-dropdown"
                                    value={createKKNObj.bb_eps}
                                    options={epsArr}
                                    onChange={onInputChange}
                                  />
                                </div>
                                <div className="p-field">
                                  <label className="p-d-block">
                                    Kümeleme Alt Sınırı
                                  </label>
                                  <Dropdown
                                    id="bb_minp"
                                    filter
                                    filterBy="alias"
                                    emptyFilterMessage="Uygun sonuç bulunamadı"
                                    optionLabel="alias"
                                    className="add-layer-dropdown"
                                    value={createKKNObj.bb_minp}
                                    options={minpointArr}
                                    onChange={onInputChange}
                                    style={{ width: "210px" }}
                                  />
                                </div>
                              </>
                            )}
                        </>
                      )}
                    </div>
                    <div className="shadow-group">
                      <div
                        className="radio-button-group"
                        style={{ display: !isMinimize ? "flex" : "none" }}
                      >
                        <RadioButton
                          className="radio-button"
                          inputId="parametric"
                          name="city"
                          value="Parametrik"
                          onChange={(e) => setRadioButtonValue(e.value)}
                          checked={radioButtonValue === "Parametrik"}
                        />
                        <label className="p-d-block" htmlFor="parametric">Parametrik</label>
                      </div>

                      {/* yapa zeka yontemi secilmezken gelen  */}
                      {radioButtonValue === "Parametrik" &&
                        createKKNObj.yontem.alias !==
                        "Yapay Zeka İle Kaza Kara Noktası Belirleme" && (
                          <div>
                            {/* Şehir İçi */}
                            <div className="city-group">
                              <div className="p-field">
                                <div className="parametric-options">
                                  <span>Şehir İçi</span>
                                </div>
                                <Checkbox
                                  id="city"
                                  onChange={onInputChange}
                                  checked={createKKNObj.city}
                                />
                              </div>
                              {createKKNObj.city && (
                                <div>
                                  <div className="subtitle-groups">
                                    <div className="parametric-subtitle-options">
                                      <span className="label">Kavşak</span>
                                      <Dropdown
                                        id="pi_kavsak"
                                        filter
                                        filterBy="alias"
                                        optionLabel="alias"
                                        className="subtitle-selection"
                                        value={createKKNObj.pi_kavsak}
                                        options={kavsakArr}
                                        onChange={onInputChange}
                                      />
                                    </div>
                                    <div className="parametric-subtitle-options">
                                      <span className="label">Karayolu</span>
                                      <Dropdown
                                        id="pi_karayol"
                                        filter
                                        filterBy="alias"
                                        optionLabel="alias"
                                        className="subtitle-selection"
                                        value={createKKNObj.pi_karayol}
                                        options={karayoluArr}
                                        onChange={onInputChange}
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      {radioButtonValue === "Parametrik" &&
                        createKKNObj.yontem.alias !==
                        "Yapay Zeka İle Kaza Kara Noktası Belirleme" && (
                          <div>
                            {/* Şehir Dışı */}
                            <div className="upstate-group">
                              <div className="p-field">
                                <div className="parametric-options">
                                  <span>Şehir Dışı</span>
                                </div>
                                <Checkbox
                                  id="upstate"
                                  onChange={onInputChange}
                                  checked={createKKNObj.upstate}
                                />
                              </div>
                              {createKKNObj.upstate && (
                                <div>
                                  <div className="subtitle-groups">
                                    <div className="parametric-subtitle-options">
                                      <span className="label">Kavşak</span>
                                      <Dropdown
                                        id="pd_kavsak"
                                        filter
                                        filterBy="alias"
                                        optionLabel="alias"
                                        className="subtitle-selection"
                                        value={createKKNObj.pd_kavsak}
                                        options={kavsakArr}
                                        onChange={onInputChange}
                                      />
                                    </div>
                                    <div className="parametric-subtitle-options">
                                      <span className="label">Karayolu</span>
                                      <Dropdown
                                        id="pd_karayol"
                                        filter
                                        filterBy="alias"
                                        optionLabel="alias"
                                        className="subtitle-selection"
                                        value={createKKNObj.pd_karayol}
                                        options={karayoluArr}
                                        onChange={onInputChange}
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        )}

                      {/* yapay zeka yonetimi seciliyken  */}

                      {radioButtonValue === "Parametrik" &&
                        createKKNObj.yontem.alias ===
                        "Yapay Zeka İle Kaza Kara Noktası Belirleme" && (
                          <div>
                            {/* Şehir İçi */}
                            <div className="city-group">
                              <div className="p-field">
                                <div className="parametric-options">
                                  <span>Şehir İçi</span>
                                </div>
                                <Checkbox
                                  id="yapay_zeka_pi"
                                  onChange={onInputChange}
                                  checked={createKKNObj.yapay_zeka_pi}
                                />
                              </div>
                              {createKKNObj.yapay_zeka_pi && (
                                <div>
                                  <div className="subtitle-groups">
                                    <div className="parametric-subtitle-options">
                                      <span className="label">Kavşak</span>
                                      <Checkbox
                                        id="yapay_zeka_pi_kavsak"
                                        onChange={onInputChange}
                                        checked={
                                          createKKNObj.yapay_zeka_pi_kavsak
                                        }
                                      />
                                    </div>

                                    {createKKNObj.yapay_zeka_pi_kavsak && (
                                      <>
                                        <div className="p-field">
                                          <label className="p-d-block">
                                            En Büyük Adım Yarıçapı
                                          </label>
                                          <Dropdown
                                            id="pi_eps_kavsak"
                                            filter
                                            filterBy="alias"
                                            optionLabel="alias"
                                            emptyFilterMessage="Uygun sonuç bulunamadı"
                                            className="add-layer-dropdown"
                                            value={createKKNObj.pi_eps_kavsak}
                                            options={epsArr}
                                            onChange={onInputChange}
                                          />
                                        </div>
                                        <div className="p-field">
                                          <label className="p-d-block">
                                            Kümeleme Alt Sınırı
                                          </label>
                                          <Dropdown
                                            id="pi_minp_kavsak"
                                            filter
                                            filterBy="alias"
                                            optionLabel="alias"
                                            className="add-layer-dropdown"
                                            emptyFilterMessage="Uygun sonuç bulunamadı"
                                            value={createKKNObj.pi_minp_kavsak}
                                            options={minpointArr}
                                            onChange={onInputChange}
                                            style={{ width: "210px" }}
                                          />
                                        </div>
                                      </>
                                    )}

                                    <div className="parametric-subtitle-options">
                                      <span className="label">Karayolu</span>
                                      <Checkbox
                                        id="yapay_zeka_pi_karayol"
                                        onChange={onInputChange}
                                        checked={
                                          createKKNObj.yapay_zeka_pi_karayol
                                        }
                                      />
                                    </div>

                                    {createKKNObj.yapay_zeka_pi_karayol && (
                                      <>
                                        <div className="p-field">
                                          <label className="p-d-block">
                                            En Büyük Adım Yarıçapı
                                          </label>
                                          <Dropdown
                                            id="pi_eps_karayol"
                                            filter
                                            filterBy="alias"
                                            optionLabel="alias"
                                            emptyFilterMessage="Uygun sonuç bulunamadı"
                                            className="add-layer-dropdown"
                                            value={createKKNObj.pi_eps_karayol}
                                            options={epsArr}
                                            onChange={onInputChange}
                                          />
                                        </div>
                                        <div className="p-field">
                                          <label className="p-d-block">
                                            Kümeleme Alt Sınırı
                                          </label>
                                          <Dropdown
                                            id="pi_minp_karayol"
                                            filter
                                            filterBy="alias"
                                            optionLabel="alias"
                                            emptyFilterMessage="Uygun sonuç bulunamadı"
                                            className="add-layer-dropdown"
                                            value={createKKNObj.pi_minp_karayol}
                                            options={minpointArr}
                                            onChange={onInputChange}
                                            style={{ width: "210px" }}
                                          />
                                        </div>
                                      </>
                                    )}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        )}

                      {radioButtonValue === "Parametrik" &&
                        createKKNObj.yontem.alias ===
                        "Yapay Zeka İle Kaza Kara Noktası Belirleme" && (
                          <div>
                            {/* Şehir Dışı */}
                            <div className="city-group">
                              <div className="p-field">
                                <div className="parametric-options">
                                  <span>Şehir Dışı</span>
                                </div>
                                <Checkbox
                                  id="yapay_zeka_pd"
                                  onChange={onInputChange}
                                  checked={createKKNObj.yapay_zeka_pd}
                                />
                              </div>
                              {createKKNObj.yapay_zeka_pd && (
                                <div>
                                  <div className="subtitle-groups">
                                    <div className="parametric-subtitle-options">
                                      <span className="label">Kavşak</span>
                                      <Checkbox
                                        id="yapay_zeka_pd_kavsak"
                                        onChange={onInputChange}
                                        checked={
                                          createKKNObj.yapay_zeka_pd_kavsak
                                        }
                                      />
                                    </div>

                                    {createKKNObj.yapay_zeka_pd_kavsak && (
                                      <>
                                        <div className="p-field">
                                          <label className="p-d-block">
                                            En Büyük Adım Yarıçapı
                                          </label>
                                          <Dropdown
                                            id="pd_eps_kavsak"
                                            filter
                                            filterBy="alias"
                                            optionLabel="alias"
                                            emptyFilterMessage="Uygun sonuç bulunamadı"
                                            className="add-layer-dropdown"
                                            value={createKKNObj.pd_eps_kavsak}
                                            options={epsArr}
                                            onChange={onInputChange}
                                          />
                                        </div>
                                        <div className="p-field">
                                          <label className="p-d-block">
                                            Kümeleme Alt Sınırı
                                          </label>
                                          <Dropdown
                                            id="pd_minp_kavsak"
                                            filter
                                            filterBy="alias"
                                            optionLabel="alias"
                                            emptyFilterMessage="Uygun sonuç bulunamadı"
                                            className="add-layer-dropdown"
                                            value={createKKNObj.pd_minp_kavsak}
                                            options={minpointArr}
                                            onChange={onInputChange}
                                            style={{ width: "210px" }}
                                          />
                                        </div>
                                      </>
                                    )}

                                    <div className="parametric-subtitle-options">
                                      <span className="label">Karayolu</span>
                                      <Checkbox
                                        id="yapay_zeka_pd_karayol"
                                        onChange={onInputChange}
                                        checked={
                                          createKKNObj.yapay_zeka_pd_karayol
                                        }
                                      />
                                    </div>

                                    {createKKNObj.yapay_zeka_pd_karayol && (
                                      <>
                                        <div className="p-field">
                                          <label className="p-d-block">
                                            En Büyük Adım Yarıçapı
                                          </label>
                                          <Dropdown
                                            id="pd_eps_karayol"
                                            filter
                                            filterBy="alias"
                                            optionLabel="alias"
                                            emptyFilterMessage="Uygun sonuç bulunamadı"
                                            className="add-layer-dropdown"
                                            value={createKKNObj.pd_eps_karayol}
                                            options={epsArr}
                                            onChange={onInputChange}
                                          />
                                        </div>
                                        <div className="p-field">
                                          <label className="p-d-block">
                                            Kümeleme Alt Sınırı
                                          </label>
                                          <Dropdown
                                            id="pd_minp_karayol"
                                            filter
                                            filterBy="alias"
                                            optionLabel="alias"
                                            emptyFilterMessage="Uygun sonuç bulunamadı"
                                            className="add-layer-dropdown"
                                            value={createKKNObj.pd_minp_karayol}
                                            options={minpointArr}
                                            onChange={onInputChange}
                                            style={{ width: "210px" }}
                                          />
                                        </div>
                                      </>
                                    )}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                    </div>
                  </>
                )}
            </div>
          </div>
          <div className="confirm-buttons">
            <Button
              className="cancel"
              label="İptal"
              onClick={onHide}
              style={{ marginRight: "10px" }}
            />
            <Button
              className="save"
              type="submit"
              label="Çalıştır"
              style={{
                background: "#286688",
                color: "white",
                border: "0px solid white",
              }}
              disabled={
                progressVisibility ||
                (createKKNObj.yontem === "" ? true : false) ||
                (createKKNObj.yontem.id === 6
                  ? createKKNObj.ad === "" ||
                  // createKKNObj.periyot === "" ||
                  createKKNObj.start_date === "" || createKKNObj.end_date === "" ||
                  createKKNObj.bolge === "" ||
                  createKKNObj.gid === "" ||
                  createKKNObj.gid.length === 0
                  : false) ||
                (createKKNObj.yontem.id !== 6
                  ? createKKNObj.ad === "" ||
                  // createKKNObj.periyot === "" ||
                  createKKNObj.start_date === "" || createKKNObj.end_date === "" ||
                  createKKNObj.bolge === "" ||
                  // createKKNObj.bb_kaza_sayisi === "" ||
                  createKKNObj.gid === "" ||
                  createKKNObj.gid.length === 0
                  : false)
                ||
                (radioButtonValue === null)
                ||
                (createKKNObj.yontem === "" && createKKNObj.bb_kesim === "" && createKKNObj.upstate !== true && createKKNObj.city !== true)
                //  ||
                // ((createKKNObj.pi_kavsak==="" || createKKNObj.pi_karayol==="") && (createKKNObj.pd_kavsak==="" || createKKNObj.pd_karayol===""))
              }
            />
          </div>
        </form>
        <Toast
          ref={toast}
          position="bottom-center"
          style={{ zIndex: "9 !important" }}
        />
      </Dialog>
    </>
  );
};
