import './EditUserInfo.scss'
import { useEffect, useRef, useState } from 'react'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { Dialog } from 'primereact/dialog';
import { useDispatch } from 'react-redux'
import { setEditUser } from '../state/features/userSlice'
import { RoleRestApi } from '../../utils/restapi/role'
import { Toast } from 'primereact/toast';

const EditUserInfo = () => {
    const dispatch = useDispatch();
    const [visible, setVisible] = useState(true);
    const [userMe, setUserMe] = useState<any>({});
    const [user, setUser] = useState<any>({ first_name: "", last_name: "", email: "", phone: "", title: "", department: "" })
    const editUserObject = {
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email,
        phone: user.phone,
        department: user.department,
        title: user.title,
    };
    const toast = useRef<any>();

    useEffect(() => {
        RoleRestApi.userMeDetail().then((res: any) => {

            setUser((user: any) => ({
                ...user,
                first_name: res.data.first_name,
                last_name: res.data.last_name,
                email: res.data.email,
                phone: res.data.phone,
                department: res.data.department,
                title: res.data.title,
            }));
        })
    }, [visible]);

    const handleSubmit = async (e: any) => {

        e.preventDefault();

        await RoleRestApi.editUser(editUserObject).then((res: any) => {

            if (res.status === 200) {
                toast.current.show({ severity: 'success', summary: 'Başarılı', detail: "Kullanıcı Bilgileri Güncellenmiştir", life: 3000 });
            }

            setTimeout(() => {
                setVisible(false)
                dispatch(setEditUser(false))
            }, 1000);

        }).catch((err: any) => {

            toast.current.show({ severity: 'error', summary: 'Başarısız', detail: err.response.data.message = ["email must be an email"] ? "Email tipini kontrol ediniz" : err.response.data.message, life: 3000 });
        })

    }
    const onHide = () => {
        setVisible(false)
        dispatch(setEditUser(false))
    }
    const handleChange = (e: any) => {
        if (e.target.type === "checkbox") {
            setUser({ ...user, [e.target?.name]: e.checked })
        } else {
            setUser({ ...user, [e.target?.name]: e.target?.value })
        }
    }

    return (
        <div className='EditUser'>
            <Dialog visible={visible} header={"Kullanıcı Bilgilerini Güncelle"} onHide={onHide}>
                <form onSubmit={handleSubmit}>
                    <div className='container'>
                        <div className='input-group'>
                            <div className='p-field'>
                                <label className='p-d-block'>Adı</label>
                                <InputText value={user?.first_name || ""} name="first_name" onChange={(e) => handleChange(e)} />
                            </div>
                            <div className='p-field'>
                                <label className='p-d-block'>Soyadı</label>
                                <InputText value={user?.last_name || ""} name="last_name" onChange={(e) => handleChange(e)} />
                            </div>
                            <div className='p-field'>
                                <label className='p-d-block'>Email *</label>
                                <InputText value={user?.email || ""} name="email" onChange={(e) => handleChange(e)} />
                            </div>
                            <div className='p-field'>
                                <label className='p-d-block'>Telefon</label>
                                <InputText value={user?.phone || ""} name="phone" onChange={(e) => handleChange(e)} />
                            </div>
                            <div className='p-field'>
                                <label className='p-d-block'>Unvan</label>
                                <InputText value={user?.title || ""} name="title" onChange={(e) => handleChange(e)} />
                            </div>
                            <div className='p-field'>
                                <label className='p-d-block'>Bölüm</label>
                                <InputText value={user?.department || ""} name="department" onChange={(e) => handleChange(e)} />
                            </div>
                        </div>
                        <div className='confirm-buttons'>
                            <Button label='İptal' onClick={onHide} style={{ "marginRight": "10px" }} />
                            <Button type='submit' label='Kaydet' style={{ "background": "#286688", color: "white", "border": "0px solid white" }} />
                        </div>
                    </div>
                </form>
                <Toast
                    ref={toast}
                    position="bottom-center"
                    style={{ zIndex: "9 !important" }}
                />
            </Dialog>
        </div>
    )
}

export default EditUserInfo
