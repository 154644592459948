import { embedDashboard } from "@superset-ui/embedded-sdk";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { DashboardRestApi } from "../../utils/restapi/dashboard";
import {
    setDashboardUUid,
    // setUserDashboards,
} from "../state/features/dashboardSlice";
import { useAppSelector } from "../state/hooks";
import "./Dashboard.scss";
const { REACT_APP_SUPERSET_URL } = process.env;

const Dashboard = () => {
    const [openDashboard, setOpenDashboard] = useState<boolean>(false);
    const [data, setData] = useState<any>(null);
    const userInfo = useAppSelector((state) => state.user.userInfo);
    const uuid = useAppSelector((state) => state.dashboard.uuid);
    // const userDashboards = useAppSelector(
    //     (state) => state.dashboard.userDashboards
    // );
    // const activeButtonType = useAppSelector(
    //     (state) => state.buttonState.activeButtonType
    // );

    useEffect(() => {
        DashboardRestApi.getDashboards()
            .then((res: any) => {
                if (res) {
                    setData(res.data);
                }
            })
            .catch((err: AxiosError) => {
                //toast.current?.show({ severity: 'error', detail: 'Dashboard error', life: 3000 });
            });
    }, []);

    const dispatch = useDispatch();

    function fetchGuestTokenFromBackend(): Promise<string> {
        return new Promise<string>((resolve) => {
            const guestToken: any = localStorage.getItem("guest_token");
            resolve(guestToken);
        });
    }

    const toggleDashboard = () => {
        setOpenDashboard(!openDashboard);
    };

    const setGuestToken = (id: number) => {
        DashboardRestApi.getGuestTokenForDashboard(id, userInfo.group)
            .then((res: any) => {
                if (res.data.token) {
                    localStorage.setItem("guest_token", res.data.token);
                }
            })
            .catch((err: AxiosError) => {
                console.log(err);
            });
    };



    const getDashboardUuid = (id: number) => {
        DashboardRestApi.getDashboardUID(id)
            .then((res: any) => {
                dispatch(setDashboardUUid(res.data.result.uuid));
            })
            .catch((err: AxiosError) => {
                console.log(err);
            });
    };

    const selectDashboard = async (id: number) => {
        setTimeout(() => {
            setOpenDashboard(true);
            getDashboardUuid(id);
            setGuestToken(id);
        }, 1000);
    };

    useEffect(() => {
        setTimeout(() => {
            if (openDashboard && uuid) {
                embedDashboard({
                    id: uuid,
                    supersetDomain: `${REACT_APP_SUPERSET_URL}`,
                    mountPoint: document.getElementById("my-superset-container")!, // any html element that can contain an iframe
                    fetchGuestToken: () => fetchGuestTokenFromBackend(),
                    dashboardUiConfig: { hideTitle: true }, // dashboard UI config: hideTitle, hideTab, hideChartControls (optional)
                });
            }
        }, 1000);
    }, [openDashboard, uuid]);

    return (
        <>
            {openDashboard && (
                <div id="all-container">
                    <div className="superset-navbar">
                        <div className="close" onClick={toggleDashboard}>
                            <i className="pi pi-times"></i>
                        </div>
                    </div>
                    <div id="my-superset-container"></div>
                </div>
            )}
            {data && !openDashboard && (
                <div className="dashboard-list">
                    {data &&
                        data.map((dashboard: any, index: number) => {
                            if (dashboard) {
                                return <li key={index} className='list' onClick={() => selectDashboard(dashboard.dashboard.id)}>{dashboard.dashboard.dashboard_title} </li>
                            }
                            <div>{dashboard.dashboard.dashboard_title}</div>
                        })}
                </div>
            )}
        </>
    );
};

export default Dashboard;
