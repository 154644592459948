import "./DateBasedStats.scss";
import { useRef, useEffect, useState } from "react";
import type { EChartsOption } from "echarts";
import { useAppSelector } from "../state/hooks";
import * as echarts from "echarts";
import { UtilRestApi } from "../../utils/restapi/util";
import { Calendar } from "primereact/calendar";
import { get, transformExtent } from 'ol/proj';
import { ProgressSpinner } from "primereact/progressspinner";
import { useDispatch } from "react-redux";
// import { Dropdown } from "primereact/dropdown";
import { setDateRange } from "../state/features/elasticSlice";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { setShowExportImage, setShowExportPdf, setStatisticName } from "../state/features/statisticSlice";
interface Kaza {
  kazatarihi: string;
  kaza_siddet: number;
}

const DateBasedStats = () => {
  const dispatch = useDispatch();
  const toast = useRef<any>();
  const lineChart = useRef<HTMLDivElement>(null);
  const dateNow = new Date()
  const extent = useAppSelector((state) => state.maps.extent);
  const cal = useRef(null);
  const [dateObj, setDateObj] = useState<any>({
    startDate: "",
    endDate: ""
  })

  const [firstProgressSpinner, setFirstProgressSpinner] = useState<any>(false)
  const [secondProgressSpinner, setSecondProgressSpinner] = useState<any>(false)
  const [thirdProgressSpinner, setThirdProgressSpinner] = useState<any>(false)

  const [firstProgressChecker, setFirstProgressChecker] = useState<any>(false)
  const [secondProgressChecker, setSecondProgressChecker] = useState<any>(false)
  const [thirdProgressChecker, setThirdProgressChecker] = useState<any>(false)

  const [filteredResults, setFilteredResults] = useState<any>([])
  const [filteredResults2, setFilteredResults2] = useState<any>([])
  const [filteredResults3, setFilteredResults3] = useState<any>([])

  const [numberofDays, setNumberofDays] = useState<number>(0)

  // Tarih ve periyot verilerine bağlı istatistik tablosun için WFS istekleri burada yapılıyor.
  useEffect(() => {

    if (dateObj.startDate !== "" && dateObj.endDate !== "" && extent.length > 0) {

      let start: any = new Date(dateObj.startDate);
      let end: any = new Date(dateObj.endDate);


      if (Math.round((end - start) / (1000 * 60 * 60 * 24)) < 0) {
        toast.current.show({
          severity: "error",
          summary: "Başarısız",
          detail:
            "Bitiş tarihi başlangıç tarihinden önce olamaz!",
          life: 7000,
        });

        setDateObj({
          ...dateObj,
          endDate: ""
        })
      }
      else {

        const dateExtent = transformExtent(extent, get('EPSG:3857') as any, get('EPSG:4326') as any)

        setFirstProgressSpinner(true)
        setSecondProgressSpinner(true)
        setThirdProgressSpinner(true)

        UtilRestApi.geoserverWfsReq(
          `version=1.0.0&request=GetFeature&typeName=trafik%3Atarih_istatistik1_v2&maxFeatures=50&outputFormat=application%2Fjson&viewparams=tarih1:${dateObj.startDate?.toISOString().slice(0, -14)};tarih2:${dateObj.endDate?.toISOString().slice(0, -14)};minx:${dateExtent[0]};miny:${dateExtent[1]};maxx:${dateExtent[2]};maxy:${dateExtent[3]}`
        ).then((res) => {

          if (res.status === 201 && typeof res.data !== "string") {
            setFirstProgressSpinner(false)
            setFilteredResults(res.data.features)
            setFirstProgressChecker(true)
          }
        });

        UtilRestApi.geoserverWfsReq(
          `version=1.0.0&request=GetFeature&typeName=trafik%3Atarih_istatistik2_v2&outputFormat=application%2Fjson&viewparams=tarih1:${dateObj.startDate?.toISOString().slice(0, -14)};tarih2:${dateObj.endDate?.toISOString().slice(0, -14)};minx:${dateExtent[0]};miny:${dateExtent[1]};maxx:${dateExtent[2]};maxy:${dateExtent[3]}`
        ).then((res) => {
          if (res.status === 201 && typeof res.data !== "string") {
            setSecondProgressSpinner(false)
            setFilteredResults3(res.data)
            setSecondProgressChecker(true)
          }
        });

        UtilRestApi.geoserverWfsReq(
          `version=1.0.0&request=GetFeature&typeName=trafik%3Atarih_istatistik3_v2&maxFeatures=50&outputFormat=application%2Fjson&viewparams=tarih1:${dateObj.startDate?.toISOString().slice(0, -14)};tarih2:${dateObj.endDate?.toISOString().slice(0, -14)};minx:${dateExtent[0]};miny:${dateExtent[1]};maxx:${dateExtent[2]};maxy:${dateExtent[3]}`
        ).then((res) => {

          if (res.status === 201 && typeof res.data !== "string") {
            setThirdProgressSpinner(false)
            setFilteredResults2(res.data.features)
            setThirdProgressChecker(true)
          }
        });
      }

    }
  }, [extent, dateObj]);


  function datediff(first: any, second: any) {
    return Math.round((second - first) / (1000 * 60 * 60 * 24));
  }

  useEffect(() => {

    if (dateObj.startDate !== "" && dateObj.endDate !== "") {


      let firstDate = new Date(dateObj.startDate);
      let secondDate = new Date(dateObj.endDate);

      if (firstProgressChecker === true && secondProgressChecker === true && thirdProgressChecker === true) {

        const dateRange = [
          formatDate(firstDate.setDate(dateObj.startDate.getDate())),
          formatDate(secondDate.setDate(dateObj.endDate.getDate()))
        ]

        setNumberofDays(datediff(firstDate, secondDate))

        dispatch(setDateRange(dateRange))
      }
    }

  }, [firstProgressChecker, secondProgressChecker, thirdProgressChecker, dateObj, dispatch])


  function formatDate(date: any) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }


  function changeDateFormat(tarihler: any) {
    return tarihler.map(function (tarih: any) {
      var parcalar = tarih.split('-');
      return parcalar[2] + '/' + parcalar[1] + '/' + parcalar[0];
    });
  }
  useEffect(() => {

    let arr: any = []

    if (filteredResults3.features?.length > 0) {

      const accidents: Kaza[] = filteredResults3.features
      const counts = new Map<string, [number, number, number, number]>();


      accidents.forEach((kaza: any) => {
        const test = kaza.properties.kazatarihi.split('T')[0]
        const value = counts.get(test) ?? [0, 0, 0, 0];
        const index = kaza.properties.kaza_siddet === "YARALANMALI" ? 0 : kaza.properties.kaza_siddet === "ÖLÜMLÜ" ? 1 : kaza.properties.kaza_siddet === "MADDİ" ? 2 : 3;
        value[index]++;
        counts.set(test, value);
      });

      arr = Array.from(counts.entries()).map(([key, value]) => [key, ...value])

      let yearList = arr.map((x: any) => x[0])
      let injuryData = arr.map((x: any) => x[1])
      let deathData = arr.map((x: any) => x[2])
      let costlyData = arr.map((x: any) => x[3])
      // let emptyData = arr.map((x: any) => x[4])
      // let totalData = arr.map((x: any) => x[1] + x[2] + x[3] + x[4])

      let optionLineChart: EChartsOption
      var lineChartDom = document.getElementById("line-chart")!;
      var lineChart = echarts.init(lineChartDom);

      var newYearListFormat = changeDateFormat(yearList)

      optionLineChart = {
        title: {
          text: 'Kaza - Zaman Grafiği',
          left: "center",
          top: 12
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross'
          }
        },
        legend: {
          data: ['ÖLÜMLÜ', 'YARALANMALI', 'MADDİ', /*'TANIMSIZ'/*, 'TOPLAM'*/],
          top: 55,
          right: "center",
          width: "100%"
        },
        grid: {
          left: '8%',
          right: '6%',
          bottom: '20%',
          top: '25%'

        },
        xAxis: {
          type: 'category',
          data: newYearListFormat,
          boundaryGap: false,
          axisLine: { onZero: false },
          splitLine: { show: false },
          min: 'dataMin',
          max: 'dataMax'
        },
        yAxis: {
          scale: true,
          splitArea: {
            show: true
          },
          min: 'dataMin',
          max: 'dataMax'

        },
        dataZoom: [
          {
            type: 'inside',
            start: 0,
            end: 100,
          },
          {
            show: true,
            type: 'slider',
            top: '90%',
            start: 0,
            end: 100
          }
        ],
        series: [
          {
            name: 'YARALANMALI',
            type: 'line',
            data: injuryData,
            smooth: true,
            lineStyle: {
              opacity: 0.8,
              color: "#ff8c00"
            },
            itemStyle: {
              color: "#ff8c00"
            },
          },
          {
            name: 'ÖLÜMLÜ',
            type: 'line',
            data: deathData,
            smooth: true,
            lineStyle: {
              opacity: 0.8,
              color: "red"
            },
            itemStyle: {
              color: "red"
            }
          },
          {
            name: 'MADDİ',
            type: 'line',
            data: costlyData,
            smooth: true,
            lineStyle: {
              opacity: 0.8,
              color: "blue"
            },
            itemStyle: {
              color: "blue"
            }
          },
          // {
          //   name: 'TANIMSIZ',
          //   type: 'line',
          //   data: emptyData,
          //   smooth: true,
          //   lineStyle: {
          //     opacity: 0.8,
          //     color: "black"
          //   },
          //   itemStyle: {
          //     color: "black"
          //   }
          // },
          // {
          //   name: 'TOPLAM',
          //   type: 'line',
          //   data: totalData,
          //   smooth: true,
          //   lineStyle: {
          //     opacity: 0.5,
          //     color: "yellow"
          //   },
          //   itemStyle: {
          //     color: "yellow"
          //   }
          // }
        ]
      };

      lineChart.setOption(optionLineChart);

    }
  }, [filteredResults3])


  const onInputChange = async (e: any) => {

    if (e) {

      if (e.target.name === "startDate") {

        setDateObj({
          ...dateObj,
          [e.target.name]: e.target.value,
        })

      }
      else if (e.target.name === "endDate") {

        setDateObj({
          ...dateObj,
          [e.target.name]: e.target.value,
        })
      }

    }
  };

  const exportImage = () => {
    dispatch(setShowExportImage(true));
  }

  const exportPdf = () => {
    dispatch(setShowExportPdf(true));
  }

  useEffect(() => {
    dispatch(setStatisticName("date-based-stats"))

  }, [dateObj, dispatch])


  return (
    <div className="date-based-stats" id="date-based-stats">
      {(dateObj.startDate === "" || dateObj.endDate === "") && <div className="warning">
        <i className="pi pi-exclamation-triangle"></i>
        <p style={{ margin: "10px 0" }}>
          İstatistik Ekranında Gösterilecek Veri Bulunamadı.
        </p>
        <p>Lütfen Tarih Aralığı Girişi Sağlayınız.</p>
      </div>}
      <div className="date-based-data">
        <div className="first-row">
          <div className="box" style={{ width: "420px", display: "flex", alignItems: "center", justifyContent: "center" }}>
            <span className="desc" style={{ fontSize: "27px", fontWeight: "bold", color: "#1A4158" }}>
              Değişim Analizi
            </span>
          </div>
          <div className="box" style={{ width: "calc(100% - 375px)" }}>
            <Calendar
              name="startDate"
              style={{}}
              maxDate={dateNow}
              className={`.p-calendar .p-inputtext ${dateObj.startDate !== "" ? 'active-calendar' : 'inactive-calendar'}`}
              id='date'
              value={dateObj.startDate}
              onChange={onInputChange}
              dateFormat="dd-mm-yy"
              readOnlyInput
              placeholder='Başlangıç Tarihi Seçiniz'
              ref={cal}
            >
            </Calendar>
          </div>

          <div className="box" style={{ width: "calc(100% - 375px)" }}>
            <Calendar
              name="endDate"
              maxDate={dateNow}
              // minDate={dateObj.startDate}
              disabled={dateObj.startDate === ""}
              className={`.p-calendar .p-inputtext ${dateObj.endDate !== "" ? 'active-calendar' : 'inactive-calendar'}`}
              id='date'
              value={dateObj.endDate}
              onChange={onInputChange}
              dateFormat="dd-mm-yy"
              readOnlyInput
              placeholder='Bitiş Tarihi Seçiniz'
              ref={cal}
            >
            </Calendar>
          </div>
        </div>
        <div className="table-section">
          {(numberofDays < 0) && <div className="warning-stats">
            <i className="pi pi-exclamation-triangle"></i>
            <p style={{ margin: "10px 0" }}>
              Girdiğiniz kriterlere uygun tablo oluşturulamadı.
            </p>
            <p>Lütfen Tarih değerlerini kontrol ediniz.</p>
          </div>}
          <div className="row-structure">
            <div className="second-row">
              <div className="second-row-title">
                <span className="header">
                  {numberofDays} {' '}
                  Günlük Değişim Oranı
                </span>
              </div>
              <div className="second-row-body">

                {firstProgressSpinner && <ProgressSpinner
                  className="progress-spinner"
                  strokeWidth="8"
                  fill="var(--surface-ground)"
                  animationDuration=".5s"
                  style={{ top: "15%" }}
                />}

                <div className="box">
                  <div className="top-box">
                    <span className="header">Ölümlü Kaza</span>
                  </div>
                  <div className="bottom-box">

                    <div className="desc" style={filteredResults[0]?.properties.degisim_olu < 0 ? { color: "green" } : filteredResults[0]?.properties.degisim_olu > 0 ? { color: "#FF4242" } : { color: "orange" }}>

                      <span style={{ fontSize: "27px", marginTop: "4px" }}> {
                        filteredResults[0] ?
                          (`%` + (filteredResults[0]?.properties.degisim_olu < 0 ?
                            `${filteredResults[0]?.properties.degisim_olu}`.slice(1) :
                            `${filteredResults[0]?.properties.degisim_olu}`)
                          )
                          : "-"
                      }</span>
                      {filteredResults[0]?.properties.degisim_olu > 0 && <i className="pi pi-arrow-up"></i>}
                      {filteredResults[0]?.properties.degisim_olu < 0 && <i className="pi pi-arrow-down"></i>}
                      {filteredResults[0]?.properties.degisim_olu === 0 && <i className="pi pi-arrows-h"></i>}
                    </div>
                  </div>
                </div>
                <div className="box">
                  <div className="top-box">
                    <span className="header">Yaralanmalı Kaza</span>
                  </div>
                  <div className="bottom-box">
                    <div className="desc" style={filteredResults[0]?.properties.degisim_yarali < 0 ? { color: "green" } : filteredResults[0]?.properties.degisim_yarali > 0 ? { color: "#FF4242" } : { color: "orange" }}>
                      <span style={{ fontSize: "27px", marginTop: "4px" }}>

                        {
                          filteredResults[0] ?
                            (`%` + (filteredResults[0]?.properties.degisim_yarali < 0 ?
                              `${filteredResults[0]?.properties.degisim_yarali}`.slice(1) :
                              `${filteredResults[0]?.properties.degisim_yarali}`)
                            )
                            : "-"
                        }
                      </span>
                      {filteredResults[0]?.properties.degisim_yarali > 0 && <i className="pi pi-arrow-up"></i>}
                      {filteredResults[0]?.properties.degisim_yarali < 0 && <i className="pi pi-arrow-down"></i>}
                      {filteredResults[0]?.properties.degisim_yarali === 0 && <i className="pi pi-arrows-h"></i>}
                    </div>
                  </div>
                </div>
                <div className="box">
                  <div className="top-box">
                    <span className="header">Maddi Hasarlı Kaza</span>
                  </div>
                  <div className="bottom-box">
                    <div className="desc" style={filteredResults[0]?.properties.degisim_maddi < 0 ? { color: "green" } : filteredResults[0]?.properties.degisim_maddi > 0 ? { color: "#FF4242" } : { color: "orange" }}>
                      <span style={{ fontSize: "27px", marginTop: "4px" }}>
                        {
                          filteredResults[0] ?
                            (`%` + (filteredResults[0]?.properties.degisim_maddi < 0 ?
                              `${filteredResults[0]?.properties.degisim_maddi}`.slice(1) :
                              `${filteredResults[0]?.properties.degisim_maddi}`)
                            )
                            : "-"
                        }
                      </span>
                      {filteredResults[0]?.properties.degisim_maddi > 0 && <i className="pi pi-arrow-up"></i>}
                      {filteredResults[0]?.properties.degisim_maddi < 0 && <i className="pi pi-arrow-down"></i>}
                      {filteredResults[0]?.properties.degisim_maddi === 0 && <i className="pi pi-arrows-h"></i>}
                    </div>
                  </div>
                </div>
                <div className="box">
                  <div className="top-box">
                    <span className="header">Toplam Kaza</span>
                  </div>
                  <div className="bottom-box">
                    <div className="desc" style={filteredResults[0]?.properties.degisim_toplam < 0 ? { color: "green" } : filteredResults[0]?.properties.degisim_toplam > 0 ? { color: "#FF4242" } : { color: "orange" }}>
                      <span style={{ fontSize: "27px", marginTop: "4px" }}>
                        {
                          filteredResults[0] ?
                            (`%` + (filteredResults[0]?.properties.degisim_toplam < 0 ?
                              `${filteredResults[0]?.properties.degisim_toplam}`.slice(1) :
                              `${filteredResults[0]?.properties.degisim_toplam}`)
                            )
                            : "-"
                        }
                      </span>
                      {filteredResults[0]?.properties.degisim_toplam > 0 && <i className="pi pi-arrow-up"></i>}
                      {filteredResults[0]?.properties.degisim_toplam < 0 && <i className="pi pi-arrow-down"></i>}
                      {filteredResults[0]?.properties.degisim_toplam === 0 && <i className="pi pi-arrows-h"></i>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row-structure">
            <div className="second-row">
              <div className="second-row-title">
                <span className="header">
                  Geçen Yıla Göre {numberofDays} Günlük Değişim
                </span>
              </div>
              <div className="second-row-body">

                {thirdProgressSpinner && <ProgressSpinner
                  className="progress-spinner"
                  strokeWidth="8"
                  fill="var(--surface-ground)"
                  animationDuration=".5s"
                  style={{ top: "30%" }}
                />}

                <div className="box">
                  <div className="top-box">
                    <span className="header">Ölümlü Kaza</span>

                  </div>
                  <div className="bottom-box">
                    <div className="desc" style={filteredResults2[0]?.properties.degisim_olu < 0 ? { color: "green" } : filteredResults2[0]?.properties.degisim_olu > 0 ? { color: "#FF4242" } : { color: "orange" }}>
                      <span style={{ fontSize: "27px", marginTop: "4px" }}>
                        {
                          filteredResults2[0] ?
                            (`%` + (filteredResults2[0]?.properties.degisim_olu < 0 ?
                              `${filteredResults2[0]?.properties.degisim_olu}`.slice(1) :
                              `${filteredResults2[0]?.properties.degisim_olu}`)
                            )
                            : "-"
                        }
                      </span>
                      {filteredResults2[0]?.properties.degisim_olu > 0 && <i className="pi pi-arrow-up"></i>}
                      {filteredResults2[0]?.properties.degisim_olu < 0 && <i className="pi pi-arrow-down"></i>}
                      {filteredResults2[0]?.properties.degisim_olu === 0 && <i className="pi pi-arrows-h"></i>}
                    </div>
                  </div>
                </div>
                <div className="box">
                  <div className="top-box">
                    <span className="header">Yaralanmalı Kaza</span>
                  </div>
                  <div className="bottom-box">
                    <div className="desc" style={filteredResults2[0]?.properties.degisim_yarali < 0 ? { color: "green" } : filteredResults2[0]?.properties.degisim_yarali > 0 ? { color: "#FF4242" } : { color: "orange" }}>
                      <span style={{ fontSize: "27px", marginTop: "4px" }}>
                        {
                          filteredResults[0] ?
                            (`%` + (filteredResults2[0]?.properties.degisim_yarali < 0 ?
                              `${filteredResults2[0]?.properties.degisim_yarali}`.slice(1) :
                              `${filteredResults2[0]?.properties.degisim_yarali}`)
                            )
                            : "-"
                        }
                      </span>
                      {filteredResults2[0]?.properties.degisim_yarali > 0 && <i className="pi pi-arrow-up"></i>}
                      {filteredResults2[0]?.properties.degisim_yarali < 0 && <i className="pi pi-arrow-down"></i>}
                      {filteredResults2[0]?.properties.degisim_yarali === 0 && <i className="pi pi-arrows-h"></i>}
                    </div>
                  </div>
                </div>
                <div className="box">
                  <div className="top-box">
                    <span className="header">Maddi Hasarlı Kaza</span>
                  </div>
                  <div className="bottom-box">
                    <div className="desc" style={filteredResults2[0]?.properties.degisim_maddi < 0 ? { color: "green" } : filteredResults2[0]?.properties.degisim_maddi > 0 ? { color: "#FF4242" } : { color: "orange" }}>
                      <span style={{ fontSize: "27px", marginTop: "4px" }}>
                        {
                          filteredResults[0] ?
                            (`%` + (filteredResults2[0]?.properties.degisim_maddi < 0 ?
                              `${filteredResults2[0]?.properties.degisim_maddi}`.slice(1) :
                              `${filteredResults2[0]?.properties.degisim_maddi}`)
                            )
                            : "-"
                        }
                      </span>
                      {filteredResults2[0]?.properties.degisim_maddi > 0 && <i className="pi pi-arrow-up"></i>}
                      {filteredResults2[0]?.properties.degisim_maddi < 0 && <i className="pi pi-arrow-down"></i>}
                      {filteredResults2[0]?.properties.degisim_maddi === 0 && <i className="pi pi-arrows-h"></i>}
                    </div>
                  </div>
                </div>
                <div className="box">
                  <div className="top-box">
                    <span className="header">Toplam Kaza</span>
                  </div>
                  <div className="bottom-box">
                    <div className="desc" style={filteredResults2[0]?.properties.degisim_toplam < 0 ? { color: "green" } : filteredResults2[0]?.properties.degisim_toplam > 0 ? { color: "#FF4242" } : { color: "orange" }}>
                      <span style={{ fontSize: "27px", marginTop: "4px" }}>
                        {
                          filteredResults2[0] ?
                            (`%` + (filteredResults2[0]?.properties.degisim_toplam < 0 ?
                              `${filteredResults2[0]?.properties.degisim_toplam}`.slice(1) :
                              `${filteredResults2[0]?.properties.degisim_toplam}`)
                            )
                            : "-"
                        }
                      </span>
                      {filteredResults2[0]?.properties.degisim_toplam > 0 && <i className="pi pi-arrow-up"></i>}
                      {filteredResults2[0]?.properties.degisim_toplam < 0 && <i className="pi pi-arrow-down"></i>}
                      {filteredResults2[0]?.properties.degisim_toplam === 0 && <i className="pi pi-arrows-h"></i>}
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div className="row-structure">
          {((filteredResults3.features?.length <= 0) || (numberofDays < 0)) && <div className="warning-chart">
            <i className="pi pi-exclamation-triangle"></i>
            <p style={{ margin: "10px 0" }}>
              Girdiğiniz kriterlere uygun grafik oluşturulamadı.
            </p>
            <p>Lütfen Tarih değerlerini kontrol ediniz.</p>
          </div>}

          <div style={{ width: "100%", height: "460px" }}>
            {secondProgressSpinner && <ProgressSpinner
              className="progress-spinner"
              strokeWidth="8"
              fill="var(--surface-ground)"
              animationDuration=".5s"
              style={{ top: "60%" }}
            />}
            <div
              ref={lineChart}
              id="line-chart"
              className="bar-chart"
              style={{ width: "680px", height: "100%" }}
            />
          </div>
        </div>
        <div className="download-group">
          {/* <Button onClick={()=>downloadAll()}/>
          <Button onClick={downloadMap}/> */}
          {/* <Button onClick={downloadPng} icon="pi pi-image" />
          <Button onClick={downloadPdf} icon="pi pi-file-pdf" /> */}
          <Button onClick={exportImage} className="immovable-button" icon="pi pi-image" />
          <Button onClick={exportPdf} className="immovable-button" icon="pi pi-file-pdf" />
        </div>
      </div>
      <Toast
        ref={toast}
        position="bottom-center"
        style={{ zIndex: "9 !important" }}
      />
    </div>
  );
};
export default DateBasedStats;
