import './GoToLocation.scss';
import { useRef, useState } from 'react';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { useDispatch } from 'react-redux';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import Point from 'ol/geom/Point';
// import { MapContext } from '../../MapContext';
import SimpleGeometry from 'ol/geom/SimpleGeometry';
import { setGoToLocation } from '../../../state/features/sharedSlice';
import { useAppSelector } from '../../../state/hooks';

export default function GoToLocation() {
    const toast = useRef<any>(null);
    const dispatch = useDispatch();
    const [coordinateValue, setCoordinateValue] = useState<string>('');
    const map = useAppSelector(state => state.maps.mapValue);
    const onInputCoordinate = (e: any) => {
        setCoordinateValue(e.target.value);
    };

    const onGoToLocation = () => {
        if (coordinateValue) {
            const replacedCoordinateValue = coordinateValue.replace(/ /g, '');
            const coordinateStringArray = replacedCoordinateValue.split(',');
            const coordinateNumberArray = coordinateStringArray.map((coordinateString) => Number(coordinateString));
            const point = new Point(coordinateNumberArray);
            point.transform('EPSG:4326', 'EPSG:3857');
            map.getView().fit(point as SimpleGeometry, { duration: 500, maxZoom: 20 })
        }
    };

    return (
        <>
            <Dialog
                className='GoToLocation'
                header='Konuma Git'
                visible={true}
                modal={false}
                onHide={() => {
                    dispatch(setGoToLocation(false));
                }}
            >
                <div className='content'>
                    <InputText placeholder="43.26,26.43" onInput={(e) => onInputCoordinate(e)} />
                    <Button onClick={(e) => onGoToLocation()} label='Git' disabled={!coordinateValue} />
                </div>
            </Dialog>
            <Toast ref={toast} />
        </>
    );
}
