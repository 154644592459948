import './Map.scss'
import 'ol/ol.css';
import View from 'ol/View';
import { PropsWithChildren, useEffect, useRef, useState } from 'react'
import { Map as OlMap } from 'ol';
import { MapContext } from './MapContext';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../state/hooks';
import { setBasemaps } from '../state/features/basemapSlice';
import { setExtent, setMapValue, setZoom } from '../state/features/mapSlice';
import { get, transformExtent } from 'ol/proj';
import { setLayerForGoToLayer, setRemoveGroupAlias } from '../state/features/sidebarSlice';
import { BaselayerRestApi } from '../../utils/restapi/baselayer';
import KML from "ol/format/KML";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import Style from "ol/style/Style";
import Stroke from "ol/style/Stroke";
import { Extent } from "ol/extent";
import GeoJSON from 'ol/format/GeoJSON';
import GML2 from 'ol/format/GML2';
import * as Shapefile from 'shapefile';
import { UtilRestApi } from '../../utils/restapi/util';
import { setImportCompleted } from '../state/features/importSlice';
import { setServices } from "../state/features/serviceSlice";
interface IProps {
    centerX: number;
    centerY: number;
    zoom: number;
}
type FileResponseType = 'ArrayBuffer' | 'Text';
export const Map = (props: PropsWithChildren<IProps>) => {
    const dispatch = useDispatch()
    const t = useRef<OlMap | null>(null);
    const mapRef = useRef(document.createElement('div'));
    const [olMap, setOlMap] = useState<OlMap | null>(null);
    const layerForGoToLayer = useAppSelector(state => state.sidebar.layerForGoToLayer)
    const fitExtent = useAppSelector(state => state.maps.fitExtent);
    const [vector, setVector] = useState<any>(null);
    const importedFile = useAppSelector(state => state.import.importedFile);
    const importedEpsg = useAppSelector(state => state.import.importedEpsg);
    const importedFileVisibility = useAppSelector(state => state.import.importedFileVisibility);
    const _geojson = new GeoJSON();
    const _gml = new GML2();
    const services = useAppSelector(state => state.service.services)
    const importGroupName = useAppSelector((state) => state.import.importedGroupAlias);
    const enabledGroupAlias = useAppSelector((state) => state.sidebar.enabledGroupAlias);
    const [features, setFeatures] = useState<any>(undefined);
    useEffect(() => {
        let changeService = services.find((el: any) => el.alias === enabledGroupAlias)
        if (!changeService) {
            changeService = services.find((el: any) => el.layers[0].alias === enabledGroupAlias)
        }
        if (changeService) {
            const changeFeatures = changeService.layers[0].features
            const changeVector = changeService.layers[0].vector
            if (changeVector && changeFeatures) {
                if (!importedFileVisibility) {
                    changeVector.setVisible(false);
                } else {
                    changeVector.setVisible(true);
                }
            }
        }
    }, [importedFileVisibility])
    useEffect(() => {
        if (features && features.length) {
            const otherServices = services.filter((el: any) => el.alias !== importGroupName)
            const offlineService = services.find((el: any) => el.alias === importGroupName)
            const offline = JSON.parse(JSON.stringify(offlineService)); //{...offlineService}//
            offline.layers[0].features = features
            offline.layers[0].vector = vector
            dispatch(setServices([...otherServices, offline]));
        }
    }, [features])

    useEffect(() => {
        if (importedFile) {
            const fileName = importedFile[0].name;
            const filenameData = fileName.split('.');
            // const filePrefixName = filenameData[0];
            let fileType = filenameData[1];
            let fileResponseType: FileResponseType = 'ArrayBuffer';
            if (fileType === undefined) {
                console.log('File type does not exist');
            }
            else {
                fileType = fileType.toLowerCase();
                fileResponseType = 'Text';
                switch (fileType) {
                    case 'kml':
                        importedFile?.map((file: any) => {
                            file.text().then((kml: any) => {
                                const kmlReader = new KML()
                                const features: any = kmlReader.readFeatures(kml,
                                    {
                                        dataProjection: importedEpsg,
                                        featureProjection: 'EPSG:3857',
                                    });
                                // for (const feature of features) {
                                //     feature.getGeometry()?.transform('EPSG:4326', 'EPSG:3857');
                                // }
                                const vector = new VectorLayer({
                                    source: new VectorSource(),
                                    zIndex: 10000000000,
                                    style: new Style({
                                        stroke: new Stroke({
                                            color: '#888',
                                            width: 3
                                        })
                                    }),
                                    properties: {
                                        alias: importGroupName
                                    }
                                });
                                setVector(vector)
                                setFeatures(features);
                                vector.getSource()?.addFeatures(features)
                                olMap?.addLayer(vector);
                                olMap?.getView().fit(vector.getSource()?.getExtent() as Extent, { padding: [10, 10, 10, 10] })
                            });
                        })
                        break;
                    case 'gml':
                        getFileAsAText(importedFile[0], fileResponseType).then((result: any) => {
                            const features = _gml.readFeatures(
                                result.response,
                                {
                                    dataProjection: importedEpsg,
                                    featureProjection: 'EPSG:3857',
                                }
                            );
                            if (features.length) {
                                const vector = new VectorLayer({
                                    source: new VectorSource(),
                                    zIndex: 10000000000,
                                    style: new Style({
                                        stroke: new Stroke({
                                            color: '#888',
                                            width: 3
                                        })
                                    }),
                                    properties: {
                                        alias: importGroupName
                                    }
                                });
                                vector.getSource()?.addFeatures(features)
                                olMap?.addLayer(vector);
                                olMap?.getView().fit(vector.getSource()?.getExtent() as Extent, { padding: [10, 10, 10, 10] })
                                setVector(vector)
                                setFeatures(features)

                            } else {
                                setTimeout(() => {
                                    dispatch(setRemoveGroupAlias(importGroupName))
                                    dispatch(setServices([...services.filter((el: any) => el.alias !== importGroupName)]));
                                }, 0);
                            }
                        })
                        break;
                    case 'json':
                    case 'geojson':
                        getFileAsAText(importedFile[0], fileResponseType).then((result: any) => {
                            const features = _geojson.readFeatures(
                                result.response,
                                {
                                    dataProjection: importedEpsg,
                                    featureProjection: 'EPSG:3857',
                                }
                            );
                            const vector = new VectorLayer({
                                source: new VectorSource(),
                                zIndex: 10000000000,
                                style: new Style({
                                    stroke: new Stroke({
                                        color: '#888',
                                        width: 3
                                    })
                                }),
                                properties: {
                                    alias: importGroupName
                                }
                            });
                            vector.getSource()?.addFeatures(features)
                            olMap?.addLayer(vector);
                            olMap?.getView().fit(vector.getSource()?.getExtent() as Extent, { padding: [10, 10, 10, 10] })
                            setVector(vector)
                            setFeatures(features)
                        })
                        break;
                    case 'shp':
                    case 'shx':
                    case 'prj':
                    case 'dbf':
                    case 'cst':
                        const files: File[] = importedFile;
                        const textRequestArray = [];
                        for (const file of files) {
                            textRequestArray.push(getFileAsAText(file, 'ArrayBuffer'));
                        }
                        Promise.all(textRequestArray).then((resultArray: Array<any>) => {
                            const alreadyAddedShapeFileNameList = [];
                            //  const featurePromiseList: any = [];
                            const features: any = [];
                            for (const item of resultArray) {
                                const fileName: string = item.file.name;
                                const filenameData = fileName.split('.');
                                const filePrefixName = filenameData[0];
                                let fileType = filenameData[1];
                                if (fileType === undefined) {
                                    console.log('File type does not exist');
                                    continue;
                                }
                                fileType = fileType.toLowerCase();

                                const indexOfOldFile = alreadyAddedShapeFileNameList.indexOf(filePrefixName);
                                if (indexOfOldFile === -1) {
                                    alreadyAddedShapeFileNameList.push(filePrefixName);
                                    const shapeFiles = resultArray.filter((e: any) => e.file.name.split('.')[0] === filePrefixName);
                                    const shpItem = shapeFiles.find((e: any) => e.file.name.split('.')[1].toLowerCase() === 'shp');
                                    const dbfItem = shapeFiles.find((e: any) => e.file.name.split('.')[1].toLowerCase() === 'dbf');
                                    if (shpItem && dbfItem) {

                                        const shapeFeaturesPromise = async () => {
                                            const source2 = await Shapefile.open(
                                                shpItem.response,
                                                dbfItem.response,
                                                { encoding: 'utf-8' }
                                            );

                                            const geojsonInstance = new GeoJSON();

                                            let isThereASomeOtherFeatures = true;
                                            while (isThereASomeOtherFeatures) {
                                                const result = await source2.read();
                                                // console.log(" result.value :", result.value);
                                                if (result.done) {
                                                    isThereASomeOtherFeatures = false;
                                                } else {

                                                    features.push(
                                                        geojsonInstance.readFeature(
                                                            result.value // readFeatureOptions
                                                        )
                                                    );
                                                }
                                            }
                                            return features;
                                        }
                                        shapeFeaturesPromise().then((features: any) => {
                                            // console.log("shapeFeaturesPromise() ",features);
                                            for (const feature of features) {
                                                feature.getGeometry()?.transform(importedEpsg, 'EPSG:3857');
                                            }
                                            // console.log("features: ",features);
                                            const vector = new VectorLayer({
                                                source: new VectorSource(),
                                                zIndex: 10000000000,
                                                style: new Style({
                                                    stroke: new Stroke({
                                                        color: '#888',
                                                        width: 3
                                                    })
                                                }),
                                                properties: {
                                                    alias: importGroupName
                                                }
                                            });
                                            vector.getSource()?.addFeatures(features)
                                            olMap?.addLayer(vector);
                                            olMap?.getView().fit(vector.getSource()?.getExtent() as Extent, { padding: [10, 10, 10, 10] })
                                            setVector(vector)
                                            setFeatures(features)
                                        })
                                        // featurePromiseList.push(shapeFeaturesPromise());
                                        // Shapefile.open(shpItem.response, dbfItem.response).then((source:any)=>{
                                        // console.log("source:",source);

                                        //     let isThereASomeOtherFeatures = true;
                                        //     while (isThereASomeOtherFeatures) {
                                        //         source.read().then((result:any)=>{
                                        //             if (result.done) {
                                        //                 isThereASomeOtherFeatures = false;
                                        //             } else {
                                        //                 features.push(_geojson.readFeature(result.value, readFeatureOptions));
                                        //                 console.log("current features  : ",features);
                                        //             }
                                        //         });
                                        //     }
                                        // })
                                        //   featurePromiseList.push({ promise: shapeFeaturesPromise(), layerName: filePrefixName });
                                    } else {
                                        // console.log('shp dbf bulunamadı');
                                    }
                                }
                            }
                        })
                        break;

                }
                setTimeout(() => {
                    dispatch(setImportCompleted(true))
                }, 500);
                /**
                 * 
                 * YÜKLENDİ MESAJI
                 * 
                 */
            }

        }
    }, [importedFile])

    useEffect(() => {

        BaselayerRestApi.getBaselayers().then((res: any) => {
            dispatch(setBasemaps(res.data));
        });


        setTimeout(() => {
            const v = new View({
                center: [4072044.8761742823, 5294605.14431233],
                zoom: 6.7,
                maxZoom: 20,
                // extent: [2584867.168089575, 4072044.8761742823, 5294605.14431233, 5428325.186148275]
                extent: [1975319.2126945856, 3410871.868219671, 6044509.909315145, 5447586.58668444]
            });
            const map = new OlMap({
                layers: [],
                view: v,
                controls: []
            });

            map.setTarget(mapRef.current)
            t.current = map;
            setOlMap(map);
            dispatch(setMapValue(map));
            (window as any).map = map

            map.on('moveend', (event: any) => {
                dispatch(setZoom(map.getView().getZoom()))
                dispatch(setExtent(map.getView().calculateExtent()))
            })

            const vector = new VectorLayer({
                source: new VectorSource(),
                zIndex: 10000000000,
                style: new Style({
                    stroke: new Stroke({
                        color: '#888',
                        width: 3
                    })
                })
            });
            setVector(vector)

            return () => {
                map.dispose()
            };

        }, 200)

    }, [mapRef]);


    useEffect(() => {
        if (fitExtent && olMap) {
            olMap.getView().fit(fitExtent, { duration: 500, padding: [10, 10, 10, 10] })
        }
    }, [fitExtent])


    useEffect(() => {
        if (layerForGoToLayer?.layer_type === 'offline') {
            olMap?.getView().fit(layerForGoToLayer.vector.getSource()?.getExtent() as Extent, { duration: 500, padding: [10, 10, 10, 10] })
        }
        else if (layerForGoToLayer?.layer && layerForGoToLayer.layer !== 'usr1_ag_kaza') {
            dispatch(setLayerForGoToLayer({}))

            UtilRestApi.geoserverWfsReq(`version=1.0.0&request=GetFeature&typeName=${layerForGoToLayer.layer}&outputFormat=application%2Fjson`)
                .then(res => {
                    if (res.data) {
                        const extent = transformExtent(res.data.bbox, get('EPSG:4326') as any, get('EPSG:3857') as any)
                        olMap?.getView().fit(extent, { padding: [20, 20, 20, 20], duration: 500 });
                    }
                })
        }

    }, [layerForGoToLayer])

    // useEffect(() => {
    //     console.log("services useEffect : "
    //     ,services);

    //     // olMap?.getLayers().getArray().find(layer=> layer.alias)
    // }, [services])
    const getFileAsAText = (file: any, type: FileResponseType) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (loadEvent) => {
                resolve({ response: reader.result, file });
            };
            reader.onerror = (error) => {
                reject(error);
            };
            if (type === 'ArrayBuffer') {
                reader.readAsArrayBuffer(file);
            } else if (type === 'Text') {
                reader.readAsText(file);
            }
        });
    };
    return (
        <>
            <div className='Map' ref={mapRef}></div>
            {
                olMap &&
                <MapContext.Provider value={olMap}>
                    {props.children}
                </MapContext.Provider>
            }
        </>
    )
};
