import './UserInfoCard.scss'
import { Button } from 'primereact/button'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { LoginRestApi } from '../../utils/restapi/login'
import { setShowImport } from '../state/features/importSlice'
import { setToken } from '../state/features/loginSlice'
import { setCreateKKNOperation, setEditUser, setEditUserPassword } from '../state/features/userSlice'
import { useAppSelector } from '../state/hooks'
import { CreateKKN } from './CreateKKN'
import EditUserInfo from './EditUserInfo'
import TGAP_Kullanici_Yardim_Dokumani_v1_2 from './help-document/TGAP_Kullanici_Yardim_Dokumani_v1_2.pdf'
import { setActiveButtonType, setActiveButtonTypeRight } from '../state/features/buttonStateSlice'
import { ActiveButtonStateRight, ActiveButtonState } from '../shared/button-state/ButtonState';
import EditUserPassword from './EditUserPassword'
const UserInfoCard = () => {
  const dispatch = useDispatch()
  const [menuOpenAuth, setMenuOpenAuth] = useState<boolean>(true)
  const [menuOpenAuth2, setMenuOpenAuth2] = useState<boolean>(true)
  const [x, y] = useState<any>([])
  const editUser = useAppSelector((state) => state.user.editUser)
  const editUserPassword = useAppSelector((state) => state.user.editUserPassword)
  const isKKNOpen = useAppSelector((state) => state.user.isKKNOpen)
  const tokenJson = useAppSelector((state) => state.login.jsonToken);
  const userFunctionPerm = useAppSelector(state => state.user.userFunctionPerm);
  const progressVisibility = useAppSelector((state) => state.shared.visible);

  useEffect(() => {

    const value = [
      {
        name: "Katman"
      },
      {
        name: "Arama"
      },
      {
        name: "Bölge Seç"
      },
      {
        name: "Alan Çiz"
      },
      {
        name: "Tarih Seç"
      },
      {
        name: "Filtreler"
      },
      {
        name: "Tematik Harita"
      },
      {
        name: "İstatistikler"
      },
      {
        name: "Dashboard"
      },
      {
        name: "Altlıklar"
      },
      {
        name: "Lejant"
      },
    ]

    y(value)


  }, [tokenJson])

  // function onDownloadFile(fileName: string) {
  //   // window.open(`${fileName}`)
  //   window.open('./help-document/TGAP_Kullanici_Yardim_Dokumani_v02.pdf');

  // }

  const logout = async () => {
    dispatch(setActiveButtonTypeRight(ActiveButtonStateRight.DEFAULT))
    dispatch(setActiveButtonType(ActiveButtonState.DEFAULT))
    await LoginRestApi.logout().then((res: any) => {
    })
    localStorage.removeItem("ag_token")
    dispatch(setToken(null))
  }

  const importFile = () => {
    dispatch(setShowImport(true));
  }

  const createKKNOperation = () => {
    dispatch(setCreateKKNOperation(true))
  }

  const editInfo = () => {
    dispatch(setEditUser(true))
  }

  const editPasswordInfo = () => {
    dispatch(setEditUserPassword(true))
  }

  return (
    <div className='UserInfoCard'>
      <div className='profile-block'>
        <div className='profile-icon'>
          <img src="icons/profile.svg" width="22" height="22" alt='Profil Bilgileri' className='filter-blue' />
        </div>
        <div style={{ "display": "flex", "alignItems": "flex-start", "flexDirection": "column", "alignSelf": "flex-start", "marginLeft": "4%", "fontSize": "15px" }}>
          <p><b>{tokenJson && tokenJson.username}</b></p>
        </div>

      </div>
      <Button onClick={() => setMenuOpenAuth(!menuOpenAuth)} className={`auth-button ${!menuOpenAuth ? 'clicked' : ''}`} label='Yetkiler' icon='pi pi-sitemap' />
      <div className={`menu-yetkiler ${!menuOpenAuth ? 'opened' : ''}`}>
        {userFunctionPerm.find((perm: any) => perm.menu === 'kkn') && !progressVisibility && <div className='auth-path' onClick={createKKNOperation}><span className='auth-value'>Kaza Kara Noktası Modülü </span> </div>}
        <div className='auth-path' onClick={importFile}><span className='auth-value'>Katman İçe Aktarma </span> </div>
        <div className='auth-path' onClick={editInfo}> <span className='auth-value'>Kullanıcı Bilgilerini Güncelle</span> </div>
        {tokenJson?.type !== "superadmin" && <div className='auth-path' onClick={editPasswordInfo}> <span className='auth-value'>Kullanıcı Şifresi Güncelle</span> </div>}
        {tokenJson?.type !== "user" && <div className='auth-path'><a className='auth-value' href={`${process.env.REACT_APP_SUPERSET_URL}`} target='_blank' rel="noreferrer" style={{ textDecoration: 'none' }}>Gösterge Paneli</a> </div>}
        {tokenJson?.type !== "user" && <div className='auth-path'><a className='auth-value' href={`${process.env.REACT_APP_TRAFIK_URL + '8a61468f73f051df1e78ebc3c4baf016'}`} target='_blank' rel="noreferrer" style={{ textDecoration: 'none' }}>Panele Git</a> </div>}
      </div>
      {/* <Button label='Ayarlar' className='auth-button' icon='pi pi-cog' /> */}

      <Button onClick={() => setMenuOpenAuth2(!menuOpenAuth2)} className={`auth-button ${!menuOpenAuth2 ? 'clicked' : ''}`} label='Yardım' icon='pi pi-book' />
      <div className={`menu-yardim ${!menuOpenAuth2 ? 'yardim-opened' : ''}`}>
        {
          // x.map((a: any) => (
          //   <div key={a.name} className='doc-path'>
          //     <span className='sub-doc' onClick={() => onDownloadFile(a.name)}>
          //       {a.name}
          //     </span>
          //   </div>
          // ))

          <div className='doc-path'>
            <a className='sub-doc' href={TGAP_Kullanici_Yardim_Dokumani_v1_2} style={{ textDecoration: "none" }} target="_blank" rel="noopener noreferrer" > Yardım Dökümanı</a>
          </div>

        }
        {/* <div className='doc-path'><span className='sub-doc'>Katman </span> </div>
        <div className='doc-path'><span className='sub-doc'>Arama </span> </div>
        <div className='doc-path'><span className='sub-doc'>Bölge Seç</span></div>
        <div className='doc-path'><span className='sub-doc'>Alan Çiz</span></div>
        <div className='doc-path'><span className='sub-doc'>Tarih Seç</span></div>
        <div className='doc-path'><span className='sub-doc'>Filtreler</span></div>
        <div className='doc-path'><span className='sub-doc'>Tematik Harita</span></div>
        <div className='doc-path'><span className='sub-doc'>İstatistikler</span></div>
        <div className='doc-path'><span className='sub-doc'>Dashboard</span></div>
        <div className='doc-path'><span className='sub-doc'>Altlıklar</span></div>
        <div className='doc-path'><span className='sub-doc'>Lejant</span></div> */}
      </div>


      <Button className="logout-button" label='Çıkış' onClick={logout} disabled={progressVisibility} />
      {editUser && <EditUserInfo />}
      {editUserPassword && <EditUserPassword />}
      {isKKNOpen && <CreateKKN />}
    </div>
  )
}

export default UserInfoCard