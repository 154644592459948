import "./Sidebar.scss";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../state/hooks";
import { Tree } from "primereact/tree";
import { ContextMenu } from "primereact/contextmenu";
import { setExternal, setServices } from "../state/features/serviceSlice";
import {
  setClickState,
  setEnabledGroupAlias,
  setIsSidebarActive,
  setKKNLayer,
  setLayerForGoToLayer,
  setRemoveGroupAlias,
  setKknProgress
} from "../state/features/sidebarSlice";
import { setIsSidebarAccidentVisible } from "../state/features/elasticSlice";
import { changeTableVisibility } from "../state/features/tableSlice";
import {
  setImportedFileVisibility,
  setLayerNodes,
} from "../state/features/importSlice";
import { ActiveButtonState } from "../shared/button-state/ButtonState";
import { LayerRestApi } from "../../utils/restapi/layer";
import { Dialog } from "primereact/dialog";
import { RadioButton } from "primereact/radiobutton";
import { Button } from "primereact/button";
import { setKKNIsSuccessfull } from "../state/features/userSlice";
import { setLayerForTable } from "../state/features/infoSlice";
import { KKNRestApi } from "../../utils/restapi/kkn";
import { Toast } from "primereact/toast";
import saveAs from "file-saver";
import { setDeleteKKNProgressVisibility } from "../state/features/sharedSlice";
import { ProgressSpinner } from "primereact/progressspinner";
import { ExternalServiceRestApi } from "../../utils/restapi/externalservices";
import { UtilRestApi } from "../../utils/restapi/util";
import { Slider } from "primereact/slider";
import { setisKKNActiveButton } from "../state/features/buttonStateSlice";
// import { setSelectedKKNGroupFeature } from "../state/features/statisticSlice";

const { REACT_APP_GEOSERVER_USER, REACT_APP_GEOSERVER_PASSWORD } = process.env;

const Sidebar = () => {
  const dispatch = useDispatch();
  // const olMap = useContext(MapContext)
  const treeRef = useRef(null);
  const toast = useRef<any>();
  const services = useAppSelector((state) => state.service.services);
  const importLayerGroup: any = useAppSelector(
    (state) => state.import.importLayerGroup
  );
  const activeButtonType = useAppSelector(
    (state) => state.buttonState.activeButtonType
  );
  const isKKNActiveButton = useAppSelector(
    (state) => state.buttonState.isKKNActiveButton
  );
  const importedFile = useAppSelector((state) => state.import.importedFile);
  const isKKNSuccessfull = useAppSelector(
    (state) => state.user.isKKNSuccessfull
  );
  const progressVisibility = useAppSelector((state) => state.shared.visible);
  const kknProgressVisibility = useAppSelector(
    (state) => state.shared.kknProgressVisibility
  );
  const kknLayerForDelete = useAppSelector(
    (state) => state.sidebar.kknLayerForDelete
  );
  const kknGroupFeature = useAppSelector(
    (state) => state.statistic.kknGroupFeature
  );
  const gotoKKN = useAppSelector(
    (state) => state.statistic.gotoKKN
  );
  const kknProgress = useAppSelector(
    (state) => state.sidebar.kknProgress
  );
  const [keyHandler, setKeyHandler] = useState<any>({})
  const [immovables, setImmovables] = useState<any>({})
  const [change, setChange] = useState(false);
  const [exportOpened, setExportOpened] = useState(false);
  const [isKKN, setIsKKN] = useState(false);
  const [isExternal, setisExternal] = useState("");
  const [isOffline, setisOffline] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isAccident, setIsAccident] = useState(false);
  const [isKKNDelete, setIsKKNDelete] = useState(false);
  const [allServices, setAllServices] = useState<any>([]);
  const [exportType, setExportType] = useState<any>("KML");
  const [nodes, setNodes] = useState<any>();
  const [newKKN, setNewKKN] = useState<any>({});
  const [selectedKey, setSelectedKey] = useState<any>();
  const [selectedNodeKey, setSelectedNodeKey] = useState<any>();
  const [selectedLayer, setSelectedLayer] = useState<any>(null);
  const [opacityTimeout, setOpacityTimeout] = useState<any>();
  const contextMenu: any = useRef(null);
  const token = localStorage.getItem("ag_token");
  const layerArr: any = [];
  services.map((service: any) => {
    layerArr.push(service.layers);
  });
  const groupNames: string[] = [];
  const importName = useAppSelector((state) => state.import.importedGroupAlias);
  const importCompleted = useAppSelector(
    (state) => state.import.importCompleted
  );
  const [selectedGroupAlias, setSelectedGroupAlias] = useState<any>(null);
  const olMap = (window as any).map

  useEffect(() => {
    let filteredService: any = [];
    let sortedService: any = [];

    ExternalServiceRestApi.getAllExternalServices().then((res: any) => {
      if (res) {
        dispatch(setExternal(res.data));
      }
    });

    LayerRestApi.getLayerGroups().then((res: any) => {
      if (res) {
        setAllServices(res.data);
        filteredService = res.data.filter(
          (service: any) => service.layers.length !== 0
        );
        sortedService = filteredService.sort(
          (a: any, b: any) => a.order - b.order
        );
        sortedService.forEach((service: any) => {
          service.layers.forEach((obj: any) => {
            if (obj.layer === "usr1_ag_kaza") {
              obj.layer_type = "WebGLPointsLayer";
            }
          });
        })
        const services = res.data;
        services.forEach((service: any) => {
          service.layers.forEach((layer: any) => {
            layer.opacity = 1;
          });
        });
        // ilk başta kazalar acik gelsin.
        const immovables = services.find((layer: any) => {
          return layer.name === "trafik_kazalari";
        });
        if (immovables) {
          immovables.layers.find((obj: any) => {
            if (obj.layer === "usr1_ag_kaza") {
              if (!obj.visible) {
                obj.visible = !obj.visible;
                dispatch(setIsSidebarAccidentVisible(obj.visible));

                isKKNDelete === false ? dispatch(setIsSidebarAccidentVisible(obj.visible)) : dispatch(setIsSidebarAccidentVisible(false));
              }
            }
          });
          setImmovables(immovables)
        }
        services.forEach((service: any) => {
          service.layers.forEach((layer: any) => {
            if (kknGroupFeature.layers?.find((el: any) => el.layer === layer.layer)) {
              if (!layer.visible) {
                layer.visible = !layer.visible;
                dispatch(setIsSidebarAccidentVisible(layer.visible));
              }
            }
          })
        });
      }
      dispatch(setServices(sortedService));
    });

  }, [token, isKKNDelete, isKKNSuccessfull, progressVisibility]);

  useEffect(() => {
    if (Object.keys(keyHandler).length > 0) {
      if (kknProgress === true) {
        if (Object.keys(keyHandler).length > 0) {
          if ('trafik_kazalari' in keyHandler && 'usr1_ag_kaza' in keyHandler) {
            dispatch(setIsSidebarAccidentVisible(true));
          }
        }
        else {
          dispatch(setIsSidebarAccidentVisible(false));
        }
      }
    }
    else {
      if (Object.keys(immovables).length > 0) {

        immovables.layers.find((obj: any) => {
          if (obj.layer === "usr1_ag_kaza") {
            if (!obj.visible) {
              obj.visible = !obj.visible;
              dispatch(setIsSidebarAccidentVisible(obj.visible));
            }
          }
        });
      }
    }
  }, [kknProgress, immovables, keyHandler, isKKNDelete])

  useEffect(() => {
    if (isKKNSuccessfull) {
      dispatch(setKKNIsSuccessfull(false));
    }
  }, [isKKNSuccessfull]);

  useEffect(() => {
    const classes = document.createElement("style");
    classes.innerHTML = ``;
    const tree = [];

    for (const service of services) {
      const children = [];
      for (const layer of service.layers) {
        const child = {
          key: layer.layer,
          label: layer.alias,
          data: layer,
          icon: `iconforsidebar_${layer.layer}`,
        };
        children.push(child);
      }

      const serviceNode = {
        key: service.name,
        label: service.alias,
        data: "",
        children,
      };
      tree.push(serviceNode);
    }

    document.getElementsByTagName("head")[0].appendChild(classes);
    if (importedFile && importedFile.length > 0) {
      setNodes([...tree /*, importLayerGroup*/]);
      dispatch(setLayerNodes([...tree /*, importLayerGroup*/]));
      setNodesVisibility(services);
    } else {
      setNodes(tree);
      dispatch(setLayerNodes(tree));
      setNodesVisibility(services);
    }
  }, [services]);

  const rightClickLayerMenu =
    isExternal !== "External"
      ? [
        isOffline || !isAccident
          ? {
            label: "Katmana Git",
            icon: "pi pi-arrow-up-right",
            command: () => {
              if (selectedLayer) {
                dispatch(setLayerForGoToLayer(selectedLayer));
              }
            },
          }
          : {
            className: "hidden-context-menu",
          },
        !isOffline
          ? {
            label: "Tablo Aç",
            icon: "pi pi-table",
            command: () => {
              if (selectedLayer) {
                dispatch(changeTableVisibility(true));
                dispatch(setLayerForTable(selectedLayer));
              }
            },
          }
          : {
            className: "hidden-context-menu",
          },
        !isAccident && !isOffline
          ? {
            label: "Dışa Aktar",
            icon: "pi pi-external-link",
            command: () => {
              setExportOpened(true);
            },
          }
          : {
            className: "hidden-context-menu",
          },
        isKKN || isOffline
          ? {
            label: "Sil",
            icon: "pi pi-trash",
            command: () => {
              if (selectedLayer) {
                if (!isOffline) {
                  setIsDeleteOpen(true)
                  dispatch(setKKNLayer(selectedLayer));
                } else {
                  const group = services.find(
                    (el: any) => el.layers[0].alias === selectedLayer.alias
                  ).alias;

                  const removed = olMap.removeLayer(olMap.getLayers().getArray().find(
                    (el: any) => el.getProperties().alias === selectedLayer.vector.getProperties().alias
                  ))

                  dispatch(setRemoveGroupAlias(group));
                  dispatch(
                    setServices([
                      ...services.filter(
                        (el: any) =>
                          el.layers[0].alias !== selectedLayer.alias
                      ),
                    ])
                  );
                }
              }
            },
          }
          : {
            className: "hidden-context-menu",
          },
        {
          template: (
            <div className="opacity">
              <div className="opacity-header">Şeffaflık</div>
              <Slider value={(selectedLayer?.opacity) * 100} onChange={(e: any) => {
                onChangeHandler((+e.value) / 100, selectedLayer)
              }} className="w-full custom-slider" />
              {/* <input
                  type="range"
                  min="0"
                  max="1"
                  value={selectedLayer?.opacity}
                  step="0.25"
                  className="custom-slider"
                  onChange={(e) => {
                    onChangeHandler(+e.target.value, selectedLayer)
                  }}
                /> */}
            </div>
          )
        }
      ]
      : [];

  useEffect(() => {
    if (kknLayerForDelete && kknLayerForDelete.id) {
      setIsDeleteOpen(true)
    } else {
      setIsDeleteOpen(false)
    }
  }, [kknLayerForDelete]);

  useEffect(() => {
    const promiseArray: any = [];
    if (isKKNDelete) {
      allServices.map((service: any) => {
        if (service.layers.length === 0 && service.name.includes("group_kkn") && service.id === kknLayerForDelete.layer_group_id) {
          const response = KKNRestApi.deleteLayerGroup(service.id);
          promiseArray.push(response);
        }
      });
      Promise.all(promiseArray).then((responseArray) => { });
    }
  }, [allServices, isKKNDelete]);

  const accept = () => {
    setIsKKNDelete(false);
    dispatch(setDeleteKKNProgressVisibility(true));

    KKNRestApi.deleteLayer(kknLayerForDelete.id)
      .then((res) => {
        if (res.status === 200) {
          setIsKKNDelete(true);
          dispatch(setKknProgress(true))
          setIsDeleteOpen(false)
          dispatch(setKKNLayer({}));
          dispatch(setDeleteKKNProgressVisibility(false));
          toast.current.show({
            severity: "success",
            summary: "Başarılı",
            detail: "Katman başarıyla silindi.",
            life: 3000,
          });
        }
      })
      .catch((err: any) => {
        dispatch(setDeleteKKNProgressVisibility(false));
        toast.current.show({
          severity: "error",
          summary: "Başarısız",
          detail: err.response.data.message,
          life: 3000,
        });
      });
  };

  const acceptAll = () => {
    let setGroupId: any = 0
    setGroupId = kknLayerForDelete.layer_group_id
    setIsKKNDelete(false);
    dispatch(setDeleteKKNProgressVisibility(true));

    try {
      LayerRestApi.getLayerGroupById(setGroupId).then((res: any) => {
        if (!res.data.layers || res.data.layers.length < 1) {
          dispatch(setDeleteKKNProgressVisibility(false));
          setIsDeleteOpen(false)
          toast?.current?.show({
            severity: "error",
            summary: "Hata",
            detail: `Katman Silme Yetkiniz Bulunamamaktadır.`,
            life: 3000,
          });
        }
        else {

          for (let i = 0; i < res.data.layers.length; i++) {
            LayerRestApi.deleteLayer(res.data.layers[i].id).then((res: any) => {
              if (res.status === 200) {
                toast?.current?.show({
                  severity: "success",
                  summary: "Başarılı",
                  detail: `Katman Başarıyla Silindi.`,
                  life: 3000,
                });
              }
            })
              .catch((err: any) => {
                dispatch(setDeleteKKNProgressVisibility(false));
                setIsDeleteOpen(false)
                toast?.current?.show({
                  severity: "error",
                  summary: "Hata",
                  detail: `Katman Silme Hatası.`,
                  life: 3000,
                });
              })
              .finally(() => {
                if (i === (res.data.layers.length - 1)) {
                  LayerRestApi.deleteLayerGroup(setGroupId).then((res: any) => {
                    if (res && res.status === 200) {
                      setIsKKNDelete(true);
                      dispatch(setKknProgress(true))
                      setIsDeleteOpen(false)
                      dispatch(setKKNLayer({}));
                      dispatch(setDeleteKKNProgressVisibility(false));
                      toast.current.show({
                        severity: "success",
                        summary: "Başarılı",
                        detail: `Katman Grubu Başarıyla Silindi.`,
                        life: 3000,
                      });
                    }
                  }).catch((err: any) => {
                    dispatch(setDeleteKKNProgressVisibility(false));
                    toast.current.show({
                      severity: "error",
                      summary: "Hata",
                      detail: `Katman Grubu Silinemedi.`,
                      life: 3000,
                    });
                  })
                }
              })
          }
        }
      })
    } catch (error: any) {
      dispatch(setDeleteKKNProgressVisibility(false));
      toast.current.show({
        severity: "error",
        summary: "Hata",
        detail: `Katman Grubu Silinemedi.`,
        life: 3000,
      });
    }
  };

  const reject = () => {
    setIsDeleteOpen(false)
  };

  useEffect(() => {

    const obj = { ...selectedKey };

    if (Object.keys(kknGroupFeature).length > 0 && isKKNActiveButton === true) {
      let x = services.find((e: any) => e.name === kknGroupFeature.name)

      if (x !== undefined) {
        obj[x.name] = {
          checked: true,
          partialChecked: false,
        };
        x.layers.forEach((element: any) => {
          obj[element.layer] = {
            checked: true,
            partialChecked: false,
          }
        })

        // setSelectedKey(obj)
        changeLayerVisibility(obj);
        setNodesVisibility(services);
      }
    }
    if (isKKNActiveButton === false) {
      changeLayerVisibility(obj);
      setNodesVisibility(services);
    }

  }, [kknGroupFeature])


  useEffect(() => {
    groupNames.push(importName);
    if (importLayerGroup) {
      const offlineLayer: any = {
        id: importLayerGroup.children[0].id,
        name: importName,
        alias: importName,
        created: "2023-02-02T11:45:32.911Z",
        layers: [
          {
            alias: importLayerGroup.children[0].label,
            created: "2023-02-02T11:45:35.567Z",
            epsg: 4326,
            fields: [],
            id: importLayerGroup.children[0].id,
            layer: importLayerGroup.children[0].label,
            layer_group_id: importLayerGroup.children[0].id,
            layer_type: "offline",
            order: importLayerGroup.children[0].id,
            position: null,
            service_id: null,
            style: null,
            opacity: 1,
            updated: "2023-02-02T11:45:35.567Z",
            visible: true,
            features: [],
            vector: undefined,
          },
        ],
        order: importLayerGroup.children[0].id,
        touchBy: 1,
        updated: "2023-02-02T11:45:32.911Z",
        visible: true
      };
      dispatch(setServices([...services, offlineLayer]));
    }
  }, [importLayerGroup]);

  useEffect(() => {
    const cloneServices = [];
    for (const service of allServices) {
      const cloneService = { ...service, layers: [] };
      const cloneLayers = [];
      for (const layer of service.layers) {
        const cloneLayer = { ...layer, visible: false };
        cloneLayers.push(cloneLayer);
      }
      (cloneService as any).layers = cloneLayers;
      cloneServices.push(cloneService);
    }
    if (selectedKey) {
      const keys = Object.keys(selectedKey).filter((key) => key.includes("-"));
      const cevrimdisiKeys = Object.keys(selectedKey).filter(
        (key: any) => selectedGroupAlias === key
      );
      for (const key of keys) {
        if (
          !importLayerGroup ||
          (importLayerGroup && key !== importLayerGroup.key)
        ) {
          const layer_id = key.split("-")[1];
          const layers = cloneServices.map((service) => service.layers).flat();

          const layer: any = layers.find(
            (obj: any) => obj.id === parseInt(layer_id)
          );
          const theLayer: any = cloneServices
            .find((service) => service.id === layer.service_id)
            ?.layers.find((obj: any) => obj.id === layer.id);
          if (theLayer) {
            theLayer.visible = true;
          }
        }
      }
      if (cevrimdisiKeys.length > 0) {
        for (const key of cevrimdisiKeys) {
          if (key) {
            dispatch(setImportedFileVisibility(true));

          } else {
            dispatch(setImportedFileVisibility(false));
          }
        }
      } else {
        dispatch(setImportedFileVisibility(false));
      }
    }
  }, [selectedKey, kknGroupFeature]);

  /**
   * Bu fonksiyon ile servislerin açılıp kapanması kontrol ediliyor.
   */
  const setNodesVisibility = (geoserverWriteServices: any[]) => {

    if (selectedKey === null || selectedKey === undefined) {

      let nodeCheckList: any = {};
      geoserverWriteServices.forEach((service: any) => {
        const layerVisibility = service.layers.map((layer: any) => {
          if (layer.visible) {
            nodeCheckList[layer.layer] = {
              checked: true,
              partialChecked: false,
            };
          }
          return { key: layer.layer, visible: layer.visible };
        });

        const serviceChecked = layerVisibility.every(
          (l: any) => l.visible === true
        );
        const servicePartialChecked =
          layerVisibility.findIndex((l: any) => l.visible === true) !== -1;

        if (serviceChecked && servicePartialChecked) {
          nodeCheckList[service.name] = {
            checked: true,
            partialChecked: false,
          };
        } else if (serviceChecked || servicePartialChecked) {
          nodeCheckList[service.name] = {
            checked: false,
            partialChecked: true,
          };
        }
        setSelectedKey(nodeCheckList);
      });
    } else {
      const changeService = services.find((el: any) => el.alias === importName);
      if (!importCompleted && changeService /* && changeService.visible*/) {
        const obj = { ...selectedKey };

        obj[importName] = {
          checked: true,
          partialChecked: false,
        };
        obj[changeService.layers[0].alias] = {
          checked: true,
          partialChecked: false,
        };

        setSelectedKey(obj);

        setTimeout(() => {
          dispatch(setImportedFileVisibility(true));
        }, 500);
      } else {

        if (Object.keys(gotoKKN).length > 0) {
          const obj = { ...selectedKey };
          obj[gotoKKN.name] = {
            checked: true,
            partialChecked: false,
          };
          for (let i = 0; i < gotoKKN.layers.length; i++) {

            obj[gotoKKN.layers[i].layer] = {
              checked: true,
              partialChecked: false,
            };
          }
          setSelectedKey(obj);
        }
        else {
          if (newKKN) {
            setSelectedKey(newKKN.value);
          }
          setSelectedKey(selectedKey);
        }
      }
    }
  };

  useEffect(() => {
    if (Object.keys(newKKN).length > 0) {
      setSelectedKey(newKKN);
    }
  }, [newKKN])

  /**
   * Bu fonksiyon ile katmanların açılıp kapanması kontrol ediliyor.
   */
  const changeLayerVisibility = (checkListValues: any) => {
    dispatch(setClickState(Boolean));
    const serviceList = services.concat();
    const nodes: string[] = [];

    for (const [key] of Object.entries(checkListValues)) {
      nodes.push(key);
    }
    const newServiceList = serviceList.map((service: any) => {
      const layers = service.layers.map((ll: any) => {
        if (nodes.findIndex((nn) => nn === ll.layer) !== -1) {
          return {
            ...ll,
            visible: true,
          };
        } else {
          return {
            ...ll,
            visible: false,
          };
        }
      });
      return {
        ...service,
        layers: layers,
      };
    });

    //katmanlardan herhangi birinin açık olup olmaması durumuna bakılan yer
    if (nodes.length > 0) {
      dispatch(setIsSidebarActive(true));
    } else if (nodes.length === 0) {
      dispatch(setIsSidebarActive(false));
    }
    //trafik kazalarının açık olup olmaması durumuna bakılan yer
    const isAccidentsActive = nodes.includes("usr1_ag_kaza");
    if (!isAccidentsActive) {
      dispatch(setIsSidebarAccidentVisible(false));
      dispatch(changeTableVisibility(false));
    } else {
      dispatch(setIsSidebarAccidentVisible(true));
    }
    dispatch(setServices(newServiceList));
  };

  const onClickExport = async () => {
    const postBody = {
      layername: selectedLayer.layer,
      exportas: exportType,
    };

    async function exportShape() {
      UtilRestApi.geoserverWfsReq(
        "version=1.0.0&service=WFS&request=GetFeature&typeName=trafik:" +
        selectedLayer.layer +
        "&outputFormat=SHAPE-ZIP"
      ).then((res: any) => {
        const { URL } = JSON.parse(res.config.data)
        const variables = URL.split("//");
        const url =
          variables[0] +
          `//${REACT_APP_GEOSERVER_USER}:${REACT_APP_GEOSERVER_PASSWORD}@` +
          variables[1];
        window.open(url, "_blank");
      });
    }
    function saveJSON(data: any, saveAs: any) {
      var stringified = JSON.stringify(data[0], null, 2);
      var blob = new Blob([stringified], { type: "application/json" });
      var url = URL.createObjectURL(blob);

      var a = document.createElement("a");
      a.download = saveAs + ".json";
      a.href = url;
      a.id = saveAs;
      document.body.appendChild(a);
      a.click();
      document.querySelector("#" + a.id)?.remove();
    }
    if (exportType === "ESRI Shapefile") {
      exportShape().then((res: any) => {
        setExportOpened(false);
      });
    } else {
      await LayerRestApi.exportLayer(postBody)
        .then((res) => {
          if (res) {
            //   if (exportType === "ESRI Shapefile") {
            //     let shapeBlob = new Blob([res.data], {
            //       type: "application/octet-stream;charset=utf-16",
            //     });
            //   saveAs(shapeBlob, selectedLayer.layer + ".zip");
            //   }else{
            const blob = new Blob([res.data], { type: "" });
            if (exportType === "KML") {
              saveAs(blob, `${selectedLayer.layer + ".kml"}`);
            }
            if (exportType === "GML") {
              saveAs(blob, `${selectedLayer.layer + ".gml"}`);
            }
            if (exportType === "GeoJSON") {
              saveJSON([res.data], selectedLayer.layer);
            }
            setExportOpened(false);
          }
        })
        .catch((err: any) => {
          if (err) {
            toast.current.show({
              severity: "error",
              summary: "Başarısız",
              detail: err.response.data.message,
              life: 3000,
            });
            setExportOpened(false);
          }
        });
    }
  };
  const selectedKeyHandler = (e: any) => {
    setKeyHandler(e.value)

    if (e.value) {
      setSelectedKey(e.value);
      changeLayerVisibility(e.value);
      setNewKKN(e.value)
    }
    else {
      const obj = { ...selectedKey };
      if (Object.keys(gotoKKN).length > 0) {
        obj[gotoKKN.name] = {
          checked: true,
          partialChecked: false,
        };
        for (let i = 0; i < gotoKKN.layers.length; i++) {

          obj[gotoKKN.layers[i].layer] = {
            checked: true,
            partialChecked: false,
          };
        }
        setSelectedKey(obj);
        changeLayerVisibility(obj);
      }
    }
  };

  const onChangeSliderData = (value: any, layer: any) => {
    const cloneServices = []
    for (const service of services) {
      const cloneService = { ...service, layers: [] }
      const cloneLayers = []
      for (const servicelayer of service.layers) {
        let cloneLayer;
        if (servicelayer.id === layer.id) {
          cloneLayer = { ...servicelayer, opacity: (value as number) }
        } else {
          cloneLayer = { ...servicelayer }
        }
        cloneLayers.push(cloneLayer);
      }
      (cloneService as any).layers = cloneLayers;
      cloneServices.push(cloneService);
    }
    dispatch(setServices(cloneServices));

    const lyr = { ...selectedLayer };
    lyr.opacity = value
    setSelectedLayer(lyr)

    if (lyr.layer_type === "WebGLPointsLayer") {
      const olMap1 = (window as any).map
      const layers_ = olMap1.getLayers().getArray()//.find((el:any)=>el.getSource().getParams().layers===selectedLayer.layer);//.forEach(el=>{console.log(el.setVisible(true))})
      layers_.forEach((layer_: any) => {
        const type = layer_.getProperties().layer_type;
        if (type && type === "WebGLPointsLayer") {
          layer_.setOpacity(value)
        }
      });
    }
    const cloneNodes = []
    for (const node of nodes) {
      const cloneNode = { ...node, children: [] }
      const cloneChildren = []
      for (const child of node.children) {
        let cloneChild;
        if (child.data.id === layer.id) {
          cloneChild = { ...child, data: { ...child.data, opacity: (value as number) } }
        } else {
          cloneChild = { ...child }
        }
        cloneChildren.push(cloneChild);
      }
      (cloneNode as any).children = cloneChildren;
      cloneNodes.push(cloneNode);
    }
    setNodes(cloneNodes);

  }

  const onChangeHandler = (value: any, data: any) => {

    if (opacityTimeout) {
      clearTimeout(opacityTimeout);
    }
    const timeout = setTimeout(() => {
      onChangeSliderData(value, data);
    }, 1);
    setOpacityTimeout(timeout)
  }
  const openContextMenu = (node: any) => {
    setSelectedNodeKey(node.key);
    setChange(true);
    if (change === true) {
      setChange(false);
    }
  };

  const nodeTemplate = (node: any, options: any) => {
    if (node.icon) {
      return (
        <div
          className="main-node"
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "center",
          }}
        >
          <div className="node-list" style={{ wordBreak: "break-word" }}>{node.label}</div>
          <div className="node-button-class">
            {!node.data.layer_type?.includes("External") ? <Button
              className="node-button"
              onClick={(event: any) => {
                if (selectedNodeKey === node.key) {
                  (contextMenu as any).current.hide(event);
                  setSelectedNodeKey(null)
                  event.stopPropagation();
                } else {
                  event.stopPropagation();
                  // contextMenu.current.props.model = rightClickLayerMenu
                  openContextMenu(node);
                  if (node.data) {
                    (contextMenu as any).current.show(event);
                  }
                  setSelectedLayer(node.data);
                  node.data.layer?.includes("layer_kkn") ||
                    node.data.layer?.includes("layer_kyyb")
                    ? setIsKKN(true)
                    : setIsKKN(false);
                  node.data.layer_type?.includes("External")
                    ? setisExternal("External")
                    : setisExternal("");
                  node.data.layer?.includes("usr1_ag_kaza")
                    ? setIsAccident(true)
                    : setIsAccident(false);
                  node.data.layer_type?.includes("offline")
                    ? setisOffline(true)
                    : setisOffline(false);
                }
              }}
            >
              <img alt="!" src="icons/edit_icon.svg" />
            </Button> : null}
          </div>
        </div>
      );
    } else {
      return (
        <div
          className="main-node"
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "center",
          }}
        >
          <div className="node-list" style={{ lineBreak: "anywhere" }}>{node.label}</div>
        </div>
      );
    }
  };

  return (
    <>
      {activeButtonType === ActiveButtonState.SIDEBAR && (
        <>
          <>
            <div className="sidebar-container">
              <ContextMenu
                model={rightClickLayerMenu}
                id={
                  isExternal === "External"
                    ? "external-hidden"
                    : "non-external-visible"
                }
                ref={contextMenu}
                onHide={() => setSelectedNodeKey(null)}
              />

              <Tree
                nodeTemplate={nodeTemplate}
                onNodeClick={(e: any) => {

                  if (e.node.key?.includes('group_kkn') || e.node.key?.includes('group_kyyb')) {
                    dispatch(setisKKNActiveButton(true))
                  }
                  if (e.node.data.layer?.includes('layer_kkn') || e.node.data.layer?.includes('layer_kyyb')) {
                    dispatch(setisKKNActiveButton(true))
                  }

                  setSelectedGroupAlias(e.node.key);
                  dispatch(setEnabledGroupAlias(e.node.key));
                }}
                onUnselect={
                  (e: any) => {
                    let layerVisibleArray: any = []
                    services.filter((e: any) => e.name.includes('group_kkn'))
                      .filter((a: any) => a.layers.forEach((b: any) => {
                        if (b.visible === true) {
                          layerVisibleArray.push(b.visible)
                        }
                      }))

                    if (layerVisibleArray.length > 2) {
                      dispatch(setisKKNActiveButton(true))
                    }
                    else {
                      if (e.node.key?.includes('group_kkn') || e.node.key?.includes('group_kyyb')) {
                        dispatch(setisKKNActiveButton(false))
                      }
                      if (e.node.data.layer?.includes('layer_kkn') || e.node.data.layer?.includes('layer_kyyb')) {
                        dispatch(setisKKNActiveButton(false))
                      }
                      // if(layerVisibleArray.length ===0){
                      //     dispatch(setisKKNActiveButton(false))
                      // }
                    }
                  }
                }
                ref={treeRef}
                className="sidebar"
                // contentClassName={nodes.find((node: any) => node.key.includes('group_kkn')) ? 'kkn' : 'sidebar'}
                // contentStyle={`${nodes.find((node: any) => node.key.includes('group_kkn'))}` ? { background: 'purple' } : { background: 'black' }}
                value={nodes}
                contextMenuSelectionKey={selectedNodeKey}
                onContextMenuSelectionChange={(event) => {
                  setSelectedNodeKey(event.value);
                }}
                // propagateSelectionDown={false}
                onContextMenu={(event) => {
                  if (event.node.data) {
                    (contextMenu as any).current.show(event.originalEvent);
                  }
                  setSelectedLayer(event.node.data);
                  event.node.data.layer?.includes("layer_kkn") ||
                    event.node.data.layer?.includes("layer_kyyb")
                    ? setIsKKN(true)
                    : setIsKKN(false);
                  event.node.data.layer_type?.includes("External")
                    ? setisExternal("External")
                    : setisExternal("");
                  event.node.data.layer?.includes("usr1_ag_kaza")
                    ? setIsAccident(true)
                    : setIsAccident(false);
                  event.node.data.layer_type?.includes("offline")
                    ? setisOffline(true)
                    : setisOffline(false);
                }}
                onCollapse={() => contextMenu.current.hide()}
                selectionKeys={selectedKey}
                selectionMode="checkbox"
                onSelectionChange={selectedKeyHandler}
              />
              {
                <Dialog
                  header="Dışa Aktar"
                  className="export-dialog"
                  visible={exportOpened}
                  onHide={() => setExportOpened(false)}
                >
                  <div className="content">
                    <div className="radio-button-group">
                      <div>
                        <RadioButton
                          value="KML"
                          onChange={(e) => {
                            setExportType(e.value);
                          }}
                          checked={exportType === "KML"}
                        />
                        <label>Kml</label>
                      </div>
                      <div>
                        <RadioButton
                          value="GML"
                          onChange={(e) => {
                            setExportType(e.value);
                          }}
                          checked={exportType === "GML"}
                        />
                        <label>Gml</label>
                      </div>
                      <div>
                        <RadioButton
                          value="GeoJSON"
                          onChange={(e) => {
                            setExportType(e.value);
                          }}
                          checked={exportType === "GeoJSON"}
                        />
                        <label>GeoJSON</label>
                      </div>
                      <div>
                        <RadioButton
                          value="ESRI Shapefile"
                          onChange={(e) => setExportType(e.value)}
                          checked={exportType === "ESRI Shapefile"}
                        />
                        <label>ShapeFile</label>
                      </div>
                      {/* <div>
                  <RadioButton value='Excel' onChange={(e) => setExportType(e.value)} checked={exportType === 'Excel'} />
                  <label>Excel</label>
                </div> */}
                    </div>
                    <Button
                      className="export-button"
                      onClick={() => onClickExport()}
                      label="Dışa Aktar"
                    ></Button>
                  </div>
                </Dialog>
              }
            </div>
            <Toast
              ref={toast}
              position="bottom-center"
              style={{ zIndex: "9 !important" }}
            ></Toast>
          </>
          <Dialog
            className="layer-delete-dialog"
            visible={isDeleteOpen}
            modal={false}
            onHide={reject}
            header="Silme İşlemi"
          >
            {kknProgressVisibility && (
              <ProgressSpinner
                className="sidebar-spinner"
                strokeWidth="8"
                fill="var(--surface-ground)"
                animationDuration=".5s"
              />
            )}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "20px",
              }}
            >
              <i
                className="pi pi-exclamation-triangle"
                style={{
                  marginRight: "20px",
                  fontSize: "30px",
                  color: "var(--primary-color)",
                }}
              />
              <p>Lütfen yapmak istediğiniz işlemi seçiniz</p>
            </div>
            <div
              className="buttons"
              style={{ display: "flex", justifyContent: "space-around" }}
            >
              {/* <Button
                type="button"
                label="İptal"
                onClick={reject}
                className="reject"
                disabled={kknProgressVisibility}
              /> */}
              <Button
                type="button"
                label="Grubu Sil"
                onClick={acceptAll}
                className="accept"
                style={{ marginLeft: "10px" }}
                disabled={kknProgressVisibility}
              />
              <Button
                type="button"
                label="Katmanı Sil"
                onClick={accept}
                className="accept"
                style={{ marginLeft: "10px" }}
                disabled={kknProgressVisibility}
              />
            </div>
          </Dialog>
        </>
      )}
    </>
  );
};

export default Sidebar;
