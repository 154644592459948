import axios from 'axios';

const { REACT_APP_BASE_URL } = process.env
const getToken = () => {
    return localStorage.getItem('ag_token')
}

async function getClusterResult(
    // limit: number,
    input?: string,
    date?: string,
    selectedCity?: string,
    selectedTown?: string,
    selectedNeighborhood?: string,
    kaza_siddet?: any,
    olussekli1?: string,
    havadurumuid?: string,
    gunid?: string,
    arackazaturuid?: string,
    cinsi?: string,
    yerlesimyeriack?: string,
    yoltipi?: string,
    geoduseyguzergahid?: string,
    kavsakid?: string,
    olu_say?: string,
    kaza_say?: string,
    yarali_say?: string,
    body?: any
) {
    const config = {
        headers: { Authorization: 'Bearer ' + getToken() }
    };
    if (!input) { input = ' ' }
    if (!date) { date = '' }
    if (!selectedCity) { selectedCity = '' }
    if (!selectedTown) { selectedTown = '' }
    if (!selectedNeighborhood) { selectedNeighborhood = '' }
    if (!kaza_siddet) { kaza_siddet = '' }
    if (!olussekli1) { olussekli1 = '' }
    if (!havadurumuid) { havadurumuid = '' }
    if (!gunid) { gunid = '' }
    if (!arackazaturuid) { arackazaturuid = '' }
    if (!cinsi) { cinsi = '' }
    if (!yerlesimyeriack) { yerlesimyeriack = '' }
    if (!yoltipi) { yoltipi = '' }
    if (!geoduseyguzergahid) { geoduseyguzergahid = '' }
    if (!kavsakid) { kavsakid = '' }
    if (!olu_say) { olu_say = '' }
    if (!kaza_say) { kaza_say = '' }
    if (!yarali_say) { yarali_say = '' }

    return await axios.post(REACT_APP_BASE_URL + `data/cluster` +
    '?q=' + input +
    '&range=' + date +
    '&ilad=' + selectedCity +
    '&ilcead=' + selectedTown +
    '&mahad=' + selectedNeighborhood +
    parameter(kaza_siddet, 'kaza_siddet') +
    parameter(olussekli1, 'olussekli1') +
    parameter(havadurumuid, 'havadurumuid') +
    parameter(gunid, 'gunid') +
    parameter(arackazaturuid, 'arackazaturuid') +
    parameter(cinsi, 'cinsi') +
    parameter(yerlesimyeriack, 'yerlesimyeriack') +
    parameter(yoltipi, 'yoltipi') +
    parameter(geoduseyguzergahid, 'geoduseyguzergahid') +
    parameter(kavsakid, 'kavsakid') +
    '&olu_say=' + olu_say +
    '&yarali_say=' + yarali_say,
    // '&kaza_say=' + kaza_say ,
    body,
    config
    );
}

function parameter(param: any, param2: string) {
    return param ? param.map((obj: any) => `&${param2}=${obj}`).join('') : ''
}

export const ClusterRestApi = {
    getClusterResult
}