import axios from "axios";

const { REACT_APP_BASE_URL } = process.env;

const getToken = () => {
  return localStorage.getItem("ag_token");
};

async function userMe() {
  const config = {
    headers: { Authorization: "Bearer " + getToken() },
  };
  return await axios.get(REACT_APP_BASE_URL + "user/me", config);
}
async function userMeDetail() {
  const config = {
    headers: { Authorization: "Bearer " + getToken() },
  };
  return await axios.get(REACT_APP_BASE_URL + "user/me/detail", config);
}
async function getUsers() {
  const config = {
    headers: { Authorization: "Bearer " + getToken() },
  };
  return await axios.get(REACT_APP_BASE_URL + "user", config);
}
async function editUser(editUserObj: any) {
  const config = {
    headers: { Authorization: "Bearer " + getToken() },
  };
  return await axios.patch(REACT_APP_BASE_URL + "user/me", editUserObj, config);
}

async function editUserPassword(editUserPasswordObj: any) {
  const config = {
    headers: { Authorization: "Bearer " + getToken() },
  };
  return await axios.post(
    REACT_APP_BASE_URL + "user/changePassword",
    editUserPasswordObj,
    config
  );
}

async function getFunctionsByGroupPerm(groupId: number) {
  const config = {
    headers: { Authorization: "Bearer " + getToken() },
  };
  return await axios.get(
    REACT_APP_BASE_URL + `function/perm/${groupId}`,
    config
  );
}

export const RoleRestApi = {
  userMe,
  getUsers,
  userMeDetail,
  editUser,
  editUserPassword,
  getFunctionsByGroupPerm,
};
