import axios from "axios";

const { REACT_APP_BASE_URL } = process.env;
const getToken = () => {
  return localStorage.getItem("ag_token");
};

async function postKKN(body?: any) {
  const config = {
    headers: { Authorization: "Bearer " + getToken() },
  };

  return await axios.post(REACT_APP_BASE_URL + `kkn`, body, config);
}
//giriş yapan kullanıcının izinli olduğu bölgeleri bu servisten alıyoruz.
async function getZones() {
  const config = {
    headers: { Authorization: "Bearer " + getToken() },
  };
  return await axios.get(REACT_APP_BASE_URL + `zone`, config);
}

async function getTownByZoneId(groupId: number, provinceId: number) {
  const config = {
    headers: { Authorization: "Bearer " + getToken() },
  };
  return await axios.get(
    REACT_APP_BASE_URL + `zone/perm/${groupId}/${provinceId}`,
    config
  );
}

async function deleteLayer(layerId: number) {
  const config = {
    headers: { Authorization: "Bearer " + getToken() },
  };
  return await axios.delete(REACT_APP_BASE_URL + "layer/" + layerId, config);
}

async function deleteLayerGroup(layerGroupId: number) {
  const config = {
    headers: { Authorization: "Bearer " + getToken() },
  };
  return await axios.delete(
    REACT_APP_BASE_URL + "layer/layergroup/" + layerGroupId,
    config
  );
}

async function postKKNParameters(body?: any) {
  const config = {
    headers: { Authorization: "Bearer " + getToken() },
  };

  return await axios.post(REACT_APP_BASE_URL + `kkn/public`, body, config);
}

async function getKKNLogs() {
  const config = {
    headers: { Authorization: "Bearer " + getToken() },
  };
  return await axios.get(REACT_APP_BASE_URL + `kkn/public`, config);
}

export const KKNRestApi = {
  postKKN,
  getZones,
  getTownByZoneId,
  deleteLayer,
  deleteLayerGroup,
  postKKNParameters,
  getKKNLogs,
};
