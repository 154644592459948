import "./Statistic2.scss";
import { useRef, useEffect } from "react";
import type { EChartsOption } from "echarts";
import { useAppSelector } from "../state/hooks";
import * as echarts from "echarts";
import AccidentMain from "./AccidentMain";

const RoadCondition = () => {
  const barChart = useRef<HTMLDivElement>(null);
  const lightingChart = useRef<HTMLDivElement>(null);
  const trafficLampChart = useRef<HTMLDivElement>(null);
  const searchResults = useAppSelector((state) => state.elastic.value);

  useEffect(() => {
    var chartDom = document.getElementById("bar-chart")!;
    var myChart = echarts.init(chartDom);
    var option: EChartsOption;

    option = {
      height: "100%",
      title: {
        text: "KAPLAMA VERİSİ",
        left: "center",
        top: "5%",
        textStyle: {
          color: "#1A4158",
          fontWeight: "bold",
          fontSize: 18,
        },
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
      },
      grid: {
        right: 40,
        top: "20%",
        height: "60%",
        width: "75%",
      },
      xAxis: {
        max: "dataMax",
      },
      yAxis: {
        type: "category",
        data: [
          "BETON",
          "STABİLİZE",
          "ASFALT",
          "PARKE",
          "HAM YOLU",
          "TOPRAK",
          "SATHİ KAPLAMA",
        ],
      },
      series: [
        {
          data: [
            searchResults.filter((el: any) => el.kaplama === "BETON").length > 0
              ? searchResults.filter((el: any) => el.kaplama === "BETON").length
              : "",
            searchResults.filter((el: any) => el.kaplama === "STABİLİZE")
              .length > 0
              ? searchResults.filter((el: any) => el.kaplama === "STABİLİZE")
                .length
              : "",
            searchResults.filter((el: any) => el.kaplama === "ASFALT").length >
              0
              ? searchResults.filter((el: any) => el.kaplama === "ASFALT")
                .length
              : "",
            searchResults.filter((el: any) => el.kaplama === "PARKE").length > 0
              ? searchResults.filter((el: any) => el.kaplama === "PARKE").length
              : "",
            searchResults.filter((el: any) => el.kaplama === "HAM YOLU")
              .length > 0
              ? searchResults.filter((el: any) => el.kaplama === "HAM YOLU")
                .length
              : "",
            searchResults.filter((el: any) => el.kaplama === "TOPRAK").length >
              0
              ? searchResults.filter((el: any) => el.kaplama === "TOPRAK")
                .length
              : "",
            searchResults.filter((el: any) => el.kaplama === "SATHİ KAPLAMA")
              .length > 0
              ? searchResults.filter(
                (el: any) => el.kaplama === "SATHİ KAPLAMA"
              ).length
              : "",
          ],
          label: {
            show: true,
            position: "right",
            fontWeight: "bold",
          },
          type: "bar",
          showBackground: true,
          backgroundStyle: {
            color: "rgba(180, 180, 180, 0.6)",
          },
        },
      ],
    };

    myChart.setOption(option);
    myChart?.resize();
  }, [searchResults]);

  useEffect(() => {
    var firstChartDom = document.getElementById("lighting-chart")!;
    var secondChartDom = document.getElementById("traffic-lamp-chart")!;
    var chart1 = echarts.init(firstChartDom);
    var chart2 = echarts.init(secondChartDom);
    var option1: EChartsOption;
    var option2: EChartsOption;

    option1 = {
      height: "50%",
      tooltip: {
        trigger: "item",
      },
      title: {
        text: "Aydınlatma Durum",
        left: "center",
        top: "5%",
        textStyle: {
          color: "#1A4158",
          fontWeight: "bold",
          fontSize: 18,
        },
      },
      series: [
        {
          type: "pie",
          top: "30%",
          radius: [35, 70],
          itemStyle: {
            borderColor: "#fff",
            borderWidth: 1,
          },
          label: {
            fontSize: "14px",
            minMargin: 5,
          },
          data: [
            {
              value:
                searchResults.filter((el: any) => el.aydinlatmaid === "YOK")
                  .length > 0
                  ? searchResults.filter((el: any) => el.aydinlatmaid === "YOK")
                    .length
                  : "",
              name: "Yok",
            },
            {
              value:
                searchResults.filter(
                  (el: any) => el.aydinlatmaid === "VAR(BOZUK)"
                ).length > 0
                  ? searchResults.filter(
                    (el: any) => el.aydinlatmaid === "VAR(BOZUK)"
                  ).length
                  : "",
              name: "VAR(BOZUK)",
            },
            {
              value:
                searchResults.filter((el: any) => el.aydinlatmaid === "VAR")
                  .length > 0
                  ? searchResults.filter((el: any) => el.aydinlatmaid === "VAR")
                    .length
                  : "",
              name: "Var",
            },
          ],
        },
      ],
    };
    option2 = {
      height: "50%",
      tooltip: {
        trigger: "item",
      },
      title: {
        text: "Trafik Lambası Durum",
        left: "center",
        top: "5%",
        textStyle: {
          color: "#1A4158",
          fontWeight: "bold",
          fontSize: 18,
        },
      },
      series: [
        {
          type: "pie",
          top: "30%",
          radius: [35, 70],
          itemStyle: {
            borderColor: "#fff",
            borderWidth: 1,
          },
          label: {
            fontSize: "14px",
            minMargin: 5,
          },
          data: [
            {
              value:
                searchResults.filter((el: any) => el.trafiklambasi === "YOK")
                  .length > 0
                  ? searchResults.filter(
                    (el: any) => el.trafiklambasi === "YOK"
                  ).length
                  : "",
              name: "Yok",
            },
            {
              value:
                searchResults.filter((el: any) => el.trafiklambasi === "VAR")
                  .length > 0
                  ? searchResults.filter(
                    (el: any) => el.trafiklambasi === "VAR"
                  ).length
                  : "",
              name: "Var",
            },
          ],
        },
      ],
    };
    chart1.setOption(option1);
    chart2.setOption(option2);
    // myChart?.resize();
  }, [searchResults]);

  return (
    <div className="RoadCondition">

      <AccidentMain />

      <div style={{ width: "100%", height: "350px" }}>
        <div
          ref={barChart}
          id="bar-chart"
          className="bar-chart"
          style={{ width: "680px", height: "100%" }}
        />
      </div>
      <div style={{ display: "flex", width: "100%", height: "300px" }}>
        <div
          ref={lightingChart}
          id="lighting-chart"
          className="pie-chart"
          style={{ width: "50%", height: "300px" }}
        />
        <div
          ref={trafficLampChart}
          id="traffic-lamp-chart"
          className="pie-chart"
          style={{ width: "50%", height: "300px" }}
        />
      </div>
    </div>
  );
};
export default RoadCondition;
