import { createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  city: "",
  town: "",
  neighborhood: "",
  isCitySelected: false,
};

export const dropdownResultsSlice = createSlice({
  name: "dropdownResults",
  initialState,
  reducers: {
    cityResult: (state: any, action) => {
      state.city = action.payload;
    },
    townResult: (state: any, action) => {
      state.town = action.payload;
    },
    neighborhoodResult: (state: any, action) => {
      state.neighborhood = action.payload;
    },
    setSelectedCityStatus: (state: any, action) => {
      state.isCitySelected = action.payload;
    },
  },
});

export const {
  cityResult,
  townResult,
  neighborhoodResult,
  setSelectedCityStatus,
} = dropdownResultsSlice.actions;

export default dropdownResultsSlice.reducer;
