
import "./3D.scss"
import * as Cesium from 'cesium'
import React, { useEffect, useRef, useState } from "react"
import { ViewerContext } from "./context"
import { useAppSelector } from "../state/hooks"


export const ThreeD: React.FC = () => {
    const mapRef = useRef(document.createElement("div"))
    const [viewer, setViewer] = useState<Cesium.Viewer | null>(null)
    const lonLat = useAppSelector((state) => state.threeD.coordinate)
    const info = useAppSelector((state) => state.info.selectedFeature)
    const selectedFeature = useAppSelector(state => state.info.selectedFeature);
    const position = Cesium.Cartesian3.fromDegrees(lonLat[0], lonLat[1], 0.0)
    const position2 = Cesium.Cartesian3.fromDegrees(lonLat[0], lonLat[1], 100)
    Cesium.Ion.defaultAccessToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJjZTJkODI5MS02MzE1LTRlOWYtYjA5MC0wNDhmZmQ4NmQ1M2MiLCJpZCI6ODE3NTgsImlhdCI6MTY0NDMwNDQzM30.jyiMmr6oq2EMWyhci7X34eYoF18TRAXYLliznKhJ4AA"

    useEffect(() => {
        if (selectedFeature.length > 0) {

            const view = new Cesium.Viewer(mapRef.current, {
                homeButton: false,
                navigationHelpButton: false,
                infoBox: false,
                animation: false,
                timeline: false,
                geocoder: false,
                baseLayerPicker: true,
                sceneModePicker: false,
                fullscreenButton: false,
                selectionIndicator: false,
                navigationInstructionsInitiallyVisible: false,

            })
            view.scene.primitives.add(Cesium.createOsmBuildings());

            view.terrainProvider = Cesium.createWorldTerrain();

            const hpr = new Cesium.HeadingPitchRoll(
                Cesium.Math.toRadians(135),
                0.0,
                0.0
            );
            const orientation = Cesium.Transforms.headingPitchRollQuaternion(
                position,
                hpr,

            );

            const kazaType = ['KARŞILIKLI ÇARPIŞMA', 'ARKADAN ÇARPMA', 'YANDAN ÇARPMA', 'ZİNCİRLEME ÇARPIŞMA', 'ARAÇTAN CİSİM DÜŞMESİ', 'ARAÇTAN İNSAN DÜŞMESİ', 'ÇOKLU ÇARPIŞMA', 'DURAN ARACA ÇARPMA', 'HAYVANA ÇARPMA', 'PARK ETMİŞ ARACA ÇARPMA', 'YAYAYA ÇARPMA', 'YOLDAN ÇIKMA']
            let vehicleModel = 'CesiumMilkTruck'
            if (kazaType.includes(info[0].olussekli1)) {
                vehicleModel = info[0].olussekli1
            }

            if (info[0].olussekli1 === "DEVRİLME/SAVRULMA/TAKLA") {
                vehicleModel = "DEVRİLMESAVRULMATAKLA"
            }
            if (info[0].olussekli1 === "ENGEL/CİSİM İLE ÇARPIŞMA") {
                vehicleModel = "ENGELCİSİMİLEÇARPIŞMA"
            }
            const entity = view.entities.add({
                position: position,
                model: {
                    uri: `../vehicle-icons/${vehicleModel}.glb`,
                    heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
                    minimumPixelSize: 32,
                    maximumScale: 200,
                },

            });

            view.camera.flyTo({
                destination: Cesium.Cartesian3.fromDegrees(lonLat[0], lonLat[1], 1000),

            });

            const vv = window as any
                ; (window as any).view = view
            setViewer(view)
            return () => {
                view.destroy()
            }
        }
    }, [selectedFeature])
    return (
        <div style={{ width: '750px', height: '510px' }}>
            <div style={{ height: "100%" }} ref={mapRef}></div>
            {viewer && <ViewerContext.Provider value={viewer}></ViewerContext.Provider>}
        </div>
    )
}


