import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  layer: {
    id: 0,
    name: "",
    alias: "",
    geomtype: "",
    public: false,
    priority: 0,
    service_id: 0,
    style_id: null,
    fields: [],
    visible: false,
    perm: false,
    desription: null,
  },
  visible: false,
  reportVisible: false,
  layerFromTable: {},
  layerForInfo: [],
};
export const tableSlice = createSlice({
  name: "table",
  initialState,
  reducers: {
    // setLayerForInfo: (state: any, action) => {
    //     state.layerForInfo = action.payload;
    // },
    changeTableVisibility: (state: any, action) => {
      state.visible = action.payload;
    },
    changeReportVisibility: (state: any, action) => {
      state.reportVisible = action.payload;
    },
    setLayerFromTable: (state: any, action) => {
      state.layerFromTable = action.payload;
    },
  },
});

export const {
  changeTableVisibility,
  changeReportVisibility,
  setLayerFromTable,
} = tableSlice.actions;

export default tableSlice.reducer;
