import { configureStore } from "@reduxjs/toolkit";
import thunkMiddleware from "redux-thunk";
import { basemapSlice } from "./features/basemapSlice";
import { buttonStateSlice } from "./features/buttonStateSlice";
import { dashboardSlice } from "./features/dashboardSlice";
import { drawStateSlice } from "./features/drawStateSlice";
import { dropdownStateSlice } from "./features/dropdownButtonState";
import { dropdownResultsSlice } from "./features/dropdownResults";
import { filterSlice } from "./features/filterSlice";
import { loginSlice } from "./features/loginSlice";
import { serviceSlice } from "./features/serviceSlice";
import { sidebarSlice } from "./features/sidebarSlice";
import { threeDSlice } from "./features/threedSlice";
import { userSlice } from "./features/userSlice";
import { mapSlice } from "./features/mapSlice";
import { tableSlice } from "./features/tableSlice";
import { elasticSlice } from "./features/elasticSlice";
import { importSlice } from "./features/importSlice";
import { tileSlice } from "./features/tileSlice";
import { kknSlice } from "./features/kknSlice";
import { infoSlice } from "./features/infoSlice";
import { sharedSlice } from "./features/sharedSlice";
import { statisticSlice } from "./features/statisticSlice";
import {zoomStateSlice} from "./features/zoomStateSlice";

export const store = configureStore({
  reducer: {
    basemaps: basemapSlice.reducer,
    elastic: elasticSlice.reducer,
    dropdownResults: dropdownResultsSlice.reducer,
    dropdownState: dropdownStateSlice.reducer,
    filters: filterSlice.reducer,
    dashboard: dashboardSlice.reducer,
    sidebar: sidebarSlice.reducer,
    login: loginSlice.reducer,
    user: userSlice.reducer,
    service: serviceSlice.reducer,
    buttonState: buttonStateSlice.reducer,
    drawState: drawStateSlice.reducer,
    zoomState: zoomStateSlice.reducer,
    threeD: threeDSlice.reducer,
    maps: mapSlice.reducer,
    table: tableSlice.reducer,
    import: importSlice.reducer,
    tile: tileSlice.reducer,
    kkn: kknSlice.reducer,
    info: infoSlice.reducer,
    shared: sharedSlice.reducer,
    statistic: statisticSlice.reducer,
  },
  middleware: [thunkMiddleware],
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
