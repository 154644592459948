import "./Statistic2.scss";
import { useRef, useEffect } from "react";
import type { EChartsOption } from "echarts";
import { useAppSelector } from "../state/hooks";
import * as echarts from "echarts";
import AccidentMain from "./AccidentMain";

const CrossingAndJunction = () => {
  const barChart = useRef<HTMLDivElement>(null);
  const crossingChart = useRef<HTMLDivElement>(null);
  const searchResults = useAppSelector((state) => state.elastic.value);

  useEffect(() => {
    var chartDom = document.getElementById("bar-chart")!;
    var myChart = echarts.init(chartDom);
    var option: EChartsOption;

    option = {
      height: "75%",
      title: {
        text: "KAVŞAK DURUM",
        left: "center",
        top: "5%",
        textStyle: {
          color: "#1A4158",
          fontWeight: "bold",
          fontSize: 18,
        },
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
      },
      grid: {
        right: 40,
        width: "65%",
        backgroundColor: "red",
      },
      xAxis: {
        max: "dataMax",
      },
      yAxis: {
        type: "category",
        data: [
          "KÖPRÜLÜ KAVŞAK",
          "HEMZEMİN GEÇİT",
          "DÖNEL KAVŞAK",
          "KAVŞAK YOK",
          "ÜÇ YÖNLÜ (Y)",
          "DİĞER KAVŞAK ÇEŞİDİ",
          "BEŞ VEYA DAHA FAZLA YÖNLÜ",
          "ÜÇ YÖNLÜ (T)",
          "DÖRT YÖNLÜ",
        ],
      },
      series: [
        {
          data: [
            searchResults.filter((el: any) => el.kavsakid === "KÖPRÜLÜ KAVŞAK")
              .length > 0
              ? searchResults.filter(
                (el: any) => el.kavsakid === "KÖPRÜLÜ KAVŞAK"
              ).length
              : "",
            searchResults.filter((el: any) => el.kavsakid === "HEMZEMİN GEÇİT")
              .length > 0
              ? searchResults.filter(
                (el: any) => el.kavsakid === "HEMZEMİN GEÇİT"
              ).length
              : "",
            searchResults.filter((el: any) => el.kavsakid === "DÖNEL KAVŞAK")
              .length > 0
              ? searchResults.filter(
                (el: any) => el.kavsakid === "DÖNEL KAVŞAK"
              ).length
              : "",
            searchResults.filter((el: any) => el.kavsakid === "KAVŞAK YOK")
              .length > 0
              ? searchResults.filter((el: any) => el.kavsakid === "KAVŞAK YOK")
                .length
              : "",
            searchResults.filter((el: any) => el.kavsakid === "ÜÇ YÖNLÜ (Y)")
              .length > 0
              ? searchResults.filter(
                (el: any) => el.kavsakid === "ÜÇ YÖNLÜ (Y)"
              ).length
              : "",
            searchResults.filter(
              (el: any) => el.kavsakid === "DİĞER KAVŞAK ÇEŞİDİ"
            ).length > 0
              ? searchResults.filter(
                (el: any) => el.kavsakid === "DİĞER KAVŞAK ÇEŞİDİ"
              ).length
              : "",
            searchResults.filter(
              (el: any) => el.kavsakid === "BEŞ VEYA DAHA FAZLA YÖNLÜ"
            ).length > 0
              ? searchResults.filter(
                (el: any) => el.kavsakid === "BEŞ VEYA DAHA FAZLA YÖNLÜ"
              ).length
              : "",
            searchResults.filter((el: any) => el.kavsakid === "ÜÇ YÖNLÜ (T)")
              .length > 0
              ? searchResults.filter(
                (el: any) => el.kavsakid === "ÜÇ YÖNLÜ (T)"
              ).length
              : "",
            searchResults.filter((el: any) => el.kavsakid === "DÖRT YÖNLÜ")
              .length > 0
              ? searchResults.filter((el: any) => el.kavsakid === "DÖRT YÖNLÜ")
                .length
              : "",
          ],
          label: {
            show: true,
            position: "right",
            fontWeight: "bold",
          },
          type: "bar",
          showBackground: true,
          backgroundStyle: {
            color: "rgba(180, 180, 180, 0.6)",
          },
        },
      ],
    };
    myChart.setOption(option);
    myChart?.resize();
  }, [searchResults]);

  useEffect(() => {
    var firstChartDom = document.getElementById("crossing-chart")!;
    var chart1 = echarts.init(firstChartDom);
    var option1: EChartsOption;

    option1 = {
      height: "50%",
      tooltip: {
        trigger: "item",
      },
      title: {
        text: "Geçit Durum",
        left: "center",
        // top: '5%',
        textStyle: {
          color: "#1A4158",
          fontWeight: "bold",
          fontSize: 18,
        },
      },
      series: [
        {
          type: "pie",
          top: "35%",
          radius: [35, 70],
          itemStyle: {
            borderColor: "#fff",
            borderWidth: 1,
          },
          label: {
            fontSize: "14px",
            minMargin: 5,
          },
          data: [
            {
              value:
                searchResults.filter((el: any) => el.gecitid === "GEÇİT YOK")
                  .length > 0
                  ? searchResults.filter(
                    (el: any) => el.gecitid === "GEÇİT YOK"
                  ).length
                  : "",
              name: "Geçit Yok",
            },
            {
              value:
                searchResults.filter((el: any) => el.gecitid === "YAYA GEÇİDİ")
                  .length > 0
                  ? searchResults.filter(
                    (el: any) => el.gecitid === "YAYA GEÇİDİ"
                  ).length
                  : "",
              name: "Yaya Geçidi",
            },
          ],
        },
      ],
    };
    chart1.setOption(option1);

    // myChart?.resize();
  }, [searchResults]);

  return (
    <div className="CrossingAndJunction">

      <AccidentMain />

      <div style={{ width: "100%", height: "500px" }}>
        <div
          ref={barChart}
          id="bar-chart"
          className="bar-chart"
          style={{ width: "680px", height: "100%" }}
        />
      </div>
      <div style={{ width: "100%", height: "300px" }}>
        <div
          style={{
            display: "flex",
            width: "100%",
            height: "300px",
            paddingTop: "25px",
            paddingLeft: "150px",
          }}
        >
          <div
            ref={crossingChart}
            id="crossing-chart"
            className="pie-chart"
            style={{ width: "100%", height: "250px" }}
          />
        </div>
      </div>
    </div>
  );
};
export default CrossingAndJunction;
