import axios from "axios";

const { REACT_APP_BASE_URL } = process.env;

const getAnkaToken = () => {
  return localStorage.getItem("ag_token");
};

function sleep() {
  return new Promise((res) => setTimeout(res, 100));
}

async function getDashboards() {
  const config = {
    headers: { Authorization: "Bearer " + getAnkaToken() },
  };
  await sleep();
  return axios.get(REACT_APP_BASE_URL + "dashboard/updated", config);
}

async function getDashboardUID(id: number) {
  await sleep();
  const config = {
    headers: { Authorization: "Bearer " + getAnkaToken() },
  };
  const postData = {
    dashboardID: id.toString(),
    domain: "",
  };
  return axios.post(
    REACT_APP_BASE_URL + "dashboard/post_embed",
    postData,
    config
  );
}

async function getGuestTokenForDashboard(id: number, group_id: number) {
  await sleep();
  const config = {
    headers: { Authorization: "Bearer " + getAnkaToken() },
  };
  const postData = {
    resources: [
      {
        id: id.toString(),
        type: "dashboard",
      },
    ],
    rls: [
      {
        dataset: 30,
        clause: `zoneid in (select zone_id from ag_perm_zone_group where group_id = ${group_id})`,
      },
    ],
    user: {
      first_name: "guest",
      last_name: "guest",
      username: "guest",
    },
  };
  return axios.post(REACT_APP_BASE_URL + "auth/guest", postData, config);
}

async function dashboardRedirect() {
  await sleep();
  const config = {
    headers: { Authorization: "Bearer " + getAnkaToken() },
  };
  return axios.get(REACT_APP_BASE_URL + "auth/home", config);
}

export const DashboardRestApi = {
  getDashboards,
  getDashboardUID,
  getGuestTokenForDashboard,
  dashboardRedirect,
};
