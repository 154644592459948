import "./Statistic2.scss";
import { useEffect, useRef, useState } from "react";
import { useAppSelector } from "../state/hooks";
import { Button } from "primereact/button";
// import html2canvas from "html2canvas";
import { Dropdown } from "primereact/dropdown";
import { useDispatch } from "react-redux";
import { KKNRestApi } from "../../utils/restapi/kkn";
import {
  setAccidentFeatures,
  setGotoKKN,
  setSelectedKKNGroupFeature,
  setSelectedKKNLayerGroup,
  setShowExportImage,
  setShowExportPdf,
  setStatisticName,
} from "../state/features/statisticSlice";
import StatisticsByProvince from "./StatisticsByProvince";
import { RoleRestApi } from "../../utils/restapi/role";
import type { EChartsOption } from "echarts";
import * as echarts from "echarts";
// import jsPDF from "jspdf";
import RoadCondition from "./RoadCondition";
import RoadCondition2 from "./RoadCondition2";
import RoadCondition3 from "./RoadCondition3";
import CrossingAndJunction from "./CrossingAndJunction";
import MomentOfAccident from "./MomentOfAccident";
import MomentOfAccident2 from "./MomentOfAccident2";
import General from "./General";
import { setLayerForGoToLayer } from "../state/features/sidebarSlice";
import { UtilRestApi } from "../../utils/restapi/util";
import { setisKKNActiveButton } from "../state/features/buttonStateSlice";
import { Paginator } from "primereact/paginator";
import { LayerRestApi } from "../../utils/restapi/layer";

const pageArr = [
  { id: 1, label: "Genel" },
  { id: 2, label: "Yol Bilgisi-1" },
  { id: 3, label: "Yol Bilgisi-2" },
  { id: 4, label: "Yol Bilgisi-3" },
  { id: 5, label: "Geçit ve Kavşak Bilgisi" },
  { id: 6, label: "Kaza Anı Bilgisi-1" },
  { id: 7, label: "Kaza Anı Bilgisi-2" }
];


const Statistic2 = () => {
  const dispatch = useDispatch();
  // const pdf = new jsPDF();
  const firstChart = useRef<HTMLDivElement>(null);
  const secondChart = useRef<HTMLDivElement>(null);
  const [selectedKKNGroup, setSelectedKKNGroup] = useState<any>(null);
  const [kyybFeatures, setKYYBFeatures] = useState<any>([]);
  const [kknFeatures, setKKNFeatures] = useState<any>([]);
  const [page, setPage] = useState<any>({ id: 1, label: "Genel" });
  const [desc, setDesc] = useState<any>("");
  const [user, setUser] = useState<any>("");
  // const [kknOption, setkknOption] = useState<any>(false);
  // const [buttonActive, setButtonActive] = useState<any>(false);
  const [kknGroupOptions, setkknGroupOptions] = useState<any>([]);
  const zoom = useAppSelector((state) => state.maps.zoom);
  const selectedLayerGroup = useAppSelector(
    (state) => state.statistic.kknLayerGroup
  );
  // const statistics = document.getElementById("Statistic2") as HTMLElement;
  // const map = document.getElementById("map") as HTMLElement;

  // const map = useContext(MapContext)
  const searchResults = useAppSelector((state) => state.elastic.value);
  // const services = useAppSelector((state) => state.service.services);
  const extent = useAppSelector((state) => state.maps.extent);
  const pageId = page.id;
  const [first, setFirst] = useState(0);
  const activeButtonTypeRight = useAppSelector(
    (state) => state.buttonState.activeButtonTypeRight
  );
  const paginatorTemplate = {
    layout: 'FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink',

    'CurrentPageReport': (options: any) => {
      options.currentPage = `${page.label}`
      return options.currentPage;
    },

    'FirstPageLink': (options: any) => {
      return (
        <button type="button" className={options.className}
          style={{ marginRight: "6px" }}
          onClick={options.onClick} disabled={options.disabled}>
          <i className="pi pi-angle-double-left" style={{ color: '#286688', fontSize: "1.6rem", display: "flex", alignItems: "center" }}></i>
        </button>
      )
    },

    'LastPageLink': (options: any) => {
      return (
        <button type="button" className={options.className} style={{ marginLeft: "6px" }} onClick={options.onClick} disabled={options.disabled}>
          <i className="pi pi-angle-double-right" style={{ color: '#286688', fontSize: "1.6rem", display: "flex", alignItems: "center" }}></i>
        </button>
      )
    },

    'PrevPageLink': (options: any) => {
      return (
        <button type="button" className={options.className}
          style={{ marginRight: "7px" }}
          onClick={options.onClick} disabled={options.disabled}>
          <i className="pi pi-angle-left" style={{ color: '#286688', fontSize: "1.6rem", display: "flex", alignItems: "center" }}></i>
        </button>
      )
    },

    'NextPageLink': (options: any) => {
      return (
        <button type="button" className={options.className} style={{ marginLeft: "7px" }} onClick={options.onClick} disabled={options.disabled}>
          <i className="pi pi-angle-right" style={{ color: '#286688', fontSize: "1.6rem", display: "flex", alignItems: "center" }}></i>
        </button>
      )
    }
  }


  useEffect(() => {
    UtilRestApi.geoserverWfsReq(
      `version=1.0.0&bbox=${extent.join(
        ","
      )},EPSG:3857&request=GetFeature&typeName=trafik%3Aist_yillar2&outputFormat=application%2Fjson`
    ).then((res) => {
      if (res) {
        dispatch(setAccidentFeatures(res.data.features));
      }
    });
  }, [extent]);

  useEffect(() => {
    if (selectedKKNGroup && selectedLayerGroup.length > 0) {
      RoleRestApi.getUsers().then((res) => {
        if (res) {
          const findedUser = res.data.find(
            (user: any) => user.id === selectedLayerGroup[0].created_by
          );
          setUser(findedUser);
        }
      });
    }
  }, [selectedKKNGroup, selectedLayerGroup]);

  // const downloadPng = () => {
  //   const buttons = document.querySelectorAll('.download-group button');
  //   const dropDownButtons = document.querySelectorAll('.Statistic2 .subgroup .selection-group .select-kkn-group');

  //   for (let i = 0; i < buttons.length; i++) {
  //     const button = buttons[i];
  //     button.classList.add("remove-shadow");
  //   }

  //   for (let i = 0; i < dropDownButtons.length; i++) {
  //     const dropDownButton = dropDownButtons[i];
  //     dropDownButton.classList.add("remove-shadow");
  //   }


  //   const statistics = document.getElementById("Statistic2")!;


  //   html2canvas(statistics).then((canvas: any) => {
  //     var link = document.createElement("a");
  //     link.download = "sehir_istatistikleri.png";
  //     link.href = canvas.toDataURL();
  //     link.click();
  //   });

  //   for (let i = 0; i < buttons.length; i++) {
  //     const button = buttons[i];
  //     button.classList.remove("remove-shadow");
  //   }

  //   for (let i = 0; i < dropDownButtons.length; i++) {
  //     const dropDownButton = dropDownButtons[i];
  //     dropDownButton.classList.remove("remove-shadow");
  //   }

  // };



  // const downloadAll = () => {
  //   const buttons = document.querySelectorAll('.download-group button');
  //   const dropDownButton = document.querySelector('.Statistic2 .subgroup .selection-group .select-kkn-group');
  //   const toolbar = document.querySelector('.Toolbar');
  //   const basemap = document.querySelector('.basemaps');
  //   const logo = document.querySelector('.logo');

  //   for (let i = 0; i < buttons.length; i++) {
  //     const button = buttons[i];
  //     button.classList.add("remove-shadow");
  //   }

  //   logo?.classList.add("remove-bottom-left-icons");
  //   basemap?.classList.add("remove-bottom-left-icons");
  //   toolbar?.classList.add("remove-toolbar");
  //   dropDownButton?.classList.add("remove-shadow");

  //   const app = document.getElementById("App") as HTMLElement;

  //   html2canvas(app).then((canvas: any) => {
  //     var link = document.createElement("a");
  //     link.download = "sehir_istatistikleri.png";
  //     link.href = canvas.toDataURL();
  //     link.click();
  //   });


  //   for (let i = 0; i < buttons.length; i++) {
  //     const button = buttons[i];
  //     button.classList.remove("remove-shadow");
  //   }


  //   logo?.classList.remove("remove-bottom-left-icons");
  //   basemap?.classList.remove("remove-bottom-left-icons");
  //   toolbar?.classList.remove("remove-toolbar");
  //   dropDownButton?.classList.remove("remove-shadow");

  // };




  // const downloadMap = () => {
  //   let page: any;
  //   page = map;

  //   html2canvas(page).then((canvas: any) => {
  //     var link = document.createElement("a");
  //     link.download = "sehir_istatistikleri.png";
  //     link.href = canvas.toDataURL();
  //     link.click();
  //   });
  // };

  // const downloadPdf = () => {
  //   const statistics = document.getElementById("Statistic2")
  //   let page: any;
  //   page = statistics;

  //   html2canvas(page).then((canvas: any) => {
  //     pdf.addImage(canvas, "PNG", 10, 10, 180, 200);
  //     pdf.save("sehir_istatistikleri.pdf");
  //   });
  // };


  const exportImage = () => {
    dispatch(setShowExportImage(true));
  }

  const exportPdf = () => {
    dispatch(setShowExportPdf(true));
  }

  useEffect(() => {
    if (selectedKKNGroup) {
      var firstChartDom = document.getElementById("first-chart")!;
      var secondChartDom = document.getElementById("second-chart")!;
      var chart1 = echarts.init(firstChartDom);
      var chart2 = echarts.init(secondChartDom);
      var option1: EChartsOption;
      var option2: EChartsOption;

      option1 = {
        height: "50%",
        tooltip: {
          trigger: "item",
        },
        title: {
          top: "10%",
          text: "Kaza Kara Noktası",
          left: "center",
          textStyle: {
            color: "#1A4158",
            fontWeight: "bold",
            fontSize: 18,
          },
        },
        color: ["#2b83ba", "#abdda4", "#ffffbf", "#fdae61", "#d7191c"],
        series: [
          {
            type: "pie",
            top: "40%",
            radius: [35, 70],
            itemStyle: {
              borderColor: "#fff",
              borderWidth: 1,
            },
            label: {
              fontSize: "14px",
              minMargin: 5,
            },
            data: [
              {
                value:
                  kknFeatures.filter((el: any) => el.indeks === 0).length > 0
                    ? kknFeatures.filter((el: any) => el.indeks === 0).length
                    : "",
                name: "1. Dereceden",
              },
              {
                value:
                  kknFeatures.filter((el: any) => el.indeks === 1).length > 0
                    ? kknFeatures.filter((el: any) => el.indeks === 1).length
                    : "",
                name: "2. Dereceden",
              },
              {
                value:
                  kknFeatures.filter((el: any) => el.indeks === 2).length > 0
                    ? kknFeatures.filter((el: any) => el.indeks === 2).length
                    : "",
                name: "3. Dereceden",
              },
              {
                value:
                  kknFeatures.filter((el: any) => el.indeks === 3).length > 0
                    ? kknFeatures.filter((el: any) => el.indeks === 3).length
                    : "",
                name: "4. Dereceden",
              },
              {
                value:
                  kknFeatures.filter((el: any) => el.indeks === 4).length > 0
                    ? kknFeatures.filter((el: any) => el.indeks === 4).length
                    : "",
                name: "5. Dereceden",
              },
            ],
          },
        ],
      };
      option2 = {
        height: "50%",
        tooltip: {
          trigger: "item",
        },
        title: {
          top: "10%",
          text: "Kaza Yüksek Yoğunluk Bölgesi",
          left: "center",
          textStyle: {
            color: "#1A4158",
            fontWeight: "bold",
            fontSize: 18,
          },
        },
        color: ["#2b83ba", "#abdda4", "#ffffbf", "#fdae61", "#d7191c"],
        series: [
          {
            type: "pie",
            top: "40%",
            radius: [35, 70],
            itemStyle: {
              borderColor: "#fff",
              borderWidth: 1,
            },
            label: {
              fontSize: "14px",
              minMargin: 5,
            },
            data: [
              {
                value:
                  kyybFeatures.filter((el: any) => el.indeks === 0).length > 0
                    ? kyybFeatures.filter((el: any) => el.indeks === 0).length
                    : "",
                name: "1. Dereceden",
              },
              {
                value:
                  kyybFeatures.filter((el: any) => el.indeks === 1).length > 0
                    ? kyybFeatures.filter((el: any) => el.indeks === 1).length
                    : "",
                name: "2. Dereceden",
              },
              {
                value:
                  kyybFeatures.filter((el: any) => el.indeks === 2).length > 0
                    ? kyybFeatures.filter((el: any) => el.indeks === 2).length
                    : "",
                name: "3. Dereceden",
              },
              {
                value:
                  kyybFeatures.filter((el: any) => el.indeks === 3).length > 0
                    ? kyybFeatures.filter((el: any) => el.indeks === 3).length
                    : "",
                name: "4. Dereceden",
              },
              {
                value:
                  kyybFeatures.filter((el: any) => el.indeks === 4).length > 0
                    ? kyybFeatures.filter((el: any) => el.indeks === 4).length
                    : "",
                name: "5. Dereceden",
              },
            ],
          },
        ],
      };
      chart1.setOption(option1);
      chart2.setOption(option2);
      // myChart?.resize();
    }
  }, [searchResults, selectedKKNGroup, kyybFeatures, kknFeatures]);

  useEffect(() => {
    if (selectedLayerGroup && selectedLayerGroup.length > 0) {
      switch (selectedLayerGroup[0]?.yontem) {
        case "Kaza Sayısı Yöntemi":
          setDesc(
            "Kullanıcı tarafından belirlenen zaman aralığı, eşik değer ve kesim uzunluğu değişkenlerinde sadece ölümlü kaza sayılarının dikkate alındığı yöntemdir."
          );
          break;
        case "Kaza Oran Yöntemi":
          setDesc(
            "Kullanıcı tarafından belirlenen zaman aralığı, eşik değer ve kesim uzunluğu değişkenlerinde ölümlü kaza sayılarının kesim uzunlukları ve yıllık ortalama günlük trafik ile oranlarının dikkate alındığı yöntemdir. Kaza Sayısı ve Kaza Frekansı yöntemlerinin aksine bölgesel dinamikleri trafik hacmi bazında dikkate alabilir."
          );
          break;
        case "Kaza Frekans Yöntemi":
          setDesc(
            "Kullanıcı tarafından belirlenen zaman aralığı, eşik değer ve kesim uzunluğu değişkenlerinde ölümlü kaza sayılarının kesim uzunlukları ile oranlarının dikkate alındığı yöntemdir. Tüm kesimlerin 1 km olması durumunda yöntem kaza sayısı yöntemine dönüşür. Ancak eşik değerler değişebilir."
          );

          break;
        case "Kaza Ağırlık Yöntemi":
          setDesc(
            "Kullanıcı tarafından belirlenen zaman aralığı, eşik değer ve kesim uzunluğu değişkenlerinde ölümlü/yaralanmalı/maddi kaza sayılarının dikkate alındığı yöntemdir. Her kaza türü kendi ağırlıkları ölçüsünde sonuca etki eder."
          );

          break;
        case "Kaza Kalite Oran Yöntemi":
          setDesc(
            "Kullanıcı tarafından belirlenen zaman aralığı, eşik değer ve kesim uzunluğu değişkenlerinde kesim uzunlukları, trafik hacmi ve kaza türlerinin dikkate alındığı yöntemdir. Diğer yöntemlerdeki parametrelerin tümünü kullanır."
          );

          break;
        case "Yapay Zeka İle Kaza Kara Noktası Belirleme":
          setDesc(
            "Kullanıcı tarafından belirlenen arama yarıçapı ve gruplama sınırı değişkenlerinde ağ taraması sağlayan denetimsiz makine öğrenmesi kümeleme yöntemidir. Diğer yöntemlerin aksine kesimler algoritma tarafından belirlenir."
          );

          break;
        default:
          setDesc("");

          break;
      }
    }
  }, [selectedLayerGroup]);

  useEffect(() => {

    let filteredKKN: any = []

    if (activeButtonTypeRight === "STATISTIC") {
      LayerRestApi.getLayerGroups().then((a: any) => {
        filteredKKN = a.data.filter((x: any) =>
          x.name.startsWith("group_kkn") && x.layers.length > 0
        )
        setkknGroupOptions(filteredKKN);
      })
    }

    // if(activeButtonTypeRight==="STATISTIC"){
    //   if(kknOption===true){

    //     LayerRestApi.getLayerGroups().then((a:any)=>{
    //       filteredKKN = a.data.filter((x:any)=>
    //         x.name.startsWith("group_kkn") && x.layers.length > 0
    //       )
    //       setkknGroupOptions(filteredKKN);
    //     })
    //    }
    // }

    // const kknLayerGroups = services.filter((x: any) =>
    //   x.name.startsWith("group_kkn")
    // );
    // setkknGroupOptions(kknLayerGroups);
  }, [activeButtonTypeRight, /*kknOption*/]);

  useEffect(() => {
    if (selectedKKNGroup) {
      getKKNLogsRes();
    }
    if (!selectedKKNGroup) {
      dispatch(setSelectedKKNLayerGroup([]));
    }
  }, [selectedKKNGroup]);

  useEffect(() => {
    if (selectedKKNGroup) {
      getKKNLogsRes();
    }
  }, [selectedKKNGroup]);

  const getKKNLogsRes = async () => {
    await KKNRestApi.getKKNLogs().then((res: any) => {
      const findedLayerGroup = res.data.find(
        (group: any) => group.status === selectedKKNGroup.name
      );
      dispatch(setSelectedKKNLayerGroup([findedLayerGroup]));
    });

    const findedKKN = selectedKKNGroup.layers.find((layer: any) =>
      layer.layer.includes("kkn")
    );
    const findedKYYB = selectedKKNGroup.layers.find((layer: any) =>
      layer.layer.includes("kyyb")
    );

    const kyybFeatures = await UtilRestApi.geoserverWfsReq(
      `&version=1.0.0&request=GetFeature&typeName=${findedKYYB.layer}&outputFormat=application%2Fjson`
    );

    const kknFeatures = await UtilRestApi.geoserverWfsReq(
      `&version=1.0.0&request=GetFeature&typeName=${findedKKN.layer}&outputFormat=application%2Fjson`
    );

    const kyyb = kyybFeatures.data.features.map(
      (feature: any) => feature.properties
    );
    const kkn = kknFeatures.data.features.map(
      (feature: any) => feature.properties
    );
    setKYYBFeatures(kyyb);
    setKKNFeatures(kkn);
  };

  const goToLayer = () => {
    const layer = selectedKKNGroup.layers.find((layer: any) =>
      layer.layer.includes("kkn")
    );
    dispatch(setLayerForGoToLayer(layer));
  };

  useEffect(() => {

    if (selectedKKNGroup != null) {
      goToLayer()
      dispatch(setGotoKKN(selectedKKNGroup))
      dispatch(setSelectedKKNGroupFeature(selectedKKNGroup))
      dispatch(setisKKNActiveButton(true))
    }
    else {
      dispatch(setGotoKKN({}))
      dispatch(setSelectedKKNGroupFeature({}))
      dispatch(setisKKNActiveButton(false))
    }

  }, [selectedKKNGroup])


  const onPageChange = (event: any) => {
    setPage(pageArr.find((a: any) => a.id === event.page + 1))
    setFirst(event.first)
  };

  useEffect(() => {
    dispatch(setStatisticName("Statistic2"))
  }, [page])

  return (
    <div className="Statistic2" id="Statistic2">
      {searchResults.length === 0 && zoom >= 8.5 && !selectedKKNGroup && (
        <div className="warning">
          <i className="pi pi-exclamation-triangle"></i>
          <p style={{ margin: "10px 0" }}>
            Girilen Filtreler İle Harita Ekranında Gösterilecek Veri Bulunamadı.
          </p>
          <p>Lütfen Parametreleri Değiştiriniz.</p>
        </div>
      )}
      {zoom < 8.5 && !selectedKKNGroup && (
        <>
          <StatisticsByProvince />
        </>
      )}
      {zoom >= 8.5 && !selectedKKNGroup && (
        <>
          {pageId === 1 && <General />}
          {pageId === 2 && <RoadCondition />}
          {pageId === 3 && <RoadCondition2 />}
          {pageId === 4 && <RoadCondition3 />}
          {pageId === 5 && <CrossingAndJunction />}
          {pageId === 6 && <MomentOfAccident />}
          {pageId === 7 && <MomentOfAccident2 />}
        </>
      )}
      <div
        className="kkn-statistics"
        style={{ display: selectedKKNGroup ? "block" : "none" }}
      >
        {selectedKKNGroup &&
          selectedLayerGroup.map((group: any) =>

          (
            <>
              <span className="header">{group?.ad + " Bilgileri"}</span>
              <div className="first-group">
                <div className="description">
                  <div className="label">
                    <span>Algoritma Hakkında</span>
                  </div>

                  <div className="result">{desc}</div>
                </div>

                <div className="input-groups">
                  <div className="label">
                    <span>Hesaplama Yöntemi</span>
                  </div>

                  <div className="result">{group?.yontem}</div>
                </div>

                <div className="input-groups">
                  <div className="label">
                    <span>Kaza Tarih Aralığı</span>
                  </div>

                  <div className="result">{group?.start_date === null && group?.end_date === null ? "Tarih Verisi Bulunamadı" :
                    new Date(group?.start_date).toLocaleDateString("tr")
                    + " - " + new Date(group?.end_date).toLocaleDateString("tr")}
                  </div>
                </div>

                <div className="input-groups">
                  <div className="label">
                    <span>Oluşturan</span>
                  </div>

                  <div className="result">{user.username}</div>
                </div>

                <div className="input-groups">
                  <div className="label">
                    <span>Tarih</span>
                  </div>

                  <div className="result">
                    {new Date(group?.created).toLocaleDateString("tr") +
                      " " +
                      new Date(group?.created).toLocaleTimeString("tr")}
                  </div>
                </div>
              </div>
            </>
          ))}

        {selectedKKNGroup &&
          selectedLayerGroup.map((group: any) => (
            <>
              <span className="header">Parametre Bilgileri</span>
              <div className="second-group">
                {group?.bb_eps && (
                  <div className="input-groups">
                    <div className="label">
                      <span>Bölge Bütünü Adım Yarıçapı</span>
                    </div>
                    <div className="result">{group?.bb_eps}</div>
                  </div>
                )}
                {group?.bb_minp && (
                  <div className="input-groups">
                    <div className="label">
                      <span>Bölge Bütünü Kümeleme Alt Sınırı</span>
                    </div>
                    <div className="result">{group?.bb_minp}</div>
                  </div>
                )}
                {group?.bb_kesim && (
                  <div className="input-groups">
                    <div className="label">
                      <span>Bölge Bütünü Kesim</span>
                    </div>
                    <div className="result">{group?.bb_kesim}</div>
                  </div>
                )}
                {group?.pi_eps_karayol && (
                  <div className="input-groups">
                    <div className="label">
                      <span>Şehir İçi Karayol Adım Yarıçapı</span>
                    </div>
                    <div className="result">{group?.pi_eps_karayol}</div>
                  </div>
                )}
                {group?.pi_minp_karayol && (
                  <div className="input-groups">
                    <div className="label">
                      <span>Şehir İçi Karayol Kümeleme Alt Sınırı</span>
                    </div>
                    <div className="result">{group?.pi_minp_karayol}</div>
                  </div>
                )}
                {group?.pi_eps_kavsak && (
                  <div className="input-groups">
                    <div className="label">
                      <span>Şehir İçi Kavşak Adım Yarıçapı</span>
                    </div>
                    <div className="result">{group?.pi_eps_kavsak}</div>
                  </div>
                )}
                {group?.pi_minp_kavsak && (
                  <div className="input-groups">
                    <div className="label">
                      <span>Şehir İçi Kavşak Kümeleme Alt Sınırı</span>
                    </div>
                    <div className="result">{group?.pi_minp_kavsak}</div>
                  </div>
                )}
                {group?.pd_eps_karayol && (
                  <div className="input-groups">
                    <div className="label">
                      <span>Şehir Dışı Karayol Adım Yarıçapı</span>
                    </div>
                    <div className="result">{group?.pd_eps_karayol}</div>
                  </div>
                )}
                {group?.pd_eps_kavsak && (
                  <div className="input-groups">
                    <div className="label">
                      <span>Şehir Dışı Kavşak Adım Yarıçapı</span>
                    </div>
                    <div className="result">{group?.pd_eps_kavsak}</div>
                  </div>
                )}
                {group?.pd_minp_karayol && (
                  <div className="input-groups">
                    <div className="label">
                      <span>Şehir Dışı Karayol Kümeleme Alt Sınırı</span>
                    </div>
                    <div className="result">{group?.pd_minp_karayol}</div>
                  </div>
                )}
                {group?.pd_minp_kavsak && (
                  <div className="input-groups">
                    <div className="label">
                      <span>Şehir Dışı Kavşak Kümeleme Alt Sınırı</span>
                    </div>
                    <div className="result">{group?.pd_minp_kavsak}</div>
                  </div>
                )}
                {group?.pi_karayol && (
                  <div className="input-groups">
                    <div className="label">
                      <span>Şehir İçi Karayol Kesim</span>
                    </div>
                    <div className="result">{group?.pi_karayol}</div>
                  </div>
                )}
                {group?.pi_kavsak && (
                  <div className="input-groups">
                    <div className="label">
                      <span>Şehir İçi Kavşak Kesim</span>
                    </div>
                    <div className="result">{group?.pi_kavsak}</div>
                  </div>
                )}
                {group?.pd_karayol && (
                  <div className="input-groups">
                    <div className="label">
                      <span>Şehir Dışı Karayol Kesim</span>
                    </div>
                    <div className="result">{group?.pd_karayol}</div>
                  </div>
                )}
                {group?.pd_kavsak && (
                  <div className="input-groups">
                    <div className="label">
                      <span>Şehir Dışı Kavşak Kesim</span>
                    </div>
                    <div className="result">{group?.pd_kavsak}</div>
                  </div>
                )}
              </div>
            </>
          ))}

        {selectedKKNGroup && (
          <div className="third-group">
            <div
              className="fifth-row"
              style={{ display: "flex", width: "100%" }}
            >
              <div className="box">
                <span className="header">Toplam Kaza Kara Noktası Sayısı</span>
                <span className="desc">{kknFeatures.length}</span>
              </div>
              <div className="box">
                <span className="header">
                  Toplam Kaza Yüksek Yoğunluk Bölgesi Sayısı
                </span>
                <span className="desc">{kyybFeatures.length}</span>
              </div>
            </div>
            <div
              className="fourth-row"
              style={{ display: "flex", width: "100%", height: "350px" }}
            >
              <div
                ref={firstChart}
                id="first-chart"
                className="pie-chart"
                style={{ width: "50%", height: "300px" }}
              />
              <div
                ref={secondChart}
                id="second-chart"
                className="pie-chart"
                style={{ width: "50%", height: "300px" }}
              />
            </div>
          </div>
        )}
      </div>
      <div className="subgroup">
        <div className="selection-group">
          {selectedKKNGroup && (
            <Button
              className="back-button immovable-button"
              icon="pi pi-times"
              onClick={() => {
                setSelectedKKNGroup(null)
                dispatch(setisKKNActiveButton(false))
              }}
            />
          )}
          <Dropdown
            id="yontem"
            filter
            filterBy="alias"
            optionLabel="alias"
            className="select-kkn-group"
            placeholder={selectedKKNGroup === null ? "KKN Seçiniz" : selectedKKNGroup.alias}
            value={selectedKKNGroup}
            options={kknGroupOptions}
            onChange={(e) => setSelectedKKNGroup(e.value)}
          // onShow={()=>{setkknOption(true)}}
          />
          {selectedKKNGroup && (
            <Button
              className="go-to-layer-button"
              label="Katmana Git"
              onClick={goToLayer}
            />
          )}
        </div>
        {zoom >= 8.5 && !selectedKKNGroup && (
          <div className="page-button-group-list" style={{ display: "flex" }}>

            {/* <div className="page-button-group">
            <i className={buttonActive===false ? "pi pi-angle-left left-arrow" : "pi pi-angle-left left-arrow-active"} style={{  color: '#286688', fontSize:"1.5rem", display:"flex", alignItems:"center" }}></i>
            <Dropdown
              className="page-dropdown"
              optionLabel="label"
              value={page}
              options={pageArr}
              onChange={(e) => setPage(e.value)}
              onShow={()=>setButtonActive(true)}
              onHide={()=>setButtonActive(false)}
            />
            <i className={buttonActive===false ? "pi pi-angle-right right-arrow" : "pi pi-angle-right right-arrow-active"}  style={{ color: '#286688', fontSize:"1.5rem", display:"flex", alignItems:"center" }}></i>
          </div> */}

            <div className="page-button-group">
              <Paginator first={first} rows={1} totalRecords={pageArr.length} onPageChange={onPageChange} template={paginatorTemplate} style={{ fontWeight: "550", fontSize: "18px", color: "#286688" }} />
            </div>
          </div>
        )}
        <div className="download-group">
          {/* <Button onClick={()=>downloadAll()}/>
          <Button onClick={downloadMap}/> */}
          {/* <Button onClick={downloadPng} icon="pi pi-image" />
          <Button onClick={downloadPdf} icon="pi pi-file-pdf" /> */}
          <Button onClick={exportImage} className="immovable-button" icon="pi pi-image" />
          <Button onClick={exportPdf} className="immovable-button" icon="pi pi-file-pdf" />
        </div>
      </div>
    </div>
  );
};
export default Statistic2;
