import './Search.scss';
import { InputText } from 'primereact/inputtext';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../state/hooks';
import {
    selectedListVisibilty, setResults, searchListVisibilty,
    setResultTime, setResultScore,
    // setHoveredResult, 
    setSelectedResult,
    // setClickedFeatureId, 
    setQuickSearchValue, setLimit, setAllFiltersClear
} from '../state/features/elasticSlice';
import { ActiveButtonState } from '../shared/button-state/ButtonState';
import { setActiveButtonType } from '../state/features/buttonStateSlice';
import { InputSwitch } from 'primereact/inputswitch';
import { CitiesRestApi } from '../../utils/restapi/getCities';
import { ListBox } from 'primereact/listbox';
import { get, transformExtent } from 'ol/proj';
import { boundingExtent } from 'ol/extent';
import { setFitExtent } from '../state/features/mapSlice';

export const Search = () => {
    // const padding = {
    //     padding: '10px',
    // }
    // const padding2 = {
    //     padding: '7px 13px',
    // }

    // const alignment = {
    //     display: 'flex',
    //     alignItems: 'center',
    //     fontWeight: '500',
    //     color: '#495057 !important'
    // }
    // const line = {
    //     margin: '0px',
    //     height: '1px',
    //     backgroundColor: 'rgb(175 175 175 / 53%)',
    //     border: 'none'
    // }

    // const underline = {
    //     color: '#007BFF !important',
    //     textDecoration: 'underline',
    // }

    const dispatch = useDispatch();
    const [selectedItem, setSelectedItem] = useState<any>('');
    // const [detailsData, setDetailsData] = useState<any>(null);
    // const [searchResultsArray, setSearchResultsArray] = useState([])
    // const [extentedFeatureNumber, setExtentedFeatureNumber] = useState(null)
    const allFiltersClear = useAppSelector(state => state.elastic.allFiltersClear);
    const selectedListBoxVisibilty = useAppSelector(state => state.elastic.selectedListVisibilty);
    // const extentedResult = useAppSelector(state => state.elastic.extentedValue);
    const clickedFeatureId = useAppSelector(state => state.elastic.clickedFeatureId);
    const searchResults = useAppSelector(state => state.elastic.value);
    const [checked, setChecked] = useState(false);
    const [selectedOsm, setSelectedOsm] = useState<any>(null);
    const [osmOptions, setOsmOptions] = useState([]);
    // const selectedCity = useAppSelector(state => state.elastic.city);
    // const selectedTown = useAppSelector(state => state.elastic.town);
    // const selectedNeighborhood = useAppSelector(state => state.elastic.neighborhood);
    // const type = useAppSelector(state => state.filters.type);
    // const category = useAppSelector(state => state.filters.category);
    // const subcategory = useAppSelector(state => state.filters.subcategory);
    // const time = useAppSelector(state => state.elastic.resultTime)
    // const score = useAppSelector(state => state.elastic.resultScore)
    // const limit = useAppSelector(state => state.elastic.limit)
    // const activeButtonType = useAppSelector((state) => state.buttonState.activeButtonType)

    // useEffect(() => {
    //     const filteredArray: any = [];
    //     extentedResult.find((extentedRes: any) => {
    //         searchResults.find((res: any) => {
    //             if (res.id === extentedRes.id) {
    //                 filteredArray.push(res);
    //             }
    //         })
    //     })

    //     // setSearchResultsArray(filteredArray);
    //     // if (filteredArray.length > 0) {
    //     //     setExtentedFeatureNumber(filteredArray.length)
    //     // } else {
    //     //     setExtentedFeatureNumber(null)

    //     // }
    // }, [extentedResult])

    useEffect(() => {
        if (selectedItem && !checked) {
            getResults();
        }
        if (selectedItem && checked) {
            getStreet();
        }
        else {
            dispatch(setResults([]))
            setOsmOptions([])
        }
    }, [selectedItem, checked]);

    useEffect(() => {
        if (clickedFeatureId) {
            const clickedItem = searchResults.find((res: any) => res.id === clickedFeatureId)
            const item = {
                value: clickedItem
            }
            onSelect(item)
        }
    }, [clickedFeatureId])

    useEffect(() => {
        if (allFiltersClear) {
            setSelectedItem('')
            dispatch(setAllFiltersClear(false))
        }
    }, [allFiltersClear])

    useEffect(() => {
        if (selectedOsm && selectedOsm.osm_id) {
            let bottom_left = [selectedOsm.boundingbox[2], selectedOsm.boundingbox[1]];
            let top_right = [selectedOsm.boundingbox[3], selectedOsm.boundingbox[0]];
            var boundingExt: any = boundingExtent([bottom_left, top_right]);
            const extent = transformExtent(boundingExt, get('EPSG:4326') as any, get('EPSG:3857') as any)
            dispatch(setFitExtent(extent));
        }
    }, [selectedOsm])

    const getResults = () => {
        if (selectedItem.length > 2 && !checked) {
            dispatch(setQuickSearchValue(selectedItem))
            // setSearchResultsArray(searchResults)
            dispatch(searchListVisibilty(true))
            dispatch(setLimit(6000))
        }
    }

    const getStreet = () => {
        if (selectedItem.length > 2) {
            CitiesRestApi.getStreet(selectedItem).then(res => {
                setOsmOptions(res.data)
            }).catch((err: any) => {
                console.log(err);
            })
        }
    }

    // const onMouseHover = (itemId: any) => {
    //     const hoveredResult = searchResults.find((item: any) => item.id === itemId);
    //     dispatch(setHoveredResult({ result: hoveredResult, show: true }))
    // }

    // const onMouseLeave = (itemId: any) => {
    //     const hoveredResult = searchResults.find((item: any) => item.id === itemId);
    //     dispatch(setHoveredResult({ result: hoveredResult, show: false }))
    // }

    // const itemTemplate = (item: any) => {
    //     return <div className='item-template' onMouseEnter={() => onMouseHover(item.id)} onMouseLeave={() => onMouseLeave(item.id)}>
    //         <div style={{ padding: '7px 25px', display: 'flex', justifyContent: 'space-between', fontWeight: '500' }}>
    //             <span style={{ color: '#2D788B', whiteSpace: 'initial' }}>#&emsp;{item.ogc_fid}</span>
    //             <span style={{ color: '#2D788B', whiteSpace: 'initial' }}>{`${new Date(item.kazatarihi).toLocaleDateString('tr')} ${new Date(item.kazatarihi).toLocaleTimeString('tr').slice(0, -3)}`}</span>
    //         </div>
    //         <hr style={line} />
    //         {/* {
    //             item.kaza_siddet && <div style={padding2}>
    //                 <span style={alignment}>
    //                     <span style={{ color: '#2D788B', whiteSpace: 'initial', textTransform: 'capitalize', display: 'flex', alignItems: 'center' }}>
    //                         {(function () {
    //                             switch (item.kaza_siddet) {
    //                                 case 1:
    //                                     return <><div className='icon-container'>
    //                                         <img className='category-icon' src={`icons/olumlukaza.svg`} alt='empty' />
    //                                     </div><span>Ölümlü Kaza</span></>;
    //                                 case 2:
    //                                     return <><div className='icon-container'>
    //                                         <img className='category-icon' src={`icons/yaralanmalikaza.svg`} alt='empty' />
    //                                     </div><span>Yaralanmalı Kaza</span></>;
    //                                 case 3:
    //                                     return <><div className='icon-container'>
    //                                         <img className='category-icon' src={`icons/maddihasarlikaza.svg`} alt='empty' />
    //                                     </div><span>Maddi Hasarlı Kaza</span></>;
    //                                 default:
    //                                     return <><div className='icon-container'>
    //                                         <img className='category-icon' src={`icons/?.svg`} alt='empty' />
    //                                     </div><span>Bilinmeyen Tür Kaza</span></>;
    //                             }
    //                         })()}
    //                     </span>
    //                 </span>
    //             </div>
    //         } */}
    //     </div >
    // }

    // const selectedItemTemplate = (item: any) => {
    //     return <div className='selected-item-template'>
    //         <div className='header'>
    //             <i className='pi pi-angle-left' onClick={goBack} ></i>
    //             <div><strong style={{ fontSize: '16px' }} >{item.il}</strong></div>
    //         </div>
    //         <div className='tags'>
    //             <span>
    //                 <a ><p>{item.il}</p></a>
    //             </span>
    //         </div>
    //         <br />
    //         <br />
    //         <div className='detail'>
    //             {item.ilcead && <div style={padding2}>
    //                 <span style={alignment}>
    //                     <div className='icon-container'>
    //                         <img src='icons/telephone.svg' alt='tel-icon' />
    //                     </div>
    //                     <a href={'tel:' + item.telefon} target="blank" style={{ textDecoration: 'none', color: '#495057', wordBreak: 'break-all' }}>
    //                         {item.telefon}
    //                     </a>
    //                 </span>
    //             </div>}
    //         </div>
    //     </div>
    // }

    const onSelect = (e: any) => {
        // setDetailsData([e.value])
        dispatch(setSelectedResult(e.value));
        dispatch(selectedListVisibilty(true))
        dispatch(searchListVisibilty(false))
    }
    // const goBack = () => {
    //     dispatch(searchListVisibilty(true))
    //     dispatch(setSelectedResult({}));
    //     // setDetailsData(null)
    //     setSelectedItem('');
    //     dispatch(setClickedFeatureId(null));
    //     dispatch(selectedListVisibilty(false))
    //     setSelectedItem(selectedItem);
    //     dispatch(setResults(searchResultsArray))
    // }
    const onClose = () => {
        dispatch(setQuickSearchValue(''))
        dispatch(searchListVisibilty(false))
        dispatch(selectedListVisibilty(false))
        setSelectedItem('');
        // setDetailsData(null)
        dispatch(setResultScore(0))
        dispatch(setResultTime(0))
        dispatch(setResults([]));
    }

    const onInputChange = (e: any) => {
        setSelectedItem(e.target.value);
        // setDetailsData(null)
        if (e.target.value) {
            // dispatch(searchListVisibilty(true))
        } else {
            onClose()
            dispatch(setQuickSearchValue(''))
        }
        if (selectedListBoxVisibilty) {
            dispatch(selectedListVisibilty(false))
            // setDetailsData(null)
        }
    }

    return <div className='Search'>
        <div className="search-container">
            <InputText value={selectedItem} onChange={(e) => onInputChange(e)} placeholder={checked ? 'OSM Arama' : 'Genel Arama'} onFocus={() => dispatch(setActiveButtonType(ActiveButtonState.SEARCH))
            } />
            <InputSwitch className='switch' checked={checked} onChange={(e) => setChecked(e.value)} tooltip={checked ? 'OSM Arama Aktif' : 'OSM Arama Pasif'} tooltipOptions={{ position: 'bottom' }} />
            {selectedItem && <i className='pi pi-times close-button' onClick={onClose} ></i>}
            {!selectedItem && <i className='pi pi-search close-button' onClick={onClose} ></i>}

        </div>
        {osmOptions && osmOptions.length > 0 && <ListBox value={selectedOsm} options={osmOptions} onChange={(e) => setSelectedOsm(e.value)} optionLabel="display_name" style={{ width: '15rem' }} />}
        {/* {time !== 0 && selectedItem.length > 2 && activeButtonType !== ActiveButtonState.SIDEBAR && <div className='result-area'>
            <div className='result-info'>
                <img src="icons/info.svg" alt="info" style={{ paddingRight: '5px' }} />
                <div className='text'>{extentedFeatureNumber ? extentedFeatureNumber : score} sonuç {time} saniyede listelendi</div>
            </div>
        </div>} */}
        {/* {time !== 0 && searchListBoxVisibilty && selectedItem.length > 2 && activeButtonType !== ActiveButtonState.SIDEBAR && <ListBox options={searchResultsArray.length > 1 ? searchResultsArray : searchResults} itemTemplate={itemTemplate} optionLabel='name' onChange={(e) => onSelect(e)} />}
        {selectedListBoxVisibilty && searchResults.length > 0 && selectedItem.length > 2 && activeButtonType !== ActiveButtonState.SIDEBAR && <ListBox options={detailsData} itemTemplate={selectedItemTemplate} />} */}
    </div>
}; 
