import { InputText } from "primereact/inputtext"
import { useAppSelector } from "../state/hooks";

export const RoadCondition = () => {
    const selectedFeature = useAppSelector(state => state.info.selectedFeature);

    return (
        <div className='container'>
            <div className="left-group">

                <div className='input-group'>
                    <label htmlFor="name">Yol Yüzeyi</label>
                    <InputText id="name" value={selectedFeature[0].yolyuzey || ''} />
                </div>
                <div className='input-group'>
                    <label htmlFor="name">Yol Tipi</label>
                    <InputText id="name" value={selectedFeature[0].yoltipi || ''} />
                </div>
                <div className='input-group'>
                    <label htmlFor="name">Aydınlatma</label>
                    <InputText id="name" value={selectedFeature[0].aydinlatmaid || ''} />
                </div>
                <div className='input-group'>
                    <label htmlFor="name">Emniyet Şeridi</label>
                    <InputText id="name" value={selectedFeature[0].emniyetseridibanketid || ''} />
                </div>
                <div className='input-group'>
                    <label htmlFor="name">Geçit</label>
                    <InputText id="name" value={selectedFeature[0].gecitid || ''} />
                </div>
                <div className='input-group'>
                    <label htmlFor="name">Geo Diğer</label>
                    <InputText id="name" value={selectedFeature[0].geodigerid || ''} />
                </div>
                <div className='input-group'>
                    <label htmlFor="name">Düşey Güzergah</label>
                    <InputText id="name" value={selectedFeature[0].geoduseyguzergahid || ''} />
                </div>
                <div className='input-group'>
                    <label htmlFor="name">Görüş Engel</label>
                    <InputText id="name" value={selectedFeature[0].goruseengelcisimid || ''} />
                </div>
                <div className='input-group'>
                    <label htmlFor="name">Hız Limiti</label>
                    <InputText id="name" value={selectedFeature[0].hizlimiti || ''} />
                </div>
                <div className='input-group'>
                    <label htmlFor="name">İşaret Levhası</label>
                    <InputText id="name" value={selectedFeature[0].isaretlevhasi || ''} />
                </div>
                <div className='input-group'>
                    <label htmlFor="name">Kaldırım</label>
                    <InputText id="name" value={selectedFeature[0].kaldirim || ''} />
                </div>
                <div className='input-group'>
                    <label htmlFor="name">Kaplama</label>
                    <InputText id="name" value={selectedFeature[0].kaplama || ''} />
                </div>
                <div className='input-group'>
                    <label htmlFor="name">Kavşak</label>
                    <InputText id="name" value={selectedFeature[0].kavsakid || ''} />
                </div>
            </div>

            <div className="right-group">
                <div className='input-group'>
                    <label htmlFor="name">Otokorkuluk</label>
                    <InputText id="name" value={selectedFeature[0].otokorkuluk || ''} />
                </div>
                <div className='input-group'>
                    <label htmlFor="name">Şerit Çizgisi</label>
                    <InputText id="name" value={selectedFeature[0].seritcizgisi || ''} />
                </div>
                <div className='input-group'>
                    <label htmlFor="name">Trafik Görevlisi</label>
                    <InputText id="name" value={selectedFeature[0].trafikgorevlisi || ''} />
                </div>
                <div className='input-group'>
                    <label htmlFor="name">Trafik Lambası</label>
                    <InputText id="name" value={selectedFeature[0].trafiklambasi || ''} />
                </div>
                <div className='input-group'>
                    <label htmlFor="name">Yaya Geçidi</label>
                    <InputText id="name" value={selectedFeature[0].yayagecidioz || ''} />
                </div>
                <div className='input-group'>
                    <label htmlFor="name">Yol Adı</label>
                    <InputText id="name" value={selectedFeature[0].yoladi || ''} />
                </div>
                <div className='input-group'>
                    <label htmlFor="name">Yolda Çalışma</label>
                    <InputText id="name" value={selectedFeature[0].yoldacalisma || ''} />
                </div>
                <div className='input-group'>
                    <label htmlFor="name">Platform Genişliği</label>
                    <InputText id="name" value={selectedFeature[0].yolplatformgenisligi || ''} />
                </div>
                <div className='input-group'>
                    <label htmlFor="name">Şerit Genişliği</label>
                    <InputText id="name" value={selectedFeature[0].yolseritgenisligi || ''} />
                </div>
                <div className='input-group'>
                    <label htmlFor="name">Şerit Çizgisi</label>
                    <InputText id="name" value={selectedFeature[0].seritcizgisi || ''} />
                </div>
                <div className='input-group'>
                    <label htmlFor="name">Şerit Sayısı</label>
                    <InputText id="name" value={selectedFeature[0].yolseritsayisi || ''} />
                </div>
                <div className='input-group'>
                    <label htmlFor="name">Yol Sorunu</label>
                    <InputText id="name" value={selectedFeature[0].yolsorunu || ''} />
                </div>
                <div className='input-group'>
                    <label htmlFor="name">Yol Sorunu İşareti</label>
                    <InputText id="name" value={selectedFeature[0].yolsorunuisareti || ''} />
                </div>
            </div>

        </div>
    )
}
