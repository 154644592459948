import './DateFilter.scss'
import { useEffect, useState } from 'react'
import { Calendar } from 'primereact/calendar';
import { Menu } from 'primereact/menu';
import { setActiveDateId, setDate, setDateInfo } from '../state/features/elasticSlice';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../state/hooks';

const DateFilter = () => {
  const dispatch = useDispatch();
  const activeDateId = useAppSelector(state => state.elastic.activeDateId)
  const dateRangeInfo = useAppSelector(state => state.elastic.dateRange)
  const [dateRange, setDateRange] = useState<Date | Date[] | any>([]);
  const [activeId, setActiveId] = useState<any>(0);
  const [activeDate, setActiveDate] = useState<any>({ id: 3, label: 'Son 1 hafta', command: (e: any) => onClickMenu(e), className: `.p-menu .p-menuitem-link ${activeId === 3 ? 'active-date' : ''}` });
  const items: any = [
    // { id: 1, label: 'Son 1 gün', command: (e: any) => onClickMenu(e), className: `.p-menu .p-menuitem-link ${activeId === 1 ? 'active-date' : ''}` },
    { id: 2, label: 'Son 3 gün', command: (e: any) => onClickMenu(e), className: `.p-menu .p-menuitem-link ${activeId === 2 ? 'active-date' : ''}` },
    { id: 3, label: 'Son 1 hafta', command: (e: any) => onClickMenu(e), className: `.p-menu .p-menuitem-link ${activeId === 3 ? 'active-date' : ''}` },
    { id: 4, label: 'Son 1 ay', command: (e: any) => onClickMenu(e), className: `.p-menu .p-menuitem-link ${activeId === 4 ? 'active-date' : ''}` },
    { id: 5, label: 'Son 3 ay', command: (e: any) => onClickMenu(e), className: `.p-menu .p-menuitem-link ${activeId === 5 ? 'active-date' : ''}` },
    { id: 6, label: 'Son 6 ay', command: (e: any) => onClickMenu(e), className: `.p-menu .p-menuitem-link ${activeId === 6 ? 'active-date' : ''}` },
    { id: 7, label: 'Son 9 ay', command: (e: any) => onClickMenu(e), className: `.p-menu .p-menuitem-link ${activeId === 7 ? 'active-date' : ''}` },
    { id: 8, label: 'Son 1 yıl', command: (e: any) => onClickMenu(e), className: `.p-menu .p-menuitem-link ${activeId === 8 ? 'active-date' : ''}` },
    { id: 9, label: 'Son 3 yıl', command: (e: any) => onClickMenu(e), className: `.p-menu .p-menuitem-link ${activeId === 9 ? 'active-date' : ''}` },
    { id: 10, label: 'Son 5 yıl', command: (e: any) => onClickMenu(e), className: `.p-menu .p-menuitem-link ${activeId === 10 ? 'active-date' : ''}` },
  ];

  useEffect(() => {
    let postDate: any
    if (dateRange.length === 0 && activeId === 0) {
      setActiveId(3)
    } else if (dateRange.length === 0 && activeId === 3) {
      setActiveId(activeDate.id)
    } else if (dateRange.length !== 0) {
      setActiveId(0)
      setActiveDate({})
      if (dateRange[0] !== null && dateRange[1] !== null) {
        postDate = `${formatDate(dateRange[0]) + ':' + formatDate(dateRange[1])}`
        dispatch(setDate(postDate))
        const firstDate = formatDate(dateRange[0]).split('-').reverse();
        const lastDate = formatDate(dateRange[1]).split('-').reverse();
        const first = firstDate.join('-');
        const last = lastDate.join('-');
        dispatch(setDateInfo(`${first + '  ' + last}`))
      }
    }
  }, [dateRange])


  // Tarih bazlı istatistik sayfasından girilen değerler varsa onu okuyor ve bu component içerisinde yer alan tarih seçim işlemini tetiklettiriyor
  useEffect(() => {

    if (dateRangeInfo.length > 1) {
      const dateRangeValue = `${dateRangeInfo[0]}:${dateRangeInfo[1]}`
      const firstDate = formatDate(dateRangeInfo[0]).split('-').reverse();
      const lastDate = formatDate(dateRangeInfo[1]).split('-').reverse();
      const first = firstDate.join('-');
      const last = lastDate.join('-');

      dispatch(setDateInfo(`${first + '  ' + last}`))
      dispatch(setDate(dateRangeValue))
      setActiveId(0)
      setActiveDate({})
    }
  }, [dateRangeInfo])

  useEffect(() => {
    if (activeDateId === 3) {
      setActiveId(3)
      setActiveDate({ id: 3, label: 'Son 1 hafta', command: (e: any) => onClickMenu(e), className: `.p-menu .p-menuitem-link ${activeId === 3 ? 'active-date' : ''}` })
    } else {
      setActiveId(activeId)
      setActiveDate(activeDate)
    }
  }, [activeDateId])

  useEffect(() => {
    if (activeDate.id) {
      setDateRange([])
      let postDate: any
      let firstDate = new Date();
      let lastDate = new Date();

      switch (activeDate.id) {
        // case 1:
        //   firstDate.setDate(lastDate.getDate() - 1)
        //   postDate = `${formatDate(firstDate) + ':' + formatDate(lastDate)}`
        //   console.log("bumu seçildi")
        //   break;
        case 2:
          firstDate.setDate(lastDate.getDate() - 3)
          postDate = `${formatDate(firstDate) + ':' + formatDate(lastDate)}`
          break;
        case 3:
          firstDate.setDate(lastDate.getDate() - 7)
          postDate = `${formatDate(firstDate) + ':' + formatDate(lastDate)}`
          break;
        case 4:
          firstDate.setMonth(lastDate.getMonth() - 1)
          postDate = `${formatDate(firstDate) + ':' + formatDate(lastDate)}`
          break;
        case 5:
          firstDate.setMonth(lastDate.getMonth() - 3)
          postDate = `${formatDate(firstDate) + ':' + formatDate(lastDate)}`
          break;
        case 6:
          firstDate.setMonth(lastDate.getMonth() - 6)
          postDate = `${formatDate(firstDate) + ':' + formatDate(lastDate)}`
          break;
        case 7:
          firstDate.setMonth(lastDate.getMonth() - 9)
          postDate = `${formatDate(firstDate) + ':' + formatDate(lastDate)}`
          break;
        case 8:
          firstDate.setFullYear(lastDate.getFullYear() - 1)
          postDate = `${formatDate(firstDate) + ':' + formatDate(lastDate)}`
          break;
        case 9:
          firstDate.setFullYear(lastDate.getFullYear() - 3)
          postDate = `${formatDate(firstDate) + ':' + formatDate(lastDate)}`
          break;
        case 10:
          firstDate.setFullYear(lastDate.getFullYear() - 5)
          postDate = `${formatDate(firstDate) + ':' + formatDate(lastDate)}`
          break;
        default:
          break;
      }
      dispatch(setDate(postDate))
      dispatch(setDateInfo(activeDate.label))
      dispatch(setActiveDateId(activeDate.id))
    }
  }, [activeDate.id])

  function formatDate(date: any) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }

  const onClickMenu = (e: any) => {
    setDateRange([])
    setActiveId(e.item.id)
    setActiveDate(e.item)
    if (dateRange.length > 0) {
      setDateRange([])
      setActiveId(e.item.id)
      setActiveDate(e.item)
    }
  }

  return (
    <div className='DateFilter'>
      <Menu model={items} />
      <Calendar
        className={`.p-calendar .p-inputtext ${dateRange.length !== 0 ? 'active-calendar' : ''}`}
        id='range'
        selectionMode="range"
        value={dateRange.length > 0 ? dateRange : undefined}
        onChange={(e: any) => setDateRange(e.value)}
        readOnlyInput
        placeholder='Bir Tarih Aralığını Seçin'>
      </Calendar>
    </div>
  )
}

export default DateFilter