import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useEffect, useRef, useState } from "react";
import { UtilRestApi } from "../../utils/restapi/util";
import { useAppSelector } from "../state/hooks";

export const Vehicle = () => {
    const dt = useRef<any>(null);
    const selectedFeature = useAppSelector(state => state.info.selectedFeature);
    const [properties, setProperties] = useState([]);
    const [rows, setRows] = useState(10);
    const [first, setFirst] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getPeopleDetails()
    }, [selectedFeature[0].kazaid])

    const getPeopleDetails = () => {
        setLoading(true)

           UtilRestApi.geoserverWfsReq(`maxFeatures=${rows}&startIndex=${first}&version=1.0.0&request=GetFeature&typeName=usr1_ag_arac&outputFormat=application%2Fjson&CQL_FILTER=kazaid='${selectedFeature[0].kazaid}'`)
            .then(res => {
                const propertyArr = res.data.features.map((feature: any) => feature.properties)
                const totalCount = res.data.totalFeatures
                setProperties(propertyArr)
                setTotalRecords(totalCount ? totalCount : propertyArr.length);
                setLoading(false)
            })
    }

    const onPage = (event: any) => {
        setRows(event.rows);
        setFirst(event.first);
    }

    const totalRecordsTemplate = <div>Veri Sayısı : {totalRecords}</div>

    return (
        <>
            <DataTable
                ref={dt}
                className='info-table'
                value={properties}
                filterDisplay="menu"
                size="small"
                selectionMode="single"
                responsiveLayout="scroll"
                resizableColumns
                stripedRows
                lazy
                paginator
                first={first}
                rows={rows}
                onPage={onPage}
                totalRecords={totalRecords}
                paginatorLeft={totalRecordsTemplate}
                paginatorPosition='top'
                loading={loading}

            >
                <Column header='Cinsi' field='cinsi' />
                <Column header='Kullanım Amacı' field='kullanimamaci' />
                <Column header='Yakıt Türü' field='yakitturu' />
                <Column header='Darbeyi Aldığı Bölüm' field='darbeyialdigibolum' />
                <Column header='Yaya Hareketi' field='yayahareketiack' />
                <Column header='Kazada Hareket' field='kazadahareket' />
                <Column header='Hasar Derecesi' field='hasarderecesi' />
                <Column header='Araç Kusuru 1' field='arackusuru1' />
                <Column header='Araç Kusuru 2' field='arackusuru2' />
            </DataTable>
        </>
    )
}
