import './EditUserPassword.scss';
import { useEffect, useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { useDispatch } from 'react-redux';
import { setEditUserPassword } from '../state/features/userSlice';
import { Toast } from 'primereact/toast';
import { Password } from 'primereact/password';
import { IUser } from '../../utils/model';
import { RoleRestApi } from '../../utils/restapi/role';
import { setJsonToken, setToken } from '../state/features/loginSlice';
import { useNavigate } from 'react-router-dom';
import { setActiveButtonType, setActiveButtonTypeRight, setisKKNActiveButton } from '../state/features/buttonStateSlice';
import { ActiveButtonState, ActiveButtonStateRight } from '../shared/button-state/ButtonState';

const EditUserPassword = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [visible, setVisible] = useState(true);
    const [isConfirmOn, setIsConfirmOn] = useState(false);
    const [user, setUser] = useState<IUser>({ old_password: "", password: "", confirmPassword: "" });
    const toast = useRef<any>();
    const [passwordInput, setPasswordInput] = useState({
        old_password: '',
        password: '',
        confirmPassword: ''
    });
    const [passwordError, setPasswordErr] = useState<any>("");
    const [proceed, setProceed] = useState<any>(false);
    const [confirmPasswordError, setConfirmPasswordError] = useState<any>("");
    const [confirmOldPasswordError, setConfirmOldPasswordError] = useState<any>("");

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        try {
            await RoleRestApi.editUserPassword({
                password: user.old_password,
                newPassword: passwordInput.password,
            }).then(() => {
                setIsConfirmOn(true);
            }).catch((err: any) => {
                setIsConfirmOn(false);
                toast.current.show({ severity: 'error', summary: 'Başarısız', detail: err.response.data.message, life: 3000 });
            });
        } catch (error) {
            setIsConfirmOn(false);
            toast.current.show({ severity: 'error', summary: 'Başarısız', detail: "Şifre Değiştirme İşlemi Başarısız", life: 3000 });
        }
    };

    useEffect(() => {
        setProceed(!(user.old_password.trim().length === 0 || user.password.trim().length === 0 || user.confirmPassword?.trim().length === 0));
    }, [user]);

    const onHide = () => {
        setVisible(false);
        dispatch(setEditUserPassword(false));
    };

    const handlePasswordChange = (evnt: any) => {
        const passwordInputValue = evnt.target.value.trim();
        const passwordInputFieldName = evnt.target.name;
        setPasswordInput({ ...passwordInput, [passwordInputFieldName]: passwordInputValue });
    };

    const handleChangeForPassword = (event: any) => {
        handlePasswordChange(event);
        setProceed(false);
        setUser({ ...user, [event.target?.name]: event.target?.value });

        if (event.target.name === 'password') {
            validatePassword(event.target.value);
        }

        if (event.target.name === 'old_password') {
            validateOldPassword(event.target.value);
        }

        if (event.target.name === "confirmPassword" || (event.target.name === "password" && passwordInput.confirmPassword.length > 0)) {
            validateConfirmPassword(event.target.value);
        }
    };

    const validatePassword = (passwordInputValue: string) => {
        const uppercaseRegExp = /(?=.*?[A-Z])/;
        const lowercaseRegExp = /(?=.*?[a-z])/;
        const digitsRegExp = /(?=.*?[0-9])/;
        const specialCharRegExp = /(?=.*?[#?!@$%^&*/()[\]\-+=\\"',.<>{}~;:_])/;
        const minLengthRegExp = /.{10,}/;

        // Password validation logic
        const passwordLength = passwordInputValue.length;
        const uppercasePassword = uppercaseRegExp.test(passwordInputValue);
        const lowercasePassword = lowercaseRegExp.test(passwordInputValue);
        const digitsPassword = digitsRegExp.test(passwordInputValue);
        const specialCharPassword = specialCharRegExp.test(passwordInputValue);
        const minLengthPassword = minLengthRegExp.test(passwordInputValue);

        if (passwordLength === 0) {
            setPasswordErr("Şifre alanı boş olamaz!");
        } else if (!uppercasePassword) {
            setPasswordErr("En az bir büyük harf gerekmektedir.");
        } else if (!lowercasePassword) {
            setPasswordErr("En az bir küçük harf gerekmektedir.");
        } else if (!digitsPassword) {
            setPasswordErr("En az bir rakam gerekmektedir.");
        } else if (!specialCharPassword) {
            setPasswordErr("En az bir özel karakter gerekmektedir.");
        } else if (!minLengthPassword) {
            setPasswordErr("Minimum 10 karakter gerekmektedir.");
        } else if (passwordInputValue.includes(" ")) {
            setPasswordErr("Parolanızda boşluk olamaz!");
        } else {
            setPasswordErr("Şifre Uygun");
        }
    };

    const validateOldPassword = (oldPasswordInputValue: string) => {

        const passwordLength = oldPasswordInputValue.length;

        if (passwordLength === 0) {
            setConfirmOldPasswordError("Eski şifre alanı boş olamaz!");
        } else {
            setConfirmOldPasswordError("");
            setUser({ ...user, old_password: oldPasswordInputValue });
        }

    };

    const validateConfirmPassword = (confirmPasswordInputValue: string) => {
        if (confirmPasswordInputValue !== passwordInput.password) {
            setConfirmPasswordError("Şifre tekrarı eşleşmiyor");
            setProceed(false);
        } else {
            setConfirmPasswordError("");
            setUser({ ...user, confirmPassword: confirmPasswordInputValue });
        }
    };

    useEffect(() => {
        // Logic for enabling/disabling the submit button
        if ((passwordError !== "Şifre Uygun" && passwordError !== "") || confirmPasswordError !== "" || confirmOldPasswordError !== "" || passwordInput.old_password === "" || passwordInput.password === "" || passwordInput.confirmPassword === "") {
            setProceed(false);
        } else {
            setProceed(true);
        }
    }, [passwordError, confirmPasswordError, confirmOldPasswordError, passwordInput]);

    useEffect(() => {
        // Logic for checking if password and confirmPassword match
        if (passwordInput.password === passwordInput.confirmPassword) {
            setConfirmPasswordError("");
        }
    }, [passwordInput.confirmPassword, passwordInput.password]);

    const accept = () => {
        setIsConfirmOn(false);
        dispatch(setEditUserPassword(false));
        dispatch(setActiveButtonType(ActiveButtonState.DEFAULT));
        dispatch(setActiveButtonTypeRight(ActiveButtonStateRight.DEFAULT));
        localStorage.removeItem("ag_token");
        dispatch(setToken(null));
        dispatch(setJsonToken(null));
        dispatch(setisKKNActiveButton(false));
        navigate("login");
    };

    const footerContent = (
        <div className='button-proceed'>
            <Button label="Tamam" onClick={accept} autoFocus />
        </div>
    );

    return (
        <div className='EditUserPassword'>
            <Dialog visible={visible} header={"Parola Güncelle"} onHide={onHide}>
                <form onSubmit={handleSubmit}>
                    <div className='container'>
                        <div className='p-field'>
                            <div className="input-group">
                                <div className='data-section'>
                                    <label className='p-d-block'>Eski Parolanız *</label>
                                    <Password
                                        type='password'
                                        className={confirmOldPasswordError !== "" ? 'p-invalid old-pass' : 'p-valid old-pass'}
                                        autoComplete="old-password"
                                        name="old_password"
                                        required
                                        toggleMask
                                        feedback={false}
                                        value={user?.old_password || ""}
                                        onChange={(e) => handleChangeForPassword(e)}
                                    />
                                </div>
                                <div className='message-section'>
                                    {confirmOldPasswordError !== "" && <p className="text-danger">{confirmOldPasswordError}</p>}
                                </div>
                            </div>
                        </div>
                        <div className='p-field'>
                            <div className='input-group'>
                                <div className='data-section'>
                                    <label className='p-d-block'>Yeni Parolanız *</label>
                                    <Password
                                        type='password'
                                        className={
                                            passwordError !== "Şifre Uygun" && passwordError !== "" ? 'p-invalid' :
                                                passwordError === "Şifre Uygun" ? 'p-valid valid-password' : ''
                                        }
                                        autoComplete="new-password"
                                        name="password"
                                        required
                                        toggleMask
                                        feedback={false}
                                        value={passwordInput.password}
                                        onChange={(e) => handleChangeForPassword(e)}
                                    />
                                </div>
                                <div className='message-section'>
                                    {(passwordError !== "Şifre Uygun" && passwordError !== "") && <p className="text-danger">{passwordError}</p>}
                                    {passwordError === "Şifre Uygun" && <p className="text-success">Şifre Uygun</p>}
                                </div>
                            </div>
                        </div>
                        <div className='p-field'>
                            <div className='input-group'>
                                <div className='data-section'>
                                    <label className='p-d-block'>Yeni Parola Tekrarı *</label>
                                    <Password
                                        type='password'
                                        className={confirmPasswordError !== "" ? 'p-invalid' : 'p-valid'}
                                        autoComplete="confirm-password"
                                        name="confirmPassword"
                                        toggleMask
                                        feedback={false}
                                        required
                                        value={passwordInput.confirmPassword}
                                        onChange={(e) => handleChangeForPassword(e)}
                                    />
                                </div>
                                <div className='message-section'>
                                    {confirmPasswordError !== "" && <p className="text-danger">{confirmPasswordError}</p>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='confirm-password-buttons'>
                        <Button label='İptal' onClick={onHide} style={{ "marginRight": "10px" }} />
                        <Button type='submit' disabled={!proceed} label='Kaydet' style={{ "background": "#286688", color: "white", "border": "0px solid white" }} />
                    </div>
                </form>
                <Toast ref={toast} position="bottom-center" style={{ zIndex: "9 !important" }} />
            </Dialog>
            {isConfirmOn &&
                <Dialog visible={isConfirmOn} onHide={accept} header="Şifre Değiştirildi" footer={footerContent}>
                    <p> Parolanız değiştirilmiştir, lütfen sisteme tekrar giriş sağlayınız. </p>
                </Dialog>}
        </div>
    );
};

export default EditUserPassword;
