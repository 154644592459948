import "./Statistic2.scss";
import { useRef, useEffect } from "react";
import { init, getInstanceByDom } from "echarts";
import type { CSSProperties } from "react";
import type { EChartsOption, ECharts, SetOptionOpts } from "echarts";
import { useAppSelector } from "../state/hooks";
import * as echarts from "echarts";
// import axios from "axios";
import { UtilRestApi } from "../../utils/restapi/util";
// import { ProgressSpinner } from "primereact/progressspinner";

export interface ReactEChartsProps {
  option?: EChartsOption;
  style?: CSSProperties;
  settings?: SetOptionOpts;
  loading?: boolean;
  theme?: "light" | "dark";
}

const StatisticsByProvince = ({
  style,
  settings,
  loading,
  theme,
}: ReactEChartsProps) => {
  const extent = useAppSelector((state) => state.maps.extent);
  const imageRequest = useAppSelector((state) => state.statistic.imageRequest);
  // const [progressSpinner, setprogressSpinner] = useState<any>(true)
  const barRace = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let chart: ECharts | undefined;
    if (barRace.current !== null) {
      chart = init(barRace.current, theme);
    }

    // Return cleanup function
    return () => {
      chart?.dispose();
    };
  }, [theme]);

  //Yıllara Göre İllerin Trafik Kaza İstatistikleri İsteği Burada Geoserver'a Yapılıyor
  useEffect(() => {
    const countryColors: any = {
      İstanbul: "#030D4F",
      İzmir: "#f00",
      Ankara: "#ffde00",
      Antalya: "#002a8f",
      Bursa: "#003580",
      Mersin: "#ed2939",
      Konya: "#000",
      Muğla: "#003897",
      Adana: "#f93",
      Hatay: "purple",
      Aydın: "#024fa2",
      Giresun: "black",
      Şanlıurfa: "#00247d",
      Afyonkarahisar: "#ef2b2d",
      Kocaeli: "#dc143c",
      Sivas: "#d52b1e",
      Gaziantep: "green",
      Edirne: "#00247d",
      Diyarbakır: "#b22234",
      Kayseri: "#FFA500",
      Manisa: "#808080",
    };
    const updateFrequency = 2000;
    const dimension = 0;
    let chartDom = document.getElementById("province-chart") as HTMLElement;
    var myChart = echarts.init(chartDom);

    // setprogressSpinner(true)

    if (extent && imageRequest === false) {
      myChart.dispose();
      UtilRestApi.geoserverWfsReq(
        `version=1.0.0&bbox=${extent.join(
          ","
        )},EPSG:3857&request=GetFeature&typeName=trafik%3Akazasay_yil&outputFormat=application%2Fjson`
      ).then((res) => {

        // if(Object.keys(res.data).length===0 || res.data===undefined){
        //   setprogressSpinner(true)
        // }

        myChart = echarts.init(chartDom);

        if (true) {
          let exampleCityData = res.data;

          let optionGeneralx: any;
          const years: any = [];

          for (let i = 0; i < exampleCityData.features.length; ++i) {
            if (
              years.length === 0 ||
              years[years.length - 1] !==
              exampleCityData.features[i].properties.yil
            ) {
              years.push(exampleCityData.features[i].properties.yil);
            }
          }

          let startIndex = -1;
          let startYear = years[startIndex];

          optionGeneralx = {
            grid: {
              top: 10,
              bottom: 30,
              left: 150,
              right: 80,
            },
            xAxis: {
              max: "dataMax",
              axisLabel: {
                formatter: function (n: any) {
                  return Math.round(n) + "";
                },
              },
            },

            dataset: {
              source: exampleCityData.features.filter(function (d: any) {
                let val = [];
                if (d.properties.yil === startYear) {
                  val.push([
                    d.properties.kaza_say,
                    d.properties.ad,
                    d.properties.yil,
                  ]);
                  d = val;
                  return d.yil === startYear;
                }
                return false;
              }),
            },

            yAxis: {
              type: "category",
              inverse: true,
              max: 16,
              axisLabel: {
                show: true,
                fontSize: 14,
                formatter: function (value: any) {
                  return value;
                },
                rich: {
                  flag: {
                    fontSize: 25,
                    padding: 5,
                  },
                },
              },
              animationDuration: 300,
              animationDurationUpdate: 300,
            },
            series: [
              {
                realtimeSort: true,
                seriesLayoutBy: "column",
                type: "bar",
                itemStyle: {
                  color: function (param: any) {
                    return countryColors[param.value[1]] || "#5470c6";
                  },
                },
                encode: {
                  x: dimension,
                  y: 3,
                },
                label: {
                  show: true,
                  precision: 1,
                  position: "right",
                  valueAnimation: true,
                  fontFamily: "monospace",
                },
              },
            ],
            // Disable init animation.
            animationDuration: 0,
            animationDurationUpdate: updateFrequency,
            animationEasing: "linear",
            animationEasingUpdate: "linear",
            graphic: {
              elements: [
                {
                  type: "text",
                  right: 160,
                  bottom: 60,
                  style: {
                    text: startYear,
                    font: "bolder 80px monospace",
                    fill: "rgba(100, 100, 100, 0.25)",
                  },
                  z: 100,
                },
              ],
            },
          };

          myChart.setOption(optionGeneralx);

          for (let i = startIndex; i < years.length - 1; ++i) {
            (function (i) {
              if ((i - startIndex) * updateFrequency === 0) {
                let firstFrequency = 1000;
                setTimeout(function () {
                  updateYear(years[i + 2]);
                }, firstFrequency);
              }
              setTimeout(function () {
                updateYear(years[i + 1]);
              }, (i - startIndex) * updateFrequency);
            })(i);
          }
          const updateYear = function (year: any) {
            let val = [];
            let source = exampleCityData.features.filter(function (d: any) {
              return d.properties.yil === year;
            });

            for (const src of source) {
              val.push([
                src.properties.kaza_say,
                src.properties.ad,
                src.properties.yil,
              ]);
            }

            optionGeneralx.series[0].data = val;
            optionGeneralx.graphic.elements[0].style.text = year;
            myChart.setOption(optionGeneralx);

            // setprogressSpinner(false)
          };

          if (barRace.current !== null) {
            const chart = getInstanceByDom(barRace.current);
            chart?.setOption(optionGeneralx, settings);
            // setprogressSpinner(false)

          }
        }
      });
    }
    // setprogressSpinner(false)
  }, [extent, imageRequest, settings]);

  return (
    <div className="StatisticsByProvince">
      {/* {progressSpinner && <div className="province-loading">
                    <ProgressSpinner
                        className="progress-spinner"
                        strokeWidth="8"
                        fill="var(--surface-ground)"
                        animationDuration=".5s"

                    /></div>} */}
      <div className="chart-group-statistics">
        <h3>YILLARA GÖRE İLLERİN TRAFİK KAZA İSTATİSTİKLERİ</h3>
        <div
          id="province-chart"
          className="province-chart"
          style={{ height: "700px" }}
        />
      </div>
      <div className="description-group">
        <img className="zoom-icon" alt="" src="zoom.svg" />
        <h5>
          Detaylı İstatistik İçin Harita Üzerine Yakınlaşmanız Gerekmektedir.
        </h5>
      </div>
    </div>
  );
};
export default StatisticsByProvince;
