import { createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  visible: false,
  tableProgressVisibility: false,
  kknProgressVisibility: false,
  goToLocation: false,
  coordContainerVisibility: false,
};

export const sharedSlice = createSlice({
  name: "sharedState",
  initialState,
  reducers: {
    //KKN oluştururken kullanılan progress
    setProgressVisibility: (state: any, action) => {
      state.visible = action.payload;
    },
    setTableProgressVisibility: (state: any, action) => {
      state.tableProgressVisibility = action.payload;
    },
    setDeleteKKNProgressVisibility: (state: any, action) => {
      state.kknProgressVisibility = action.payload;
    },
    setGoToLocation: (state: any, action) => {
      state.goToLocation = action.payload;
    },
    setCoordinateContainer: (state: any, action) => {
      state.coordContainerVisibility = action.payload;
    },
  },
});

export const {
  setProgressVisibility,
  setTableProgressVisibility,
  setDeleteKKNProgressVisibility,
  setGoToLocation,
  setCoordinateContainer,
} = sharedSlice.actions;

export default sharedSlice.reducer;
