import { createSlice } from "@reduxjs/toolkit";
import {
  ActiveButtonState,
  ActiveButtonStateRight,
} from "../../shared/button-state/ButtonState";

const initialState: any = {
    activeButtonType: ActiveButtonState.DEFAULT,
    activeButtonTypeRight: ActiveButtonStateRight.DEFAULT,
    isKKNActiveButton: false
}

export const buttonStateSlice = createSlice({
    name: 'buttonState',
    initialState,
    reducers: {
        setActiveButtonType: (state: any, action) => {
            state.activeButtonType = action.payload;
        },
        setActiveButtonTypeRight: (state: any, action) => {
            state.activeButtonTypeRight = action.payload;
        },
        setisKKNActiveButton: (state: any, action) => {
            state.isKKNActiveButton = action.payload;
        }
    },
})

export const {
    setActiveButtonType,
    setActiveButtonTypeRight,
    setisKKNActiveButton,
  } = buttonStateSlice.actions;

export default buttonStateSlice.reducer