import Draw from "ol/interaction/Draw";
import { useContext, useEffect, useState } from "react";
import { MapContext } from "../MapContext";
import { Circle as CircleStyle, Fill, Stroke, Style } from "ol/style";
import Text from "ol/style/Text";
import { never } from "ol/events/condition";
import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import { Feature } from "ol";
import { useDispatch } from "react-redux";
import { setShape } from "../../state/features/elasticSlice";
import {
  setShapeState,
} from "../../state/features/zoomStateSlice";

export const DrawPolygon = () => {
  const dispatch = useDispatch();
  const map = useContext(MapContext);
  const [source, setsource] = useState(new VectorSource())

  useEffect(() => {
    const vector = new VectorLayer({
      source,
      zIndex: 100000000000,
      style: styleFunc as any,
      visible: true,
    });

    map.addLayer(vector);
    let draw = new Draw({
      source: source,
      type: "Polygon",
      stopClick: true,
      style: styleFunc as any,
      finishCondition: never,
      condition: function (e: any) {
        //sadece solla çizim yapıyor ve sağla bitiriyor
        if (e.originalEvent.buttons === 1) {
          return true;
        } else if (e.originalEvent.buttons === 2) {
          draw.finishDrawing();
          return false;
        } else {
          return false;
        }
      },
    });

    map.addInteraction(draw);
    drawEnd(draw);

    return () => {
      map.removeLayer(vector);
      map.removeInteraction(draw);
    };
  }, []);

  const drawEnd = (draw: Draw) => {
    draw.on("drawend", (event) => {
      map.removeInteraction(draw);
      const geometry = event.feature?.getGeometry();
      dispatch(setShape(geometry));
      dispatch(setShapeState(true));
    });
  };

  const styleFunc = (feature: Feature<any>): any => {
    let style;
    const geom = feature.getGeometry();
    const geomType = feature.getGeometry()?.getType();
    switch (geomType) {
      case "Point":
        // const translated = toLonLat(geom.getCoordinates())
        style = new Style({
          image: new CircleStyle({
            radius: 5,
            fill: new Fill({
              color: "#286688",
            }),
          }),
          text: new Text({
            // text: `${translated[0].toFixed(6)} , ${translated[1].toFixed(6)}`,
            font: "bold 15px 'Open Sans', 'Arial Unicode MS', 'sans-serif'",
            placement: "point",
            offsetY: -15,
            fill: new Fill({
              color: "white",
            }),
            stroke: new Stroke({
              color: "black",
              width: 3,
            }),
          }),
        });

        break;
      case "LineString":
        style = new Style({
          stroke: new Stroke({
            color: "#286688",
            width: 2,
          }),
          text: new Text({
            // text: `${geom.getLength().toFixed(2)} m`,
            font: "bold 15px 'Open Sans', 'Arial Unicode MS', 'sans-serif'",
            placement: "point",
            offsetY: -15,
            fill: new Fill({
              color: "white",
            }),
            stroke: new Stroke({
              color: "black",
              width: 3,
            }),
          }),
        });

        break;
      case "Polygon":
        style = new Style({
          fill: new Fill({
            color: "rgba(110, 193, 177, 0.28)",
          }),
          stroke: new Stroke({
            color: "#286688",
            width: 2,
          }),
          text: new Text({
            text: `${(geom.getArea() / 1000000).toFixed(2)} km²`,
            font: "bold 15px 'Open Sans', 'Arial Unicode MS', 'sans-serif'",
            placement: "point",
            offsetY: -15,
            fill: new Fill({
              color: "white",
            }),
            stroke: new Stroke({
              color: "black",
              width: 3,
            }),
          }),
        });
        break;
      default:
        break;
    }

    return style;
  }
  return (
    <></>
  )
}
