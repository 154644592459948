import { createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  kknResult: [],
  isMinimize: false,
};

export const kknSlice = createSlice({
  name: "kkn",
  initialState,
  reducers: {
    setKKNResult: (state: any, action) => {
      state.kknResult = action.payload;
    },
    setMinimizeKKNDialog: (state: any, action) => {
      state.isMinimize = action.payload;
    },
  },
});

export const { setKKNResult, setMinimizeKKNDialog } = kknSlice.actions;

export default kknSlice.reducer;
